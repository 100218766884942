import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FiltroControllerService {

  usersDashboard: any = [];

  usersTarefas: any = [];
  filtroStatusTarefas: any = 1;
  
  usersListNegocios: any = [];

  usersKanban: any = [];

  filtroCorretoras:any;

  filtroClientes:any;

  filtroListaNegocio:any = 0; 

  filtroCotacoes:any = 0;

  filtroDocumentos:any;

  constructor() { 
    this.verificaLocalStorage();    
  }

  verificaLocalStorage() {
    this.usersDashboard      = localStorage.getItem('UD') ? JSON.parse(localStorage.getItem('UD')) : this.usersDashboard;
    this.usersTarefas        = localStorage.getItem('UT') ? JSON.parse(localStorage.getItem('UT')) : this.usersTarefas;
    this.usersKanban         = localStorage.getItem('UK') ? JSON.parse(localStorage.getItem('UK')) : this.usersKanban;
    this.usersListNegocios   = localStorage.getItem('UN') ? JSON.parse(localStorage.getItem('UN')) : this.usersListNegocios;
    this.filtroStatusTarefas = localStorage.getItem('filtroTarefas') ? JSON.parse(localStorage.getItem('filtroTarefas')) : this.filtroStatusTarefas;
    this.filtroClientes      = localStorage.getItem('filtroClientes') ? JSON.parse(localStorage.getItem('filtroClientes')) : this.filtroClientes;
    this.filtroCotacoes      = localStorage.getItem('filtroCotacoes') ? localStorage.getItem('filtroCotacoes') : 0;
    this.filtroListaNegocio  = localStorage.getItem('filtroListaNegocio') ? localStorage.getItem('filtroListaNegocio') : 0;
    this.filtroCorretoras    = localStorage.getItem('filtroCorretoras') ? JSON.parse(localStorage.getItem('filtroCorretoras')) : null;
    this.filtroDocumentos    = localStorage.getItem('filtroDocumentos') ? JSON.parse(localStorage.getItem('filtroDocumentos')) : null;
  }


  addUser(userId, array) {
    let verifica = this.verificaDuplicado(userId, array);
    if(verifica === true) {
      if(array === 'dashboard') {
        this.usersDashboard.push(userId);
        this.usersDashboard = [...new Set(this.usersDashboard)];
        localStorage.setItem('UD', JSON.stringify(this.usersDashboard));
      } else if(array === 'tarefas') {
        this.usersTarefas.push(userId);
        this.usersTarefas = [...new Set(this.usersTarefas)];
        localStorage.setItem('UT', JSON.stringify(this.usersTarefas));
      } else if(array === 'kanban') {
        this.usersKanban.push(userId);
        this.usersKanban = [...new Set(this.usersKanban)];
        localStorage.setItem('UK', JSON.stringify(this.usersKanban));
      } else if(array === 'listaNegocios') {
        this.usersListNegocios.push(userId);
        this.usersListNegocios = [...new Set(this.usersListNegocios)];
        localStorage.setItem('UN', JSON.stringify(this.usersListNegocios));
      }
    } else {
      console.log('duplicado \n', userId);
    }
  }

  removeUser(userId, array) {
    if(array === 'dashboard') {
      let index = this.usersDashboard.findIndex(u => u == userId);
      this.usersDashboard.splice(index, 1);
      localStorage.removeItem('UD');
      localStorage.setItem('UD', JSON.stringify(this.usersDashboard));
    } else if(array === 'tarefas') {
      let index = this.usersTarefas.findIndex(u => u == userId);
      this.usersTarefas.splice(index, 1);
      localStorage.removeItem('UT');        
      localStorage.setItem('UT', JSON.stringify(this.usersTarefas));
    } else if(array === 'kanban') {
      let index = this.usersKanban.findIndex(u => u == userId);
      this.usersKanban.splice(index, 1);
      localStorage.removeItem('UK');   
      localStorage.setItem('UK', JSON.stringify(this.usersKanban));
    } else if(array === 'listaNegocios') {
      let index = this.usersListNegocios.findIndex(u => u == userId);
      this.usersListNegocios.splice(index, 1);
      localStorage.removeItem('UN');   
      localStorage.setItem('UN', JSON.stringify(this.usersListNegocios));
    }
  }

  verificaDuplicado(userId, array) {
    if(array === 'dashboard') {
      if(this.usersDashboard.length === 0) {
        return true;
      } else {
        for(let user of this.usersDashboard) {
          if(user === userId) {
            return false;
          } else {
            return true;
          }
        }
      }
    } else if(array === 'tarefas') {
      if(this.usersTarefas.length === 0) {
        return true;
      } else {
        for(let user of this.usersTarefas) {
          if(user === userId) {
            return false;
          } else {
            return true;
          }
        }
      }
    } else if(array === 'kanban') {
      if(this.usersKanban.length === 0) {
        return true;
      } else {
        for(let user of this.usersKanban) {
          if(user === userId) {
            return false;
          } else {
            return true;
          }
        }
      }
    } else if(array === 'listaNegocios') {
      if(this.usersListNegocios.length === 0) {
        return true;
      } else {
        for(let user of this.usersListNegocios) {
          if(user === userId) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  }

  limparFiltro(array) {
    if(array === 'dashboard') {
      this.usersDashboard = [];
      localStorage.removeItem('UD');
    } else if(array === 'tarefas') {
      this.usersTarefas = [];
      localStorage.removeItem('UT');        
      this.filtroStatusTarefas = 1;
    } else if(array === 'kanban') {
      this.usersKanban = [];
      localStorage.removeItem('UK');   
    } else if(array === 'corretoras') {
      this.filtroCorretoras = null;
      localStorage.removeItem('filtroCorretoras');   
    } else if(array === 'clientes') {
      this.filtroClientes = null;
      localStorage.removeItem('filtroClientes');   
    } else if(array === 'cotacoes') {
      this.filtroCotacoes = 0;
      localStorage.removeItem('filtroCotacoes');   
    } else if(array === 'listaNegocios') {
      this.filtroListaNegocio = 0;
      this.usersListNegocios = [];
      localStorage.removeItem('UN');   
      localStorage.removeItem('filtroListaNegocio');
    } else if(array === 'corretoras') {
      this.filtroCorretoras = null;
      localStorage.removeItem('filtroCorretoras');
    } else if(array === 'documentos') {
      this.filtroDocumentos = null;
      localStorage.removeItem('filtroDocumentos');
    }

  }

  addFiltrosExtras(tela,filtro) {
    if(tela === 'tarefas') {
      this.filtroStatusTarefas = filtro;
      localStorage.setItem('filtroTarefas', this.filtroStatusTarefas);
    } else if(tela === 'clientes') {
      this.filtroClientes = {
        ativo: filtro.ativo,
        creationPeriodBegin: filtro.creationPeriodBegin,
        creationPeriodEnd: filtro.creationPeriodEnd,
        dataFim: filtro.dataFim,
        dataIni: filtro.dataIni,
        favorito: filtro.favorito,
        filtroBusca: filtro.filtroBusca,
        limit: filtro.limit,
        orderBy: filtro.orderBy,
        page: filtro.page,
        periodo: filtro.periodo,
        rotuloPeriodo: filtro.rotuloPeriodo,
        updatePeriodBegin: filtro.updatePeriodBegin,
        updatePeriodEnd: filtro.updatePeriodEnd
      };
      localStorage.setItem('filtroClientes', JSON.stringify(this.filtroClientes));
    } else if(tela === 'listaNegocios') {
      this.filtroListaNegocio = filtro;
      localStorage.setItem('filtroListaNegocio', this.filtroListaNegocio);
    } else if(tela === 'cotacoes') {
      this.filtroCotacoes = filtro;
      localStorage.setItem('filtroCotacoes', this.filtroCotacoes);
    } else if(tela === 'documentos') {
      this.filtroDocumentos = filtro.values;
      localStorage.setItem('filtroDocumentos', JSON.stringify(this.filtroDocumentos));
    } else if(tela === 'corretoras') {
      this.filtroCorretoras = filtro.values;
      localStorage.setItem('filtroCorretoras', JSON.stringify(this.filtroDocumentos));
    }
  }

  clearFilterLS() {
    localStorage.removeItem('UD');
    localStorage.removeItem('UT');
    localStorage.removeItem('UK');
    localStorage.removeItem('UN');
    localStorage.removeItem('filtroTarefas');
    localStorage.removeItem('filtroClientes');
    localStorage.removeItem('filtroCotacoes');
    localStorage.removeItem('filtroListaNegocio');
    localStorage.removeItem('filtroCorretoras');
    localStorage.removeItem('temFiltroDocumentos');
    localStorage.removeItem('filtroDocumentos')

    this.usersDashboard = [];
    this.usersTarefas = [];
    this.filtroStatusTarefas = 1;
    this.usersKanban = [];
    this.usersListNegocios = [];
    this.filtroCorretoras = null;
    this.filtroClientes = null;
    this.filtroListaNegocio = 0; 
    this.filtroCotacoes = 0;
  }


  dashFiltroAtivo:boolean = false;
	tarefasFiltroAtivo:boolean = false;
	clientesFiltroAtivo:boolean = false;
	cotacoesFiltroAtivo:boolean = false;
	listaNegociosFiltroAtivo:boolean = false;
  kanbanFiltroAtivo:boolean = false;
  corretorasFiltroAtivo:boolean = false;
  temFiltroDocumentos:any;
  
  verificaFiltro() {
    if (location.pathname == '/dashboard') {
      this.dashFiltroAtivo = this.usersDashboard.length != 0 ? true : false;
		} else if (location.pathname == '/clientes') {
      this.clientesFiltroAtivo = this.filtroClientes === undefined || this.filtroClientes === null ? false : this.filtroClientes?.ativo != 'ativos' || this.filtroClientes?.favorito != 'todos'? true : false;
		} else if (location.pathname == '/lista-tarefas') {
      this.tarefasFiltroAtivo = this.filtroStatusTarefas != 1 || this.usersTarefas.length != 0 ? true : false;
		} else if (location.pathname == '/funil-de-vendas') {
      this.kanbanFiltroAtivo = this.usersKanban.length != 0 ? true : false;
		} else if (location.pathname == '/lista-negocios') {
      this.listaNegociosFiltroAtivo = this.filtroListaNegocio != 0 || this.usersListNegocios.length != 0 ? true : false;
		} else if (location.pathname == '/cotacoes') {
      this.cotacoesFiltroAtivo = this.filtroCotacoes != 0 ? true : false;
    } else if (location.pathname == '/corretoras') {
      this.corretorasFiltroAtivo = this.filtroCorretoras?.status != "" || this.filtroCorretoras?.teste != "" || this.filtroCorretoras?.diasCriacao != "" || this.filtroCorretoras?.diasSemUso != "" || this.filtroCorretoras?.situation != "" || this.filtroCorretoras?.plano != "" || this.filtroCorretoras?.dataIni != "" || this.filtroCorretoras?.dataFim != "" ? true : false;
    } else if(location.pathname == '/documentos') {
      this.temFiltroDocumentos = this.filtroDocumentos === null || this.filtroDocumentos === undefined ? false : this.filtroDocumentos?.dataIni === "" && this.filtroDocumentos?.dataFim === "" && this.filtroDocumentos?.seguradora === "" && this.filtroDocumentos?.periodoInv === "I" && this.filtroDocumentos?.tipoPeriodo === "E" ? false : true;
    }
  }

  // urlFiltroCorretoras;
  // montaFiltroCorretoras(dIni, dFin) {
  //   this.filtroCorretoras = {
  //     dataIni: dIni,
  //     dataFim: dFin,
  //   }
  //   this.urlFiltroCorretoras = `dateIni=${this.filtroCorretoras?.dataIni}&dateFim=${this.filtroCorretoras?.dataFim}`;
  //   localStorage.setItem('filtroCorretoras', JSON.stringify(this.filtroCorretoras));
  // }
}
