export class EventosModel {
    id?: String; // ao atualizar
    tipo?: number; //  2 - Anotacao ; 3 - Tarefa; 4 - Agendamento
    subTipo?: number;
    titulo?: String;
    descricao?: String;
    concluido?: boolean;
    cancelado?: String;
    dataHoraIni?: any;
    dataHorafim?: any;
    createdAt?: any;
    dataEncerramento?: any;
    status?: number; // 2 - concluido ; 3 - cancelado 
    negocioId?: String;
    observacaoStatus?: String;
    responsavelId?: String;
    eventoAnteriorId?: String;
    
}
export function getLabelSubTipo(subTipo){    
    if(subTipo == 1){
        return 'Telefonema'
    }else if(subTipo == 2){
        return 'Email'
    }else if(subTipo == 3){
        return 'Reunião'
    }else if(subTipo == 4){
        return 'Atividade'
    }else if(subTipo == 5){
        return 'Visita'
    }else if(subTipo == 6){
        return 'Almoço'
    }else if(subTipo == 7){
        return 'Jantar'
    }else{
        return ''
    }
}
export function getIconSubTipo(subTipo){
    if(subTipo == 1){
        return 'phone'
    }else if(subTipo == 2){
        return 'email'
    }else if(subTipo == 3){
        return 'account-group'
    }else if(subTipo == 4){
        return 'calendar-text-outline'
    }else if(subTipo == 5){
        return 'account-tie'
    }else if(subTipo == 6){
        return 'almoco'
    }else if(subTipo == 7){
        return 'jantar'
    }else{
        return ''
    }
}
/** SUBTIPO
 id: 1, label: 'Ligacao'
 id: 2, label: 'Email'
 id: 3, label: 'Reuniao'
 id: 4, label: 'Atividade'
 id: 5, label: 'Visita'
 id: 6, label: 'Almoço'
 id: 7, label: 'Jantar'
*/