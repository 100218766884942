import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalFormComponent } from './modal-form.component';
import { ClienteModule } from '../cliente.module';



@NgModule({
  declarations: [ModalFormComponent],
  imports: [
    CommonModule,
    ClienteModule
  ],
  exports: [ModalFormComponent]
})
export class ModalFormModule { }
