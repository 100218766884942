import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GerenciadorArquivosComponent, ModalConfirm } from './gerenciador-arquivos.component';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [GerenciadorArquivosComponent, ModalConfirm],
  imports: [
    CommonModule,
    FileUploadModule,
    ModulosDeFormulario,
    MatTooltipModule,
    MatListModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule
  ],
  exports: [GerenciadorArquivosComponent, ModalConfirm]
})
export class GerenciadorArquivosModule { }
