import { Component, Input, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
	selector: 'app-gerencia-arquivo-multi',
	templateUrl: './gerencia-arquivo-multi.component.html',
	styleUrls: ['./gerencia-arquivo-multi.component.scss']
})
export class GerenciaArquivoMultiComponent implements OnInit {

	@Input('title') title: string;
	@Input('configS3') configS3: any;
	@Input('extensions') extensions: any;
	@Input('listFiles') listFiles: boolean;
	@Input('singleFileName') singleFileName: string;
	@Input('sizeLimit') sizeLimit: number;

	anexos = [];
	anexosTemp = [];
	erroAnexos;
	contadorAnexosTemp = 0;
	keyCount = 0;
	extensao;
	prefix = "";
	showName;

	carregandoAnexos: boolean = false;
	mostrarAnexos: boolean = false;
	editandoAnexo: boolean = false;
	salvandoAnexo: boolean = false;
	showTitle: boolean = true;

	public uploader: FileUploader = new FileUploader({ url: 'URL' });

	constructor(
		private apiS3: ApiS3Service,
		public registerIcon: RegisterIconsService,
		private utilsService: UtilsService) {

		const icons = ['image', 'file-presentation-box', 'google-spreadsheet',
			'file-video-outline', 'file-document-box-outline', 'file-pdf',
			'folder-zip-outline', 'audiobook']
		registerIcon.registerIcons(icons);
	}

	ngOnInit() {
		if (this.singleFileName == "fechamento") {
			this.configS3.fileName = this.configS3.negocioIdIntegracao + ".pdf";
			this.prefix = this.configS3.path + this.configS3.fileName;
			this.showName = "proposta.pdf";
		}

		this.apiS3.setConfig(this.configS3)
			.then(this.listaAnexos.bind(this))
			.catch(function (e) { console.log(e) });
	}

	adicionaIcone(desc) {

		let tipoArquivo = this.verificaTipoArquivo(desc);

		let nomeIcone;

		switch (tipoArquivo) {
			case "imagem":
				nomeIcone = "folder-image"
				break;
			case "pdf":
				nomeIcone = "file-pdf"
				break;
			case "doc":
				nomeIcone = "file-document-outline"
				break;
			default:
				nomeIcone = "alert-circle-outline"
				break;
		}

		return nomeIcone;
	}

	verificaTipoArquivo(desc) {
		try {

			let ext = desc.split('.').pop();

			let iamgens = ["jpg", "png", "gif", "jpeg", "svg"];
			let pdf = ["pdf"];
			let doc = ["doc", "docx"];

			let tiposExtenssao = [];

			tiposExtenssao = tiposExtenssao.concat(iamgens);
			tiposExtenssao = tiposExtenssao.concat(pdf);
			tiposExtenssao = tiposExtenssao.concat(doc);

			if (tiposExtenssao.includes(ext)) {

				if (iamgens.includes(ext)) {
					return "imagem"
				} else if (pdf.includes(ext)) {
					return "pdf"
				} else if (doc.includes(ext)) {
					return "doc"
				}

			} else {
				return false;
			}

		} catch (error) {
			return false
		}

	}

	async listaAnexos() {

		if (this.listFiles) {
			this.carregandoAnexos = true;
			try {

				let anexos: any = await this.apiS3.listaAnexos2(this.prefix);

				this.erroAnexos = null;

				for (let anexo of anexos) {
					anexo['open'] = false;
					anexo['showName'] = this.showName ? this.showName : anexo.fileName;
					anexo['icone'] = this.adicionaIcone(anexo.fileName);
				}
				
				this.anexos = anexos;
				this.carregandoAnexos = false;
			} catch (error) {
				this.erroAnexos = error.message;
				this.carregandoAnexos = false;
			}

			this.mostrarAnexos = true;
		}
	}

	/**
	   * Quando o usuário seleciona um anexo
	   * @param event 
	   */
	async fileChangeEvent(event) {

		if (event && event.target.files && event.target.files.length == 1) {
			let file = event.target.files[0];
			await this.criaAnexo(file);
		}

		// else {
		// console.log('FileChangeEvent: Somente um de cada vez');
		// }

	}

	async criaAnexo(file) {

		let validarExts = false;
		if (this.extensions) {
			this.extensions.forEach(ext => {
				if (file.name.match(new RegExp("\\." + ext + "\$")))
					validarExts = true;
			});
		}

		if (!validarExts) {
			if (this.extensions.length == 1)
				await this.utilsService.createAlert("Atenção", "É necessario que o formato do arquivo seja: " + this.extensions[0] + ".");
			else {
				let exts = ""
				this.extensions.forEach(function (ext, idx) {
					exts = exts + ext;
					if (idx != this.extensions.length - 1)
						exts = exts + ", ";
				});
				await this.utilsService.createAlert("Atenção", "É necessario que o arquivo esteja em um dos seguintes formatos: " + exts + ".");
			}
			return false;
		}

		if (this.sizeLimit && (file.size / 1000000 > this.sizeLimit)) {
			await this.utilsService.createAlert("Atenção", "O tamanho do arquivo não pode exceder o limite de " + this.sizeLimit + " MB.");
			return false;
		}

		this.keyCount = this.keyCount + 1;

		this.salvarAnexo({
			open: false,
			showName: (this.showName ? this.showName : file.name) + " - 0%",
			showNameWithoutProgress: this.showName ? this.showName : file.name,
			icone: this.adicionaIcone('loading'),
			key: this.keyCount,
			fileData: file,
			fileName: file.name,
			newFile: true,
			fileUrl: null,
			progress: null,
			error: null,
			editMode: false,
			tempName: ''
		});

	}

	async salvarAnexo(anexo, upload?) {

		let setVarsEndUpload = function (anexo) {
			anexo.salvandoAnexo = false;
			anexo.progress = null;
			this.salvandoAnexo = false;
			this.editandoAnexo = false;
		}.bind(this)

		this.salvandoAnexo = true;

		try {

			let ret: any;
			anexo.error = false;
			anexo.progress = 0;

			let s3Name = this.configS3.fileName ? this.configS3.fileName : anexo.fileName;

			ret = await this.apiS3.uploadAnexo2(
				anexo.fileData,
				s3Name,
				(err, success) => { // Callback de sucesso
					setVarsEndUpload(anexo);
					if (err) {
						this.anexos = this.anexos.filter(function (ea) { return ea.showName != anexo.showName });
						this.utilsService.createAlert("Atenção", "Ocorreu um erro ao realizar upload da proposta.");
						console.log('O upload foi concluido com error: ', err);
					} else {
						this.listaAnexos();
						console.log('O upload foi concluido com success: ', success);
					}
				});

			if (ret) {


				this.anexos = this.anexos.filter(function (ea) { return ea.showName != anexo.showNameWithoutProgress });
				this.anexos.push(anexo);

				ret
					.on('httpUploadProgress', function (progress) {

						anexo.progress = 100 * (progress ? progress.loaded / progress.total : 0);

						if (anexo.progress >= 100) {
							anexo.progress = null;
						}

						if (anexo.progress && anexo.progress < 100)
							anexo.showName = anexo.progress.toFixed(2) + " % - " + (this.showName ? this.showName : anexo.fileName);
						//anexo.showName = (this.showName ? this.showName : anexo.fileName) + " - " + anexo.progress.toFixed(2) + "%";
						else
							anexo.showName = this.showName ? this.showName : anexo.fileName

					}.bind(this))
			} else
				setVarsEndUpload(anexo);

		} catch (error) {

			setVarsEndUpload(anexo);

			if (upload) {
				anexo.salvandoAnexo = false;
			}
			if (error.arquivoExistente) {
				anexo.error = error.message;
			}
			else if (error.message) {
				anexo.error = error.message;
			}
			else {
				anexo.error = 'Falha no upload';
			}

		}


	}

}
