import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { GetIconMimeTypes } from 'src/app/utils/getIconMimeTypes';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ControllerModalsService } from 'src/app/services/controller-modals.service';

@Component({
    selector: 'gerenciador-arquivos',
    templateUrl: './gerenciador-arquivos.component.html',
    styleUrls: ['./gerenciador-arquivos.component.scss']
})
export class GerenciadorArquivosComponent implements OnInit {

    @Input('path') path: string;
    @Input('id') id: string;
    @Input('type') type: string;
    @Input('showTitle') showTitle: boolean = true;
    @Input('customitle') customTitle: string;
    @Input('showUpload') showUpload:boolean;
    @Output('qtdeAnexos') qtdeAnexos = new EventEmitter();

    @ViewChild('arquivosSelecionados') arquivosSelecionados: any;
    getIconMimeTypes = GetIconMimeTypes;

    anexos = [];
    anexosTemp = [];
    erroAnexos;
    contadorAnexosTemp = 0;
    extensao;
    anexoNameOld;

    carregandoAnexos: boolean = false;
    mostrarAnexos: boolean = false;
    editandoAnexo: boolean = false;
    salvandoAnexo: boolean = false;

    functionLista;

    tipoExibicao: String = 'lista';

    constructor(
        private modalCtrl: ControllerModalsService,
        public platformService: PlatformService,
        public dialog: MatDialog,
        private apiS3: ApiS3Service,
        public icons: RegisterIconsService) {
        modalCtrl.setModalContext(dialog);
        icons.registerIcons(['image', 'file-presentation-box', 'google-spreadsheet', 'paperclip',
            'file-video-outline', 'file-document-box-outline', 'file-pdf', 'close',
            'dots-vertical', 'loading', 'delete', 'pencil',
            'folder-zip-outline', 'check', 'audiobook', 'format-list-bulleted', 'grid']);
    }

    ngOnInit() {
        if(this.showUpload === undefined) {
            this.showUpload = true;
        }
        if (this.id && this.path) {
            this.listaAnexos();
        }

        let countIntervalDrop = 0;
        let intervalDrop = setInterval(() => {

            let drop = document.querySelector("#inputFile");

            if (drop) {
                let boxInput: any = document.querySelector('.anexosBox-list');
                drop.addEventListener('dragenter', () => {
                    boxInput.classList.add('hoverInputFile');
                })
                drop.addEventListener('dragleave', () => {
                    boxInput.classList.remove('hoverInputFile');
                });
                drop.addEventListener('drop', () => {
                    boxInput.classList.remove('hoverInputFile');
                });

                clearInterval(intervalDrop);
            }

            if (countIntervalDrop > 50) {
                clearInterval(intervalDrop);
            }
            countIntervalDrop++
        }, 100)

    }

    async listaAnexos() {
        if (await this.apiS3.isS3Enabled()) {
            this.carregandoAnexos = true;
            // console.table({
            //     'id':this.id, 'path':this.path
            // })
            try {
                let anexos: any = await this.apiS3.listaAnexos(this.id, this.path);
                this.erroAnexos = null;

                for (let anexo of anexos) {
                    anexo['open'] = false;
                    anexo['icon'] = await this.getIconMimeTypes.getTypeIcon(anexo.fileName);
                }

                this.anexos = anexos;
                // console.log('anexos', this.anexos);
                this.qtdeAnexos.emit(this.anexos.length);

                this.carregandoAnexos = false;
            } catch (error) {
                this.erroAnexos = error.message;
                this.carregandoAnexos = false;
            }
            this.mostrarAnexos = true;
        } else {
            this.anexos = null;
            this.erroAnexos = null;
            this.mostrarAnexos = false;
            this.carregandoAnexos = false;
        }
        return;
    }

	/**
     * Quando o usuário seleciona um anexo
     * @param event 
     */
    async fileChangeEvent(event) {
        if (event && event.target.files && event.target.files.length > 0) {
            // let file = event.target.files[0];
            let files = event.target.files;
            this.criaAnexo(files);
        } else {
            return
        }
    }

    criaAnexo(files) {
        //   console.log('Criando')
        if (!files) {
            throw new Error('Arquivo indefinido');
        }
        let count = 0;
        for (let file of files) {
           // console.log('for file', file)
            let idTemp = this.contadorAnexosTemp;
            idTemp++;
            count++;
            let anexo = {
                key: idTemp,
                newFile: true,
                fileName: null,
                fileUrl: null,
                fileData: null,
                progress: null,
                error: null,
                editMode: false,
                tempName: ''
            };
            let request;

            anexo.fileData = file;
            anexo.fileName = file.name;
            this.anexosTemp.unshift(anexo);
            this.salvarAnexo(anexo);
            if (count == files.length) {
                this.arquivosSelecionados.nativeElement.value = '';
            }

        }
    }

    async salvarAnexo(anexo, upload?) {
        let thisObj = this;
        if (anexo) {
            thisObj.salvandoAnexo = true;
            try {
                let ret: any;
                anexo.error = false;
                if (!upload && !this.anexoNameOld) {
                    anexo.progress = 30;
                    ret = await this.apiS3.uploadAnexo(this.id, anexo.fileData, anexo.fileName, this.path);
                } else {
                    ret = await this.apiS3.uploadRenameAnexo(this.id, anexo.fileData, this.anexoNameOld, anexo.fileName, this.path);
                }

                ret.request
                    .on('httpUploadProgress', function (progress) {
                        anexo.progress = 100 * (progress ? progress.loaded / progress.total : 0);

                        if (anexo.progress >= 100) {
                            anexo.progress = null;
                        }
                    })
                    .on('error', function (response) {
                        thisObj.anexoNameOld = '';
                    })
                    .on('success', function (response) {
                        // deleta o anexo dos itens da fila de envio
                        let idx = thisObj.anexosTemp.findIndex(function (val) { return val.key == anexo.key; });
                        if (idx >= 0) { thisObj.anexosTemp.splice(idx, 1); }
                        // atualiza a lista de arquivos salvos
                        try {
                            thisObj.listaAnexos();
                        } catch (error) {

                        }
                        finally {
                            thisObj.anexoNameOld = '';
                            if (upload) {
                                thisObj.anexos = [];
                                anexo.editMode = false;
                            }
                        }
                    });

            } catch (error) {
                if (upload) {
                    anexo.salvandoAnexo = false;
                }
                if (error.arquivoExistente) {
                    anexo.error = error.message;
                }
                else if (error.message) {
                    anexo.error = error.message;
                }
                else {
                    anexo.error = 'Falha no upload';
                }
            }
            finally {
                this.anexoNameOld = anexo.error ? this.anexoNameOld : '';
                anexo.salvandoAnexo = false;
                thisObj.salvandoAnexo = false;
                thisObj.editandoAnexo = false;
                anexo.progress = null;
            }

        } else {
            return
        }
    }

    /**
     * Quando o usuário inicializa a edição de anexo
     * @param anexo 
     */
    entraEdicaoAnexo(anexo) {
        if (anexo) {
            this.editandoAnexo = true;
            let removeExtensao = /\.[a-zA-Z0-9À-ÿ\s]+$/;
            this.anexoNameOld = anexo.error ? '' : anexo.fileName;
            anexo.editMode = true;
            anexo.tempName = anexo.fileName.replace(removeExtensao, '');
            let tokens = anexo.fileName.split('.');
            this.extensao = tokens[tokens.length - 1];
        }
    }
    /**
     * Quando o usuário finaliza a edição do anexo
     * @param anexo 
     */
    async concluiEdicaoAnexo(anexo) {
        if (anexo) {
            anexo.salvandoAnexo = true;
            anexo.fileName = `${anexo.tempName}.${this.extensao}`;
            try {
                await this.salvarAnexo(anexo, anexo.error ? false : true);

            } catch (error) {
                console.error('Erro ao salvar', error);
            }
        }
    }

    /**
     * Quando o usuário cancela a edição do anexo
     * @param anexo 
     */
    cancelaEdicaoAnexo(anexo) {
        this.editandoAnexo = false;
        anexo.editMode = false;
        anexo.fileName = this.anexoNameOld;
        anexo.error = '';
        this.anexoNameOld = '';
    }

    async deletaAnexo(anexo) {
        let thisObj = this;
        async function handlerDeletarAnexo() {
            // anexos enviando
            if (anexo.key) {
                let idx = thisObj.anexosTemp.findIndex(function (val) {
                    return anexo.key == val.key // && val.url == anexo.url;
                });
                if (idx >= 0) {
                    thisObj.anexosTemp.splice(idx, 1);
                }
            }
            // anexo já salvo
            else {
                try {
                    let retDel = await thisObj.apiS3.deletaAnexo(thisObj.id, anexo.fileName, thisObj.path);

                    let idx = thisObj.anexos.findIndex(function (val) {
                        return val.fileName == anexo.fileName;
                    });
                    if (idx >= 0) {
                        thisObj.anexos.splice(idx, 1);
                    }
                    setTimeout(()=>{
                        thisObj.listaAnexos();
                    },1000)
                } catch (error) {
                    this.mostrarMensagem(error.message ? error.message : 'Falha ao deletar anexo');
                }
            }
        }

        if (anexo) {
            let dialog = this.dialog.open(ModalConfirm);
            dialog.afterClosed().subscribe(ret=>{
               // console.log('fechou modal', ret);
                if(ret){
                    handlerDeletarAnexo();
                }
            })            
        }
    }

    erroAnexo(anexo) {

    }

    ngOnDestroy(){
        this.modalCtrl.clear();
    }

}

@Component({
    selector: 'modal-confirm',
    templateUrl: 'modal-confirm.html',
})
export class ModalConfirm {

    constructor(
        public dialogRef: MatDialogRef<ModalConfirm>) { }

    closeModal(): void {
        this.dialogRef.close(false);
    }

    confirmar() {
        this.dialogRef.close(true);
    }

}