<div [ngClass]="['periodoTeste', 'link-video']" *ngIf="validacaoCorretora?.periodoTeste && validacaoCorretora?.prazo >= 0 && autenticado && isAdmin && platformService.virtualDeviceType === 'desktop' && showAlert && !existParceiro && !isRoot; else elseBlock"> 
    <span class="periodoTeste__text" [ngStyle]="{'margin-left': 'auto', 'font-weight': '500'}">Período de teste restante: {{validacaoCorretora ? 
        validacaoCorretora.prazo == 1 ?  validacaoCorretora.prazo + " dia" :
        validacaoCorretora.prazo == 0 ? "Último dia!" : 
        validacaoCorretora.prazo + " dias" : ''}}</span>        
    <button mat-button class="periodoTeste__contratar" (click)="contratar()" [ngStyle]="{'margin-left': '8px'}" [ngStyle]="{'visibility':'hidden'}"><span>CONTRATAR AGORA</span></button>
    <link-video class="periodoTeste__video" #link toolTip="Bem vindo(a) ao Ápice!" (click)="link.open('intro')" [ngStyle]="{'visibility':'hidden'}"></link-video>

    <button mat-icon-button (click)="esconderPeriodo()" [ngStyle]="{'margin-left': 'auto'}">
        <mat-icon [svgIcon]="'close'" [ngStyle]="{'color': '#FFF'}"></mat-icon>
    </button>
</div>
 
<ng-template #elseBlock>
    <div [ngClass]="['periodoTeste']" *ngIf="validacaoCorretora?.periodoLimitado && autenticado && isAdmin && showAlert && !existParceiro"> 
        <span class="periodoTeste__text" [ngStyle]="{'margin-left': 'auto', 'font-weight': '500'}">Seu período de teste acabou.</span> 
    
        <button mat-button class="periodoTeste__contratar" (click)="contratar()" [ngStyle]="{'margin-left': '8px'}" [ngStyle]="{'visibility':'hidden'}"><span>CONTRATAR AGORA</span></button>
    
        <button mat-icon-button (click)="esconderPeriodo()" [ngStyle]="{'margin-left': 'auto'}" [ngStyle]="{'visibility':'hidden'}">
            <mat-icon [svgIcon]="'close'" [ngStyle]="{'color': '#FFF'}"></mat-icon>
        </button>
    </div>
</ng-template>  

<div
    class="app"
    [ngStyle]="{'height': validacaoCorretora?.periodoTeste && mostraPeriodoTeste ? 'calc(100% - 40px)' : '100%'}"
    (window:resize)="platformService.onResize($event)">
    <input
        class="extensao"
        type="text"
        id="bExtensaoExterno"
        tabindex="-1">

    <div [ngClass]="[menuToggle && platformService.virtualDeviceType != 'desktop' ? 'menuSide__backDrop' : '']" (click)="closeMenuToggle()"></div>

    <div *ngIf="autenticado" [ngClass]="['menuSide', menuContraido && platformService.virtualDeviceType == 'desktop' ? 'diminuirMenu' : '', menuToggle ? 'menuSide__open' : '']">
        <mat-toolbar>
            <mat-toolbar-row>
                <button mat-icon-button (click)="alternaMenu()" *ngIf="platformService.virtualDeviceType == 'desktop'">
                    <mat-icon [svgIcon]="'menu'" style="color: #fff;"></mat-icon>
                </button>
                <span
                    *ngIf="platformService.virtualDeviceType == 'desktop'"
                    class="mdi mdi-menu pd-8"
                    [ngStyle]="{'cursor':'pointer', 'padding-left':'0'}"></span>
                <img
                    [ngClass]="[menuContraido && platformService.virtualDeviceType == 'desktop' ? 'hiddenLogo' : 'showLogo' ]"
                    src="../assets/logos/apice-horizontal.png">
                <button mat-icon-button class="menuPerfil__about"
                        *ngIf="platformService.virtualDeviceType != 'desktop'"
                        [ngStyle]="{'margin-left': 'auto', 'width': '40px', 'height':'40px'}"
                        matTooltip="Sobre" (click)="openAbout()">
                    <mat-icon [svgIcon]="'information-outline'" [ngStyle]="{'color':'#fff'}"></mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="menuSide__items">
            <mat-nav-list>
            <!-- <mat-nav-list *ngIf="apiS3.apiBase.credenciaisApice"> -->
                <mat-list-item
                    [ngClass]="['identificacao-usuario']"
                    (click)="abrirPerfil()"
                    *ngIf="platformService.virtualDeviceType != 'desktop'">
                    <div
                        matListAvatar
                        [ngStyle]="{'position':'relative'}">
                        <!-- [src]="apiS3.avatarUsuarioLogado" -->
                        <!-- [title]="apiS3.avatarUsuarioLogado" -->
                        <img
                            matListAvatar
                            [src]="apiS3.avatarUsuarioLogado"
                            onError="this.src='assets/img/usuarioAnonimo.png';"
                            alt="Ícone Ápice"
                            [title]="''">
                            <!-- *ngIf="loadImagem && platformService.virtualDeviceType == 'desktop'" -->
                        <div
                            [ngClass]="'imagePerfilApp'"
                            *ngIf="platformService.virtualDeviceType == 'desktop'">
                            <span>
                                <div class="lds-perfilApp">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </span>
                        </div>
                    </div>
                   
                    <h3
                        [ngClass]="menuContraido ? 'hiddenTituloMenu' : 'showTituloMenu'"
                        matLine
                        *ngIf="platformService.virtualDeviceType != 'desktop'">
                        {{ sessao.nome }}
                    </h3>
                    <p
                        [ngClass]="['labelTpUser', menuContraido ? 'hiddenTituloMenu' : 'showTituloMenu']"
                        matLine
                        *ngIf="platformService.virtualDeviceType != 'desktop'">
                        {{ sessao.admin ? 'Administrador' : 'Colaborador' }}
                    </p>
                </mat-list-item>
           

                <span
                *ngFor="let menu of menus; let i = index"
                    [ngClass]="['itemsMenu',  (menu.titulo === 'Painel Administrativo' && platformService.virtualDeviceType == 'desktop') || (!sessao?.root && menu.titulo === 'Painel Administrativo') ? 'paraOcultarItem' : '']">
                    <div
                        *ngIf="(!(menu.titulo === 'Sair' || menu.titulo === 'Painel Administrativo') && platformService.virtualDeviceType == 'desktop') || (platformService.virtualDeviceType != 'desktop')"
                        [ngClass]="'itemsMenu__menu'"
                        (click)="menu.titulo == 'Configurações' || menu.titulo == 'Cotações' || menu.titulo == 'Negócios' || menu.titulo === 'Operacional' ? toggleSubMenu(i) : ''">
                      
                        <a
                            mat-list-item
                            (click)="rotaLinkMenu(menu.url, i)"
                            [ngClass]="menu.active ? 'activeSubMenu' : ''">

                            <mat-icon
                                mat-list-icon
                                [svgIcon]="menu.icone"                               
                                [ngClass]="[menu.active ? 'activeSubMenu' : '', 'itemsMenu__icon']"></mat-icon>
                            <span [ngClass]="[menuContraido ? 'hiddenTituloMenu' : 'showTituloMenu', 'fontHelvetica16']">{{ menu.titulo }}</span>

                        </a>
                    </div>
                    <span
                        [ngClass]="['itemsMenu__menu-span', platformService.virtualDeviceType == 'desktop' && menuContraido ? 'itemsMenu__menu-contraido' : '']"
                        *ngIf="menu.subList.length > 0">
                        <div
                            *ngFor="let sub of menu.subList; let j = index"
                            [ngClass]="[menu.open ? 'toggleMenu' : '', 'itemsMenu__menu-subMenu']">
                          
                            <a
                                mat-list-item
                                (click)="rotaLinkMenu(sub.url, i, j)"
                                [ngClass]="sub.active ? 'activeSubMenu' : ''">
                                <mat-icon
                                    mat-list-icon
                                    [svgIcon]="sub.icone"
                                    [ngStyle]="{'padding-left':'0'}"
                                    [ngClass]="[ sub.active ? 'activeSubMenu' : '', 'itemsMenu__icon']"></mat-icon>                           
                                <span [ngClass]="['colorTitleMenu']">{{ sub.titulo }}</span>
                            </a>
                        </div>
                    </span>
                </span>
            </mat-nav-list>

            <div class="logoBottom">
                <img
                    [ngStyle]="{'width':'auto'}"
                    [ngClass]="[menuContraido && platformService.virtualDeviceType == 'desktop' ? 'showLogoBottom' : 'hiddenLogoBottom' ]"
                    src="../assets/logos/apice.png">
                <!-- <span [ngClass]="[!menuContraido && apiS3.apiBase.credenciaisApice && platformService.virtualDeviceType == 'desktop' ? 'menu-watermark' : 'menu-watermark-hidden']"> -->
                <span [ngClass]="[!menuContraido && platformService.virtualDeviceType == 'desktop' ? 'menu-watermark' : 'menu-watermark-hidden']">
                    <!-- {{ apiS3.apiBase.credenciaisApice.corretora }} -->
                   {{ sessao.corretora }}
                </span>
            </div>
            <p 
                class="fontSmall"
                [ngClass]="[!menuContraido && platformService.virtualDeviceType == 'desktop'  || (platformService.virtualDeviceType != 'desktop')? 'versao-show' : 'versao-hidden']"
                [ngStyle]="{ 'padding':'0 !important' }" >Versão {{ version }}</p>

        </div>

    </div>

    <div class="contentSide" [ngStyle]="{'width': platformService.virtualDeviceType == 'desktop' ? 'calc(100% - 250px)' : '100%'}">
        <router-outlet></router-outlet>

        <fab-buttons [fabButtons]="fabButtons" *ngIf="autenticado && showFabButton"></fab-buttons>
    </div>

    <div [ngClass]="['menu-filtro', filtro.open ? 'openfiltro' : '']" *ngIf="autenticado">
        <filtro [page]="filtro.page"></filtro>
    </div>

</div>
