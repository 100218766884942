import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ControllerModalsService {

  private modalContext;
  constructor() { }

  closeAllModals(){
    if(this.modalContext){
      this.modalContext.closeAll();
    }
  }

  setModalContext(context: any){    
    this.modalContext = context;
  }

  clear(){    
    this.modalContext = null;
  }
  
}
