<div class="modalDocs">
    <div class="modalDocs__header">
        <div class="title">
            <h3 class="personalData textOverflow">{{ data?.segurado?.nome}}</h3>
        </div>
        <div class="btns-actions-modal">
            <button mat-icon-button (click)="fecharModal()" tabindex="-1" [disabled]="loadingCriaNegocio" [ngStyle]="{'color': '#727272'}" [matTooltip]="'Fechar'">
                <mat-icon [svgIcon]="'close'"></mat-icon>
            </button>
            <!-- <button mat-button *ngIf="data?.mostrarBotaoRenovacao && !data.route"
            tabindex="-1"
            [disabled]="loadingCriaNegocio"
            [ngStyle]="{'color': '#727272', 'opacity':''}"         
            (click)="data.negocioId ? abrirModalNegocio() : criaNovoNegocio()">
            {{data.negocioId ? 'Ver negócio' : 'Criar negócio'}}          
            </button> -->
        </div>
    </div>

    <div mat-dialog-content class="modalDocs__content">
        <mat-tab-group class="modalDocs__tabGroup">
            <mat-tab class="modalDocs__tab" label="Documento">
                <div class="modalDocs__documento">
                    <form [formGroup]="formFake">
                        <mat-form-field>
                            <mat-label>Seguradora</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="seguradora" [value]="data.extras.tooltip">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Apolice</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="apolice" [value]="data.apolice" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Vigência</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="vigencia" [value]="data.datasVig.ini + ' à ' + data.datasVig.fim">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data de Emissão</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="dataEmissao" [value]="data.dataEmissaoF">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data de Importação</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="dataImportacao" [value]="data.dataImportacaoF">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Proposta</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="proposta" [value]="data.proposta" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data da proposta</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="dataProposta" [value]="data.dataPropostaF">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Prêmio liqído</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="premioLiquido" [value]="data.premios.liquido">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Prêmio total</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="premioTotal" [value]="data.premios.total">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Status</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="status" [value]="data.statusTxt">
                        </mat-form-field>
                    </form>
                </div>
            </mat-tab>
            <mat-tab class="modalDocs__tab" label="Cliente">
                <div class="modalDocs__cliente">
                    <form [formGroup]="formFake2">
                        <mat-form-field>
                            <mat-label>Nome</mat-label>
                            <input type="text" class="inputName" matInput [readonly]="disabled" formControlName="nome" [value]="data.segurado.nome" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>CPF/CNPJ</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="cpfCnpj" [value]="data.segurado.cpfCnpj" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Sexo</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="sexo" [value]="data.segurado.sexoF" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data de nascimento</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="dataNascimento" [value]="data.segurado.dataNascF" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="email" [value]="data.segurado.email" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Cep</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="cep" [value]="data.segurado.cep" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Endereço</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="logradouro" [value]="data.segurado.logradouro" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Bairro</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="bairro" [value]="data.segurado.bairro" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Cidade</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="cidade" [value]="data.segurado.cidade" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>UF</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="uf" [value]="data.segurado.uf">
                        </mat-form-field>
                    </form>
                </div>
            </mat-tab>
            <mat-tab class="modalDocs__tab" label="Parcelas">
                <div class="modalDocs__parcelas">
                    <mat-nav-list *ngIf="data.parcelas && data.parcelas?.length > 0" [ngStyle]="{'width':'100%'}">
                        <mat-list-item *ngFor="let parcela of data.parcelas">
                            <div class="row">
                                <div class="coluna-1">
                                    <span>{{ parcela.numero + '/' +  data.parcelas.length }}</span>
                                </div>
                                <div class="coluna-2">
                                    <div class="coluna-2__1" [ngStyle]="parcela.dataVencimento ? {'display':'hidden'} : {}">
                                        <span class="dataVencimento" *ngIf="parcela.dataVencimento">Data de vencimento:</span>
                                        <span>{{ parcela.dataVencimento ?  ' ' + parcela.dataVencimento : '' }}</span>
                                    </div>
                                    <div class="coluna-2__2" [ngStyle]="parcela.valorF ? {'display':'hidden'} : {}">
                                        <span [matTooltip]="'Valor'">{{ parcela.valorF }}</span>
                                    </div>
                                </div>
                                <div class="coluna-3" *ngIf="parcela.formaPagamento">
                                    <mat-icon [matTooltip]="parcela.formaPagamento" *ngIf="parcela.formaPagamento" [svgIcon]="parcela.iconFP"></mat-icon>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>

                    <span *ngIf="!data.parcelas || data.parcelas?.length === 0" class="fontHelvetica16"> Nenhuma parcela disponível </span>
                </div>
            </mat-tab>
            <mat-tab class="modalDocs__tab" label="Itens">
                <div class="modalDocs__itens" *ngIf="data.ramo === 31">
                    <form [formGroup]="formFake3">
                        <mat-form-field>
                            <mat-label>CI</mat-label>
                            <input type="text" class="inputName" matInput [readonly]="disabled" formControlName="CI" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Ano de fabricação</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="anoFabricacao" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Ano modelo</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="anoModelo" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Chassi</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="chassi" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Código FIPE</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="codigoFipe">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Combustivel</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="combustivel">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Danos Corporais</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="danosCorporais">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Danos Materiais</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="danosMateriais">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Danos Morais</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="danosMorais">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Eixos</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="eixos">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Fabricante</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="fabricante">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Passagieros</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="passageiros" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Placa</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="placa" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Portas</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="portas">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Veículo</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="veiculo" >
                        </mat-form-field>
                    </form>
                </div>

                <div class="modalDocs__itens" *ngIf="data.ramo === 14 || data.ramo === 2 || data.ramo === 18">
                    <form [formGroup]="formFake4">
                        <mat-form-field>
                            <mat-label>Logradouro</mat-label>
                            <input type="text" class="inputName personalData" matInput [readonly]="disabled" formControlName="logradouro" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Numero</mat-label>
                            <input type="text" class="inputName personalData" matInput [readonly]="disabled" formControlName="numero" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Bairro</mat-label>
                            <input type="text" class="inputName personalData" matInput [readonly]="disabled" formControlName="bairro" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Cidade</mat-label>
                            <input type="text" class="inputName personalData" matInput [readonly]="disabled" formControlName="cidade" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>CEP</mat-label>
                            <input type="text" class="inputName personalData" matInput [readonly]="disabled" formControlName="cep" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>UF</mat-label>
                            <input type="text" class="inputName" matInput [readonly]="disabled" formControlName="uf" >
                        </mat-form-field>
                        
                    </form>
                </div>

                <div class="modalDocs__itens" *ngIf="data.ramo === 91">
                    <form [formGroup]="formFake2">
                        <mat-form-field>
                            <mat-label>Nome</mat-label>
                            <input type="text" class="inputName personalData" matInput [readonly]="disabled" formControlName="nome" [value]="data.segurado.nome">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>CPF/CNPJ</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="cpfCnpj" [value]="data.segurado.cpfCnpj" class="personalData">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Sexo</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="sexo" [value]="data.segurado.sexoF" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data de nascimento</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="dataNascimento" [value]="data.segurado.dataNascF" >
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input type="text" matInput [readonly]="disabled" formControlName="email" [value]="data.segurado.email" class="personalData">
                        </mat-form-field>
                    </form>
                </div>
            </mat-tab>
            <mat-tab class="modalDocs__tab" label="Anexos">
                <div class="modalDocs__anexos">
                    <mat-nav-list *ngIf="anexos?.length > 0">
                        <mat-list-item *ngFor="let anexo of anexos" (click)="openDocument(anexo)">
                            <div class="row">
                                <mat-icon [svgIcon]="'file-download-outline'"></mat-icon>
                                <span class="fontHelvetica16 personalData">{{ anexo.descricao }}</span>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>

                    <span *ngIf="anexos?.length === 0" class="fontHelvetica16"> Nenhum anexo disponível </span>

                </div>
            </mat-tab>
        </mat-tab-group>
        
       
        
        
        
    </div>
</div>
