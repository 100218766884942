import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CotacoesRamoComponent } from './cotacoes-ramo.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [CotacoesRamoComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    MatTooltipModule
  ],
  exports: [CotacoesRamoComponent]
})
export class CotacoesRamoModule { }
