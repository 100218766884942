import { getRotuloSeguradora, getIiconeSeguradora } from "./FormatterSeguradoras";

export class Formatter {


    static geraDataSql (data:string, dayEnd?:boolean) : string {
		if (!data) return undefined;
		try {
			let tokens = data.split('/');
			if (tokens.length != 3) return undefined;
			let str;
			if (!dayEnd) {
				str = `${tokens[2]}-${tokens[1]}-${tokens[0]}T00:00:00.000Z`;
			} else {
				str = `${tokens[2]}-${tokens[1]}-${tokens[0]}T23:59:59.999Z`;
			}
			return str;
		} catch (error) {
			return undefined;
		}

	}

    static dateAAAAMMDD(date){
        if(!date){
            return '';
        }else if(typeof date === 'string'){
            
            if(date.match(/^(\d{2})\/(\d{1,2})\/(\d{4})/)){// 22/08/2008
                               
                let arrayData = date.split('/'); //dividir data através da barra		
		        let newDate = arrayData[2] + '-' + arrayData[1] + '-' + arrayData[0]; //montar formato da data para salvar
                return newDate;
            }else{
                return date
            }
            //throw new Error('Parâmetro inválido ao formatar data: ' + date);
        }
    }

    static dateDDMMAAAA(date, options?: any) {
        if (!date) {
            return '';
        } else if (typeof date === 'string') {

            if (date.match(/^(\d{4})-(\d{1,2})-(\d{1,2})/) || date.match(/^(\d{2})\/(\d{1,2})\/(\d{4})/) || date.match(/^(\d{4})\/(\d{1,2})\/(\d{1,2})/)) {

                // 2018-2-3 
                // 3/2/2018

                
                let arrayData = date.match(/\d+/g);
                let year = arrayData[0]
                let month = arrayData[1].length == 1 ? '0' + arrayData[1] : arrayData[1] 
                let day = arrayData[2].length == 1 ? '0' + arrayData[2] : arrayData[2] 

                let dataStr = day + '/' + month + '/' + year;
                return dataStr;
            } 
            throw new Error('Parâmetro inválido ao formatar data.');
        } else if (typeof date === 'number') {
            date = new Date(date);
        } else if (typeof date === 'object' && date.constructor.name !== 'Date') {
            throw new Error('Parâmetro de tipo inválido ao formatar data.');
        }
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return (day < 10 ? '0' + day : day) + '/' +
            (month < 10 ? '0' + month : month) + '/' +
            year;
    }

    static dateRangeDDMMAAAA(ini, fim) {
        ini = Formatter.dateDDMMAAAA(ini);
        fim = Formatter.dateDDMMAAAA(fim);
        let range = (ini ? ini : '') + ' à ' + (fim ? fim : '');
        return range;
    }

    static dateRangeDDMMAAAA2(ini, fim) {
        let range = (ini ? ini : '') + ' a ' + (fim ? fim : '');
        return range;
    }

    static dateDDMM(dia, mes, options?: any) {
        let day = dia;
        let month = mes + 1;
        if (!options) {
            options = {};
        }
        if (options.mesAbreviado) {
            month = Formatter.mesAbreviado(mes, false);
        } else if (options.mesTexto) {
            month = Formatter.mes(mes, false);
        } else {
            month = mes + 1;
        }
        return (day < 10 ? '0' + day : day) + '/' +
            (month < 10 ? '0' + month : month);
    }

    static isoDateToDate(date: string): Date {

        if (date && date.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/)) {
            let year = Number(date.substr(0, 4));
            let month = Number(date.substr(5, 2)) - 1;
            let day = Number(date.substr(8, 2));
            let hour = Number(date.substr(11, 2));
            let minutes = Number(date.substr(14, 2));
            let seconds = Number(date.substr(17, 2));
            let _date = new Date(year, month, day, hour, minutes, seconds);
            //console.log('datas', date, _date, _date.getMonth())
            return _date;
        } else {
            throw new Error('Parâmetro inválido ao formatar data.');
        }
    }

    static meses = {
        0: 'janeiro',
        1: 'fevereiro',
        2: 'março',
        3: 'abril',
        4: 'maio',
        5: 'junho',
        6: 'julho',
        7: 'agosto',
        8: 'setembro',
        9: 'outubro',
        10: 'novembro',
        11: 'dezembro'
    }
    static mes(index, capitalize?: boolean): string {
        let value = Formatter.meses[index];
        if (value) {
            if (capitalize) {
                return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            } else {
                return value;
            }
        } else {
            return undefined;
        }
    }
    static mesesAbreviados = {
        0: 'jan',
        1: 'fev',
        2: 'mar',
        3: 'abr',
        4: 'mai',
        5: 'jun',
        6: 'jul',
        7: 'ago',
        8: 'set',
        9: 'out',
        10: 'nov',
        11: 'dez'
    }
    static mesAbreviado(index, capitalize?: boolean): string {
        let value = Formatter.mesesAbreviados[index];
        if (value) {
            if (capitalize) {
                return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
            } else {
                return value;
            }
        } else {
            return undefined;
        }
    }

    static mesNumerico(index) {
        if (index) {
            index++;
            if (index >= 0 && index < 10) {
                return '0' + index;
            } else {
                return index;
            }
        }
        return undefined
    }



    static idade(nascimento): any {
        let birth_month;
        let birth_day;
        let birth_year;

        if (!nascimento) {
            return '';
        } else if (typeof nascimento === 'string') {
            if (nascimento.match(/^(\d{4})-(\d{2})-(\d{2})/)) {
                let year = nascimento.substr(0, 4);
                let month = nascimento.substr(5, 2);
                let day = nascimento.substr(8, 2);
                nascimento = new Date(
                    Number(year),
                    Number(month) - 1,
                    Number()
                );
                //return  day + '/' + month + '/' + year;
            } else {
                throw new Error('Parâmetro inválido ao formatar idade.');
            }

        } else if (typeof nascimento === 'number') {
            nascimento = new Date(nascimento);
        } else if (typeof nascimento === 'object' && nascimento.constructor.name !== 'Date') {
            throw new Error('Parâmetro de tipo inválido ao formatar idade.');
        }
        birth_month = nascimento.getMonth() + 1;
        birth_day = nascimento.getDate();
        birth_year = nascimento.getFullYear();
        let today_date = new Date();
        let today_year = today_date.getFullYear();
        let today_month = today_date.getMonth();
        let today_day = today_date.getDate();
        let age = today_year - birth_year;

        if (today_month < (birth_month - 1)) {
            age--;
        }
        if (((birth_month - 1) == today_month) && (today_day < birth_day)) {
            age--;
        }

        return age;
    }

    static numberWithComma(num) {
        if (num === undefined || num === null) {
            return '';
        } else if (typeof num === 'string') {
            num = Number(num);
        } else if (typeof num !== 'number') {
            throw new Error('Parâmetro de tipo inválido ao formatar número: \'' + num + '\'');
        }
        return num.toString().replace('.', ',');
    }

    static moneyBRReal(num) {
        if (num === undefined || num === null || num === false) {
            return '';
        } else if (typeof num === 'string') {
            num = Number(num);
        } else if (typeof num !== 'number') {
            throw new Error('Parâmetro de tipo inválido ao formatar número: \'' + num + '\'');
        }
        return num.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    static percVal(perc?, val?) {

        let _perc = perc ? Formatter.moneyBRReal(perc) + '%' : '';
        let _val = val ? 'R$ ' + Formatter.moneyBRReal(val) : '';
        if (val) {
            _val = perc ? ' (' + _val + ')' : _val;
        }
        //console.log('perc ' + _perc)
        return _perc + _val;
    }

    static substring(str, maxSize: number, ellipsis?: boolean) {
        if (!str || str.length < maxSize) {
            return str;
        } else {
            return str.substring(0, maxSize) + (ellipsis ? '...' : '');
        }
    }

    /*
        Formata um número de telefone com ddd.
        @returns string com o telefone formatado.  Exs.: (DDD) NNNNN-NNNN, (DDD) NNNNN, NNNN-NNNNN.
    */
    static telefone(numero, ddd?, ramal?) {
        let numeroStr = ((numero ? numero : '') + '')
            .replace(/\./g, "")
            .replace(/-/g, "")
            .replace(/\//g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/ /g, "");

        if (numeroStr.length > 6) {
            numeroStr = numeroStr.substring(0, numeroStr.length - 4) + '-' + numeroStr.substring(numeroStr.length - 4, numeroStr.length);
        }
        let str = (ddd ? '(' + ddd + ') ' : '') + numeroStr + (ramal ? ' ramal ' + ramal : '');
        return str;
    }

    static formatFone(fone){
        if(!fone){
            return ''
        }else{
            let format = fone.replace(/\D/g,"");
            format = format.replace(/^(\d{2})(\d)/g,"($1) $2");
            format = format.replace(/(\d)(\d{4})$/,"$1-$2");
            
            return format;
        }
    }

    /*
        Aplica negrido em uma substing
    */
    static boldSubStr(str: string, substr: string) {
        if (!str || !substr) return str;
        let idx = str.indexOf(substr);
        if (idx === -1) return str;
        return str.substring(0, idx) + '<b>' + str.substring(idx, idx + substr.length) + '</b>' + str.substring(idx + substr.length, str.length);
    }


    static tiposDeTelefone = {
        'P': 'Telefone pessoal',
        'R': 'Telefone residencial',
        'C': 'Telefone comercial',
        'O': 'Telefone Eventual'
    };
    static rotuloTelefone(codigo) {
        let rotulo = Formatter.tiposDeTelefone[codigo];
        if (!rotulo) rotulo = 'Telefone';
        return rotulo;
    }

    static estadosCivis = {
        1: 'Solteiro(a)',
        2: 'Casado(a)',
        3: 'Separado(a)',
        4: 'Divorciado(a)',
        5: 'Viúvo(a)',
        6: 'União Estável'
    };
    static estadoCivil(codigo) {
        let value = Formatter.estadosCivis[codigo];
        if (!value) value = '';
        return value;
    }


    static escolaridades = {
        1: 'Analfabeto',
        2: 'FUNDAMENTAL INCOMPLETO',
        3: 'FUNDAMENTAL COMPLETO',
        4: 'MÉDIO INCOMPLETO',
        5: 'MÉDIO COMPLETO',
        6: 'SUPERIOR INCOMPLETO',
        7: 'SUPERIOR COMPLETO',
        8: 'PÓS GRADUAÇÃO',
        9: 'DOUTORADO',
        10: 'MESTRADO',
        11: 'PHD'
    };
    static escolaridade(codigo) {
        let value = Formatter.escolaridades[codigo];
        if (!value) value = '';
        return value;
    }

    static enderecos = {
        'P': 'Endereço Pessoal',
        'R': 'Endereço Residencial',
        'C': 'Endereço Comercial',
        'O': 'Endereço Eventual'
    };
    static endereco(codigo) {
        let value = Formatter.enderecos[codigo];
        if (value) return value;
        return 'Endereço';
    }

    static enderecoInline(endereco: any) {
        let logradouro: any = [];
        let cidade: any = [];

        if (endereco.endereco) {
            if (endereco.endereco.length > 0) logradouro.push(endereco.endereco);
        } else if (endereco.logradouro) {
            if (endereco.logradouro.length > 0) logradouro.push(endereco.logradouro);
        }
        // if (endereco.endereco && endereco.endereco.length > 0) logradouro.push(endereco.endereco); 
        if (endereco.numero) logradouro.push(endereco.numero);
        if (endereco.bairro && endereco.bairro.length > 0) logradouro.push(endereco.bairro);
        if (endereco.complemento && endereco.complemento.length > 0) logradouro.push(endereco.complemento);

        if (endereco.cidade && endereco.cidade.length > 0) cidade.push(endereco.cidade);
        if (endereco.estado && endereco.estado.length > 0) cidade.push(endereco.estado);

        let cep = Formatter.formatCEP(endereco.cep);

        logradouro = logradouro.join(', ');
        cidade = cidade.join(', ');

        let _endereco = [];
        if (logradouro && logradouro !== '') _endereco.push(logradouro);
        if (cidade && cidade !== '') _endereco.push(cidade);
        if (cep && cep !== '') _endereco.push(cep);
        let str = _endereco.join(' - ');
        return str;
    }

    static iconesEnderecos = {
        'P': 'person',
        'R': 'home',
        'C': 'briefcase',
        'O': 'repeat'
    };
    static iconEndereco(codigo) {
        let value = Formatter.iconesEnderecos[codigo];
        if (value) return value;
        return 'help';
    }

    static linkTelefone(numero, ddd) {
        let str = Formatter.telefone(numero, ddd);
        if (str !== '') return 'tel:' + str;
        return undefined;
    }

    static email(email, options?: any) {
        if (email) {
            if (options.breakeableHTML) {
                email = email.replace(new RegExp('@', 'g'), '<wbr>@<wbr>');
                email = email.replace(new RegExp('.', 'g'), '<wbr>.<wbr>');
                email = email.replace(new RegExp('-', 'g'), '<wbr>-<wbr>');
                email = email.replace(new RegExp('_', 'g'), '<wbr>_<wbr>');
            }

            return email;
        }
        return email;
    }

    static linkEmail(email) {
        if (!email) return '';
        return 'mailto:' + email;
    }

    static pessoas = {
        'F': 'Física',
        'J': 'Jurídica'
    }
    static pessoa(codigo) {
        let value = Formatter.pessoas[codigo];
        if (value) return value;
        return '';
    }
    static iconesPessoas = {
        'F': 'person',
        'J': 'briefcase'
    }
    static iconePessoa(codigo) {
        let value = Formatter.iconesPessoas[codigo];
        if (value) return value;
        return undefined;
    }

    static rotulosCPF_CNPJ = {
        'F': 'CPF',
        'J': 'CNPJ'
    }
    static rotuloCPF_CNPJ(codigo) {
        let rotulo = Formatter.rotulosCPF_CNPJ[codigo];
        if (rotulo) {
            return rotulo;
        }
        if (typeof codigo === 'string' && codigo.length === 11) {
            return 'CPF';
        } else if (typeof codigo === 'string' && codigo.length === 14) {
            return 'CNPJ';
        }
        return 'CPF/CNPJ';
    }

    static formatCPF_CNPJ(cpf_cnpj) {
        if (!cpf_cnpj) {
            return '';
        }

        cpf_cnpj = cpf_cnpj.replace(/\./g, "").replace(/-/g, "").replace(/\//g, "");
        if (cpf_cnpj.length === 11) {
            return cpf_cnpj.substring(0, 3) + '.' + cpf_cnpj.substring(3, 6) + '.' + cpf_cnpj.substring(6, 9) + '-' + cpf_cnpj.substring(9, 11);
        } else if (cpf_cnpj.length === 14) {
            return cpf_cnpj.substring(0, 2) + '.' + cpf_cnpj.substring(2, 5) + '.' + cpf_cnpj.substring(5, 8) + '/' + cpf_cnpj.substring(8, 12) + '-' + cpf_cnpj.substring(12, 14);
        } else {
            return cpf_cnpj;
        }
    }

    static formatCEP(codigo) {
        if (!codigo) return codigo;
        codigo = String(codigo).replace(/-/g, "").replace(/\./g, "").replace(/ /g, "");        
        return codigo.substring(0, 5) + '-' + codigo.substring(5, 8);
    }

    static googleAddressArgs(logradouro, numero, bairro, cidade, estado) {
        logradouro = logradouro ? String(logradouro).trim().replace(/\s/g, '+') : undefined;
        numero = numero ? String(numero).trim().replace(/\s/g, '+') : undefined;

        let endereco = logradouro ?
            (numero ? logradouro + ',+' + numero : logradouro) :
            undefined;


        bairro = bairro ? String(bairro).trim().replace(/\s/g, '+') : undefined;
        cidade = cidade ? String(cidade).trim().replace(/\s/g, '+') : undefined;
        estado = estado ? String(estado).trim().replace(/\s/g, '+') : undefined;
        bairro = bairro ?
            (cidade ? bairro + ',+' + cidade : bairro) :
            cidade;

        if (endereco && bairro) {
            endereco = endereco + '+-+' + bairro;
        } else if (bairro) {
            endereco = bairro;
        }

        if (endereco && estado) {
            endereco = endereco + '+-+' + estado;
        } else if (estado) {
            endereco = estado;
        }

        return endereco ? endereco : '';
    }

    static googleMapAddressLink(logradouro, numero, bairro, cidade, estado) {
        let url = 'https://www.google.com.br/maps/place/' + Formatter.googleAddressArgs(logradouro, numero, bairro, cidade, estado);
        return url;
    }

    static googleMapRouteLink(logradouro, numero, bairro, cidade, estado) {
        let url = 'https://www.google.com/maps/dir//' + Formatter.googleAddressArgs(logradouro, numero, bairro, cidade, estado);
        return url;
    }

    static sexos = {
        'F': 'Feminino',
        'M': 'Masculino'
    };
    static sexo(codigo) {
        let value = Formatter.sexos[codigo];
        if (value) return value;
        return '';
    };

    static tiposDocumento = {
        'A': 'Apólice',
        'C': 'Endosso de Cobrança',
        'D': 'Endosso de Documento',
        'E': 'Vida Estipulante',
        'F': 'Fatura',
        'I': 'Endosso Cancelamento de Item',
        'M': 'Endosso sem Prêmio',
        'R': 'Endosso de Restituição',
        'V': 'Vida Carnê',
        'X': 'Endosso Cancelamento de Apólice'
    }
    static documento(codigo) {
        let value = Formatter.tiposDocumento[codigo];
        if (value) return value;
        return '';
    }

    static tiposDocumentoShort = {
        'A': 'Apólice',
        'C': 'Endosso',
        'D': 'Endosso',
        'E': 'Vida Estipulante',
        'F': 'Fatura',
        'I': 'Endosso',
        'M': 'Endosso',
        'R': 'Endosso',
        'V': 'Vida Carnê',
        'X': 'Endosso'
    }
    static documentoShort(codigo) {
        let value = Formatter.tiposDocumentoShort[codigo];
        if (value) return value;
        return 'Documento';
    }


    static coresDocumento = {
        'A': 'blue',
        'C': 'deep-purple',
        'D': 'deep-purple',
        'E': 'green',
        'F': 'indigo',
        'I': 'deep-purple',
        'M': 'deep-purple',
        'R': 'deep-purple',
        'V': 'teal',
        'X': 'deep-purple'
    }
    static corDocumento(codigo) {
        let value = Formatter.coresDocumento[codigo];
        if (value) return value;
        return 'indigo';
    }


    static tiposRamos = {
        1: 'Automovel',
        2: 'Pacotes',
        3: 'Vida',
        4: 'Demais',
        5: 'Resp. Civil',
        6: 'Transportes',
        7: 'Fiança Locatícia',
        8: 'RCF-DC',
        9: 'RCTR-C',
        10: 'RCTR-VI',
        11: 'RR-N',
        12: 'RR-I',
        13: 'Garantia',
        14: 'Penhor Rural e Benf.',
        15: 'Agricola'
    }
    static ramo(codigo) {
        let value = Formatter.tiposRamos[codigo];
        if (value) return value;
        return '';
    }

    static coresRamos = {
        1: 'blue', // Automovel
        2: 'yellow', // Pacotes
        3: 'deep-purple', // Vida
        4: 'blue-gray', // Demais
        5: 'blue-gray', // Resp. Civil',
        6: 'blue-gray', // Transportes
        7: 'yellow-darker', // Fiança Locatícia

        8: 'blue-gray', // 'RCF-DC', //   (Responsabilidade Civil Corporativa - Danos Corporais)
        9: 'blue-gray', //  'RCTR-C', // 
        10: 'blue-gray', //  'RCTR-VI',
        11: 'blue-gray', //  'RR-N',
        12: 'blue-gray', //  'RR-I',

        13: 'blue-gray', // Garantia
        14: 'teal', //:'', // Penhor Rural e Benf.
        15: 'green', //Agricola'
    }
    static corRamo(codigo) {
        let cor = Formatter.coresRamos[codigo];

        if (cor) return cor;
        return undefined;
    }

    static iconesRamos = {
        1: 'carro', // Automovel
        2: 'casa', // Pacotes
        3: 'pessoa', // Vida
        4: 'add', // Demais
        5: 'hammer', // Resp. Civil',
        6: 'bus', // Transportes
        7: 'pessoa', // Fiança Locatícia

        8: 'hammer', // 'RCF-DC', //   (Responsabilidade Civil Corporativa - Danos Corporais)
        9: 'hammer', //  'RCTR-C', // 
        10: 'hammer', //  'RCTR-VI',
        11: 'hammer', //  'RR-N',
        12: 'hammer', //  'RR-I',

        13: 'ribbon', // Garantia
        14: 'hand', //:'', // Penhor Rural e Benf.
        15: 'leaf', //Agricola'
    }
    static iconeRamo(codigo) {
        let icone = Formatter.iconesRamos[codigo];
        if (icone) return icone;
        return undefined;
    }

    static tiposImoveis = {
        1: 'Apartamento',
        2: 'Área Rural',
        3: 'Box',
        4: 'Casa',
        5: 'Sala Comercial',
        6: 'Terreno'
    }
    static tipoImovel(codigo) {
        let value = Formatter.tiposImoveis[codigo];
        if (value) {
            return value;
        }
        return undefined;
    }

    static origens = {
        1: 'Cross Selling',
        2: 'Leads',
        3: 'Perdidos',
        4: 'Renovação'
    }
    static origem(codigo) {
        let value = Formatter.origens[codigo];
        if (value) {
            return value;
        }
        return undefined;
    }

    static iconesOrigens = {
        1: 'cross_selling',
        2: 'leads',
        3: 'perdido',
        4: 'renovacao'
    }
    static iconeOrigem(codigo) {
        let value = Formatter.iconesOrigens[codigo];
        if (value) {
            return value;
        }
        return undefined;
    }


    static coresStatusRenovacao = {
        1: 'green',
        2: 'yellow',
        3: 'red'
    }
    static corStatusRenovacao(codigo) {
        let value = Formatter.coresStatusRenovacao[codigo];
        if (value) {
            return value;
        }
        return undefined;
    }

    static mapBoolean(val, caseTrue, caseFalse, caseUndefined?) {
        if (caseUndefined && (val === undefined || val === null)) {
            return caseUndefined;
        } else if (val) {
            return caseTrue;
        } else {
            return caseFalse;
        }
    }

    static formasDePagamento = {
        1: 'Cheque',
        2: 'Boleto Bancário',
        3: 'Débito em Conta',
        4: 'Cartão de Crédito',
        5: 'Dinheiro',
        6: 'Carnê c/ Cliente',
        7: 'Auto-Financiamento'
    };
    static formaDePagamento(codigo) {
        let value = Formatter.formasDePagamento[codigo];
        if (value) return value;
        return undefined;
    }

    static formatChave(chave: string) {
        if (chave) {
            return chave.replace(/;/g, '_');
        }
        return chave;
    }

    static fipe (fipe: string) {                
        let fipeLimpo = (fipe||'').split('-').join('').replace(/\D/g, '');
        let fipeFormatado = fipeLimpo.length >= 7 ? fipeLimpo.substring(0, 6) + '-' + fipeLimpo.substring(6, 7) : fipeLimpo;
        return fipeFormatado;
    }

    static rotuloSeguradora (seguradoraId) {
        return getRotuloSeguradora(seguradoraId);
    }

    static iconeSeguradora (seguradoraId) {
        return getIiconeSeguradora(seguradoraId);
    }

};