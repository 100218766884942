<div
    class="anexosBox"
    *ngIf="showTitle">
    <h3 *ngIf="!customTitle">
        Anexos {{anexos && anexos.length ? '(' + anexos.length + ')' : ''}}
    </h3>
    <h3 *ngIf="customTitle">
        {{ customTitle }} {{anexos && anexos.length ? '(' + anexos.length + ')' : ''}}
    </h3>
    <button
        *ngIf="anexos && anexos.length"
        mat-icon-button
        matTooltip="Lista"
        (click)="tipoExibicao = 'lista'">
        <mat-icon [svgIcon]="'format-list-bulleted'"></mat-icon>
    </button>
    <button
        *ngIf="anexos && anexos.length"
        mat-icon-button
        matTooltip="Grid"
        (click)="tipoExibicao = 'grid'">
        <mat-icon [svgIcon]="'grid'"></mat-icon>
    </button>
</div>

<!-- FILA DE ENVIO DE ANEXOS -->
<div
    *ngIf="anexosTemp && mostrarAnexos"
    margin-left
    no-padding
    no-margin
    [ngStyle]="{'width':'100%'}"
    [ngClass]="['lista-anexos']">
    <div
        ion-col
        size="12"
        *ngFor="let anexo of anexosTemp"
        [ngStyle]="{'width':'100%'}"
        [ngClass]="['lista-anexos-item']">
        <file-upload
            [anexo]="anexo"
            (onEnterEdition)="entraEdicaoAnexo(anexo)"
            (onSaveEdition)="concluiEdicaoAnexo(anexo)"
            (onCancelEdition)="cancelaEdicaoAnexo(anexo)"
            (onRemove)="deletaAnexo(anexo)"
            (onError)="erroAnexo(anexo)">
        </file-upload>
    </div>
</div>

<!-- ANEXOS ENVIADOS -->
<mat-nav-list class="anexosBox-list anexosBox mb-10" *ngIf="showUpload">
    <mat-list-item class="listaAnexos">
        <span class="fontDefault btnPlus">
            <span class="visibleTitleAnexo">
                <mat-icon [svgIcon]="'paperclip'"></mat-icon>
                ADICIONAR ANEXO
            </span>
            <span class="dropAnexo">Solte os anexos aqui</span>
            <span class="listaAnexos__txtInput visibleTitleAnexo">
                Clique para selecionar ou arraste o anexo para este local
            </span>
            <input
                autocomplete="off"
                #arquivosSelecionados
                padding
                ion-input
                [disabled]="salvandoAnexo"
                id="inputFile"
                (change)="fileChangeEvent($event)"
                [ngClass]="'seletor-de-arquivos inputFile__input'"
                type="file"
                title
                multiple="multiple">
        </span>
    </mat-list-item>
</mat-nav-list>

<mat-nav-list
    class="anexosBox-list"
    *ngIf="anexos.length && tipoExibicao == 'lista'">

    <mat-list-item
        *ngFor="let anexo of anexos"
        [ngClass]="['listaAx', anexo.editMode ? 'mb-32' : '']">

        <div
            class="inputEdit"
            *ngIf="anexo.editMode">
            <!-- Campo de edição do nome -->
            <mat-form-field class="editMode">

                <!-- Campo texto de edição do nome -->
                <input
                    autocomplete="off"
                    matInput
                    placeholder="Nome do arquivo"
                    type="text"
                    [(ngModel)]="anexo.tempName"
                    [disabled]="anexo.salvandoAnexo">
                <mat-hint *ngIf="anexo.editMode && anexo.error">{{anexo?.error}}</mat-hint>
            </mat-form-field>

            <!-- Cancelar -->
            <button
                mat-icon-button
                [disabled]="anexo.salvandoAnexo"
                [ngStyle]="{'margin-right':'5px'}"
                (click)="cancelaEdicaoAnexo(anexo)">
                <mat-icon [svgIcon]="'close'"></mat-icon>
            </button>

            <!-- Salvar -->
            <button
                mat-icon-button
                (click)="concluiEdicaoAnexo(anexo)"
                [disabled]="anexo.salvandoAnexo">
                <mat-icon [svgIcon]="'check'" ></mat-icon>
            </button>
        </div>

        <!-- Link anexo visível quando não está em edição -->
        <div class="anexoLink">
            <a
                [ngClass]="['link', anexo.salvandoAnexo? 'salvandoAnexo': '']"
                [href]="anexo.salvando? null: anexo.fileUrl"
                target="_blank"
                *ngIf="!anexo.editMode">
                <mat-icon
                    [ngStyle]="{'color': anexo.icon?.color}"
                    matListIcon
                    class="buttonIconSvgCustom"
                    [svgIcon]="anexo.icon?.name"></mat-icon>
                <p
                    matLine
                    class="fontDefault mbi-0 personalData">
                    {{anexo.fileName}}
                </p>
            </a>
        </div>

        <!-- Ativa botão de opções (quando o anexo visível não está em edição) -->
        <button
            *ngIf="!anexo.editMode && !anexo.salvandoAnexo"
            [ngClass]="[platformService.virtualDeviceType == 'desktop' ? 'menuAnexo' : '', anexo.open ? 'menuAnexoShow' : '', 'btnMenuAnexo']"
            [matMenuTriggerFor]="menuAnexo"
            (menuOpened)="anexo.open = true"
            (menuClosed)="anexo.open =  false"
            [disabled]="editandoAnexo"
            mat-icon-button>
            <mat-icon [svgIcon]="'dots-vertical'"></mat-icon>
        </button>

        <!-- Icone salvandoAnexo -->
        <mat-icon
            *ngIf="anexo.salvandoAnexo"
            [svgIcon]="'loading'"
            [ngClass]="['loading']"></mat-icon>

        <!-- Menu opções anexo -->
        <mat-menu #menuAnexo="matMenu">
            <button
                mat-menu-item
                (click)="deletaAnexo(anexo)">
                <mat-icon [svgIcon]="'delete'"></mat-icon>
                Excluir anexo
            </button>
            <button
                mat-menu-item
                (click)="entraEdicaoAnexo(anexo)">
                <mat-icon [svgIcon]="'pencil'"></mat-icon>
                Renomear anexo
            </button>
        </mat-menu>
    </mat-list-item>
</mat-nav-list>

<!-- VIEW GRID -->
<div
    class="anexosBox-grid"
    *ngIf="anexos.length && tipoExibicao == 'grid'">

    <div
        *ngFor="let anexo of anexos"
        [ngClass]="['anexosGridFor', anexo.editMode ? 'editFile' : '']">

        <div [ngClass]="['anexoLinkGrid']">
            <!-- Ativa botão de opções (quando o anexo visível não está em edição) -->
            <button
                *ngIf="!anexo.editMode && !anexo.salvandoAnexo"
                [ngClass]="[platformService.virtualDeviceType == 'desktop' ? 'menuAnexo' : '', anexo.open ? 'menuAnexoShow' : '', 'btnMenuAnexo']"
                [matMenuTriggerFor]="menuAnexo"
                (menuOpened)="anexo.open = true"
                (menuClosed)="anexo.open =  false"
                [disabled]="editandoAnexo"
                mat-icon-button>
                <mat-icon [svgIcon]="'dots-vertical'"></mat-icon>
            </button>

            <a
                [ngClass]="['link', anexo.salvandoAnexo? 'salvandoAnexo': '']"
                [href]="anexo.salvando? null: anexo.fileUrl"
                target="_blank">
                <mat-icon
                    [ngStyle]="{'color': anexo.icon?.color}"
                    matListIcon
                    class="buttonIconSvgCustom"
                    [svgIcon]="anexo.icon?.name"></mat-icon>
                <p
                    matLine
                    *ngIf="!anexo.editMode"
                    class="fontDefault textOverflow mbi-0 personalData">
                    {{anexo.fileName}}
                </p>
            </a>

            <div
                class="inputEdit"
                *ngIf="anexo.editMode">

                <mat-form-field class="editMode">
                    <!-- Campo texto de edição do nome -->
                    <input
                        autocomplete="off"
                        matInput
                        placeholder="Nome do arquivo"
                        type="text"
                        [(ngModel)]="anexo.tempName"
                        [disabled]="anexo.salvandoAnexo">
                    <mat-hint *ngIf="anexo.editMode && anexo.error">{{anexo?.error}}</mat-hint>
                </mat-form-field>

                <!-- Cancelar -->
                <button
                    mat-icon-button
                    [disabled]="anexo.salvandoAnexo"
                    [ngStyle]="{'margin-right':'5px'}"
                    (click)="cancelaEdicaoAnexo(anexo)">
                    <mat-icon [svgIcon]="'close'"></mat-icon>
                </button>

                <!-- Salvar -->
                <button
                    mat-icon-button
                    (click)="concluiEdicaoAnexo(anexo)"
                    [disabled]="anexo.salvandoAnexo">
                    <mat-icon [svgIcon]="'check'"  *ngIf="!anexo.salvandoAnexo"></mat-icon>
                     <!-- Icone salvandoAnexo -->
                    <mat-icon
                    *ngIf="anexo.salvandoAnexo"
                    [svgIcon]="'loading'"
                    [ngClass]="['loading']"></mat-icon>
                </button>
            </div>
        </div>


        <!-- Menu opções anexo -->
        <mat-menu #menuAnexo="matMenu">
            <button
                mat-menu-item
                (click)="deletaAnexo(anexo)">
                <mat-icon [svgIcon]="'delete'"></mat-icon>
                Excluir anexo
            </button>
            <button
                mat-menu-item
                (click)="entraEdicaoAnexo(anexo)">
                <mat-icon [svgIcon]="'pencil'"></mat-icon>
                Renomear anexo
            </button>
        </mat-menu>
    </div>
</div>
