import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiCorretoraService } from 'src/app/services/api-corretora.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';

@Component({
  selector: 'filtro-lista-negocios',
  templateUrl: './lista-negocios.component.html',
  styleUrls: ['./lista-negocios.component.scss']
})
export class ListaNegociosComponent implements OnInit {

  @Output('eventFilter') eventFilter = new EventEmitter();
  @Output('clearFilter') limpaFiltro = new EventEmitter();
	@Input('listaUsuarios') listaUsuarios = [];

  erroBuscarUsuarios;
	userSessao;
	checkboxSelected: any;
	avatares = {};
	filterUsersSelected = [];
	arrayPage: any = 'listaNegocios';

  filtroStatus = 0;
  iconesRamos = [
    "home",
    "home-group",
    "briefcase",
    "cash-refund",
    "wrench",
    "alert",
    "shield-plus",
    "flash-alert",
    "account-group",
    "pine-tree",
    "monitor-lock",
    "account-alert",
    "account-tie",
    "credit-card",
    "cash-100",
    "currency-usd",
    "home-city-outline",
    "shield-account",
    "car-side",
    "truck",
    "cash-usd",
    "account-multiple",
    "home-group",
    "tractor",
    "account",
    "ferry",
    "airplane",
    "oil",
    "hospital-box",
    "clipboard-text",
    "car-hatchback",
    "motorbike",
    "arrow-left",
    "earth"
  ]

  constructor(public apiUsuario: ApiUsuarioService, private apiS3: ApiS3Service,public filtroController: FiltroControllerService, private icons: RegisterIconsService, public corretoraService: ApiCorretoraService) {
    icons.registerIcons(this.iconesRamos);
  }

  ngOnInit() {
    for (let _user of this.listaUsuarios) {
			_user['selected'] = false;
		}
		this.existeUsuarioSelecionado()
		this.verificaCheck()
    this.getRamosProdutos();
    this.filtroStatus = this.filtroController?.filtroListaNegocio ? parseInt(this.filtroController?.filtroListaNegocio) : 0;
  }

  ramoSelct
  ramosProdutos = [];
  carregandoRamos: boolean = false;
  async getRamosProdutos() {
    this.carregandoRamos = true;
    try {
      let ret: any = await this.corretoraService.buscarListaProdutosRamos();
      if (ret && ret.merge) {
        let found = ret.merge.filter(arrAtivos => arrAtivos.ativoNaCorretora)
        this.ramosProdutos = found;
        this.ordenaRamos(this.ramosProdutos);
      }

    } catch (error) {
      console.log('erro ao buscar ramos/produtos', error);
    } finally {
      this.carregandoRamos = false;
    }
  }

  ordenaRamos(ramos) {
    let newRamosProdutos = [];
    let removeRamosPadroes = ramos.filter(r => r.nome != 'Residencial' && r.nome != 'Vida (Individual)' && r.nome != 'Empresarial' && r.nome != 'Automóvel');
    removeRamosPadroes.sort((a, b) => {
      if (a && b) {
        let nome1 = a.nome
        let nome2 = b.nome
        if (nome1 < nome2) {
          return -1;
        } else if (nome1 > nome2) {
          return 1;
        } else {
          return 0;
        }
      }
      else if (a) {
        return -1;
      } else if (b) {
        return 1;
      } else {
        return 0;
      }
    });

    //console.log(removeRamosPadroes)

    let ramosPadroes = ramos.filter(r => r.nome === 'Residencial' || r.nome === 'Vida (Individual)' || r.nome === 'Empresarial' || r.nome === 'Automóvel');
    ramosPadroes.sort((a, b) => {
      if (a && b) {
        let nome1 = a.nome
        let nome2 = b.nome
        if (nome1 < nome2) {
          return -1;
        } else if (nome1 > nome2) {
          return 1;
        } else {
          return 0;
        }
      }
      else if (a) {
        return -1;
      } else if (b) {
        return 1;
      } else {
        return 0;
      }
    });

    //console.log(ramosPadroes)

    for (let rp of ramosPadroes) {
      newRamosProdutos.push(rp);
    }
    for (let rrp of removeRamosPadroes) {
      newRamosProdutos.push(rrp);
    }
    //console.log(newRamosProdutos);
    return this.ramosProdutos = newRamosProdutos;
  }

  buildString() {
    this.filtroController.addFiltrosExtras('listaNegocios', this.filtroStatus);
    let rawValue = {
      status: this.filtroController.filtroListaNegocio,
      userSelected: this.filtroController.usersListNegocios
    };
    if (this.ramoSelct) {
      this.filtroController.addFiltrosExtras('filtroRamoLista', this.ramoSelct);
      rawValue['ramo'] = this.ramoSelct;
    }
    let filtros = { values: rawValue };
    this.filtroController.verificaFiltro();
    this.eventFilter.emit(filtros);

  }

  clearFilter() {
    this.filterUsersSelected = [];
		this.checkboxSelected = false;
		for (let user of this.listaUsuarios) {
			user['selected'] = false;
			user['selectedDefault'] = user.id == this.userSessao ? true : false;
		}
    this.filtroStatus = 0;
    this.ramoSelct = '';
    this.filtroController.limparFiltro('filtroRamoLista');
    this.filtroController.limparFiltro('listaNegocios');
    this.filtroController.verificaFiltro();
    this.limpaFiltro.emit();
    this.buildString();
  }

  verificaCheck() {
		this.checkboxSelected = this.filtroController.usersKanban.length === this.listaUsuarios.length ? true : false;
	}

  existeUsuarioSelecionado() {
		//seleciona os usuarios
		for (let user of this.listaUsuarios) {
			for (let id of this.filtroController.usersKanban) {
				if (user.id === id) {
					user['selected'] = true;
				}
			}
		}
	}

	filterUserSelect(user) {
		for (let _user of this.listaUsuarios) {
			if (_user.id == user.id) {
				if (user.hasOwnProperty('selected')) {
					_user['selected'] = !user['selected'];
					if (_user['selected']) {
						this.filterUsersSelected.push(user.id);
						this.filtroController.addUser(user.id, this.arrayPage);
					} else {
						let index = this.filterUsersSelected.findIndex(u => u == user.id);
						this.filterUsersSelected.splice(index, 1);
						this.filtroController.removeUser(user.id, this.arrayPage);
					}
				} else {
					_user['selected'] = true;
					this.filterUsersSelected.push(user.id);
					this.filtroController.addUser(user.id, this.arrayPage);
				}
			}
			_user['selectedDefault'] = false;
		}
		this.verificaCheck();
	}

  selectAll() {
		if (this.checkboxSelected) {
			for (let _user of this.listaUsuarios) {
				_user['selected'] = true;
				this.filtroController.addUser(_user.id, this.arrayPage)
				this.filterUsersSelected.push(_user.id);
			}
			this.verificaCheck()
		} else {
			for (let _user of this.listaUsuarios) {
				_user['selected'] = false;
				this.filtroController.removeUser(_user.id, this.arrayPage);
			}
			this.verificaCheck()
			this.filterUsersSelected = [];
		}
	}

}
