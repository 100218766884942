<div mat-dialog-title [ngStyle]="{'display':'flex'}" [ngClass]="[shadowHeader ? 'scrolllShadow' : '', 'detalhesCliente']" *ngIf="!carregando">
    <button class="btnBack-desktop" *ngIf="negocio" mat-icon-button (click)="voltaTelaNegocio.emit(true)">
        <mat-icon svgIcon="arrow-left"></mat-icon>
    </button>
    <div class="titleCliente">
        <h3 no-margin class="personalData">{{ cliente?.nome }}</h3>
        <span class="fontSmall" *ngIf="cliente">{{ cliente?.ativo ? 'Ativo' : 'Inativo' }}</span>
    </div>
    <div [ngStyle]="{'margin-left':'auto'}" class="boxBtns">
        <button class="btnBack-mobile" *ngIf="negocio" mat-icon-button (click)="voltaTelaNegocio.emit(true)">
            <mat-icon  [svgIcon]="'arrow-left'"></mat-icon>
        </button>
        <button mat-icon-button (click)="changeFavorito(cliente?.id, cliente?.favorito)" class="boxBtns-stars">
            <mat-icon
                [svgIcon]="favorito ? 'star' : 'star-outline'"
                [ngStyle]="{'color' : favorito ? '#ef712a' : 'inherit'}"></mat-icon>
        </button>
        <button mat-icon-button (click)="openCadastroCliente = true">
            <mat-icon [svgIcon]="'pencil'"></mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon [svgIcon]="'dots-vertical'"></mat-icon>
        </button>
        <button class="btnCloseModal-desktop" mat-icon-button *ngIf="!negocio" (click)="closeModal()">
            <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
        <button class="btnCloseModal-mobile" mat-icon-button *ngIf="!negocio" (click)="closeModal()">
            <mat-icon [svgIcon]="'close'"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="desativarAtivar()">
                <mat-icon [svgIcon]="cliente?.ativo ? 'block-helper' : 'checkbox-blank-circle-outline'" [ngClass]="['pt-1']"></mat-icon >
                        {{ cliente && cliente?.ativo ? 'Desativar' : 'Ativar' }}
            </button>
        </mat-menu>
    </div>
</div>

<loading-page *ngIf="carregando"></loading-page>

    <mat-dialog-content class="containerCliente" #content *ngIf="!carregando">

        <div [ngClass]="['actionButtons', cliente?.fone && cliente?.fones[0] && cliente?.fones[0].tipo == 'whatsApp' ? '' : 'flex-end']" *ngIf="cliente && cliente?.fone">
                <button mat-stroked-button *ngIf="cliente?.fone && cliente?.fones[0] &&  cliente?.fones[0].tipo == 'whatsApp'" class="btn-primary-ap icon-inside-btn" color="primary"
                 (click)="btnWhats(cliente?.fones[0].numero)">
                 <mat-icon matListIcon [svgIcon]="'whatsapp'"></mat-icon> WhatsApp</button>


                <button mat-stroked-button class="btn-primary-ap icon-inside-btn" color="primary" *ngIf="cliente?.fones[0] && cliente?.fones[0].numero" (click)="btnFone(cliente?.fones[0].numero)">
                <mat-icon matListIcon [svgIcon]="'phone'"></mat-icon> Ligar</button>
        </div>

        <mat-list [ngClass]="['listaDadosCliente', cliente?.fone ||  cliente?.fones[0] && cliente?.fones[0].tipo == 'whatsApp' ? 'temFone' : '' ]">
            <mat-list-item *ngIf="cliente && cliente?.cpfCnpj">
                <mat-icon matListIcon [svgIcon]="'card-account-details'"></mat-icon>
                <p matLine class="fontDefault personalData">
                    {{formatter.formatCPF_CNPJ(cliente?.cpfCnpj)}}
                    <span class="fontSmall">
                     - NIF
                    </span>
                </p>
            </mat-list-item>
            <mat-list-item *ngFor="let fone of cliente?.fones; let i = index">
                <mat-icon matListIcon [svgIcon]="'phone-outline'" [ngStyle]="{'visibility': i == 0 ? 'unset' : 'hidden'}"></mat-icon>
    
                <div matLine>
                    <a class="fontDefault personalData" href="tel:{{cliente?.fones && fone.numero ? fone.numero : ''}}">
                        {{fone.numero}}            
                    </a>                   
                </div>
    
            </mat-list-item>
            <mat-list-item *ngFor="let email of cliente?.emails; let i = index">
                <mat-icon matListIcon [svgIcon]="'email-outline'" [ngStyle]="{'visibility': i == 0 ? 'unset' : 'hidden'}"></mat-icon>
                <a mat-line href="mailto: {{ email && email.email ? email.email : ''}}" class="fontDefault personalData">
                    {{email.email}}
                </a>
    
            </mat-list-item>
            <mat-list-item *ngIf="cliente && cliente?.dataNas">
                <mat-icon matListIcon [svgIcon]="'cake-variant'"></mat-icon>
                <p matLine class="fontDefault">
                    {{cliente?.dataNas ? formatter.dateDDMMAAAA(cliente?.dataNas) : ''}}
                </p>
            </mat-list-item>
            <mat-list-item *ngFor="let end of cliente?.listaEnderecos; let i = index">
                <mat-icon matListIcon [svgIcon]="'map-marker-outline'" [ngStyle]="{'visibility': i == 0 ? 'unset' : 'hidden'}"></mat-icon>
                <a [href]="end.link" target="_system" matLine class="fontDefault personalData">
                    {{end?.enderecoFormatado}}
                </a>
            </mat-list-item>
            <mat-list-item *ngIf="cliente && cliente?.dataPrimHabil">
                <mat-icon matListIcon [svgIcon]="'card-account-details-outline'"></mat-icon>
                <p matLine class="fontDefault">
                    {{cliente?.dataPrimHabil ? formatter.dateDDMMAAAA(cliente?.dataPrimHabil) : ''}}
                </p>
            </mat-list-item>
            <mat-list-item *ngIf="cliente && cliente?.sexo">
                <mat-icon matListIcon [svgIcon]="cliente?.sexo == 'M'? 'gender-male': 'gender-female'"></mat-icon>
                <p matLine class="fontDefault">
                    {{cliente?.sexo == 'M'? 'Masculino': 'Feminino'}}
                </p>
            </mat-list-item>
            <mat-list-item *ngIf="cliente && cliente?.estadoCivil">
                <mat-icon matListIcon [svgIcon]="'ring'"></mat-icon>
                <p matLine class="fontDefault">
                    {{cliente?.estadoCivilTxt}}
                </p>
            </mat-list-item>
        </mat-list>
    
        <div class="mt-16 mb-16 observacaoDetalheCli sem-linha">
            <mat-form-field>
                <mat-label>Observação</mat-label>
                <textarea cdkTextareaAutosize cdkAutosizeMinRows="2" matInput placeholder="Adicione uma observação" (blur)="adicionarObservacao($event.target)" [ngStyle]="{'width':'100%'}" [(ngModel)]="observacao"></textarea>
            </mat-form-field>
    
            <mat-divider class="mt-16"></mat-divider>
        </div>
    
        <gerenciador-arquivos [path]="'clientes'" [id]="clienteId" [customitle]="'Anexos do cliente'"></gerenciador-arquivos>
        <gerenciador-arquivos
            [path]="'negocios'"
            [id]="negId"
            (qtdeAnexos)="verificaQtdeAnexos($event)"
            [showUpload]="false"
            [customitle]="'Anexos de negócios do cliente'"
            [ngStyle]="{'visibility': qtdeAnexosNegocio === 0 ? 'hidden' : ''}"></gerenciador-arquivos>
        <!-- <div style="padding-bottom: 16px;margin-bottom: 16px;"></div> -->
        <div class="outros-anexos" *ngIf="outrosAnexos.length > 0">
            <span class="fontDefault" [ngStyle]="{'padding-bottom':'4px'}">Documentos</span>
            <mat-nav-list class="outros-anexos__lista">
                <mat-list-item *ngFor="let anexo of outrosAnexos" (click)="openDocument(anexo)">
                    <div class="row-lista">
                        <mat-icon [svgIcon]="'file-download-outline'"></mat-icon>
                        <span class="fontDefault personalData" [ngStyle]="{'padding-left':'4px'}">{{ anexo.descricao }}</span>
                    </div>
                </mat-list-item>
            </mat-nav-list>
        </div>
    </mat-dialog-content>

<div [ngClass]="['formularioCliente', openCadastroCliente ? 'open' : '']">
    <cliente-form *ngIf="openCadastroCliente" [negocio]="true" [clienteId]="cliente?.id" (dadosClienteNegocio)="dadosClienteNegocio.emit($event)" (voltaTelaNegocio)="atualizarCliente()">
    </cliente-form>
</div>

<mat-dialog-actions class="actions-buttons" *ngIf="!carregando">   
    <button mat-button class="btn-primary-ap" (click)="salvarCliente()" [disabled]="!informationChanged || salvandoCliente">
	    SALVAR
		<mat-icon *ngIf="salvandoCliente" [svgIcon]="'loading'" [ngClass]="['loading']" [ngStyle]="{'margin-left':'2px'}">
		</mat-icon>
	</button>
</mat-dialog-actions>