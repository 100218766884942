import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadMultiComponent } from './file-upload-multi.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [FileUploadMultiComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule, 
    MatListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  exports: [FileUploadMultiComponent, FileUploadModule]
})
export class FileUploadMultiModule { }
