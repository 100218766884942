import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkVideoComponent } from './link-video.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [LinkVideoComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports: [LinkVideoComponent]
})
export class LinkVideoModule { }
