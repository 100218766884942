import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';

@Component({
	selector: 'filtro-tarefas',
	templateUrl: './tarefas.component.html',
	styleUrls: ['./tarefas.component.scss']
})
export class TarefasComponent implements OnInit {

	@Output('eventFilter') eventFilter = new EventEmitter();
	@Output('clearFilter') limpaFiltro = new EventEmitter();

	erroBuscarUsuarios: boolean = false;
	userSessao;
	avatares = {};
	checkboxSelected:any;
	listaUsuarios = [];
	filterUsersSelected = [];
	filtroStatus = 1;
	arrayPage: any = 'tarefas';

	constructor(public apiUsuario: ApiUsuarioService, private apiS3: ApiS3Service, public filtroController: FiltroControllerService ) { }

	ngOnInit() {
		this.filtroStatus = this.filtroController.filtroStatusTarefas; 
		this.getUsuarios();
		this.existeUsuarioSelecionado();
		this.verificaCheck()
	}

	existeUsuarioSelecionado() {
		//seleciona os usuarios
		for(let user of this.listaUsuarios) {
			for(let id of this.filtroController.usersTarefas) {
				if(user.id === id) {
					user['selected'] = true;
				}
			}
		}
	}

	async getUsuarios() {
		this.erroBuscarUsuarios = false;
		try {			
			let usuarios: any;
			if(this.apiUsuario.listaUsuarios.length){
				usuarios = this.apiUsuario.listaUsuarios;
			}else{
				usuarios = await this.apiUsuario.buscaUsuarios2();	
				this.apiUsuario.listaUsuarios = usuarios;
			}
			this.userSessao = this.apiUsuario.getCredenciais();
			usuarios = usuarios.filter(u => u.ativo == true && u.confirmacao == false && u.tipoRedefinicaoSenha != 'novo');
			for (let user of usuarios) {
				if (!this.apiUsuario.avatares[user.id]) {
					this.apiUsuario.avatares[user.id] = await this.getAvatarUsuario(user.id);
				}
				user['img'] = this.apiUsuario.avatares[user.id];
				if (this.userSessao && this.userSessao == user.id) {
					user['selectedDefault'] = true;
				}
			}
			this.listaUsuarios = usuarios;
			for (let _user of this.listaUsuarios) {
				_user['selected'] = false;
			}
		} catch (error) {
			this.erroBuscarUsuarios = true;
		}
	}

	async getAvatarUsuario(usuarioId) {
		try {
			let ret = await this.apiS3.getAvatarUsuario(usuarioId);
			return ret
		} catch (error) {
			return ''
		}
	}

	filterUserSelect(user) {
		for (let _user of this.listaUsuarios) {
			if (_user.id == user.id) {
				if (user.hasOwnProperty('selected')) {
					_user['selected'] = !user['selected'];
					if (_user['selected']) {
						this.filterUsersSelected.push(user.id);
						this.filtroController.addUser(user.id, this.arrayPage);
					} else {
						let index = this.filterUsersSelected.findIndex(u => u == user.id);
						this.filterUsersSelected.splice(index, 1);
						this.filtroController.removeUser(user.id, this.arrayPage);
					}
				} else {
					_user['selected'] = true;
					this.filterUsersSelected.push(user.id);
					this.filtroController.addUser(user.id, this.arrayPage);
				}
			}
			_user['selectedDefault'] = false;
		}
		this.verificaCheck();
	}

	buildString() {
		this.filtroController.addFiltrosExtras(this.arrayPage, this.filtroStatus);
		let rawValue = {
			status: this.filtroController.filtroStatusTarefas,
			userSelected: this.filtroController.usersTarefas
		};
		this.filtroController.verificaFiltro();
		let filtros = { values: rawValue };
		this.eventFilter.emit(filtros);

	}

	clearFilter() {
		this.checkboxSelected = false;
		this.filterUsersSelected = [];
		for (let user of this.listaUsuarios) {
			user['selected'] = false;
			user['selectedDefault'] = user.id == this.userSessao ? true : false;
		}
		this.filtroStatus = 1;
		this.filtroController.limparFiltro(this.arrayPage);
		this.filtroController.verificaFiltro();
		this.limpaFiltro.emit();
		this.buildString();
	}

	selectAll() {
		if(this.checkboxSelected) {
			for (let _user of this.listaUsuarios) {
				_user['selected'] = true;
				this.filtroController.addUser(_user.id, this.arrayPage)
				this.filterUsersSelected.push(_user.id);
			}
			this.verificaCheck()
		} else {
			for (let _user of this.listaUsuarios) {
				_user['selected'] = false;
				this.filtroController.removeUser(_user.id, this.arrayPage);
			}
			this.verificaCheck()
			this.filterUsersSelected = [];
		}
	}

	verificaCheck() {
		this.checkboxSelected = this.filtroController.usersTarefas.length === this.listaUsuarios.length ? true : false;
	}

}
