<div
    mat-dialog-title
    [ngClass]="[shadowHeader ? 'scrolllShadow' : '']"
    [ngStyle]="{'display':'flex','align-items':'center'}">
    <button
        mat-icon-button
        (click)="voltarTelaNegocio()"
        *ngIf="negocio">
        <mat-icon [svgIcon]="'arrow-left'"></mat-icon>
    </button>
    <h3 [ngStyle]="{'margin': '10px 0 10px 6px'}">
        {{idCliente ? 'Editando Cliente' : 'Novo Cliente'}}
    </h3>
    <button
        *ngIf="!idCliente"
        mat-icon-button
        mat-dialog-close
        tabindex="-1"
        [ngStyle]="{'margin': '4px 4px 0 auto',  'color': '#727272'}"
        [disabled]="salvando">
        <mat-icon
            *ngIf="!salvando"
            [svgIcon]="'close'"></mat-icon>
        <mat-icon
            *ngIf="salvando"
            [svgIcon]="'loading'"
            [ngClass]="['loading']"></mat-icon>
    </button>
</div>

<loading-page
    *ngIf="carregando"
    class="loading"></loading-page>

<mat-dialog-content #content>

    <error-page *ngIf="!carregando && erro">
        <h2>Falha ao buscar cliente</h2>
        <h4>Tente novamente</h4>
        <button
            mat-button
            (click)="reload()"
            color="primary"
            [ngStyle]="{margin:'8px'}">
            <mat-icon [svgIcon]="'refresh'"></mat-icon>
            Atualizar
        </button>
    </error-page>

    <div
        class="animated zoomIn faster"
        *ngIf="!carregando">
        <div
            no-margin
            no-padding
            class="card">
            <form [formGroup]="formCliente">
                <div>
                    <h3 color="primary">Cliente</h3>

                    <!-- PESSOA -->
                    <!-- 
                    <div>
                        <mat-radio-group
                            (change)="mudaPessoa()"
                            [formControl]="formCliente.controls.rdPessoa">
                            <mat-label>Pessoa</mat-label>
                            <br>
                            <mat-radio-button value="F">Física</mat-radio-button>
                            <mat-radio-button value="J">Jurídica</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    -->

                    <!-- NOME -->
                    <div class="mt-16">
                        <mat-form-field>
                            <mat-icon
                                matPrefix class="mr-8"
                                [svgIcon]="'account'" ></mat-icon>

                            <input
                                class="personalData"
                                autocomplete="off"
                                placeholder="* Nome"
                                [formControl]="formCliente.controls.nome"
                                maxlength="50"
                                matInput
                                type="text"
                                debounce-validation
                                [validate-on-begin]="idCliente">

                            <mat-error *ngIf="errorMessage(formCliente.controls.nome)">
                                {{errorMessage(formCliente.controls.nome)}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- CPF/CNPJ -->
                    <div class="mt-16">
                        <mat-form-field>
                            <mat-icon
                                matPrefix class="mr-8"
                                [svgIcon]="'card-account-details'">
                            </mat-icon>

                            <input
                                class="personalData"
                                autocomplete="off"
                                placeholder="NIF/NIPC"
                                matInput
                                type="text"
                                inputmode="numeric"
                                [mask]="masksPortugal.NIF"
                                [formControl]="formCliente.controls.cpfCnpj"
                                debounce-validation="500"
                                [validate-on-keydown]="true"
                                [validate-on-begin]="idCliente">

                            <mat-error *ngIf="errorMessage(formCliente.controls.cpfCnpj)">
                                {{errorMessage(formCliente.controls.cpfCnpj)}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- NASCIMENTO -->
                    <div
                        class="mt-16"
                        >
                        <mat-form-field>

                            <mat-icon
                                matPrefix class="mr-8"
                                [svgIcon]="'cake-variant'"
                                ></mat-icon>

                            <input
                                autocomplete="off"
                                matInput
                                placeholder="Data de Nascimento"
                                [formControl]="formCliente.controls.dataNas"
                                type="text"
                                [dropSpecialCharacters]="false"
                                [mask]="masks.DD_MM_AAAA"
                                inputmode="numeric"
                                debounce-validation="500"
                                [validate-on-keydown]="true"
                                [validate-on-begin]="idCliente">

                            <mat-error *ngIf="errorMessage(formCliente.controls.dataNas)">
                                {{errorMessage(formCliente.controls.dataNas)}}
                            </mat-error>

                            <mat-icon
                                matSuffix
                                [svgIcon]="'calendar'"
                                (click)="openCalendar(picker,formCliente.controls.dataNas, formCliente.controls.dataNasInv, $event)"></mat-icon>
                            <mat-datepicker #picker></mat-datepicker>

                            <input
                                [matDatepicker]="picker"
                                (dateChange)="onChange($event, formCliente.controls.dataNas)"
                                [ngStyle]="{display: 'none'}"
                                [formControl]="formCliente.controls.dataNasInv">
                        </mat-form-field>

                    </div>

                    <!-- SEXO -->
                    <div>
                        <mat-radio-group [formControl]="formCliente.controls.sexo">
                            <mat-label>Género</mat-label>
                            <br>
                            <mat-radio-button value="M">Masculino</mat-radio-button>
                            <mat-radio-button value="F">Feminino</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <!-- ESTADO CIVIL -->
                    <div
                        class="mt-16"
                        *ngIf="rotuloCpfCnpj == 'NIF/NIPC'">
                        <mat-form-field>

                            <mat-icon
                                matPrefix class="mr-8"
                                [svgIcon]="'ring'"></mat-icon>

                            <mat-select
                               
                                placeholder="Estado"
                                [formControl]="formCliente.controls.estadoCivil"
                                [compareWith]="compareFn">
                                <mat-option
                                   
                                    [value]="estadoCivil.value"
                                    *ngFor="let estadoCivil of estadosCivis">
                                    {{estadoCivil.nome}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="errorMessage(formCliente.controls.estadoCivil)">
                                {{errorMessage(formCliente.controls.estadoCivil)}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>

                <!-- E-MAILS -->
                <div>                   
                    <span color="primary" class="fontHelvetica16">E-mails</span>

                    <!-- E-MAIL PRINCIPAL -->
                    <div class="mt-16">
                        <mat-form-field>

                            <mat-icon
                                matPrefix class="mr-8"
                                [svgIcon]="'email'"></mat-icon>

                            <input
                                class="personalData"
                                autocomplete="off"
                                matInput
                                placeholder="E-mail Principal"
                                [formControl]="formCliente.controls.email"
                                type="text"
                                inputmode="email"
                                debounce-validation
                                [validate-on-begin]="idCliente">

                            <mat-error *ngIf="errorMessage(formCliente.controls.email)">
                                {{errorMessage(formCliente.controls.email)}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <mat-divider></mat-divider> -->

                    <!-- E-MAILS ALTERNATIVOS -->
                    <div
                    
                        *ngFor="let formEmail of formEmails; let i = index">

                        <form
                            [formGroup]="formEmail.formgroup"
                            fill-width>

                            <mat-form-field>
                                <mat-icon
                                    matPrefix class="mr-8"
                                    [svgIcon]="'email'"></mat-icon>

                                <input
                                    class="personalData"
                                    autocomplete="off"
                                    placeholder="E-mail Alternativo"
                                    matInput
                                    type="text"
                                    inputmode="email"
                                    [formControl]="formEmail.formgroup.controls.email">

                                <mat-icon
                                    matSuffix
                                    [svgIcon]="'close-circle'"
                                    (click)="removeEmail(i)"></mat-icon>

                                <mat-error *ngIf="errorMessage(formEmail.formgroup.controls.email)">
                                    {{errorMessage(formEmail.formgroup.controls.email)}}
                                </mat-error>
                            </mat-form-field>
                        </form>
                    </div>

                    <div>
                        <button
                            mat-button
                            color="primary"
                            (click)="criaEmails()">
                            <mat-icon [svgIcon]="'plus-circle'" color="primary"></mat-icon>
                            Adicionar E-mail
                        </button>
                    </div>

                </div>
                <div class="mt-16">
                    <span color="primary" class="fontHelvetica16">Telefones</span>

                    <!-- TELEFONE PRINCIPAL -->
                    <div class="mt-16">
                        <div>
                            <mat-form-field>
                                <input
                                    class="personalData"
                                    autocomplete="off"
                                    matInput
                                    placeholder="Telefone Principal"
                                    [formControl]="formCliente.controls.fone"
                                    type="text"
                                    inputmode="numeric"
                                    [mask]="masks.getFoneMask(formCliente.controls.fone.value)"
                                    debounce-validation
                                    [validate-on-begin]="idCliente">

                                <mat-icon
                                    matPrefix class="mr-8"
                                    [svgIcon]="'phone'"></mat-icon>
                                <mat-error *ngIf="errorMessage(formCliente.controls.fone)">
                                    {{errorMessage(formCliente.controls.fone)}}
                                </mat-error>
                            </mat-form-field>

                            <mat-checkbox [formControl]="formCliente.controls.whatsApp">
                                Este número possuí
                                WhatsApp
                            </mat-checkbox>

                        </div>
                    </div>

                    <div
                    
                        *ngFor="let formFone of formFones; let i = index">
                        <!-- TELEFONES ALTERNATIVOS -->
                        <form
                            style="width: 100%"
                            [formGroup]="formFone.formgroup">
                            <mat-form-field>
                                <mat-icon
                                    matPrefix class="mr-8"
                                    [svgIcon]="'phone'"></mat-icon>

                                <input
                                    class="personalData"
                                    autocomplete="off"
                                    placeholder="Telefone Alternativo"
                                    matInput
                                    [mask]="masks.getFoneMask(formFone.formgroup.controls.numero.value)"
                                    type="text"
                                    inputmode="numeric"
                                    [formControl]="formFone.formgroup.controls.numero">

                                <mat-icon
                                    matSuffix
                                    [svgIcon]="'close-circle'"                                   
                                    (click)="removeTelefone(i)"></mat-icon>

                            </mat-form-field>

                        </form>

                    </div>

                    <div class="mt-16">
                        <button
                            mat-button
                            color="primary"
                            (click)="criaFones()">
                            <mat-icon [svgIcon]="'plus-circle'" color="primary"></mat-icon>
                            Adicionar Telefone
                        </button>
                    </div>

                </div>
            </form>

            <!-- ENDEREÇOS -->          
            <div class="mt-16">
                <span color="primary" class="fontHelvetica16">Endereços</span>
            </div>         
            <div *ngIf="!escondeFormEndereco">
                <div *ngFor="let form of formsEnderecos; let i = index">
                   
                    <form [formGroup]="form.formgroup">
                        <div class="mt-16">
                            <!-- CEP -->
                            <div
                            
                                [ngStyle]="{'display': 'flex', 'align-items':'center','flex-wrap':'wrap'}">
                                <mat-form-field
                                    [floatLabel]="false"
                                    [ngStyle]="{'width': '180px'}">
                                    <mat-icon
                                        matPrefix class="mr-8"
                                        [svgIcon]="'map-marker'"></mat-icon>
                                    <input
                                        class="personalData"
                                        autocomplete="off"
                                        matInput
                                        placeholder="Código Postal"
                                        type="text"
                                        inputmode="numeric"
                                        [formControl]="form.formgroup.get('cep')"
                                        [mask]="masksPortugal.CEP"
                                        ngxDebouncing
                                        [debounceTimer]="1000"
                                        (debounceFunction)="buscaCEP(form)">
                                    <mat-icon
                                        matSuffix
                                        [svgIcon]="buscandoCEP ? 'loading' : 'magnify'"
                                        [ngClass]="[buscandoCEP ? 'loading' : '']"
                                        (click)="buscaCEP(form)"></mat-icon>
                                    <mat-error *ngIf="errorMessage(form.formgroup.get('cep'))">
                                        {{errorMessage(form.formgroup.get('cep'))}}
                                    </mat-error>
                                </mat-form-field>
                                <div class="removeEnd">
                                    <mat-checkbox
                                        [formControl]="form.formgroup.get('principal')"
                                        (click)="mudaEnderecoPrincipal(i)">
                                        Endereço Principal
                                    </mat-checkbox>
                                    <button
                                        mat-button
                                        color="primary"
                                        class="icon-inside-btn"
                                        (click)="removeEndereco(i,form)"
                                        [disabled]="form.removendoEndereco">
                                        <mat-icon [svgIcon]="form.removendoEndereco ? 'loading' : 'close-circle'" [ngClass]="[form.removendoEndereco ? 'loading' : '']"></mat-icon>
                                        Remover
                                    </button>
                                </div>
                            </div>

                            <!-- Logradouro -->
                            <div>
                                <mat-form-field>
                                    <input
                                        class="personalData"
                                        autocomplete="off"
                                        placeholder="Morada"
                                        matInput
                                        type="text"
                                        (blur)="form.habilitarFuncao ? VerificaCampoEditado(form,i) : ''"
                                        [formControl]="form.formgroup.controls.residLogradouro">
                                </mat-form-field>
                            </div>

                            <!-- Número -->
                            <div
                            
                                size-md="4">
                                <mat-form-field>
                                    <input
                                        class="personalData"
                                        autocomplete="off"
                                        placeholder="Número"
                                        (blur)="form.habilitarFuncao ? VerificaCampoEditado(form,i) : ''"
                                        matInput
                                        type="text"
                                        inputmode="numeric"
                                        [formControl]="form.formgroup.controls.residNumero">
                                </mat-form-field>
                            </div>

                            <!-- Complemento -->
                            <div
                            
                                size-md="8">
                                <mat-form-field>
                                    <input
                                        class="personalData"
                                        autocomplete="off"
                                        placeholder="Complemento"
                                        (blur)="form.habilitarFuncao ? VerificaCampoEditado(form,i) : ''"
                                        matInput
                                        type="text"
                                        [formControl]="form.formgroup.controls.residComplemento">
                                </mat-form-field>
                            </div>

                            <!-- Bairro -->
                            <div>
                                <mat-form-field>
                                    <input
                                        class="personalData"
                                        autocomplete="off"
                                        placeholder="Bairro"
                                        (blur)="form.habilitarFuncao ? VerificaCampoEditado(form,i) : ''"
                                        matInput
                                        type="text"
                                        [formControl]="form.formgroup.controls.residBairro">
                                </mat-form-field>
                            </div>

                            <!-- Cidade -->
                            <div
                            
                                size-md="6">
                                <mat-form-field>
                                    <input
                                        class="personalData"
                                        autocomplete="off"
                                        matInput
                                        type="text"
                                        placeholder="Cidade"
                                        (blur)="form.habilitarFuncao ? VerificaCampoEditado(form,i) : ''"
                                        [formControl]="form.formgroup.controls.residCidade">
                                </mat-form-field>
                            </div>

                            <!-- UF -->
                            <div
                            
                                size-md="6">
                                <mat-form-field>
                                    <mat-label>Distrito</mat-label>
                                    <mat-select name="estado" autocomplete="off" [formControl]="form.formgroup.controls.residUF">
                                        <mat-option *ngFor="let estado of estados" [value]="estado.value"
                                            (blur)="form.habilitarFuncao ? VerificaCampoEditado(form,i) : ''">
                                            {{estado.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="errorMessage(form.formgroup.controls.residUF)">
                                        {{errorMessage(form.formgroup.controls.residUF)}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                        </div>

                    </form>

                </div>

            </div>

            <div>
                <div>
                    <button
                        mat-button
                        color="primary"
                        (click)="criarEndereco()">
                        Adicionar Endereço
                    </button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions
  class="actions-buttons"
    *ngIf="!carregando">
    <button
        mat-button
        class="def-w-btn"
        mat-dialog-close
        [disabled]="salvando || !formCliente.get('nome').valid"
        *ngIf="!negocio">
        CANCELAR
    </button>
    <button
        mat-button
        class="def-b-btn"
        (click)="salvarCliente()"
        [disabled]="salvando || !formCliente.get('nome').valid"
        color="primary">
        SALVAR
        <mat-icon
            [svgIcon]="salvando ? 'loading' : 'check'"
            [ngClass]="[salvando ? 'loading' : '']"
            [ngStyle]="{'margin-left':'2px'}"></mat-icon>
    </button>
</mat-dialog-actions>
