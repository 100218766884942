
<div>
    <div [ngClass]="['filterPage__filtroUser']">
        <div class="filterPage__titles">
            <span>Por Utilizadores</span>
            <mat-checkbox class="checkboxFD" [(ngModel)]="checkboxSelected" (change)="selectAll()"><span>Selecionar todos</span></mat-checkbox>
        </div>
        <div [ngClass]="['filterPage__filtroUser-users']">
            <div *ngFor="let user of listaUsuarios" (click)="filterUserSelect(user)" [ngStyle]="{'margin-bottom':'4px'}" [matTooltip]="user ? user.nome : ''">
                <img [src]="user.img" onError="this.src='assets/img/usuarioAnonimo.png'">
                <div [ngClass]="['filterPage__filtroUser-users-selected']" *ngIf="user.selected || user.selectedDefault"></div>
            </div>
        </div>
    </div>

    <mat-form-field *ngIf="ramosProdutos.length">
        <mat-radio-group>
            <mat-select placeholder="Ramo" [(ngModel)]="ramoSelct">
                <mat-option
                [value]="ramo.id"
                *ngFor="let ramo of ramosProdutos">
                <mat-icon [svgIcon]="ramo.icone"></mat-icon>
                {{ramo.nome}}
            </mat-option>
            </mat-select>
        </mat-radio-group>
    </mat-form-field>

    <div class="actionButtonsFiltros">
        <button mat-button class="btn-primary-ap" (click)="buildString()">FILTRAR</button>
        <button mat-button class="btn-back" (click)="clearFilter()">LIMPAR FILTROS</button>
    </div>
</div>