<div class="filter__header"> 
    <div class="filter__header__title">
        <div class="filter__header__title__label">          
            <mat-icon class="filter__header__title__label-icon" [svgIcon]="'filter'"></mat-icon>
            <div class="fontHelvetica16"> Filtros </div>
            <button mat-icon-button class="filter__header__title__label-close" (click)="closeFilter()">
                <mat-icon [svgIcon]="'close'"></mat-icon>
            </button>
        </div>
    </div>

    <div class="filter__header__division" *ngIf="numFilter && numFilter >= 1">
        <!-- Adicionar efeito de transição assim que algum filtro for ativado -->
        <span class="filter__header__division-label">Você tem {{numFilter}} {{numFilter && numFilter > 1 ?  "filtros ativos" : "filtro ativo" }}</span>
        <button mat-icon-button class="filter__header__division-clear" (click)="clearFilter()">
            <mat-icon [svgIcon]="'filter-remove'"></mat-icon>
        </button>

    </div>
</div>

<div class="filter">

    <div class="filter__container">
        <filtro-dashboard (eventFilter)="sendFilter($event) " (clearFilter)="clearFilter() " *ngIf="this.page=='dashboard'"></filtro-dashboard>
        <filtro-clientes (eventFilter)="sendFilter($event)" (clearFilter)="clearFilter()" *ngIf="this.page=='clientes'"></filtro-clientes>
        <filtro-tarefas (eventFilter)="sendFilter($event) " (clearFilter)="clearFilter() " *ngIf="this.page=='tarefas'"></filtro-tarefas>
        <filtro-lista-negocios [listaUsuarios]="listaUsuarios" (eventFilter)="sendFilter($event) " (clearFilter)="clearFilter() " *ngIf="this.page=='lista-negocios'"></filtro-lista-negocios>
        <filtro-corretoras (eventFilter)="sendFilter($event)" (clearFilter)="clearFilter()" *ngIf="this.page == 'corretoras'"></filtro-corretoras>
        <filtro-negocios [listaUsuarios]="listaUsuarios" (eventFilter)="sendFilter($event) " (clearFilter)="clearFilter() " *ngIf="this.page=='negocios'"></filtro-negocios>
        <filtro-ramos (eventFilter)="sendFilter($event) " (clearFilter)="clearFilter() " *ngIf="this.page=='ramos'"></filtro-ramos>
        <filtro-cotacoes-ramo (eventFilter)="sendFilter($event) " (clearFilter)="clearFilter() " *ngIf="this.page=='cotacoes'"></filtro-cotacoes-ramo>
        <filtro-documentos (eventFilter)="sendFilter($event)" (clearFilter)="clearFilter()" *ngIf="this.page==='documentos'"></filtro-documentos>
    </div>
</div>
