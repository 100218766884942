import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroComponent } from './filtro.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RamosFiltroModule } from './ramos/ramos.module';
import { ClientesFiltroModule } from './clientes/clientes.module';
import { TarefasFiltroModule } from './tarefas/tarefas.module';
import { ListaNegociosModule } from './lista-negocios/lista-negocios.module';
import { NegociosFiltroModule } from './negocios/negocios.module';
import { CorretorasFiltroModule } from './corretoras-filtro/corretoras-filtro.module';
import { DashboardModule } from './dashboard/dashboard.module'
import { CotacoesRamoModule } from './cotacoes-ramo/cotacoes-ramo.module'
import { DocumentosModule } from './documentos/documentos.module';

@NgModule({
  declarations: [FiltroComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RamosFiltroModule,
    ClientesFiltroModule,
    TarefasFiltroModule,
    ListaNegociosModule,
    NegociosFiltroModule,
    CorretorasFiltroModule,
    DashboardModule,
    CotacoesRamoModule,
    DocumentosModule
  ],
  exports: [FiltroComponent]
})
export class FiltroModule { }
