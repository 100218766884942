function isFone(string): boolean {
    return string == null ? true : string.length <= 10 ? true : false;
}

function isCpfCnpj(string): boolean {
    return string == null ? true : string.length < 12 ? true : false;
}

export class Mask {
    static HORA             = '00:00';
    static DD_MM_AAAA       = '00/00/0000';
    static PLACA            = 'AAA-AA00';
    static CARTAO_VALIDADE  = '00/0000';
    static CARTAO           = '0000 0000 0000 0000';
    static CEP              = '00000-000'; 
    static FIPE             = '000000-0';
    static ANO              = '0000';
    static CHASSI           = 'AAA-AAAAAA-AA/AAAAAA';
    static CPF              = '000.000.000-00';
    static NIF              = '000000000';
    static CNPJ             = '00.000.000/0000-00';
    static NIPC              = '000000000';
    static ALTURA           = '0.00';
    static PESO             = '000';
    // static DINHEIRO         = '000.000,00';
    static PERC             = '000';
    static NUMERO_IMOVEL    = '00000';
    static PORTAS           = '0';
    static PASSAGEIROS      = '00';
    static EIXOS            = '00';
    static getFoneMask(string) {
        return isFone(string) ? '(00) 0000-00009' : '(00) 0 0000-0000';
    }

    static getCpfCnpj(string) {
        return isCpfCnpj(string) ? '000.000.000-00999' : '00.000.000/0000-00';
    }

}