export class FabricanteJson {

    static getFabricanteByValor (valor, tipo?:'v'|'m'|'c') {
        return FabricanteJson.dados.find(fabricante=>{
            return fabricante && fabricante.valor == valor && (tipo ? fabricante.tipo == tipo : true);
        });
    }

    static getFabricanteByNome (nome, tipo?:'v'|'m'|'c') {
        return FabricanteJson.dados.find(fabricante=>{            
            return fabricante && fabricante.nome == nome && (tipo ? fabricante.tipo == tipo : true);
        });
    }

    static dados: any = [
        {
            "nome": "ACURA",
            "valor": 1,
            "tipo": "v"
        },
        {
            "nome": "AGRALE",
            "valor": 2,
            "tipo": "v"
        },
        {
            "nome": "ALFA ROMEO",
            "valor": 3,
            "tipo": "v"
        },
        {
            "nome": "AM GEN",
            "valor": 4,
            "tipo": "v"
        },
        {
            "nome": "ASIA MOTORS",
            "valor": 5,
            "tipo": "v"
        },
        {
            "nome": "ASTON MARTIN",
            "valor": 189,
            "tipo": "v"
        },
        {
            "nome": "AUDI",
            "valor": 6,
            "tipo": "v"
        },
        {
            "nome": "BABY",
            "valor": 207,
            "tipo": "v"
        },
        {
            "nome": "BMW",
            "valor": 7,
            "tipo": "v"
        },
        {
            "nome": "BRM",
            "valor": 8,
            "tipo": "v"
        },
        {
            "nome": "BUGRE",
            "valor": 123,
            "tipo": "v"
        },
        {
            "nome": "CADILLAC",
            "valor": 10,
            "tipo": "v"
        },
        {
            "nome": "CBT JIPE",
            "valor": 11,
            "tipo": "v"
        },
        {
            "nome": "CHANA",
            "valor": 136,
            "tipo": "v"
        },
        {
            "nome": "CHANGAN",
            "valor": 182,
            "tipo": "v"
        },
        {
            "nome": "CHERY",
            "valor": 161,
            "tipo": "v"
        },
        {
            "nome": "CHRYSLER",
            "valor": 12,
            "tipo": "v"
        },
        {
            "nome": "CITRO\u00ebN",
            "valor": 13,
            "tipo": "v"
        },
        {
            "nome": "CROSS LANDER",
            "valor": 14,
            "tipo": "v"
        },
        {
            "nome": "DAEWOO",
            "valor": 15,
            "tipo": "v"
        },
        {
            "nome": "DAIHATSU",
            "valor": 16,
            "tipo": "v"
        },
        {
            "nome": "DODGE",
            "valor": 17,
            "tipo": "v"
        },
        {
            "nome": "EFFA",
            "valor": 147,
            "tipo": "v"
        },
        {
            "nome": "ENGESA",
            "valor": 18,
            "tipo": "v"
        },
        {
            "nome": "ENVEMO",
            "valor": 19,
            "tipo": "v"
        },
        {
            "nome": "FERRARI",
            "valor": 20,
            "tipo": "v"
        },
        {
            "nome": "FIAT",
            "valor": 21,
            "tipo": "v"
        },
        {
            "nome": "FIBRAVAN",
            "valor": 149,
            "tipo": "v"
        },
        {
            "nome": "FORD",
            "valor": 22,
            "tipo": "v"
        },
        {
            "nome": "FOTON",
            "valor": 190,
            "tipo": "v"
        },
        {
            "nome": "FYBER",
            "valor": 170,
            "tipo": "v"
        },
        {
            "nome": "GEELY",
            "valor": 199,
            "tipo": "v"
        },
        {
            "nome": "GM - CHEVROLET",
            "valor": 23,
            "tipo": "v"
        },
        {
            "nome": "GREAT WALL",
            "valor": 153,
            "tipo": "v"
        },
        {
            "nome": "GURGEL",
            "valor": 24,
            "tipo": "v"
        },
        {
            "nome": "HAFEI",
            "valor": 152,
            "tipo": "v"
        },
        {
            "nome": "HONDA",
            "valor": 25,
            "tipo": "v"
        },
        {
            "nome": "HYUNDAI",
            "valor": 26,
            "tipo": "v"
        },
        {
            "nome": "ISUZU",
            "valor": 27,
            "tipo": "v"
        },
        {
            "nome": "IVECO",
            "valor": 208,
            "tipo": "v"
        },
        {
            "nome": "JAC",
            "valor": 177,
            "tipo": "v"
        },
        {
            "nome": "JAGUAR",
            "valor": 28,
            "tipo": "v"
        },
        {
            "nome": "JEEP",
            "valor": 29,
            "tipo": "v"
        },
        {
            "nome": "JINBEI",
            "valor": 154,
            "tipo": "v"
        },
        {
            "nome": "JPX",
            "valor": 30,
            "tipo": "v"
        },
        {
            "nome": "KIA MOTORS",
            "valor": 31,
            "tipo": "v"
        },
        {
            "nome": "LADA",
            "valor": 32,
            "tipo": "v"
        },
        {
            "nome": "LAMBORGHINI",
            "valor": 171,
            "tipo": "v"
        },
        {
            "nome": "LAND ROVER",
            "valor": 33,
            "tipo": "v"
        },
        {
            "nome": "LEXUS",
            "valor": 34,
            "tipo": "v"
        },
        {
            "nome": "LIFAN",
            "valor": 168,
            "tipo": "v"
        },
        {
            "nome": "LOBINI",
            "valor": 127,
            "tipo": "v"
        },
        {
            "nome": "LOTUS",
            "valor": 35,
            "tipo": "v"
        },
        {
            "nome": "MAHINDRA",
            "valor": 140,
            "tipo": "v"
        },
        {
            "nome": "MASERATI",
            "valor": 36,
            "tipo": "v"
        },
        {
            "nome": "MATRA",
            "valor": 37,
            "tipo": "v"
        },
        {
            "nome": "MAZDA",
            "valor": 38,
            "tipo": "v"
        },
        {
            "nome": "MCLAREN",
            "valor": 211,
            "tipo": "v"
        },
        {
            "nome": "MERCEDES-BENZ",
            "valor": 39,
            "tipo": "v"
        },
        {
            "nome": "MERCURY",
            "valor": 40,
            "tipo": "v"
        },
        {
            "nome": "MG",
            "valor": 167,
            "tipo": "v"
        },
        {
            "nome": "MINI",
            "valor": 156,
            "tipo": "v"
        },
        {
            "nome": "MITSUBISHI",
            "valor": 41,
            "tipo": "v"
        },
        {
            "nome": "MIURA",
            "valor": 42,
            "tipo": "v"
        },
        {
            "nome": "NISSAN",
            "valor": 43,
            "tipo": "v"
        },
        {
            "nome": "PEUGEOT",
            "valor": 44,
            "tipo": "v"
        },
        {
            "nome": "PLYMOUTH",
            "valor": 45,
            "tipo": "v"
        },
        {
            "nome": "PONTIAC",
            "valor": 46,
            "tipo": "v"
        },
        {
            "nome": "PORSCHE",
            "valor": 47,
            "tipo": "v"
        },
        {
            "nome": "RAM",
            "valor": 185,
            "tipo": "v"
        },
        {
            "nome": "RELY",
            "valor": 186,
            "tipo": "v"
        },
        {
            "nome": "RENAULT",
            "valor": 48,
            "tipo": "v"
        },
        {
            "nome": "ROLLS-ROYCE",
            "valor": 195,
            "tipo": "v"
        },
        {
            "nome": "ROVER",
            "valor": 49,
            "tipo": "v"
        },
        {
            "nome": "SAAB",
            "valor": 50,
            "tipo": "v"
        },
        {
            "nome": "SATURN",
            "valor": 51,
            "tipo": "v"
        },
        {
            "nome": "SEAT",
            "valor": 52,
            "tipo": "v"
        },
        {
            "nome": "SHINERAY",
            "valor": 183,
            "tipo": "v"
        },
        {
            "nome": "SMART",
            "valor": 157,
            "tipo": "v"
        },
        {
            "nome": "SSANGYONG",
            "valor": 125,
            "tipo": "v"
        },
        {
            "nome": "SUBARU",
            "valor": 54,
            "tipo": "v"
        },
        {
            "nome": "SUZUKI",
            "valor": 55,
            "tipo": "v"
        },
        {
            "nome": "TAC",
            "valor": 165,
            "tipo": "v"
        },
        {
            "nome": "TOYOTA",
            "valor": 56,
            "tipo": "v"
        },
        {
            "nome": "TROLLER",
            "valor": 57,
            "tipo": "v"
        },
        {
            "nome": "VOLVO",
            "valor": 58,
            "tipo": "v"
        },
        {
            "nome": "VW - VOLKSWAGEN",
            "valor": 59,
            "tipo": "v"
        },
        {
            "nome": "WAKE",
            "valor": 163,
            "tipo": "v"
        },
        {
            "nome": "WALK",
            "valor": 120,
            "tipo": "v"
        },
        {
            "nome": "ADLY",
            "valor": 60,
            "tipo": "m"
        },
        {
            "nome": "AGRALE",
            "valor": 61,
            "tipo": "m"
        },
        {
            "nome": "AMAZONAS",
            "valor": 131,
            "tipo": "m"
        },
        {
            "nome": "APRILIA",
            "valor": 62,
            "tipo": "m"
        },
        {
            "nome": "ATALA",
            "valor": 63,
            "tipo": "m"
        },
        {
            "nome": "BAJAJ",
            "valor": 64,
            "tipo": "m"
        },
        {
            "nome": "BEE",
            "valor": 205,
            "tipo": "m"
        },
        {
            "nome": "BENELLI",
            "valor": 162,
            "tipo": "m"
        },
        {
            "nome": "BETA",
            "valor": 65,
            "tipo": "m"
        },
        {
            "nome": "BIMOTA",
            "valor": 66,
            "tipo": "m"
        },
        {
            "nome": "BMW",
            "valor": 67,
            "tipo": "m"
        },
        {
            "nome": "BRANDY",
            "valor": 68,
            "tipo": "m"
        },
        {
            "nome": "BRAVA",
            "valor": 130,
            "tipo": "m"
        },
        {
            "nome": "BRP",
            "valor": 150,
            "tipo": "m"
        },
        {
            "nome": "BUELL",
            "valor": 117,
            "tipo": "m"
        },
        {
            "nome": "BUENO",
            "valor": 155,
            "tipo": "m"
        },
        {
            "nome": "BULL",
            "valor": 212,
            "tipo": "m"
        },
        {
            "nome": "BYCRISTO",
            "valor": 69,
            "tipo": "m"
        },
        {
            "nome": "CAGIVA",
            "valor": 70,
            "tipo": "m"
        },
        {
            "nome": "CALOI",
            "valor": 71,
            "tipo": "m"
        },
        {
            "nome": "DAELIM",
            "valor": 72,
            "tipo": "m"
        },
        {
            "nome": "DAFRA",
            "valor": 145,
            "tipo": "m"
        },
        {
            "nome": "DAYANG",
            "valor": 137,
            "tipo": "m"
        },
        {
            "nome": "DAYUN",
            "valor": 142,
            "tipo": "m"
        },
        {
            "nome": "DERBI",
            "valor": 73,
            "tipo": "m"
        },
        {
            "nome": "DUCATI",
            "valor": 74,
            "tipo": "m"
        },
        {
            "nome": "EMME",
            "valor": 75,
            "tipo": "m"
        },
        {
            "nome": "FOX",
            "valor": 132,
            "tipo": "m"
        },
        {
            "nome": "FUSCO MOTOSEGURA",
            "valor": 209,
            "tipo": "m"
        },
        {
            "nome": "FYM",
            "valor": 128,
            "tipo": "m"
        },
        {
            "nome": "GARINNI",
            "valor": 143,
            "tipo": "m"
        },
        {
            "nome": "GAS GAS",
            "valor": 76,
            "tipo": "m"
        },
        {
            "nome": "GREEN",
            "valor": 133,
            "tipo": "m"
        },
        {
            "nome": "HAOBAO",
            "valor": 138,
            "tipo": "m"
        },
        {
            "nome": "HAOJUE",
            "valor": 203,
            "tipo": "m"
        },
        {
            "nome": "HARLEY-DAVIDSON",
            "valor": 77,
            "tipo": "m"
        },
        {
            "nome": "HARTFORD",
            "valor": 78,
            "tipo": "m"
        },
        {
            "nome": "HERO",
            "valor": 79,
            "tipo": "m"
        },
        {
            "nome": "HONDA",
            "valor": 80,
            "tipo": "m"
        },
        {
            "nome": "HUSABERG",
            "valor": 81,
            "tipo": "m"
        },
        {
            "nome": "HUSQVARNA",
            "valor": 82,
            "tipo": "m"
        },
        {
            "nome": "INDIAN",
            "valor": 202,
            "tipo": "m"
        },
        {
            "nome": "IROS",
            "valor": 158,
            "tipo": "m"
        },
        {
            "nome": "JIAPENG VOLCANO",
            "valor": 141,
            "tipo": "m"
        },
        {
            "nome": "JOHNNYPAG",
            "valor": 174,
            "tipo": "m"
        },
        {
            "nome": "JONNY",
            "valor": 151,
            "tipo": "m"
        },
        {
            "nome": "KAHENA",
            "valor": 129,
            "tipo": "m"
        },
        {
            "nome": "KASINSKI",
            "valor": 118,
            "tipo": "m"
        },
        {
            "nome": "KAWASAKI",
            "valor": 85,
            "tipo": "m"
        },
        {
            "nome": "KTM",
            "valor": 87,
            "tipo": "m"
        },
        {
            "nome": "KYMCO",
            "valor": 204,
            "tipo": "m"
        },
        {
            "nome": "LANDUM",
            "valor": 159,
            "tipo": "m"
        },
        {
            "nome": "L'AQUILA",
            "valor": 88,
            "tipo": "m"
        },
        {
            "nome": "LAVRALE",
            "valor": 89,
            "tipo": "m"
        },
        {
            "nome": "LERIVO",
            "valor": 139,
            "tipo": "m"
        },
        {
            "nome": "LIFAN",
            "valor": 178,
            "tipo": "m"
        },
        {
            "nome": "LON-V",
            "valor": 148,
            "tipo": "m"
        },
        {
            "nome": "MAGR\u00c3O TRICICLOS",
            "valor": 175,
            "tipo": "m"
        },
        {
            "nome": "MALAGUTI",
            "valor": 146,
            "tipo": "m"
        },
        {
            "nome": "MIZA",
            "valor": 126,
            "tipo": "m"
        },
        {
            "nome": "MOTO GUZZI",
            "valor": 90,
            "tipo": "m"
        },
        {
            "nome": "MOTOCAR",
            "valor": 201,
            "tipo": "m"
        },
        {
            "nome": "MOTORINO",
            "valor": 200,
            "tipo": "m"
        },
        {
            "nome": "MRX",
            "valor": 160,
            "tipo": "m"
        },
        {
            "nome": "MV AGUSTA",
            "valor": 91,
            "tipo": "m"
        },
        {
            "nome": "MVK",
            "valor": 92,
            "tipo": "m"
        },
        {
            "nome": "ORCA",
            "valor": 93,
            "tipo": "m"
        },
        {
            "nome": "PEGASSI",
            "valor": 164,
            "tipo": "m"
        },
        {
            "nome": "PEUGEOT",
            "valor": 94,
            "tipo": "m"
        },
        {
            "nome": "PIAGGIO",
            "valor": 95,
            "tipo": "m"
        },
        {
            "nome": "POLARIS",
            "valor": 210,
            "tipo": "m"
        },
        {
            "nome": "REGAL RAPTOR",
            "valor": 173,
            "tipo": "m"
        },
        {
            "nome": "RIGUETE",
            "valor": 198,
            "tipo": "m"
        },
        {
            "nome": "ROYAL ENFIELD",
            "valor": 192,
            "tipo": "m"
        },
        {
            "nome": "SANYANG",
            "valor": 96,
            "tipo": "m"
        },
        {
            "nome": "SHINERAY",
            "valor": 134,
            "tipo": "m"
        },
        {
            "nome": "SIAMOTO",
            "valor": 97,
            "tipo": "m"
        },
        {
            "nome": "SUNDOWN",
            "valor": 98,
            "tipo": "m"
        },
        {
            "nome": "SUZUKI",
            "valor": 99,
            "tipo": "m"
        },
        {
            "nome": "TARGOS",
            "valor": 176,
            "tipo": "m"
        },
        {
            "nome": "TIGER",
            "valor": 187,
            "tipo": "m"
        },
        {
            "nome": "TRAXX",
            "valor": 119,
            "tipo": "m"
        },
        {
            "nome": "TRIUMPH",
            "valor": 100,
            "tipo": "m"
        },
        {
            "nome": "VENTO",
            "valor": 180,
            "tipo": "m"
        },
        {
            "nome": "WUYANG",
            "valor": 135,
            "tipo": "m"
        },
        {
            "nome": "YAMAHA",
            "valor": 101,
            "tipo": "m"
        },
        {
            "nome": "AGRALE",
            "valor": 102,
            "tipo": "c"
        },
        {
            "nome": "BEPOBUS",
            "valor": 206,
            "tipo": "c"
        },
        {
            "nome": "CHEVROLET",
            "valor": 103,
            "tipo": "c"
        },
        {
            "nome": "CICCOBUS",
            "valor": 121,
            "tipo": "c"
        },
        {
            "nome": "DAF",
            "valor": 197,
            "tipo": "c"
        },
        {
            "nome": "EFFA-JMC",
            "valor": 179,
            "tipo": "c"
        },
        {
            "nome": "FIAT",
            "valor": 104,
            "tipo": "c"
        },
        {
            "nome": "FORD",
            "valor": 105,
            "tipo": "c"
        },
        {
            "nome": "FOTON",
            "valor": 191,
            "tipo": "c"
        },
        {
            "nome": "GMC",
            "valor": 106,
            "tipo": "c"
        },
        {
            "nome": "HYUNDAI",
            "valor": 181,
            "tipo": "c"
        },
        {
            "nome": "IVECO",
            "valor": 122,
            "tipo": "c"
        },
        {
            "nome": "MAN",
            "valor": 184,
            "tipo": "c"
        },
        {
            "nome": "MARCOPOLO",
            "valor": 108,
            "tipo": "c"
        },
        {
            "nome": "MASCARELLO",
            "valor": 196,
            "tipo": "c"
        },
        {
            "nome": "MAXIBUS",
            "valor": 194,
            "tipo": "c"
        },
        {
            "nome": "MERCEDES-BENZ",
            "valor": 109,
            "tipo": "c"
        },
        {
            "nome": "NAVISTAR",
            "valor": 110,
            "tipo": "c"
        },
        {
            "nome": "NEOBUS",
            "valor": 111,
            "tipo": "c"
        },
        {
            "nome": "PUMA-ALFA",
            "valor": 112,
            "tipo": "c"
        },
        {
            "nome": "SAAB-SCANIA",
            "valor": 113,
            "tipo": "c"
        },
        {
            "nome": "SCANIA",
            "valor": 114,
            "tipo": "c"
        },
        {
            "nome": "SHACMAN",
            "valor": 193,
            "tipo": "c"
        },
        {
            "nome": "SINOTRUK",
            "valor": 166,
            "tipo": "c"
        },
        {
            "nome": "VOLKSWAGEN",
            "valor": 115,
            "tipo": "c"
        },
        {
            "nome": "VOLVO",
            "valor": 116,
            "tipo": "c"
        },
        {
            "nome": "WALKBUS",
            "valor": 144,
            "tipo": "c"
        }
    ]
    constructor() { }
}