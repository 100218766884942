import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarefaComponent } from './tarefa.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import { DialogConfirmModule } from './dialog-confirm/dialog-confirm.module';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import {MatMenuModule} from '@angular/material/menu';
import { TextMaskModule } from 'angular2-text-mask';
import { DebounceModule } from 'src/app/directives/debounce/debounce.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatTooltipModule } from '@angular/material/tooltip';
@NgModule({
  declarations: [TarefaComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatDialogModule,
    MatChipsModule,
    DialogConfirmModule,
    ModulosDeFormulario,
    MatMenuModule,
    TextMaskModule,
    DebounceModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule
  ],
  exports: [TarefaComponent]
})
export class TarefaModule { }
