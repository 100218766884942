import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';


@Injectable({
	providedIn: 'root'
})
export class PlatformService {

	desktop = false;
	device_type: string = 'celular';
	enable_drawer_menu: boolean;
	onResizeWindow: Subject<any> = new Subject();

	/** Sistema operacional. */
	os: string;

	/*
	   Tipo de dispositivo no qual o app está rodando.
	   Pode ser desktop, tablet ou phone.
	*/
	deviceType: string;

	/*
	   Tipo virtual do dispositivo no qual o app está rodando.
	   Pode ser desktop, tablet ou phone.
	   Quando por desktop o tipo será calculado de acordo com
	   o tamanho do documento.
	*/
	virtualDeviceType: string;
	
	/* Rotação da tela. */
	deviceOrientation: string;

	/** Tamanho da tela em pixels. */
	size = { width: 0, height: 0 };

	constructor(public platform: Platform, public deviceService: DeviceDetectorService) {		
		this.onPlatformReady();		
	}

	async onPlatformReady() {
		try {
			if (this.platform.ANDROID) {
				this.os = 'android';
			} else if (this.platform.IOS) {
				this.os = 'ios';
			} else if (this.deviceService.isDesktop()) {
				this.os = 'windows';
			}
			

			// busca informações sobre o tipo de dispositivo.
			if (this.deviceService.isDesktop()) {
				this.deviceType = 'desktop';
				this.desktop = true;
			} else if (this.deviceService.isTablet()) {
				if (window && window.navigator && window.navigator.platform == 'Win32') {
					this.deviceType = 'desktop';
					this.virtualDeviceType = 'desktop';
				} else {
					this.deviceType = 'tablet';
					this.virtualDeviceType = 'tablet';
				}
			} else {
				//is mobile
				if (window && window.navigator && window.navigator.platform == 'Win32') {
					this.deviceType = 'desktop';
					this.virtualDeviceType = 'desktop';
				} else {
					this.deviceType = 'phone';
					this.virtualDeviceType = 'phone';
				}
			}
			
			this.setSize(window.innerWidth, window.innerHeight);
		} catch (error) {
			console.error(error)
		}

	}

	/*
		  Define informações sobre o tamanho da tela.
		  Se estiver em um desktop calcula o tipo de dipostivo virtual
		  em função do tamanho da tela.
	*/
	setSize(width: number, height: number) {
		this.size.width = width, this.size.height = height;

		if (this.deviceType === 'desktop') {
			if (width < 768) {
				this.virtualDeviceType = 'phone';
				this.enable_drawer_menu = true;
			} else if (width < 992) {
				this.virtualDeviceType = 'tablet';
				this.enable_drawer_menu = true;
			} else {
				this.virtualDeviceType = 'desktop';
				this.enable_drawer_menu = false;
			}
		} else {
			this.enable_drawer_menu = true;
		}

		if (width <= height) {
			this.deviceOrientation = 'vertical';
		} else {
			this.deviceOrientation = 'horizontal';
		}

		this.onResizeWindow.next();
	}

	/*
		  Função que deve ser chamada quando a view for redimensionada.
		  Usar (window:resize)="platformProvider.onResize($event)" no elemento para disparar o evento.
	*/
	onResize(event: Event) {
		let target: any = event.target;
	
		// atualiza o tamanho do documento quando a janela é redimensionada
		this.setSize(target.innerWidth, target.innerHeight);
	}
}
