import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ApiUsuarioService } from './api-usuario.service';

@Injectable({
  providedIn: 'root'
})
export class TodoControllerService {

  private ENV:any = environment;
  private URL;
  
  todoList;
  extensaoInstalada;

  constructor(private http: HttpClient, private BS: BaseService, private apiUsuario: ApiUsuarioService) { 
    this.URL = this.ENV.apice.url;
    this.todoList = new BehaviorSubject<any>([]);
  }

  //funçao para buscar dados do todo
  jaVerificouExtensao = false;
  dadosTodoList = [];
  buscaToDoList() {
    
    return new Promise(async (resolve, reject)=>{
      try {
       let data:any = await this.http.get(`${this.URL}cfg/todolist`, this.BS.getHeaders(true)).toPromise();
       this.todoList.next(data);
       this.dadosTodoList = data;
       resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    })
  }

  //funcao para atualizar o todo
  atualizarToDoList(body): Promise<any> {
    return this.http.post(`${this.URL}cfg/todolist`, body, this.BS.getHeaders(true)).toPromise().then((data)=>{
      this.todoList.next(data);
    }).catch(err =>{
      console.log(err);
      debugger
    })
  }


  async verificarToDo(source) {    
    await this.buscaToDoList();
    let atualizaJson = false;
    for (let item of this.dadosTodoList) {
      if (source == item.key_video) {
        if (item.concluido != item.total) {
          if (item.concluido == 0) {
            item.titulo = item.titulo + "*"
            item.concluido = 1
            atualizaJson = true;
          } else if (item.concluido == 1) {
            item.titulo = item.titulo + "*"
            item.concluido = 2;
            atualizaJson = true;
          }

        }
        
      }

    }
    
    if (atualizaJson && !this.apiUsuario.getCredenciais().root) {
      this.atualizarToDoList(this.todoList.value);
    }

  }

}
