import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { ApiUsuarioService } from './api-usuario.service';

@Injectable({
	providedIn: 'root'
})
export class ApiEnderecoService {

	private ENV:any = environment;
	private URL;

	constructor(private http: HttpClient, private BS: BaseService, private apiUsuario: ApiUsuarioService) {
		this.URL = this.ENV.apice.url;
	}

	/**
	 *  Busca por CEP;
	 */
	buscaCEP(CEP:string) {
		let environment = this.ENV;
		let urlCEP = environment.CEP.url;
		let cep = (CEP||'').replace(/\D/g, '');
		if (cep.length != 8) {
			return null;
		}		
		return this.http.get(this.URL + urlCEP + cep, this.BS.getHeaders(true)).toPromise();
	}

	/**
	 * Busca CEP no P-Docs.
	 * @param CEP 
	 * @returns array de endereços.
	 */
	async buscaCEPPDocs(CEP): Promise<any> {
		try {
			let cep = (CEP||'').replace(/\D/g, '');
			if (cep.length != 8) { 
				throw new Error('CEP inválido');
			}
			if (!CEP) {
				throw new Error('CEP indefinido');
			}
			let authPdocs = await this.apiUsuario.autenticaNoPDocs();
			if(authPdocs?.urlPDocs){
				let url = authPdocs.urlPDocs + 'calculo/cep?cep=' + CEP;
				try {
					let reqOpts = {
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
							'Authorization': authPdocs.token
						})
					};
					let ret = await this.http.get(url, reqOpts).toPromise();
					return ret;
				} catch (error) {
					throw error;
				}
			}else{
				return null
			}
		} catch (error) {
			throw error;
		}

	}

	/** Busca endereço por CEP.
	 * @param cep
	 */
	buscaEndereco (cep:string) {		
		cep = (cep||'').replace(/\D/g, '');
		if (cep.length != 8) { 
			throw new Error('CEP inválido');
		}
		if (!cep) {
			throw new Error('CEP indefinido');
		}
		try {		
			let environment = this.ENV;
			let reqOpts = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
			let url = encodeURI(`${environment.multi.url}calculo/cep?cep=${cep}`);
			return this.http.get(url, reqOpts).toPromise();			
		} catch (error) {
			throw error;
		}
	}

	decodeEndereco(endereco){
		try {
			endereco = JSON.parse(decodeURIComponent(escape(JSON.stringify(endereco))));	
			return endereco;			
			
		} catch (error) {
			return endereco;
		}
	}

}
