import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfirmComponent } from '../components/alert-confirm/alert-confirm.component';

@Injectable()

export class AlertConfirmService {

  // EXEMPLO DE UTILIZACAO PARA QUANDO SAIR FORA DA JANELA
  // this.alertConfirmService.confirm(title, question)
  // .then((confirmed) => console.log('User confirmed:', confirmed))
  // .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  constructor(private modalService: NgbModal) { }

  public async confirm(
    title: string,
    message: string,
    btnOkText: string = 'Confirmar',
    btnCancelText: string = 'Cancelar'): Promise<boolean> {
    const modalRef = this.modalService.open(AlertConfirmComponent, {
      size: "450px",
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    let ret;
    try {
      ret = await modalRef.result;
    } catch (e) { console.log(e) }

    return ret;

  }

  public async alert(
    title: string,
    message: string,
    btnOkText: string = 'OK'): Promise<boolean> {
    const modalRef = this.modalService.open(AlertConfirmComponent, {
      size: "450px",
      //backdrop : 'static',
      //keyboard : false
    });

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;

    let ret;
    try {
      ret = await modalRef.result;
    } catch (e) { console.log(e) }

    return ret;

  }

}
