<div mat-dialog-title [ngStyle]="{'display': 'flex',  'align-items': 'center'}">
    <h3 [ngStyle]="{'font-size': '20px!important'}" *ngIf="status != 4">{{status == 3 ? 'Cancelar Tarefa' : 'Tarefa Concluída'}}</h3>
    <h3 [ngStyle]="{'font-size': '20px!important'}" *ngIf="status == 4">Excluir Tarefa</h3>

    <button tabindex="-1" mat-icon-button mat-dialog-close [ngStyle]="{'margin': '4px 4px 4px auto',  'color': '#727272'}">
        <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
</div>
<mat-dialog-content [ngStyle]="{'height': 'auto'}"> 
    <div [ngStyle]="{'margin-top':'10px'}">
        <span class="fontHelvetica16" *ngIf="status != 4">
            Deseja marcar como {{status == 3 ? 'CANCELADA' : 'CONCLUÍDA'}} a tarefa?
        </span>
        <span class="fontHelvetica16" *ngIf="status == 4">
            Deseja excluir a tarefa?
        </span>
    </div>
    <div class="mt-16">
        <mat-form-field>
            <mat-label>Observação</mat-label>
            <textarea
                [(ngModel)]="observacaoStatus"
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                matInput
                placeholder="Adicione uma observação"              
                [ngStyle]="{'width':'100%'}">
            </textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-dialog-close
        class="def-w-btn"
        mat-button>
        CANCELAR
    </button>
    <button
        (click)="confirmar()"
        mat-button
        class="def-b-btn">
        SALVAR
        <mat-icon
            *ngIf="false"
            [ngClass]="['mdi mdi-loading mdi-spin']"
            [ngStyle]="{'margin-left':'2px'}">
        </mat-icon>
    </button>
</mat-dialog-actions>
