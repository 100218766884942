import { Component, OnInit, Inject } from '@angular/core';
import { SeguradorasBase } from 'src/app/models/seguradoras/factory/base.model';
import { GlobalConstants } from 'src/app/constants/global';
import { UtilsService } from 'src/app/services/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PdocsService } from 'src/app/services/pdocs.service';
import { ViewValue } from 'src/app/models/view-value.model';
import { Masks } from 'src/app/services/text-mask.service';
import { ApiInterfaceService } from 'src/app/services/api/api-interface.service';
import { IntegracaoConstants } from 'src/app/constants/integracao';
import { ApiCotacoesService } from 'src/app/services/api-cotacoes.service';
import { ApiNegociosService } from 'src/app/services/api-negocios.service';

@Component({
  selector: 'app-modal-fechamento',
  templateUrl: './modal-fechamento.component.html',
  styleUrls: ['./modal-fechamento.component.scss']
})

export class ModalFechamentoComponent implements OnInit {

  public clienteId = 999999999999;
  public seguradoras: any[];
  public draft: any;
  public draftTemp: any;
  public pathPdf: string;
  public configS3: any = {};
  public myControlGeneral = new FormControl();
  public isNegocioFechado: boolean;
  public salvou: boolean;
  public infosDefault: any;
  public close: any;
  public ramo: number;
  public ramoKey: string;
  public _ramos = IntegracaoConstants.ramos;
  public desfazerNegocio: boolean;
  public extensions = ["pdf"];

  private _seguradorasBase: SeguradorasBase = GlobalConstants.seguradoras;
  private _tipoPagamentoTxt = GlobalConstants.tipoPagamentoTxt;

  formVidaValidacaoFechamento: FormGroup;
  formVidaValidacaoNaoFechamento: FormGroup;

  tipoPagamento: ViewValue[] = this.utilsService.objectToViewValue(this._tipoPagamentoTxt);
  qtdParcelas: ViewValue[] = [
    { viewValue: "À vista", value: 1 },
    { viewValue: "2x", value: 2 },
    { viewValue: "3x", value: 3 },
    { viewValue: "4x", value: 4 },
    { viewValue: "5x", value: 5 },
    { viewValue: "6x", value: 6 },
    { viewValue: "7x", value: 7 },
    { viewValue: "8x", value: 8 },
    { viewValue: "9x", value: 9 },
    { viewValue: "10x", value: 10 },
    { viewValue: "11x", value: 11 },
    { viewValue: "12x", value: 12 },
  ];

  motivosNaoFechamento: ViewValue[] = [
    { viewValue: "Renovou com outro corretor/concorrente", value: 1 },
    { viewValue: "Dificuldades financeiras", value: 2 },
    { viewValue: "Vendeu o veículo", value: 3 },
    { viewValue: "Ficará sem seguro", value: 4 },
    { viewValue: "Achou muito caro", value: 5 },
    { viewValue: "Não gostou do atendimento", value: 6 },
    { viewValue: "Apolice cancelada por falta de pagamento", value: 7 },
    { viewValue: "Apólice cancelada por perda total", value: 8 },
    { viewValue: "Apólice cancelada por solicitação do segurado", value: 9 }
  ];

  public maskValidate = this.mask.validate();

  //do lado do apice quando não é calculado o ramo
  ramoNaoCalculado: boolean = false;
  naoSalvar: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fechamentoService: ApiNegociosService,
    private apiNegocio: ApiNegociosService,
    private apiCotacao: ApiCotacoesService,
    private utilsService: UtilsService,
    private pdocsService: PdocsService,
    private mask: Masks,
    private formBuilder: FormBuilder,
    private api: ApiInterfaceService
  ) {
    // this.seguradoras = this.utilsService.idNameObjectToViewValue(this._seguradorasBase);

    if (!data.draft.fechamento) {
      data.draft.fechamento = {};
    }
    this.fechamentoService.dadosReturn = null;
    if(data.ramoNaoCalculado){
      this.ramoNaoCalculado = true;
    }
    if(data.naoSalvar){
      this.naoSalvar = true;
    }

    this.draftTemp = JSON.parse(JSON.stringify(data.draft));

    this.draft = data.draft;
    this.ramo = this.draft?.ramo;
    this.configS3 = data.configS3;
    this.isNegocioFechado = data.isNegocioFechado;
    this.desfazerNegocio = data.desfazerNegocio;
    this.close = data.close;

    if (data.infosDefault) {
      this.draftTemp.fechamento.seguradora = data.infosDefault.seguradora;
      this.draftTemp.fechamento.premio = data.infosDefault.premio;
      this.draftTemp.fechamento.franquia = data.infosDefault.franquia;
      this.draftTemp.fechamento.comissao = data.infosDefault.coberturas.percComissao;
      this.draftTemp.fechamento.qtdParcelas = 1;
      this.draftTemp.fechamento.tpPagamento = 3;
    }

    this.formVidaValidacaoFechamento = this.formBuilder.group({
      seguradora: new FormControl('', [
        Validators.required,
      ]),
      tpPagamento: new FormControl('', [
        Validators.required,
      ]),
      qtdParcelas: new FormControl('', [
        Validators.required,
      ]),
      premio: new FormControl('', [
        Validators.required,
      ]),
      franquia: new FormControl('', [
        Validators.required,
      ]),
      comissao: new FormControl('', [
        Validators.required,
      ]),
      observacoes: new FormControl('', [
      ]),
    });

    this.formVidaValidacaoNaoFechamento = this.formBuilder.group({
      motivo: new FormControl('', [
        Validators.required,
      ]),
      observacoes: new FormControl('', [
      ]),
    });
  }
  async ngOnInit(): Promise<void> {
    await this.setSeguradoresForRamo();
    this.clearMotivosNaoFechamentoForRamo();

    if (this.ramo != this._ramos.AUTO)
      this.formVidaValidacaoFechamento?.controls.franquia.setErrors(null);
  }

  public async saveCalculoNegocio(action) {

    let formValid;

    if (action == 'cancelarNegocio') {
      formValid = {};
      formValid.status = "VALID";
    }
    else if (action == "negocioFechado")
      formValid = this.formVidaValidacaoFechamento
    else if (action == "negocioPerdido")
      formValid = this.formVidaValidacaoNaoFechamento

    if (formValid.status == "VALID") {
      if (action == 'cancelarNegocio') {
        this.draftTemp.status = 2;
        this.draftTemp.fechamento = {};
      }
      else
        this.draftTemp.status = this.isNegocioFechado ? 4 : 5;

      if (action != 'cancelarNegocio') {
        this.draftTemp.fechamento.segName = this.setNameSeguradora();
        this.draftTemp.fechamento.pathProposta = this.configS3.path + this.configS3.negocioIdIntegracao;
      }

      let sendDraft = JSON.parse(JSON.stringify(this.draftTemp));

      if(this.ramoNaoCalculado){
        sendDraft.ramoNaoCalculado = true;
      }
      debugger
      if(this.naoSalvar){
        this.fechamentoService.dadosReturn = sendDraft;
        this.fechamentoService.modalClosed.next(true);
        this.close();
      }else{
        this.apiCotacao.fechamentoNegocio(sendDraft)
        .then(async function (ret: any) {
          if (action != 'cancelarNegocio') {
            for (let idx in this.draftTemp.fechamento) {
              this.draft.fechamento[idx] = this.draftTemp.fechamento[idx];
            }
          }

          this.draft.status = this.draftTemp.status;
          this.apiNegocio.setUpdateNegocio(true);
          this.close();
          await this.utilsService.createAlert("Atenção", "Fechamento registrado com sucesso.");
        }.bind(this))
        .catch(async function (e) {
          console.log(e);
          this.close();
          await this.utilsService.createAlert("Atenção", "Houve um erro ao salvar esta cotação.");
        }.bind(this));
      }
    }
  }

  public async setSeguradoresForRamo() {

    this.seguradoras = [];

    return new Promise((resolve) => {
      this.api.getSeguradoraAnterior()
        .then(function (seguradoras: any) {

          for (const key in this._ramos) {
            if (this._ramos.hasOwnProperty(key)) {
              if (this._ramos[key] === this.ramo) {
                this.ramoKey = key;
                break;
              }
            }
          }
          
          if(this.ramoNaoCalculado){
            this.seguradoras = this.utilsService.idNameObjectToViewValue(this._seguradorasBase);
          }else{
            this.ramoKey = this.ramoKey.toLowerCase(this.ramoKey);

            if (this.ramo != this._ramos.VIDA) {
              seguradoras.filter((seguradora) => {
                if (seguradora[this.ramoKey])
                  this.seguradoras.push(seguradora);
              });
              this.seguradoras = this.utilsService.idNameObjectToViewValue(this.seguradoras);
            }
            else
              this.seguradoras = this.utilsService.idNameObjectToViewValue(this._seguradorasBase);
          }
         

          resolve(this.seguradoras);
        }.bind(this))
        .catch(async function (e) {
          console.log(e);
          this.seguradoras = [];
        }.bind(this));
    })
  }

  public clearMotivosNaoFechamentoForRamo() {
    if (this.ramo != this._ramos.AUTO)
      this.motivosNaoFechamento.forEach(element => {
        if (element.value == 3)
          this.motivosNaoFechamento.splice(2, 1)
      });
  }

  public setNameSeguradora(): string {
    let idSeguradora = this.formVidaValidacaoFechamento?.controls.seguradora.value;
    let segName: string;

    this.seguradoras.forEach(element => {
      if (element.value == idSeguradora)
        segName = element.viewValue;
    });

    return segName;
  }
}
