import { Directive, Output, Input, HostListener, EventEmitter } from "@angular/core";


	@Directive({ selector: '[debouncing]' })
	export class DebouncingDirective {
	@Output() debounceFunction = new EventEmitter();
	@Input('debouncing') debounce: string = ''; //receber a função
	@Input('debounceTimer') debounceTimer: any; //receber o timer do debounce

	time: any;
	timerDebounce: any;

	constructor() {}

	@HostListener('ionChange', ['$event'])
	onchange() {		
		this.timerDebounce = this.debounceTimer > 0 ? this.debounceTimer : 500;
		this.deboucingFunction(this.timerDebounce);
	}

	deboucingFunction(timer) {
		clearTimeout(this.time);
		this.time = setTimeout(() => {
			if (this.debounce != '') {				
				this.debounceFunction.emit(this.debounce); //emitir a função recebida
			}
		}, timer);
	}


}
