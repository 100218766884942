export class SeguradoraCalcular {
  id: number
  ativo: boolean
  nome: string
  percCom: number
  percDesc: number
  logo: string
  noChange?: boolean

  constructor();
  constructor(id: number, ativo: boolean, nome: string, percCom: number, percDesc: number, logo: string)
  constructor(id?: number, ativo?: boolean, nome?: string, percCom?: number, percDesc?: number, logo?: string) {
    this.id = id;
    this.ativo = ativo;
    this.nome = nome;
    this.percCom = percCom;
    this.percDesc = percDesc;
    this.logo = logo;
  }

}
