export class GetIconMimeTypes {
    static typesFile = [
        {
            format: 'imagem',
            type: ['.ai', '.bmp','.gif', '.ico', '.jpeg', '.jpg', '.png', '.ps', '.psd', '.svg', '.tif', '.tiff'],
            icon: {name: 'image', color: '#d93025'},
        },
        {
            format: 'presentation',
            type: ['.key', '.odp', '.pps', '.ppt', '.pptx'],
            icon: {name:'file-presentation-box', color: '#f4b400'}
        },
        {
            format: 'spreadsheet',
            type: ['.ods', '.xlr', '.xls', '.xlsx'],
            icon: {name: 'google-spreadsheet', color: '#0f9d58'}            
        },
        {
            format: 'video',
            type: ['.3g2', '.3gp', '.avi', '.flv', '.h264', '.m4v', '.mkv', '.mov', '.mp4', '.mpg', '.mpeg', '.rm', '.swf', '.vob', '.wmv'],
            icon: {name: 'file-video-outline', color: '#d93025'}            
        },
        {
            format: 'docs',
            type: ['.doc', '.odt', '.rtf', '.tex', '.txt', '.wks', '.wps', '.wpd'],
            icon: {name: 'file-document-box-outline', color: '#1a73e8'}            
        },
        {
            format: 'adobe',
            type: ['.pdf'],
            icon: {name: 'file-pdf', color: '#d93025'}            
        },
        {
            format: 'compressed',
            type: ['.7z', '.arj', '.deb', '.pkg', '.rar', '.rpm', '.tar.gz', '.z', '.zip'],
            icon: {name: 'folder-zip-outline', color: '#202124'}            
        },
        {
            format: 'audio',
            type: ['.aif', '.cda', '.mid', '.midi', '.mp3', '.mpa', '.ogg', '.wav', '.wma', '.wpl'],
            icon: {name: 'audiobook', color: '#1a73e8'}            
        }
    ];

    static getTypeIcon(file){
        file=(file || '').toLowerCase();
        let count = 0;
        for(let format of GetIconMimeTypes.typesFile){
            for(let type of format.type){
                if(file.endsWith(type)){
                    return format.icon
                }
            }
            if(count >= GetIconMimeTypes.typesFile.length - 1){
                return ''
            }
            count++
        }           
    }
}