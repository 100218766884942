<div class="col-12" id="conteudo-historico">
    <div class="loader" [hidden]="!loadingResults">Loading...</div>
    <div [hidden]="loadingResults">
        <div [hidden]="this.arrayPrecoMedio.length > 1" class="col-6 descricao box-indisponivel-grande">
            <h3>FIPE e abrangência de Preço Médio</h3>
            <h4 class="text-center text-danger">Temporariamente indisponível</h4>
        </div>
        <div [hidden]="this.arrayPrecoMedio.length <= 1" class="col-6 descricao">
            <div class="cardMedidor__container">
                <div class="cardMedidor__title"><span>{{automovel.descricao}} / {{automovel.anoModelo}}</span>
                    <small class="info-pessoais">CEP | {{automovel.cepPernoite}}
                        {{automovel.condutorMaior25 ? 'Maior de 25 anos' : 'Menor de 25 anos'}}</small>
                    <div class="subTitle"><span>VALOR FIPE <span style="font-size: 14px;">
                            </span>{{fipeValAtual}}</span>
                    </div>
                    <span class="cardMedidor__title__headline">Preço Médio do Seguro<small>*</small>
                        <span class="dataMediaSeg">{{dataMediaSeg}}</span><br>{{precoMedioMes}}</span>
                </div>

                <div class="cardMedidor__img"><img src="./assets/images/imgMinMedioMax.png" style="max-width: 350px;">
                    <div class="text-center precisao">
                        <span>ABRANGÊNCIA**</span>
                        <div class="precisao__div">
                            <span id="prec70" (click)="selAbrangencia = 70"
                                [ngClass]="selAbrangencia == 70 ? 'active' : ''">70%</span>
                            <span id="prec95" (click)="selAbrangencia = 95"
                                [ngClass]="selAbrangencia == 95 ? 'active' : ''" class="">95%</span>
                        </div> 
                    </div>  
                    <div class="row cardMedidor__media"> 
                        <div class="col-auto">
                            <span> {{selAbrangencia == 70 ? abrangencia.basica.min : abrangencia.completa.min}}</span>
                        </div>
                        <div class="empty"></div>
                        <div class="col-auto">
                            <span> {{selAbrangencia == 70 ? abrangencia.basica.max : abrangencia.completa.max}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="obs">
                <div>
                    <span>&nbsp;&nbsp;*Preço médio do seguro na região de pernoite do veículo.</span><br>
                    <span>&nbsp;&nbsp;**Percentual de cotações dentro do intervalo entre o preço mínimo e máximo.</span>
                </div>
            </div>
        </div>
        <div class="col-6 grafico">
            <div class="col-12">
                <h3>Histórico Preço Médio/Fipe</h3>
                <div [hidden]="this.arrayPrecoMedio.length <= 1" class="col-12" id="chartFipe"></div>
                <div [hidden]="this.arrayPrecoMedio.length > 1" class="col-12 box-indisponivel"> 
                    <h4 class="text-center text-danger">Temporariamente indisponível</h4>
                </div>
            </div>

            <div class="col-12">
                <h3>Taxa do Seguro</h3>
                <div [hidden]="this.arrayTaxa.length <= 1" class="col-12" id="chartTaxa"></div>
                <div [hidden]="this.arrayTaxa.length > 1" class="col-12 box-indisponivel"> 
                    <h4 class="text-center text-danger">Temporariamente indisponível</h4>
                </div>
            </div>
        </div>
    </div>
</div>