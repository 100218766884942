<loading-page *ngIf="carregando"></loading-page>

<div
    mat-dialog-title
    [ngClass]="[shadowHeader ? 'mat-elevation-z3' : '']"
    [ngStyle]="{'display':'flex', 'align-items':'center'}"
    *ngIf="!carregando">

    <div [ngClass]="['colNegocioHeader', !negocio ? 'colNegocioHeader-notNegocio' : '' ]">
        <div *ngIf="negocio">
            <button
                mat-icon-button
                [ngStyle]="{'display':'flex', 'align-items':'center'}"
                [matMenuTriggerFor]="menuStatus">
                <mat-icon [svgIcon]="iconeStatus"></mat-icon>
            </button>
            <mat-menu #menuStatus="matMenu">
                <button
                    mat-menu-item
                    (click)="selectStatus('oportunidade')">
                    <mat-icon [svgIcon]="'oportunidade'"></mat-icon>
                    Oportunidade
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('iniciar')">
                    <mat-icon [svgIcon]="'iniciar'"></mat-icon>
                    Início
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('cotacao')">
                    <mat-icon [svgIcon]="'cotacao'"></mat-icon>
                    Cotação
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('acompanhamento')">
                    <mat-icon [svgIcon]="'acompanhamento'"></mat-icon>
                    Acompanhamento
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('fechado')">
                    <mat-icon [svgIcon]="'fechado'"></mat-icon>
                    Fechado
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('perdido')">
                    <mat-icon [svgIcon]="'negocio_perdido'"></mat-icon>
                    Perdido
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('descartar')">
                    <mat-icon [svgIcon]="'descartado'"></mat-icon>
                    Descartado
                </button>
                <button
                    mat-menu-item
                    (click)="selectStatus('concluido')">
                    <mat-icon [svgIcon]="'fechado_concluido'"></mat-icon>
                    Fechado e Concluído
                </button>
            </mat-menu>
        </div>

        <div
            class="colNegocioHeader__name"
            (click)="cadastroCliente()"
            *ngIf="negocio">
            <h3 class="mbi-0 personalData">{{formCliente.get('nome').value}}</h3>
        </div>
        <div *ngIf="!negocio">
            <h3 [ngStyle]="{'margin': '10px 0px 10px 12px'}">Novo Negócio</h3>
        </div>
    </div>

    <div class="btnStatusCalc">
        <button
            mat-icon-button
            *ngIf="negocio && negocio?.documentoId && !routeData"
            (click)="verDocumento()"
            matTooltip="Ver documento">
            <mat-icon [svgIcon]="'file-document-outline'"></mat-icon>
        </button>
        <button
            mat-icon-button
            *ngIf="negocio && negocio.ramo == 32 && !negocio.cotacao"
            (click)="iniciarCotacao(negocio.segmento, negocio.ramo, negocio, $event)"
            matTooltip="Iniciar cotação">
            <mat-icon [svgIcon]="'cotacao'"></mat-icon>
        </button>
        <button
            mat-icon-button
            *ngIf="negocio && negocio.ramo == 32 && negocio.cotacao && ((negocio.cotacao.versaoFormulario >= 1 || !negocio.cotacao.versaoFormulario) && negocio.cotacao.status == 1)"
            [routerLink]="'/cotacao/auto/formulario/'+negocio.cotacao.id"
            (click)="stopModal($event)"
            matTooltip="Editar Cotação">
            <mat-icon [svgIcon]="'editando'"></mat-icon>
        </button>
        <button
            mat-icon-button
            *ngIf="negocio && negocio.ramo == 32 && negocio.cotacao && negocio.cotacao.status >= 2"
            [routerLink]="'/cotacao/auto/resultados/'+negocio.cotacao.id"
            (click)="stopModal($event)"
            matTooltip="Ver Cotação">
            <mat-icon
                matPrefix
                [ngStyle]="{'margin': '0 0 5px 10px'}"
                [svgIcon]="negocio?.cotacao?.status? negocio.cotacao.status == 3 ? 'calculando' : (negocio.cotacao.status == 2 || negocio.cotacao.status == 4 || negocio.cotacao.status == 5) ? 'calculado' : '' : '' "
                [ngClass]="['iconSvgCustom', negocio.cotacao.status == 3 ? 'iconSvgCustom-calculando-neg' : '']"
                matListIcon></mat-icon>
        </button>
<!-- 
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 2 && !negocio.cotacao" (click)="iniciarCotacao(negocio.segmento, negocio.ramo, negocio, $event)" matTooltip="Iniciar cotação">
            <mat-icon [svgIcon]="'cotacao'"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 2 && negocio.cotacao && ((negocio.cotacao.versaoFormulario >= 1 || !negocio.cotacao.versaoFormulario) && negocio.cotacao.status == 1)" [routerLink]="'/cotacao/residencial/formulario/'+negocio.cotacao.id" (click)="stopModal($event)" matTooltip="Editar Cotação">
            <mat-icon [svgIcon]="'editando'"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 2 && negocio.cotacao && negocio.cotacao.status >= 2" [routerLink]="'/cotacao/residencial/resultados/'+negocio.cotacao.id" (click)="stopModal($event)" matTooltip="Ver Cotação">
            <mat-icon
                matPrefix
                [ngStyle]="{'margin': '0 0 5px 10px'}"
                [svgIcon]="negocio?.cotacao?.status? negocio.cotacao.status == 3 ? 'calculando' : negocio.cotacao.status == 2 ? 'calculado' : '' : '' "
                [ngClass]="['iconSvgCustom', negocio.cotacao.status == 3 ? 'iconSvgCustom-calculando-neg' : '']"
                matListIcon></mat-icon>
        </button>

        <button mat-icon-button *ngIf="negocio && negocio.ramo == 91 && !negocio.cotacao" (click)="iniciarCotacao(negocio.segmento, negocio.ramo, negocio, $event)" matTooltip="Iniciar cotação">
            <mat-icon [svgIcon]="'cotacao'"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 91 && negocio.cotacao && ((negocio.cotacao.versaoFormulario >= 1 || !negocio.cotacao.versaoFormulario) && negocio.cotacao.status == 1)" [routerLink]="'/cotacao/vida/formulario/'+negocio.cotacao.id" (click)="stopModal($event)" matTooltip="Editar Cotação">
            <mat-icon [svgIcon]="'editando'"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 91 && negocio.cotacao && negocio.cotacao.status >= 2" [routerLink]="'/cotacao/vida/resultados/'+negocio.cotacao.id" (click)="stopModal($event)" matTooltip="Ver Cotação">
            <mat-icon
                matPrefix
                [ngStyle]="{'margin': '0 0 5px 10px'}"
                [svgIcon]="negocio?.cotacao?.status? negocio.cotacao.status == 3 ? 'calculando' : negocio.cotacao.status == 2 ? 'calculado' : '' : '' "
                [ngClass]="['iconSvgCustom', negocio.cotacao.status == 3 ? 'iconSvgCustom-calculando-neg' : '']"
                matListIcon></mat-icon>
        </button>

        <button mat-icon-button *ngIf="negocio && negocio.ramo == 18 && !negocio.cotacao" (click)="iniciarCotacao(negocio.segmento, negocio.ramo, negocio, $event)" matTooltip="Iniciar cotação">
            <mat-icon [svgIcon]="'cotacao'"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 18 && negocio.cotacao && ((negocio.cotacao.versaoFormulario >= 1 || !negocio.cotacao.versaoFormulario) && negocio.cotacao.status == 1)" [routerLink]="'/cotacao/empresarial/formulario/'+negocio.cotacao.id" (click)="stopModal($event)" matTooltip="Editar Cotação">
            <mat-icon [svgIcon]="'editando'"></mat-icon>
        </button>
        <button mat-icon-button *ngIf="negocio && negocio.ramo == 18 && negocio.cotacao && negocio.cotacao.status >= 2" [routerLink]="'/cotacao/empresarial/resultados/'+negocio.cotacao.id" (click)="stopModal($event)" matTooltip="Ver Cotação">
            <mat-icon
                matPrefix
                [ngStyle]="{'margin': '0 0 5px 10px'}"
                [svgIcon]="negocio?.cotacao?.status? negocio.cotacao.status == 3 ? 'calculando' : negocio.cotacao.status == 2 ? 'calculado' : '' : '' "
                [ngClass]="['iconSvgCustom', negocio.cotacao.status == 3 ? 'iconSvgCustom-calculando-neg' : '']"
                matListIcon></mat-icon>
        </button>
-->
        <button
            mat-icon-button
            tabindex="-1"
            mat-dialog-close
            [ngStyle]="{'margin': '4px 4px 4px 4px'}"
            [disabled]="loadSalvaNegocio">
            <mat-icon
                *ngIf="!loadSalvaNegocio"
                [svgIcon]="'close'"></mat-icon>
            <mat-icon
                *ngIf="loadSalvaNegocio"
                svgIcon="loading"
                [ngClass]="['loading']"></mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content
    class="dialog-content-negocio"
    *ngIf="!carregando"
    #content
    [ngStyle]="{'scroll-behavior':'smooth'}">

    <mat-tab-group
        class="tabsNegocioModal"
        [(selectedIndex)]="selectedIndex">
        <mat-tab label="NEGÓCIO">
            <form
                [formGroup]="formCliente"
                class="mt-16 pl-16 pr-16"
                *ngIf="!negocio">
                <div fxLayout="row wrap">
                    <div fxFlex="100">
                        <mat-form-field>
                            <mat-label>
                                <span [ngStyle]="{'color':'red'}">*</span>
                                Cliente
                            </mat-label>
                            <mat-icon
                                matPrefix
                                class="mr-8"
                                [svgIcon]="'account'"></mat-icon>
                            <input
                                type="text"
                                matInput
                                formControlName="nome"
                                (keyup)="buscarClientes($event)"
                                [matAutocomplete]="idClienteComplete"
                                [disabled]="editarNegocio">
                            <mat-autocomplete #idClienteComplete="matAutocomplete">
                                <mat-option
                                    *ngFor="let option of listaDeClientes"
                                    [value]="option.nome"
                                    (onSelectionChange)="setDadosCliente(option)">
                                    {{option.nome}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="erroBuscaClientes">
                                Erro ao buscar clientes, tente novamente.
                            </mat-error>
                            <mat-icon
                                *ngIf="buscandoCliente"
                                [svgIcon]="'loading'"
                                [ngClass]="['loading']"
                                [ngStyle]="{'position':'absolute','right':'16px','top':'0'}"></mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100">
                        <mat-form-field>
                            <mat-icon
                                matPrefix
                                class="mr-8"
                                [svgIcon]="'email'"></mat-icon>
                            <input
                                autocomplete="off"
                                formControlName="email"
                                matInput
                                placeholder="E-mail"
                                type="text"
                                inputmode="email">
                        </mat-form-field>
                    </div>

                    <div fxFlex="50">
                        <mat-form-field>
                            <mat-label>Telefone</mat-label>
                            <mat-icon
                                matPrefix
                                class="mr-8"
                                [svgIcon]="'phone'"></mat-icon>
                            <input
                                autocomplete="off"
                                formControlName="fone"
                                matInput
                                [mask]="mask.getFoneMask(formCliente.controls.fone.value)"
                                placeholder="Telefone"
                                type="text"
                                inputmode="tel">
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <div class="pl-16 pr-16">
                <form [formGroup]="formDadosSeguro">
                    <div class="mt-16 mb-8">
                        <div fxFlex="100">
                            <span class="fontHelvetica16">Dados do Seguro</span>
                        </div>
                    </div>
                    <div
                        class="mt-16"
                        fxLayout="row wrap"
                        fxLayoutGap="16px grid">
                        <div
                            fxFlex="calc(50% - 16px)"
                            class="pbi-0">
                            <mat-form-field>
                                <mat-select
                                    placeholder="Tipo de Seguro"
                                    formControlName="tipoSeguro"
                                    [disabled]="editarNegocio">
                                    <mat-option [value]="1">
                                        <mat-icon [svgIcon]="'file-star-outline'"></mat-icon>
                                        Seguro Novo
                                    </mat-option>
                                    <mat-option [value]="2">
                                        <mat-icon [svgIcon]="'file-refresh-outline'"></mat-icon>
                                        Renovação de Outro Corretor
                                    </mat-option>
                                    <mat-option [value]="3">
                                        <mat-icon [svgIcon]="'file-refresh'"></mat-icon>
                                        Renovação
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            fxFlex="calc(50% - 16px)"
                            class="pbi-0">
                            <mat-form-field>
                                <mat-label>
                                    <span [ngStyle]="{'color':'red'}">*</span>
                                    Ramo
                                </mat-label>

                                <mat-select
                                    formControlName="ramo"
                                    [disabled]="desativarRamo"
                                    (ngModelChange)="selectRamo($event, negocio ? negocio?.configs : null)">
                                    <mat-option
                                        [value]="ramo.id"
                                        *ngFor="let ramo of ramosProdutos">
                                        <mat-icon [svgIcon]="ramo.icone"></mat-icon>
                                        {{ramo.nome}}
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            fxFlex="calc(50% - 16px)"
                            class="pbi-0">
                            <mat-form-field>
                                <mat-label>Inicío de Vigência</mat-label>
                                <input
                                    matInput
                                    formControlName="vigIni"
                                    (ngModelChange)="vigIni($event)"
                                    [dropSpecialCharacters]="false"
                                    [mask]="mask.DD_MM_AAAA"
                                    autocomplete="off"
                                    inputmode="numeric"
                                    debounce-validation="calc(50% - 16px)0"
                                    [validate-on-keydown]="true">

                                <mat-icon
                                    matSuffix
                                    svgIcon="calendar"
                                    (click)="openCalendar(picker, formDadosSeguro.controls.vigIni, formDadosSeguro.controls.vigInv, $event)"></mat-icon>
                                <mat-datepicker #picker></mat-datepicker>

                                <input
                                    [matDatepicker]="picker"
                                    (dateChange)="onChange($event, formDadosSeguro.controls.vigIni)"
                                    [ngStyle]="{display: 'none'}"
                                    formControlName="vigInv">

                                <mat-error *ngIf="errorMessage(formDadosSeguro.controls.vigIni)">
                                    {{errorMessage(formDadosSeguro.controls.vigIni)}}
                                </mat-error>

                            </mat-form-field>
                        </div>
                        <div
                            fxFlex="calc(50% - 16px)"
                            class="pbi-0">
                            <mat-form-field>
                                <mat-label>Prémio (€)</mat-label>
                                <input
                                    autocomplete="off"
                                    matInput
                                    type="text"
                                    (ngModelChange)="changeComissao($event)"
                                    formControlName="premio"
                                    currencyMask
                                    [placeholder]="'0,00 €'"
                                    [options]="ngxCurrencyOptions">
                            </mat-form-field>
                        </div>
                        <div
                            fxFlex="calc(50% - 16px)"
                            class="pbi-0">
                            <mat-form-field>
                                <mat-label>% Comissão</mat-label>
                                <input
                                    autocomplete="off"
                                    matInput
                                    type="text"
                                    (ngModelChange)="changeComissao($event)"
                                    formControlName="percComissao"
                                    currencyMask
                                    [placeholder]="'% 0,00'"
                                    [options]="{ prefix: '% ', thousands: '.', decimal: ',', allowNegative: 'false', nullable: 'true'}">
                            </mat-form-field>
                        </div>
                        <div
                            fxFlex="calc(50% - 16px)"
                            class="pbi-0">
                            <mat-form-field>
                                <mat-label>Comissão (€)</mat-label>
                                <input
                                    autocomplete="off"
                                    matInput
                                    type="text"
                                    (keyup)="changeComissaoEst($event)"
                                    formControlName="comissaoEstimada"
                                    currencyMask
                                    [placeholder]="'0,00 €'"
                                    [options]="ngxCurrencyOptions">
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <form [formGroup]="formSeguroItem">
                    <div class="mt-16 mb-8">
                        <div fxFlex="100">
                            <span class="fontHelvetica16">Resumo do Item</span>
                        </div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutGap="16px grid" *ngIf="formDadosSeguro.get('ramo').value == 32">
                        <div fxFlex="calc(50% - 16px)" class="pbi-0">
                            <mat-form-field>
                                <input
                                    class="personalData"
                                    matInput
                                    [mask]="masksPortugal.PLACA"
                                    placeholder="Matrícula"
                                    formControlName="placa"
                                    (change)="buscarPlaca($event)">
                            </mat-form-field>
                        </div>

                        <div fxFlex="calc(50% - 16px)" class="pbi-0">
                            <div class="btnAutomoveis">
                                <button
                                    mat-button
                                    [disabled]="desativarRamo"
                                    (click)="formSeguroItem.get('segmento').setValue('v')"
                                    [ngClass]="[(negocio && negocio.segmento == 'v') || formSeguroItem.get('segmento').value == 'v' ? 'selectSegmento' : '']">
                                    <mat-icon svgIcon="car-hatchback"></mat-icon>
                                </button>
                                <button
                                    mat-button
                                    [disabled]="desativarRamo"
                                    (click)="formSeguroItem.get('segmento').setValue('m')"
                                    [ngClass]="[(negocio && negocio.segmento == 'm') || formSeguroItem.get('segmento').value == 'm'? 'selectSegmento' : '']">
                                    <mat-icon svgIcon="motorbike"></mat-icon>
                                </button>
                                <button
                                    mat-button
                                    [disabled]="desativarRamo"
                                    (click)="formSeguroItem.get('segmento').setValue('c')"
                                    [ngClass]="[(negocio && negocio.segmento == 'c') || formSeguroItem.get('segmento').value == 'c' ? 'selectSegmento' : '']">
                                <mat-icon svgIcon="truck"></mat-icon>
                            </button>

                            </div>
                        </div>

                        <div fxFlex="calc(50% - 16px)" class="pbi-0">
                            <mat-form-field>
                                <mat-label>Ano de Fabricação</mat-label>
                                <input
                                    type="number"
                                    matInput
                                    formControlName="anoFab"
                                    (ngModelChange)="changeAnoFab($event, negocio ? negocio?.configs : null)">
                            </mat-form-field>
                        </div>
                        <div fxFlex="calc(50% - 16px)" class="pbi-0">
                            <mat-form-field>
                                <mat-label>Ano Modelo</mat-label>
                                <mat-select
                                    placeholder="Ano Modelo"
                                    formControlName="anoMod">
                                    <mat-option
                                        [value]="ano"
                                        *ngFor="let ano of anosMod">
                                        {{ano}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="100">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Automóvel"
                                    formControlName="descricao"
                                    autocomplete="off"
                                    (keyup)="buscarModelos($event)"
                                    [matAutocomplete]="modeloAuto"
                                    [disabled]="!anoFabValido">
                                <mat-icon
                                    *ngIf="loadBuscaModelo"
                                    svgIcon="loading"
                                    [ngClass]="['loading']"
                                    [ngStyle]="{'position':'absolute','right':'16px','top':'0'}"></mat-icon>
                            </mat-form-field>
                            <mat-autocomplete #modeloAuto="matAutocomplete">
                                <mat-option
                                    *ngFor="let _modelo of modelos"
                                    [value]="_modelo.modelo"
                                    [ngClass]="['optionModelo']"
                                    (onSelectionChange)="setModelo(_modelo)">
                                    <span class="titleOptionComplete">
                                        {{getFabricante(_modelo.fipeFabricanteId, _modelo.tipo)}}
                                    </span>
                                    {{ _modelo.modelo }}
                                </mat-option>
                            </mat-autocomplete>
                        </div>

                    </div>
                    <div>
                        <div fxFlex="100">
                            <mat-form-field>
                                <mat-label>Outros detalhes</mat-label>
                                <textarea
                                    matInput
                                    matAutosizeMinRows="1"
                                    formControlName="detalhesSeguro"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>

            <form [formGroup]="formSeguroItem" class="pl-16 pr-16">
                <div class="colNegocio mb-16">
                    <div
                        fxLayout="row wrap"
                        fxFlex="100">
                        <div
                            class="colNegocio__title"
                            fxFlex="40"
                            *ngIf="!negocio">
                            <span class="fontHelvetica16">NEGÓCIO</span>
                        </div>
                        <div
                            fxFlex="60"
                            *ngIf="!negocio">
                            <mat-form-field class="colNegocio__selectCustom">
                                <mat-select formControlName="status">
                                    <mat-option [value]="1">
                                        <mat-icon [svgIcon]="'oportunidade'"></mat-icon>
                                        Oportunidade
                                    </mat-option>
                                    <mat-option [value]="2">
                                        <mat-icon [svgIcon]="'iniciar'"></mat-icon>
                                        Início
                                    </mat-option>
                                    <mat-option [value]="3">
                                        <mat-icon [svgIcon]="'cotacao'"></mat-icon>
                                        Cotação
                                    </mat-option>
                                    <mat-option [value]="4">
                                        <mat-icon [svgIcon]="'acompanhamento'"></mat-icon>
                                        Acompanhamento
                                    </mat-option>
                                    <mat-option [value]="5">
                                        <mat-icon [svgIcon]="'fechado'"></mat-icon>
                                        Fechado
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div
                            fxFlex="100"
                            class="mt-16">
                            <button
                                mat-button
                                (click)="getUsuarios()"
                                *ngIf="erroBuscarUsuarios">
                                CARREGAR UTILIZADORES
                            </button>
                            <mat-form-field *ngIf="!erroBuscarUsuarios">
                                <mat-chip-list
                                    #chipList
                                    aria-label="User selection">
                                    <mat-chip
                                        *ngFor="let user of users"
                                        [selectable]="selectable"
                                        [removable]="removable || users.length == 1"
                                        (removed)="remove(user)"
                                        [disabled]="data?.negocio ? false : true">
                                        {{user?.nome}}
                                        <mat-icon
                                            matChipRemove
                                            *ngIf="removable && users.length > 1">
                                            cancel
                                        </mat-icon>
                                    </mat-chip>
                                    <input
                                        placeholder="Utilizadores"
                                        #usersInput
                                        [formControl]="userCtrl"
                                        [disabled]="data?.negocio ? false : true"
                                        [matAutocomplete]="usuariosChip"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="addOnBlur"
                                        (matChipInputTokenEnd)="add($event)">
                                </mat-chip-list>
                                <mat-autocomplete #usuariosChip="matAutocomplete">
                                    <mat-option
                                        *ngFor="let user of filteredUsers | async"
                                        [value]="user.nome"
                                        (onSelectionChange)="selected($event, user)">
                                        {{user.nome}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon
                                    *ngIf="loadAddRemoveuser"
                                    svgIcon="loading"
                                    [ngClass]="['loading']"
                                    [ngStyle]="{'position':'absolute','right':'16px','bottom':'6px'}"></mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div fxLayout="row wrap" fxLayoutGap="16px grid">
                    <div fxFlex="calc(50% - 16px)" class="pbi-0">
                        <mat-form-field>
                            <mat-label>Origem</mat-label>
                            <mat-select formControlName="origemNegocio">
                                <mat-option [value]="1">Indicação</mat-option>
                                <mat-option [value]="2">Redes Sociais</mat-option>
                                <mat-option [value]="3">Sites de busca (Google, Bing, etc)</mat-option>
                                <!-- <mat-option [value]="4">Anúncios em mídias impressas</mat-option> -->
                                <!-- <mat-option [value]="5">Anúncios em rádios</mat-option> -->
                                <mat-option [value]="6">Outros anúncios</mat-option>
                                <mat-option [value]="7">Cliente atual</mat-option>
                                <mat-option [value]="8">Corretor digital</mat-option>
                                <mat-option [value]="9">Site da corretora</mat-option>
                                <mat-option [value]="10">E-mail de marketing</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxFlex="calc(50% - 16px)" class="pbi-0">
                        <mat-form-field>
                            <mat-label>Prazo</mat-label>
                            <input
                                matInput
                                formControlName="prazo"
                                [dropSpecialCharacters]="false"
                                [mask]="mask.DD_MM_AAAA"
                                autocomplete="off"
                                inputmode="numeric"
                                debounce-validation="500"
                                [validate-on-keydown]="true">

                            <mat-icon
                                matSuffix
                                [svgIcon]="'calendar'"
                                (click)="openCalendar(pickerp1, formSeguroItem.controls.prazo, formSeguroItem.controls.prazoInv, $event)"></mat-icon>
                            <mat-datepicker #pickerp1></mat-datepicker>

                            <input
                                [matDatepicker]="pickerp1"
                                (dateChange)="onChange($event, formSeguroItem.controls.prazo)"
                                [ngStyle]="{display: 'none'}"
                                formControlName="prazoInv">

                            <mat-error *ngIf="errorMessage(formSeguroItem.controls.prazo)">
                                {{errorMessage(formSeguroItem.controls.prazo)}}
                            </mat-error>

                        </mat-form-field>
                    </div>

                    <div fxFlex="100">
                        <mat-form-field>
                            <mat-label>Observações</mat-label>
                            <textarea
                                matInput
                                matAutosizeMinRows="1"
                                formControlName="observacao"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </mat-tab>

        <mat-tab
            label="EVENTOS"
            [disabled]="!negocio"
            >
            <div class="pl-16 pr-16">
                <div>
                    <div fxFlex="100">
                        <div class="def-title-primary btnAutomoveis">
                            <button
                                mat-button
                                [ngClass]="[eventoSelecionado == 1 ? 'selectSegmento' : '']"
                                (click)="eventoSelecionado = 1">
                                Anotação
                            </button>
                            <button
                                mat-button
                                [ngClass]="[eventoSelecionado == 2 ? 'selectSegmento' : '']"
                                (click)="eventoSelecionado = 2">
                                Tarefa
                            </button>

                        </div>
                    </div>
                </div>

                <div fxLayout="row wrap">
                    <div
                        fxFlex="100"
                        *ngIf="eventoSelecionado == 1"
                        class="mt-16">
                        <mat-form-field>
                            <mat-label>Descrição da anotação</mat-label>
                            <textarea
                                matInput
                                matAutosizeMinRows="1"
                                [(ngModel)]="descricaoEvento"></textarea>
                        </mat-form-field>

                        <div [ngStyle]="{'display':'flex', 'justify-content':'flex-end'}">
                            <button
                                mat-button
                                (click)="createUpdateEvent()"
                                [disabled]="!descricaoEvento || loadEvent">
                                Adicionar
                                <mat-icon
                                    *ngIf="loadEvent"
                                    [svgIcon]="'loading'"
                                    [ngClass]="['loading']"
                                    [ngStyle]="{'margin-left':'2px'}">
                                </mat-icon>
                            </button>
                        </div>
                    </div>

                    <div
                        fxFlex="100"
                        *ngIf="eventoSelecionado == 2">
                        <tarefa
                            #tarefas
                            [modalNegocio]="true"
                            [clienteNome]="formCliente.controls.nome"
                            [negocioId]="data?.negocio"
                            (atualizarLista)="atualizarEventos($event)"></tarefa>
                    </div>

                    <div fxFlex="100">
                        <ul class="listaTarefas">
                            <li
                                *ngFor="let event of listEvents"
                                [@anim]="'novo'">
                                <div class="line">
                                    <div [ngClass]="['icon', event.tipo == 3 ? 'warning' : '']">
                                        <mat-icon
                                            *ngIf="event.tipo != 1"
                                            matPrefix
                                            svgIcon="calendar-text-outline2"
                                           ></mat-icon>
                                        <mat-icon
                                            *ngIf="event.tipo == 1"
                                            matPrefix
                                            class="iconApice"
                                            svgIcon="apice"></mat-icon>
                                    </div>
                                </div>
                                <div class="texto">
                                    <div class="data">
                                        <div class="fontSmall">
                                            {{event.subTipoLabel ? event.subTipoLabel + ' - ' : ''}}{{event.dataLabel}}
                                        </div>
                                    </div>
                                    <span class="fontDefault">{{event.descricao || ''}}</span>
                                    <div
                                        class="listaTarefas__observacaoStatus"
                                        *ngIf="event.observacaoStatus">
                                        <span class="fontSmall">Finalizada em: {{event.dataEncLabel}}</span>
                                        <span class="fontDefault">Obs: {{event.observacaoStatus || ''}}</span>
                                    </div>
                                    <div class="usuario">
                                        <mat-icon
                                            matPrefix
                                            svgIcon="account"></mat-icon>
                                        <span class="fontSmall">{{event.nomeCriador || ''}}</span>
                                    </div>
                                </div>
                                <div
                                    class="controles"
                                    *ngIf="event.tipo == 3 && event.status != 2 && event.status != 3 && !event.dataEncerramento">
                                    <div>
                                        <button
                                            mat-icon-button
                                            class="editar"
                                            (click)="editarTarefa(event)">
                                            <mat-icon
                                                matPrefix
                                                [svgIcon]="'pencil'"></mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab
            [label]="anexosComponent && anexosComponent.anexos.length > 0 ? 'ANEXOS ' + anexosComponent.anexos.length : 'ANEXOS'"
            [disabled]="!negocio">
            <div class="p-16">
                <gerenciador-arquivos
                    #anexosComponent
                    [path]="'negocios'"
                    [id]="data.negocio"
                    [showTitle]="false"></gerenciador-arquivos>
            </div>
        </mat-tab>

    </mat-tab-group>

</mat-dialog-content>
<div [ngClass]="['detalhesCliente', openCadastroCliente ? 'open' : '']">
    <detalhes-editar-cliente
        class="modalCliente"
        *ngIf="openCadastroCliente"
        [clienteId]="formCliente.get('id').value"
        [negocio]="true"
        (dadosClienteNegocio)="getDadosClienteSalvo($event)"
        (voltaTelaNegocio)="cadastroCliente($event)"></detalhes-editar-cliente>
</div>

<mat-dialog-actions
    [ngStyle]="{'justify-content': 'flex-end'}"
    *ngIf="!carregando">
    <button
        mat-button
        class="def-w-btn"
        [mat-dialog-close]="true"
        [disabled]="!formDadosSeguro.get('ramo').value || loadSalvaNegocio || selectedIndex != 0">
        CANCELAR
    </button>
    <button
        mat-button
        class="def-b-btn"
        (click)="criarSalvarNegocio()"
        [disabled]="!formDadosSeguro.get('ramo').value || loadSalvaNegocio || selectedIndex != 0">
        SALVAR
        <mat-icon
            *ngIf="loadSalvaNegocio"
            svgIcon="loading"
            [ngClass]="['loading']"
            [ngStyle]="{'margin-left':'2px'}">
        </mat-icon>
    </button>
</mat-dialog-actions>
