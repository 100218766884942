export class StatusNegocio {
    status: number;

    get getStatus() {
        if (this.status == 1) {
            return 'Oportunidade'
        } else if (this.status == 2) {
            return 'Iniciar'
        } else if (this.status == 3) {
            return 'Cotação'
        } else if (this.status == 4) {
            return 'Acompanhamento'
        } else if (this.status == 5) {
            return 'Fechado'
        } else if (this.status == 6) {
            return 'Perdido'
        } else if (this.status == 7) {
            return 'Descartado'
        } else if (this.status == 8) {
            return 'Fechado e Concluído'
        }
    }

    getNameIcons(status) {
        if (status == 1) {
            return 'oportunidade'
        } else if (status == 2) {
            return 'iniciar'
        } else if (status == 3) {
            return 'cotacao'
        } else if (status == 4) {
            return 'acompanhamento'
        } else if (status == 5) {
            return 'fechado'
        } else if (status == 6) {
            return 'negocio_perdido'
        } else if (status == 7) {
            return 'descartado'
        } else if (status == 8) {
            return 'fechado_concluido'
        } else {
            return ''
        }
    }

    set setStatus(status) {
        if (status == 'oportunidade' || status == 'Oportunidade') {
            this.status = 1;
        } else if (status == 'iniciar' || status == 'Iniciar') {
            this.status = 2;
        } else if (status == 'cotacao' || status == 'Cotação') {
            this.status = 3;
        } else if (status == 'acompanhamento' || status == 'Acompanhamento') {
            this.status = 4;
        } else if (status == 'fechado' || status == 'Fechado') {
            this.status = 5;
        }
        else if (status == 'perdido' || status == 'Perdido') {
            this.status = 6;
        }
        else if (status == 'descartar' || status == 'Descartado' || status == 'descartado') {
            this.status = 7;
        }
        else if (status == 'concluido' || status == 'Fechado e Concluído') {
            this.status = 8;
        } else {
            this.status = null;
        }
    }
}