import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalNegocioComponent } from './modal-negocio.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { PageModules } from 'src/app/utils/pageModules';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { TextMaskModule } from 'angular2-text-mask';
import { TarefaModule } from 'src/app/components/tarefa/tarefa.module';
import { GerenciadorArquivosModule } from 'src/app/components/gerenciador-arquivos/gerenciador-arquivos.module';
import { DebounceModule } from 'src/app/directives/debounce/debounce.module';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { NgxCurrencyModule } from "ngx-currency";
import { DetalhesEditarModule } from '../../cliente/detalhes-editar/detalhes-editar.module';
import { ApiInterfaceService } from 'src/app/services/api-interface.service';
import { ApiApiceService } from 'src/app/services/api/api-apice.service';
import { ModalDocsAtravesNegocioModule } from '../../documentos/modal-docs-atraves-negocio/modal-docs-atraves-negocio.module';
@NgModule({
  declarations: [ModalNegocioComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    PageModules,
    MatDialogModule,
    MatMenuModule,
    MatTabsModule,
    TextMaskModule,
    TarefaModule,
    GerenciadorArquivosModule,
    DebounceModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatChipsModule,
    RouterModule,
    NgxCurrencyModule,
    DetalhesEditarModule,
    ModalDocsAtravesNegocioModule 
  ],
  exports: [ModalNegocioComponent],
  providers: [
    { provide: ApiInterfaceService, useClass: ApiApiceService }
]
})
export class ModalNegocioModule { }
