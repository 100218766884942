import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EventosModel } from 'src/app/models/eventos.model';
import { Observable } from 'rxjs';
import { mandatorio, validaDate } from 'src/app/utils/validadores';
import { Mask } from 'src/app/utils/masks';
import { MessageHandler } from 'src/app/utils/form-field-message-handler';
import * as Moment from'moment';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { ApiEventosService } from 'src/app/services/api-eventos.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { openCalendarDatepicker, onChangeDate } from 'src/app/utils/moment-config-datepicker';
import { ControllerModalsService } from 'src/app/services/controller-modals.service';
import { TodoControllerService } from 'src/app/services/todo-controller.service';
import { ModalNegocioComponent } from 'src/app/pages/negocios/modal-negocio/modal-negocio.component';

export interface UserResponsavel {
	id: String;
	nome: String;
}

@Component({
	selector: 'tarefa',
	templateUrl: './tarefa.component.html',
	styleUrls: ['./tarefa.component.scss']
})
export class TarefaComponent implements OnInit {

	@ViewChild('usersInput') usersInput: ElementRef<HTMLInputElement>;
	@ViewChild('usuariosChip') matAutocomplete: MatAutocomplete;
	@Input('negocio') negocio: any;
	@Input('negocioId') negocioId: String;
	@Input('clienteNome') clienteNome: any;
	@Input('tarefa') tarefa: any;
	@Input('visualizar') visualizar: boolean = false;
	@Input('status') status: boolean = false;
	@Input('concluindo') concluindo: boolean = false;


	@Input('isModal') isModal: boolean = false;
	@Input('modalNegocio') modalNegocio: boolean = false;
	@Output('atualizarLista') atualizarLista = new EventEmitter();

	separatorKeysCodes: number[] = [ENTER, COMMA];
	errorMessage = MessageHandler.errorMessage;
	mask = Mask;

	tiposTarefa = [
		{ value: 6, name: "Almoço", icon: 'almoco' },
		{ value: 4, name: "Atividade", icon: 'calendar-text-outline' },
		{ value: 2, name: "E-mail", icon: 'email' },
		{ value: 7, name: "Jantar", icon: 'jantar' },
		{ value: 1, name: "Telefonema", icon: 'phone' },
		{ value: 3, name: "Reunião", icon: 'account-group' },
		{ value: 5, name: "Visita", icon: 'account-tie' }
	]
	tipoTarefa;
	formTarefa: FormGroup;
	moment = Moment;
	usuarioResponsavel: UserResponsavel;
	dataAtual = this.moment().locale('pt-br');
	observacaoStatus: String = null;
	editando: boolean = false;
	estado: string = "2";
	realizou: number;
	tarefaBkp: any;
	loadingSave: boolean = false;
	constructor(private apiUsuario: ApiUsuarioService,
		private modalCtrl: ControllerModalsService,
		private apiEventos: ApiEventosService, 
		private registerIcons: RegisterIconsService,
		private snack: MatSnackBar, 
		public dialog: MatDialog,
		public todoController: TodoControllerService) {
		registerIcons.registerIcons(['plus', 'minus', 'close', 'phone', 'email', 'account-group', 'calendar-text-outline',
			'account-tie', 'almoco', 'jantar', 'cancel', 'check', 'clock-outline', 'pencil', 'dots-vertical', 'calendar-plus', 'calendar-check', 'delete', 'loading', 'calendar'])
		modalCtrl.setModalContext(dialog);
	}

	ngOnInit() {
		this.editando = false;
		if (this.concluindo) {
			this.realizou = 2;
		}
		if (this.negocio) {
			this.negocioId = this.negocio.id;
		}

		if (this.tarefa) {
			this.tarefaBkp = this.tarefa;
			for (let tipo of this.tiposTarefa) {
				if (tipo.value == this.tarefa.subtipo || tipo.value == this.tarefa.subTipo) {
					this.tipoTarefa = tipo;
					break;
				}
			}

			this.setFormTarefa()
		} else {
			this.formTarefa = new FormGroup({
				id: new FormControl(undefined),
				subTipo: new FormControl(1),
				titulo: new FormControl(this.clienteNome && this.clienteNome.value || this.negocio && this.negocio.cliente.nome || ''),
				descricao: new FormControl(''),
				responsavelId: new FormControl(''),
				negocioId: new FormControl(this.negocioId || ''),
				inicioHidden: new FormControl(''),
				dataHoraIni: new FormControl('', { validators: [mandatorio, validaDate] }),
				fim: new FormControl(''),
				hora: new FormControl('')
			})

		}

		this.usuarioResponsavel = { id: this.apiUsuario.getCredenciais().id, nome: this.apiUsuario.getCredenciais().nome }		
	}

	setFormTarefa() {
		let tarefa: any = this.tarefa || this.tarefaBkp;
		this.negocioId = tarefa.negocioId;
		let dataMoment: any = tarefa.dataHoraIni ? this.convertDateHoras(tarefa.dataHoraIni) : '';
		let hora = dataMoment.horas || '';
		let dataIni = dataMoment.dias || '';

		this.formTarefa = new FormGroup({
			id: new FormControl(tarefa.id),
			subTipo: new FormControl(tarefa.subTipo || tarefa.subtipo),
			titulo: new FormControl(tarefa.titulo),
			descricao: new FormControl(tarefa.descricao),
			responsavelId: new FormControl(tarefa.responsavelId),
			negocioId: new FormControl(tarefa.negocioId || ''),
			inicioHidden: new FormControl(''),
			dataHoraIni: new FormControl(dataIni, { validators: [mandatorio, validaDate] }),
			fim: new FormControl(''),
			hora: new FormControl(hora),
			observacaoStatus: new FormControl(tarefa.observacaoStatus || null)
		})

	}

	openCalendar = openCalendarDatepicker;
	onChange = onChangeDate;

	//#region matchips
	//** INICIO MATCHIPS USERS */
	visible = false;
	selectable = false;
	removable = false;
	addOnBlur = false;

	erroBuscarUsuarios: boolean = false;
	loadAddRemoveuser: boolean = false;

	userCtrl = new FormControl();
	filteredUsers: Observable<string[]>;
	users = [];
	allUsers = [];

	convertDateHoras(date) {
		if (date) {
			let dias = this.moment(date).locale('pt-br').format('L');
			let horas = this.moment(date).locale('pt-br').format('LT');
			return { dias: dias, horas: horas };
		} else {
			return false
		}
	}

	async getUsuarios() {
		this.erroBuscarUsuarios = false;
		this.loadAddRemoveuser = true;
		try {
			let usuarios:any;	
			if (this.apiUsuario.listaUsuarios.length){
				usuarios = this.apiUsuario.listaUsuarios;
			}else{
				usuarios = await this.apiUsuario.buscaUsuarios2();	
				this.apiUsuario.listaUsuarios = usuarios;	
			}
			this.allUsers = usuarios.filter(u => u.ativo == true && u.confirmacao == false && u.tipoRedefinicaoSenha != 'novo');
			this.loadAddRemoveuser = false;
		} catch (error) {
			this.erroBuscarUsuarios = true;
			this.loadAddRemoveuser = false;

		}
		if (this.apiUsuario?.getCredenciais()) {
			this.usuarioResponsavel = { id: this.apiUsuario.getCredenciais().id, nome: this.apiUsuario.getCredenciais().nome }
		}

	}

	remove(user: string): void {
		//console.log('remove' + user);
		if (!this.loadAddRemoveuser) {

		}
	}

	async addUsuario(user) {
		this.loadAddRemoveuser = true;
		let obj = {
			id: undefined,
			usuarioId: user.id,
			gravacao: true,
			leitura: true,
			versao: undefined,
			calculo: undefined,
			adicaoUsuario: true,
			remocaoUsuario: false
		}
		try {
			// let ret = await this.apiCotacao.salvaNegocioUsuario(obj);
			this.users.push(user);
			this.usersInput.nativeElement.value = '';
			this.userCtrl.setValue(null);
			// console.log('this.user', this.users);
			// console.log('add', ret);
			this.loadAddRemoveuser = false;
		} catch (error) {
			this.loadAddRemoveuser = false;
			this.usersInput.nativeElement.value = '';
			this.userCtrl.setValue(null);
			// this.openMessage('Falha ao adicionar usuário, tente novamente!');
			// console.log('erro ao adicionar usuário ao negócio ', error);
		}
	}

	selected(event: MatAutocompleteSelectedEvent, user): void {
		if (!this.loadAddRemoveuser) {
			// console.log('selected user');
			this.addUsuario(user);
		}
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our user
		if ((value || '').trim()) {
			this.users.push(value.trim());
		}
		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.usersInput.nativeElement.value = '';
		this.userCtrl.setValue(null);
	}

	private _filter(value: any) {
		const filterValue = value ? value.toLowerCase() : value;
		return this.allUsers.filter(user => {
			//console.log('user', user)
			return user.nome.toLowerCase().indexOf(filterValue) === 0
		});
	}
	//** FIM MATCHIPS USERS */
	//#endregion matchips

	openDetalhes: boolean = false;
	abrirDetalhes() {
		this.openDetalhes = !this.openDetalhes;
	}


	async criarEditarTarefa() {
		this.loadingSave = true;
		this.formTarefa.controls.responsavelId.setValue(this.usuarioResponsavel.id);
		let dados = this.formTarefa.getRawValue();
		let splitDate = dados.dataHoraIni.split('/');
		let dia = splitDate[0];
		let mes = splitDate[1];
		let ano = splitDate[2];
		let data = ano + '-' + mes + '-' + dia;
		let dataHoraIni = this.moment(data + ' ' + dados.hora).format('YYYY-MM-DDTHH:mm');

		let evento: EventosModel = {};
		evento.id = dados.id;
		evento.tipo = 3;
		evento.negocioId = this.negocioId ? this.negocioId : null;
		evento.subTipo = dados.subTipo;
		evento.titulo = dados.titulo;
		evento.descricao = dados.descricao;
		evento.responsavelId = dados.responsavelId;
		evento.dataHoraIni = dataHoraIni;
		evento.observacaoStatus = dados.observacaoStatus || null;
		evento.status = dados.status || this.realizou || 1;

		if (this.tarefa && this.tarefa.createdAt)
			evento.createdAt = this.tarefa.createdAt;
		try {
			let ret = await this.apiEventos.criarEditarEvento(evento);
			this.todoController.verificarToDo("tarefas");
			if (this.negocioId || this.tarefa || this.isModal) {
				if (ret === true && this.modalNegocio) { //atualizado
					this.atualizarLista.emit(evento);
				} else { //criado
					this.atualizarLista.emit(ret);
				}
			}
			this.formTarefa.controls.subTipo.setValue(1);
			this.formTarefa.controls.descricao.setValue('');
			this.formTarefa.controls.dataHoraIni.setValue('');
			this.formTarefa.controls.inicioHidden.setValue('');
			this.formTarefa.controls.hora.setValue('');
			// console.log('sucesso criar tarefa ', ret);
		} catch (error) {
			console.log('error ao criar tarefa ', error);
			this.snack.open('Houve alguma falha, tente novamente!', 'OK', { duration: 6000 });
		} finally {
			this.loadingSave = false;
		}

	}

	loadingStatus: boolean = false;
	async excluirTarefa(status, ev) {
		ev.stopPropagation();
		let dialog = this.dialog.open(DialogConfirmComponent, {
			data: { status: status },
			panelClass: 'modalDefault'
		});
		dialog.beforeClosed().subscribe(async ret => {
			if (ret && ret.tipo == 'confirmar') {
				this.loadingStatus = true;

				let evento: EventosModel = {};
				evento.id = this.tarefa?.id || this.tarefaBkp?.id;
				evento.tipo = 3;
				evento.negocioId = this.tarefa?.negocioId ? this.tarefa?.negocioId : this.tarefaBkp?.negocioId ? this.tarefaBkp?.negocioId : '';
				evento.subTipo = this.tarefa?.subTipo || this.tarefaBkp?.subTipo;
				evento.descricao = this.tarefa?.descricao || this.tarefaBkp?.descricao;
				evento.responsavelId = this.tarefa?.responsavelId || this.tarefaBkp?.responsavelId;
				evento.dataHoraIni = this.tarefa?.dataHoraIni || this.tarefaBkp?.dataHoraIni;
				evento.titulo = this.tarefa?.titulo || this.tarefaBkp?.titulo;

				evento.status = status;
				evento.observacaoStatus = ret.observacaoStatus;

				try {
					let ret = await this.apiEventos.criarEditarEvento(evento);
					if (this.negocioId || this.tarefa) {
						if (ret === true && this.modalNegocio) {
							this.atualizarLista.emit(evento);
						} else {
							this.snack.open('Sua tarefa foi deletada com sucesso!', 'OK', { duration: 6000 })
							this.atualizarLista.emit(ret);
						}
					}

				} catch (error) {
					console.log('error ao excluir tarefa ', error);
					this.loadingStatus = false;
				} finally {
					this.loadingStatus = false;
				}

			}

		})

	}

	editarTarefa(ev, tarefa?) {
		ev.stopPropagation();
		this.editando = true;
		this.visualizar = false;
	}

	novaTarefa(ev, tarefa) {
		ev.stopPropagation();
		this.editando = false;
		this.visualizar = false;
		this.tarefa = '';
		this.negocio = tarefa || this.tarefaBkp;
		this.negocioId = this.negocio?.negocioId || '';
		this.formTarefa = new FormGroup({
			id: new FormControl(undefined),
			subTipo: new FormControl(1),
			titulo: new FormControl(this.clienteNome && this.clienteNome.value || this.negocio && this.negocio.cliente && this.negocio.cliente.nome || this.negocio.titulo || ''),
			descricao: new FormControl(''),
			responsavelId: new FormControl(''),
			negocioId: new FormControl(this.negocioId),
			inicioHidden: new FormControl(''),
			dataHoraIni: new FormControl('', { validators: [mandatorio, validaDate] }),
			fim: new FormControl(''),
			hora: new FormControl('')
		})
	}

	loadingAdiar: boolean = false;
	async adiarData(tarefa, tempo, ev) {
		ev.stopPropagation();
		this.loadingAdiar = true;
		let dados;
		if (!tarefa) {
			this.tarefaBkp.adiar = tempo;
			dados = this.tarefaBkp;
		} else {
			tarefa.adiar = tempo;
			dados = tarefa;
		}
		/**
		 * TEMPO 
		 * 1 - amanhã
		 * 2 - depois de amanhã
		 * 3 - próxima semana
		 */
		let converteNovaData = (data, tempo) => {
		debugger
			let horas = this.moment(data).format('LT');
			let dataAtual = this.moment(this.dataAtual).format('L');
			let splitDate = dataAtual.split('/');
			let dia = splitDate[0];
			let mes = splitDate[1];
			let ano = splitDate[2];
			let dataFormatada = ano + '-' + mes + '-' + dia;
			let novaDataMoment = this.moment(dataFormatada + ' ' + horas).format('YYYY-MM-DDTHH:mm');
			let novaData = this.moment(novaDataMoment).add(tempo, 'days');

			return novaData;
		}
		debugger
		let dataHoraIni = dados.dataHoraIni ? converteNovaData(dados.dataHoraIni, tempo) : dados.dataHoraIni;

		let evento: EventosModel = {};
		evento.id = dados.id;
		evento.tipo = 3;
		evento.negocioId = dados.negocioId;
		evento.subTipo = dados.subTipo || dados.subtipo;
		evento.descricao = dados.descricao;
		evento.titulo = dados.titulo || dados.cliente.nome;
		evento.responsavelId = dados.responsavelId;
		evento.dataHoraIni = dataHoraIni;

		try {
			let ret = await this.apiEventos.criarEditarEvento(evento);
			if (this.negocioId || this.tarefa) {
				if (ret === true && this.modalNegocio) {
					this.atualizarLista.emit(evento);
				} else {
					this.atualizarLista.emit(ret);
				}
			}

		} catch (error) {
			console.log('error ao criar tarefa ', error);
			this.tarefa.adiar = 0;
			this.loadingAdiar = false;
			this.snack.open('Houve alguma falha, tente novamente!', 'OK', { duration: 6000 });
		} finally {
			this.loadingSave = false;
		}

	}

	concluirTarefa(ev, tarefa) {
		ev.stopPropagation();
		this.visualizar = true;
		this.concluindo = true;
		this.formTarefa.addControl('status', new FormControl(2));
	}

	mudaStatus() {
		this.estado
		if (this.estado == "3") {
			this.realizou = 3;
			if (this.formTarefa.controls.status) {
				this.formTarefa.controls.status.setValue(3);
			}
		} else if (this.estado == "2") {
			this.realizou = 2;
			if (this.formTarefa.controls.status) {
				this.formTarefa.controls.status.setValue(2);
			}
		}
	}

	close() {
		if (!this.visualizar && this.tarefaBkp) {
			this.visualizar = true;
			this.setFormTarefa()

		}
		else if (this.status) {
			this.visualizar = true;
			this.concluindo = false;
			this.status = true;
		} else if (this.concluindo) {
			this.concluindo = false;
		} else if (!this.negocio && !this.tarefaBkp) {
			this.dialog.closeAll();

		} else {
			this.dialog.closeAll();
		}

	}

	verNegocio(){		
		let dialog = this.dialog.open(ModalNegocioComponent, {
			data: { negocio: this.tarefa.negocioId },
			minWidth: 300,
			minHeight: 300,
			panelClass: 'modalNegocio'
		})
		document.querySelector('body').classList.add('tiraScrollBody');
	}

	ngOnDestroy(){
		this.modalCtrl.clear();
	}

}
