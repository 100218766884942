import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EstatisticasVeiculoComponent } from '../components/estatisticas-veiculo/estatisticas-veiculo.component';
import { ApiInterfaceService } from './api/api-interface.service';
import { UtilsService } from './utils.service';

@Injectable()

export class EstatisticasVeiculoService {

  constructor(private modalService: NgbModal, private api: ApiInterfaceService, private utilsService: UtilsService) { }

  public fipeModelo;
  public valorMedio;
  public automovel: any = {};
  public fipeAnterior: any = {};

  public async open(automovel, historico) {

    const modalRef = this.modalService.open(EstatisticasVeiculoComponent, {
      size: "600px"
    });

    let lif;

    try {
      lif = await this.loadInfosFipe(automovel, historico);

      this.fipeAnterior = automovel.fipe;

      if (lif[0])
        this.updateVarsFipeModeloResult(automovel, lif[0]);

      if (lif[1])
        this.valorMedio = lif[1];

      if (this.valorMedio.filter((vm) => { return vm; }).length == 0) {
        modalRef.close();
        await this.utilsService.createAlert("Atenção", "Não existe amostra suficiente para exibir as estatísticas deste veículo.");
        return false;
      }

    } catch (e) {
      console.log(e);
      return false;
    }

    modalRef.componentInstance.fipeModelo = this.fipeModelo;
    modalRef.componentInstance.valorMedio = this.valorMedio;
    modalRef.componentInstance.automovel = this.automovel;

    return modalRef.componentInstance.updateValues();

    //return true; //modalRef.result;

  }

  public async setInfosFipeModelo(automovel, fipeCarregada) {
    this.automovel = automovel;
    let isZeroKm = this.automovel.zeroKm;

    this.api.getCalculoFipeModelo(
      automovel.fipeTxt,
      automovel.anoModelo,
      isZeroKm
    ).then(function (fipeModelo) {
      this.updateVarsFipeModeloResult(automovel, fipeModelo);

      fipeCarregada.valor = automovel.valorFipeTxt;
      fipeCarregada.codigo = automovel.fipe;
    }.bind(this)).catch(function (e) {
      console.log(e);
    });
  }

  public updateVarsFipeModeloResult(automovel, fipeModelo) {
    this.fipeModelo = fipeModelo;
    let fipeValAtual = this.utilsService.getFipeAtualValores(fipeModelo);

    automovel.valorFipeTxt = fipeValAtual ? this.utilsService.numberFormat(fipeValAtual, true) : "";
  }

  public async setInfosValorMedio(automovel, historico) {

    let maior25 = false;
    try {
      if (automovel.condutores[0])
        maior25 = this.utilsService.getIdade(new Date(automovel.condutores[0].dataNasc)) > 25 ? true : false;
      this.automovel = automovel;
      automovel.condutorMaior25 = maior25;
    } catch (e) {
      console.log(e);
    }
    this.api.getCalculoValorMedio(
      automovel.fipeTxt,
      automovel.anoModelo,
      automovel.cepCirculacao,
      maior25,
      historico
    ).then(function (valorMedio) {
      this.valorMedio = valorMedio;
    }.bind(this)).catch(function (e) {
      console.log(e);
    });
  }

  public async loadInfosFipe(automovel, historico) {

    let maior25 = false;
    try {
      if (automovel.condutores[0])
        maior25 = this.utilsService.getIdade(new Date(automovel.condutores[0].dataNasc)) > 25 ? true : false;

      this.automovel = automovel;
      automovel.condutorMaior25 = maior25;
    } catch (e) {
      console.log(e);
    }

    let promissesArray = [];

    if (automovel.fipe != this.fipeAnterior || (automovel.fipe == this.automovel.fipe && !this.fipeModelo))
      promissesArray.push(this.api.getCalculoFipeModelo(
        automovel.fipeTxt,
        automovel.anoModelo,
        automovel.zeroKm
      ));
    else
      promissesArray.push(this.fipeModelo);

    if (automovel.fipe != this.fipeAnterior || (automovel.fipe == this.automovel.fipe && !this.valorMedio))
      promissesArray.push(this.api.getCalculoValorMedio(
        automovel.fipeTxt,
        automovel.anoModelo,
        automovel.cepCirculacao,
        maior25,
        historico
      ));
    else
      promissesArray.push(this.valorMedio);

    return Promise.all(promissesArray);

  }

}
