import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import *  as  linksVideos from 'src/docs/links-videos.json';
@Component({
  selector: 'app-modal-video-intro',
  templateUrl: './modal-video-intro.component.html',
  styleUrls: ['./modal-video-intro.component.scss']
})
export class ModalVideoIntroComponent implements OnInit {
  private linksVideos = (linksVideos as any).default;
  intro;
  naoMostrar;
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.intro = this.domSanitizer.bypassSecurityTrustResourceUrl(this.linksVideos.intro);
    this.naoMostrar = localStorage.getItem('INTRO-VIDEO') == 'true';
  }

  change(){
    localStorage.setItem('INTRO-VIDEO', this.naoMostrar);
  }

}
