import { Injectable } from '@angular/core';
import { ApiInterfaceService } from './api-interface.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../base.service';
import { environment } from 'src/environments/environment';
import { SharedMemoryService } from '../shared-memory.service';
import { Subscription } from 'rxjs';
import { ApiUsuarioService } from '../api-usuario.service';
import { ApiClienteService } from '../api-cliente.service';
import { ApiCorretoraService } from 'src/app/services/api-corretora.service';
import { SeguradoraWakandaAtiva } from '../../models/seguradora/entities/wakanda-ativa.model';
import { SeguradoraCalcular } from '../../models/seguradora/entities/calcular.model';
import { SeguradorasStatus } from '../../models/seguradoras/entities/status.model';
import { ServicesInfosBase } from '../../models/services-infos.model';
import { UtilsService } from '../utils.service';
import { ClienteModelForm, ClienteModel } from 'src/app/models/cliente.model';

@Injectable()
export class ApiApiceService implements ApiInterfaceService {
  private subscriptions: any = new Subscription();
  private ENV: any = environment;
  private URL;
  public servicesInfos;

  /** CONTROLLER PARA ATUALIZAR O CLIENTE NA LISTA DE CLIENTES CASO SEJA EDITADO */
  updateClienteModal: boolean = false;

  constructor(
    private http: HttpClient,
    private BS: BaseService,
    private utilsService: UtilsService,
    private sharedMemoryService: SharedMemoryService,
    private apiUsuario: ApiUsuarioService,
    private apiCliente: ApiClienteService,
    private apiCorretora: ApiCorretoraService) {
    this.URL = this.ENV.apice.url;
    this.sharedMemoryService.addSubscription(this, 'servicesInfos', (data) => { this.servicesInfos = data; });
  }

  async getSeguradoById(id: string) {
    try {
      let ret: any = await this.http.get(this.URL + 'cadastros/cliente/' + id, this.BS.getHeaders(true)).toPromise();
      if (ret) {
        ret.estadoCivil = parseInt(ret.estadoCivil);
        ret.dataNasc = ret.dataNas;
        if (ret?.enderecos?.length) {
          ret.cep = ret?.cep ? ret?.cep : ret?.enderecos[0]?.cep;
          ret.logradouro = ret?.logradouro ? ret?.logradouro : ret?.enderecos[0]?.logradouro;
          ret.numero = ret?.numero ? ret?.numero : ret?.enderecos[0]?.numero;
          ret.uf = ret?.uf ? ret?.uf : ret?.enderecos[0]?.uf;
          ret.cidade = ret?.cidade ? ret?.cidade : ret?.enderecos[0]?.cidade;
          ret.complemento = ret?.complemento ? ret?.complemento : ret?.enderecos[0]?.complemento;
          ret.bairro = ret?.bairro ? ret?.bairro : ret?.enderecos[0]?.bairro;
          ret.residLogradouro = ret?.logradouro ? ret?.logradouro : ret?.enderecos[0]?.logradouro;
          ret.residNumero = ret?.numero ? ret?.numero : ret?.enderecos[0]?.numero;
          ret.residUF = ret?.uf ? ret?.uf : ret?.enderecos[0]?.uf;
          ret.residCidade = ret?.cidade ? ret?.cidade : ret?.enderecos[0]?.cidade;
          ret.residBairro = ret?.bairro ? ret?.bairro : ret?.enderecos[0]?.bairro;
        }
      }
      return new ClienteModel().deserialize(ret);
    } catch (error) {
      throw error;
    }

  }

  async delay(ms) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  async testVar(varToTest, tentativas, timeout) {
    for (let i = 0; i < tentativas; i++) {
      if (this[varToTest])
        break;
      else
        await this.delay(timeout);
    }
    return this[varToTest];
  }

  async sendEmail() {
    //TODO: Implementar conforme interface
    console.log("Função não implementada!");
    return false;
  }

  async getServicesInfos() {
    //TODO: Implementar conforme interface
    
    debugger;
    await this.updateServicesInfos();
    return await this.testVar("servicesInfos", 20, 250);
  }

  async getCfgSeg(idIntegracao) {
    return this.http.get(this.URL + `cfg/seguradora/credencial/${idIntegracao}`, this.BS.getHeaders(true)).toPromise();
  }

  calcular(cotacao, negocio?) {
    if (cotacao.negocioId) {
      negocio = { id: cotacao.negocioId }
    } else if (cotacao.negocioUrl) {
      negocio = { id: cotacao.negocioUrl }
    }

    let body = {
      apiV2: true,
      cotacao: cotacao,
      negocio: negocio || null
    }
    return this.http.post(this.URL + 'calculo/calcularV2', body, this.BS.getHeaders(true)).toPromise();
  }

  async getUserInfos() {
    let corretora: any = await this.apiCorretora.buscaCorretora();
    let userInfos = await this.apiUsuario.getCredenciais();
    userInfos.resultadoParcOpc1 = corretora?.configsCorretora?.resultadoParcOpc1 ? corretora?.configsCorretora?.resultadoParcOpc1 : 1;
    userInfos.resultadoParcOpc2 = corretora?.configsCorretora?.resultadoParcOpc2 ? corretora?.configsCorretora?.resultadoParcOpc2 : 1;
    userInfos.fone = corretora.fone;
    userInfos.foneCorretoraPadrao = userInfos.foneCorretora;
    userInfos.emailCorretoraPadrao = userInfos.emailCorretora;
    userInfos.propostaObsPadrao = corretora?.configsCorretora?.mensagemOrcamento || '';
    if (corretora?.configsCorretora?.formaPagPadrao) {
      if (corretora.configsCorretora.formaPagPadrao == "Boleto") {
        userInfos.propostaTipoPagtoPadrao = 3;
      } else if (corretora.configsCorretora.formaPagPadrao == "Débito em conta") {
        userInfos.propostaTipoPagtoPadrao = 2;
      } else if (corretora.configsCorretora.formaPagPadrao == "Cartão de crédito") {
        userInfos.propostaTipoPagtoPadrao = 1;
      }
    }
    return userInfos;
  }

  async copiarNegocio(cotacao) {
    //TODO: Implementar conforme interface
    console.log("Função não implementada!");
    return false;
  }

  async getCoberturasAutoUser(api) {
    let coberturas = await this.http.get(this.URL + 'cfg/cobertura', this.BS.getHeaders(true)).toPromise();
    let corretora: any = await this.http.get(this.URL + 'cfg/corretora', this.BS.getHeaders(true)).toPromise();
    let pacotePadrao = corretora.configsCorretora?.pacoteCobertPadrao.toLowerCase();
    coberturas[pacotePadrao].default = true;
    return coberturas;
  }

  async autenticaPdcos() {
    try {
      let tokenPdocs = await this.apiUsuario.autenticaNoPDocs();
      if (tokenPdocs?.token) {
        let headers = new HttpHeaders();
        headers = headers.set('authorization', tokenPdocs.token);
        this.apiUsuario.setCredenciaisPDocs(tokenPdocs)
        return headers
      } else {
        throw new Error('Não autenticado');
      }
    } catch (error) {
      throw error;
    }
  }

  async getCalculos(idIntegracao, version) {
    try {
      let headers = await this.autenticaPdcos();
      const url = this.ENV.multi.url + 'calculo/cotacao/calculos/' + idIntegracao + '/' + version;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getSeguradorasUser() {
    let result: any = await this.http.get(this.URL + 'calculo/seguradorasMulti', this.BS.getHeaders(true)).toPromise();

    result.forEach(seguradora => {
      let id = seguradora.id
      delete seguradora.id
      seguradora.ID = id;
    });

    return result.filter(({ seguradora }) => seguradora === 5 || seguradora === 7 || seguradora === 12);
  }

  async getVersoes(idIntegracao) {
    try {
      let headers = await this.autenticaPdcos();
      const url = this.ENV.multi.url + 'calculo/cotacao/versoes/' + idIntegracao;
      return this.http.get(url, { headers: headers }).toPromise();

    } catch (error) {
      throw error;
    }
  }

  async getNegocio(idIntegracao) {
    try {
      let headers = await this.autenticaPdcos();
      const url = this.ENV.multi.url + 'calculo/negocio/' + idIntegracao;
      return this.http.get(url, { headers: headers }).toPromise();

    } catch (error) {
      throw error;
    }
  }

  async saveNegocio(cotacao, negocio?) {
    if (cotacao && !cotacao.hasOwnProperty('ramo')) {
      cotacao.ramo = 32;
    }

    if (cotacao.negocioId) {
      negocio = { id: cotacao.negocioId }
    } else if (cotacao.negocioUrl) {
      negocio = { id: cotacao.negocioUrl }
    }

    let body = {
      apiV2: true,
      cotacao: cotacao,
      negocio: negocio || null
    }
    return this.http.post(this.URL + 'calculo/calcularV2?salvar=true', body, this.BS.getHeaders(true)).toPromise();
  }

  //implementar função
  async updateSegurado(cpf) {
    let result = await this.apiCliente.buscaClientePorCpfCnpj(cpf, undefined, true);
    if (result?.length) {
      result[0].estadoCivil = parseInt(result[0].estadoCivil);
      result[0].dataNasc = result[0].dataNas;
      if (result[0]?.enderecos?.length) {
        result[0].cep = result[0]?.cep ? result[0]?.cep : result[0]?.enderecos[0]?.cep;
        result[0].logradouro = result[0]?.logradouro ? result[0]?.logradouro : result[0]?.enderecos[0]?.logradouro;
        result[0].numero = result[0]?.numero ? result[0]?.numero : result[0]?.enderecos[0]?.numero;
        result[0].uf = result[0]?.uf ? result[0]?.uf : result[0]?.enderecos[0]?.uf;
        result[0].cidade = result[0]?.cidade ? result[0]?.cidade : result[0]?.enderecos[0]?.cidade;
        result[0].complemento = result[0]?.complemento ? result[0]?.complemento : result[0]?.enderecos[0]?.complemento;
        result[0].bairro = result[0]?.bairro ? result[0]?.bairro : result[0]?.enderecos[0]?.bairro;
      }
    }
    return result
  }

  //implementar função
  async updateEndereco(cep) {
    try {
      let headers = await this.autenticaPdcos();
      const url = this.ENV.multi.url + `calculo/cep?cep=${cep}`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  //implementar função
  async getCalculoFipeModelo(fipe, ano, isZeroKm) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://api.multicalculo.net/calculo/fipeModelo?ano=${ano}&fipe=${fipe}&zero=${isZeroKm}`;
      return await this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getModelo(modelo, tipo, ano) {
    try {
      let headers = await this.autenticaPdcos();
      const url = this.ENV.multi.url + `calculo/ptModelo?modelo=${modelo}&tipo=${tipo}&ano=${ano}`
      return await this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  //implementar função
  async getCalculoValorMedio(fipe, ano, cep, maior25, historico) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://api.multicalculo.net/calculo/valorMedio?fipe=${fipe}&ano=${ano}&cep=${cep}&maior25=${maior25}&historico=${historico}`;
      let ret = await this.http.get(url, { headers: headers }).toPromise();
      console.log('BUSCOU CALCULOR VALOR MEDIO', ret);
      return ret
    } catch (error) {
      throw error;
    }

  }

  //implementar função
  async getCalculoDescricaoModelo(tipo, modelo, ano) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://api.multicalculo.net/calculo/fipeModelo?tipo=${tipo}&modelo=${modelo}&ano=${ano}`;
      // const url = this.ENV.multi.url + `calculo/fipeModelo?tipo=${tipo}&modelo=${modelo}&ano=${ano}`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  //implementar função
  async getCalculoPlacaModelo(placa) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://api.multicalculo.net/calculo/buscaPlaca?placa=${placa}`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  //implementar função
  async getCalculoChassiModelo(chassi) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://api.multicalculo.net/calculo/chassiDecoder?chassi=${chassi}`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getSeguradoraAnterior() {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://api.multicalculo.net/calculo/seguradorasRenovacao`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getCalculoFabricanteModelo(modelo, fabricante, tipo, ano) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://dev-api.multicalculo.net/calculo/ptModelo?tipo=${tipo}&ano=${ano}&ptFabricanteId=${fabricante}&modelo=${modelo}`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getFabricantes(fabricante) {
    try {
      let headers = await this.autenticaPdcos();
      const url = `https://dev-api.multicalculo.net/calculo/ptFabricante?descricao=${fabricante}`;
      return this.http.get(url, { headers: headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }

  getUrlBase(ramo, full?) {
    return "/cotacao/" + ramo + "/";
  }

  getAplicacaoId() {
    return 6;
  }

  public async updateServicesInfos() {

    let headers = new HttpHeaders();
    let tokenPdocs
    try {
      tokenPdocs = await this.apiUsuario.autenticaNoPDocs();
      headers = headers.set('authorization', tokenPdocs?.token);
    } catch (error) {
      throw error
    }

    if (tokenPdocs?.token) {

      // const url = this._url + 'app/seguradoraStatus';
      const url = this.ENV.production ? 'https://api.multicalculo.net/' + 'app/seguradoraStatus' : 'https://dev-api.multicalculo.net/' + 'app/seguradoraStatus';
      this.http.get(url, { headers: headers }).toPromise()
        .then((retJsonSeguradorasMulti: any) => {

          if (retJsonSeguradorasMulti.length) {

            let jsonSeguradorasMulti: any = retJsonSeguradorasMulti;

            try {

              let listaSegs: SeguradoraWakandaAtiva[] = jsonSeguradorasMulti;
              let objRet = new ServicesInfosBase();

              listaSegs.forEach(function (s: SeguradoraWakandaAtiva) {

                let segCalc = new SeguradoraCalcular(s.id, true, this.utilsService.getSegNameById(s.id), 0, 0, this.utilsService.getPathLogoById(s.id));

                this.addSegService(objRet.calculos.auto.car, s.autoStatus, segCalc);
                this.addSegService(objRet.calculos.auto.moto, s.motoStatus, segCalc);
                this.addSegService(objRet.calculos.auto.truck, s.caminhaoStatus, segCalc);
                this.addSegService(objRet.calculos.pacote.residencial, s.residStatus, segCalc);
                this.addSegService(objRet.calculos.pacote.empresarial, typeof s.empresarialStatus === "number" ? s.empresarialStatus : parseInt(s.empresarialStatus), segCalc);
                this.addSegService(objRet.buscarDocumentos.auto, s.documentosStatus, segCalc);
                this.addSegService(objRet.parcelasPendentes, s.parcelasStatus, segCalc);
                this.addSegService(objRet.acompanhamento, s.acompanhamentoStatus, segCalc);
                this.addSegService(objRet.calculos.vida, s.vidaStatus, segCalc);

              }.bind(this));

              this.servicesInfos = objRet;
              this.sharedMemoryService.set('servicesInfos', objRet);

            } catch (e) {
              console.log(e);
              this.servicesInfos = null;
              this.sharedMemoryService.set('servicesInfos', null);
            }

          } else {
            this.servicesInfos = null;
            this.sharedMemoryService.set('servicesInfos', null);
          }

        })
        .catch(e => {
          console.log(e);
          this.servicesInfos = null;
          this.sharedMemoryService.set('servicesInfos', null);
        });

    }

  }

  private getSegStatus(status: number) {
    switch (status) {
      case 0: return 'desabilitadas';
      case 1: return 'habilitadas';
      default: return false;
    }
  }

  private addSegService(arr: SeguradorasStatus, status: number, obj: SeguradoraCalcular): void {
    let segStatus = this.getSegStatus(status);
    if (segStatus)
      arr[segStatus].push(obj);
  }

  //implementar função
  async sendMessageByObservable(property, value) {
    return false;
  }

  //implementar função
  async forceRefreshHome() {
    return false;
  }

}
