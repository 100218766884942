<mat-list-item
    [ngClass]="'perfilUsuario'"
    [disableRipple]="true"
    *ngIf="platformService.deviceService.isDesktop()">
    <!-- ACESSO RAPIDO AS SEGURADORAS -->
    <div [ngClass]="'contentOptions'">     
        <div
            [ngClass]="'extensaoDiv'"
            *ngIf="(platformService.deviceService.browser == 'Chrome' || platformService.deviceService.browser == 'MS-Edge-Chromium') && platformService.deviceService.isDesktop() && platformService.virtualDeviceType == 'desktop' && !statusExtensao">
            <button
                tabindex="-1"
                mat-button
                [ngClass]="['btnExtensao', 'def-b-btn']"
                (click)="instalarExtensao()"
                [matTooltipClass]="{'default' : true}"
                matTooltip="Clique e instale a extensão para melhor proveito do sistema ">
                INSTALAR EXTENSÃO
            </button>
        </div>
        <div
            [ngClass]="'matMenuSeg'"
            *ngIf="(platformService.deviceService.browser == 'Chrome' || platformService.deviceService.browser == 'MS-Edge-Chromium') && platformService.deviceService.isDesktop() && platformService.virtualDeviceType == 'desktop' && statusExtensao">
            <button
                mat-icon-button
                (click)="openMatMenu()"
                [disabled]="disable"
                [matMenuTriggerFor]="menuSeg"
                [matTooltipClass]="{'default' : true}"
                matTooltip="Acesso rápido às seguradoras">
                <mat-icon [svgIcon]="'city'" [ngStyle]="{'color':'#FFF'}"></mat-icon>
            </button>
        </div>
    </div>

    <mat-menu
        #menuSeg="matMenu"
        [class]="'menuSeguradoras'">
        <div
            class="iconSeg"
            *ngIf="carregando">
            <mat-icon  [svgIcon]="'loading'" class="loading"></mat-icon>
        </div>
        <div
            class="seguradoras"
            *ngIf="!carregando">
            <button
                mat-icon-button
                *ngFor="let confSeg of confsSeguradoras"
                [disabled]="!confSeg.credenciaisValidas"
                (click)="acessaSeguradora(confSeg.idIntegracao)"
                class="itemSeg">
                <img
                    *ngIf="!carregando"
                    [src]="confSeg.imgIcone"
                    [matTooltipClass]="{'default' : true}"
                    [matTooltip]="confSeg.credenciaisValidas ?  confSeg.nomeSeguradora : confSeg.nomeSeguradora + ' / Credenciais Inválidas'">
            </button>
        </div>
        <div
            class="seguradorasInv"
            *ngIf="!carregando">
            <button
                mat-icon-button
                *ngFor="let confSeg of confsSeguradorasInv"
                [disabled]="!confSeg.credenciaisValidas"
                (click)="acessaSeguradora(confSeg.idIntegracao)"
                class="itemSeg">
                <img
                    *ngIf="!carregando"
                    [src]="confSeg.imgIcone"
                    [matTooltipClass]="{'toolColor' : true}"
                    [ngStyle]="{'filter': 'grayscale(80%)', 'opacity':'0.8'}"
                    [matTooltip]="confSeg.credenciaisValidas ?  confSeg.nomeSeguradora : confSeg.nomeSeguradora + ' / Credenciais Inválidas'">
            </button>
        </div>

        <div class="refreshSeguradoras">
            <button 
                mat-icon-button
                [disabled]="disable"
                (click)="buscaSeguradoras()"
                matTooltip="Atualizar credenciais">
                <mat-icon [svgIcon]="'refresh'"></mat-icon>
            </button>
        </div>
    </mat-menu>
    <!-- FIM -->
    <div
        matListAvatar
        *ngIf="platformService.deviceService.isDesktop() && platformService.virtualDeviceType == 'desktop'"
        matRipple
        [ngClass]="'avatarUsuario'"
        [matMenuTriggerFor]="menu">
        <img
            matListAvatar
            [src]="apiS3.avatarUsuarioLogado"
            onError="this.src='assets/img/usuarioAnonimo.png';"
            alt="Ícone Ápice"
            [title]="apiUsuario.getCredenciais()?.nome || ''">
        <div
            [ngClass]="'imagePerfilApp'"
            *ngIf="loadImagem && platformService.deviceService.isDesktop() && platformService.virtualDeviceType == 'desktop'">
            <span>
                <div class="lds-perfilApp">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </span>
        </div>
    </div>

    <mat-menu
        #menu="matMenu"
        [class]="'matMenuUsuario'">
        <div class="menuPerfil">
            <div
                matListAvatar
                class="menuPerfil__img"
                [ngStyle]="{'position':'relative'}">
                <img
                    matListAvatar
                    [src]="apiS3.avatarUsuarioLogado"
                    onError="this.src='assets/img/usuarioAnonimo.png'">
            </div>
            <div class="menuPerfil__info">
                <span matLine>
                    {{ apiUsuario.getCredenciais()?.nome || '' }}
                </span>
                <span matLine>
                    {{ apiUsuario.getCredenciais()?.email || '' }}
                </span>
                <span matLine>
                    {{ apiUsuario.getCredenciais() ? apiUsuario.getCredenciais().admin ? 'Administrador' : 'Colaborador' : '' }}
                </span>
            </div>
            <button mat-icon-button class="menuPerfil__about" matTooltip="Sobre" (click)="openAbout()">
                <mat-icon svgIcon="information-outline"></mat-icon>
            </button>
        </div>
        <div class="menuPerfil-btn">
            <div>
                <button
                    mat-button
                    (click)="abrirPerfil()">
                    <mat-icon [svgIcon]="'account-edit'"></mat-icon>
                    Editar Perfil
                </button>
            </div>
            <div>
                <button
                    mat-button
                    (click)="logout()">
                    <mat-icon [svgIcon]="'exit-to-app'"></mat-icon>
                    Sair
                </button>
            </div>
        </div>
        <div class="menuPerfil__painelAdmin" *ngIf="apiUsuario.getCredenciais().root">
            <button
                mat-button
                (click)="abrirPainelAdmin()">
                <mat-icon [svgIcon]="'briefcase'"></mat-icon>
                Painel Administrativo
            </button>
        </div>
        <div class="menuPerfil__painelNotificacoes" *ngIf="apiUsuario.getCredenciais().root">
            <button
                mat-button
                (click)="abrirPainelNotificacoes()">
                <mat-icon [svgIcon]="'bell'"></mat-icon>
                Painel Notificações
            </button>
        </div>
    </mat-menu>
</mat-list-item>
