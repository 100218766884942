<div class="fab-container">
    <button
        mat-fab
        (click)="onToggleFab()"
        class="fab-toggler">
        <mat-icon [svgIcon]="'plus'" [@fabToggler]="{value: fabTogglerState}"></mat-icon>
    </button>
    <div [@speedDialStagger]="buttons.length">
        <button
            *ngFor="let btn of buttons"            
            mat-mini-fab
            [title]="btn.titulo"
            [ngClass]="['fab-secondary', btn.calcular ? 'botao-calcular' : '']"
            (click)="btn.open ? [btn.open(btn.url), hideItems()] : false"
            color="secondary">
            <mat-icon [svgIcon]="btn.icone"></mat-icon>
        </button>
    </div>
</div>
