import { Component, ViewChild, Output, Input, ElementRef, EventEmitter } from '@angular/core';
import { MenusService } from 'src/app/services/menus.service';
import { PlatformService } from 'src/app/services/platform.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';
import { Router } from '@angular/router';
import { ListaNotificacoesComponent } from 'lista-notificacoes';
import { environment } from 'src/environments/environment';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';

@Component({
	selector: 'tool-bar-actions',
	templateUrl: './tool-bar-actions.component.html',
	styleUrls: ['./tool-bar-actions.component.scss']
})
export class ToolBarActionsComponent {

	@ViewChild('inputSearch') inputSearch: ElementRef;
	
	@Output('searchEmit')     search          = new EventEmitter();
	@Output('emitShowFilter') emitShowFilter  = new EventEmitter();
	@Output('onOpenSearch')   onOpenSearch    = new EventEmitter();
	@Output('onCloseSearch')  onCloseSearch   = new EventEmitter();

	@Input('placeholder') placeholder: string  = 'Nome / CPF / CNPJ / Placa';
	@Input('filtro')      filtro:      boolean = false;
	@Input('showSearch')  showSearch:  boolean = true;
	@Input('pagina')      pagina:      any     = '';

	valorInput: String = '';
	verificaAlt: boolean = false;
	searchingLoad: boolean = false;

	openSearch: boolean = false;
	showFilter: boolean = false;
	buscaAvancada: boolean = false;
	prefix: string = '';
	showPrefix: boolean = false;
	rota: any;

	constructor(
		private router: Router,
		public platformService: PlatformService,
		public apiUsuario: ApiUsuarioService,
		private icons: RegisterIconsService,
		public filtroController: FiltroControllerService,
		public menuFiltro: MenusService) {
			this.rota = location.pathname;
			this.filtroController.verificaFiltro();

			setInterval(x => {this.filtroController.verificaFiltro();this.verificaVariaveis()}, 1500)
			icons.registerIcons(['close', 'magnify', 'loading', 'filter', 'filter-outline'])

			if (this.rota == "/clientes") {
				this.prefix = "clientes";
				this.showPrefix = true;
			} else if (this.rota == "/config-ramos") {
				this.prefix = "ramos";
				this.showPrefix = true;
			} else if (this.rota == "/corretoras") {
				this.prefix = "corretoras";
				this.showPrefix = true;
			} else if (this.rota == "/cotacoes") {
				this.prefix = "cotacoes";
				this.showPrefix = true;
			} else if (this.rota == "/funil-de-vendas" || this.rota == "/lista-negocios") {
				this.prefix = "negocios";
				this.showPrefix = true;
			} else if (this.rota == "/documentos") {
				this.prefix = "documentos";
				this.showPrefix = true;

			} else if (this.rota == "/busca") {
				this.openSearch = true;
				this.showPrefix = false;
				this.prefix = "";

				if(history.state.valorInput) {
					this.valorInput = history.state.valorInput;
				}

			} else {
				this.prefix = "";
				this.showPrefix = false;
			}
		}

	timeSearch
	searching(ev) {
		if (this.showFilter) {
			this.actionFilter();
		}

		if (this.showPrefix) {
			clearTimeout(this.timeSearch);
			this.timeSearch = setTimeout(() => {
				this.verificaAlt = true;
				let value = ev.target.value.toLowerCase();
				this.search.emit(value);
			}, 800)
		} else {
			clearTimeout(this.timeSearch);
			this.timeSearch = setTimeout(() => {
				this.verificaAlt = true;
				let value = ev.target.value.toLowerCase();
				this.valorInput = value;

				if (this.rota == "/busca"){
					this.search.emit(value);
				} else {
					this.router.navigateByUrl('/busca', { state: { valorInput:  value} });
				}
				
			}, 800)
		}
	}

	ngOnInit() {
		this.buscaCredenciaisUsuario() 
	}

	notificacao1:boolean = false;
	notificacao2:boolean = false;
	notificacao3:boolean = false;
	notificacao4:boolean = false;
	notificacao5:boolean = false;
	notificacao6:boolean = false;
	notificacao7:boolean = false;
	notificacao8:boolean = false;
	verificaVariaveis() {
		if(this.filtroController.dashFiltroAtivo && this.rota === '/dashboard') {
			this.notificacao1 = true;
		} 
		if(!this.filtroController.dashFiltroAtivo && this.rota === '/dashboard') {
			this.notificacao1 = false;
		}
		if(this.filtroController.tarefasFiltroAtivo && this.rota === '/lista-tarefas') {
			this.notificacao2 = true;
		}
		if(!this.filtroController.tarefasFiltroAtivo && this.rota === '/lista-tarefas') {
			this.notificacao2 = false;
		}
		if(this.filtroController.clientesFiltroAtivo && this.rota === '/clientes') {
			this.notificacao3 = true;
		}
		if(!this.filtroController.clientesFiltroAtivo && this.rota === '/clientes') {
			this.notificacao3 = false;
		}
		if(this.filtroController.cotacoesFiltroAtivo && this.rota === '/cotacoes') {
			this.notificacao4 = true;
		}
		if(!this.filtroController.cotacoesFiltroAtivo && this.rota === '/cotacoes') {
			this.notificacao4 = false;
		}
		if(this.filtroController.listaNegociosFiltroAtivo && this.rota === '/lista-negocios') {
			this.notificacao5 = true;
		}
		if(!this.filtroController.listaNegociosFiltroAtivo && this.rota === '/lista-negocios') {
			this.notificacao5 = false;
		}
		if(this.filtroController.kanbanFiltroAtivo && this.rota === '/funil-de-vendas') {
			this.notificacao6 = true;
		}
		if(!this.filtroController.kanbanFiltroAtivo && this.rota === '/funil-de-vendas') {
			this.notificacao6 = false;
		}
		if(this.filtroController.corretorasFiltroAtivo && this.rota === '/corretoras') {
			this.notificacao7 = true;
		}
		if(!this.filtroController.corretorasFiltroAtivo && this.rota === '/corretoras') {
			this.notificacao7 = false;
		}
		if(this.filtroController.temFiltroDocumentos === true && this.rota === '/documentos') {
			this.notificacao8 = true;
		}
		if(!this.filtroController.temFiltroDocumentos && this.rota === '/documentos') {
			this.notificacao8 = false;
		}
	}


	actionFilter() {
		this.showFilter = !this.showFilter;
		if (this.showFilter) {
			this.emitShowFilter.emit(true);
		} else {
			this.emitShowFilter.emit(false);
		}

	}

	openSearchBar() {
		this.openSearch = !this.openSearch;
		if (this.openSearch && this.showPrefix) {
			this.verificaAlt = false;
			setTimeout(() => {
				this.inputSearch.nativeElement.focus();
			}, 0);
			this.onOpenSearch.emit(this.valorInput);
		}

		else if (this.openSearch && !this.showPrefix) {
			this.verificaAlt = false;
			setTimeout(() => {
				this.inputSearch.nativeElement.focus();
			}, 0);
			// console.log('busca geral teste')


		}

		else {
			if (this.showPrefix) {
				this.openSearch = !this.openSearch;
				this.showPrefix = false;
				this.valorInput = '';
				if (this.verificaAlt) {
					this.search.emit(this.valorInput);
				}
				
				// console.log('busca geral')
				this.verificaAlt = false;
				setTimeout(() => {
					this.inputSearch.nativeElement.focus();
				}, 0);

			} else {
				this.showPrefix = false;
				if (this.rota == "/clientes" || this.rota =="/cotacoes" || this.rota =="/documentos" || this.rota == "/funil-de-vendas" ||
					this.rota == "/lista-negocios" || this.rota == "/config-ramos" || this.rota == "/corretoras") {
					this.showPrefix = true;
				}
				this.valorInput = '';
				this.onCloseSearch.emit(this.valorInput);
				if (this.verificaAlt && this.rota != "/busca") {
					this.search.emit(this.valorInput);
				}

			}

		}

	}

	openFilter() {		
		// Criado um desvio para utilizar o novo filtro somente na tela de Corretoras
		
		if (location.pathname == '/corretoras') {		
			/** variavel intermediaria pra mandar o contrario sempre true e false */
			this.menuFiltro.open = !this.menuFiltro.open;
			//console.log("ToolBarActions - this.menuFiltro.setMenuFilter({open: this.menuFiltro.open, page: 'corretoras'})")
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'corretoras' })
		}
		else if (location.pathname == '/dashboard') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'dashboard' })
		}
		else if (location.pathname == '/clientes') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'clientes' })
		}
		else if (location.pathname == '/lista-tarefas') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'tarefas' })
		}
		else if (location.pathname == '/funil-de-vendas') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'negocios' })
		}
		else if (location.pathname == '/lista-negocios') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'lista-negocios' })
		}
		else if (location.pathname == '/cotacoes') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'cotacoes' })
		}
		else if (location.pathname == '/config-ramos') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'ramos' })
		}
		else if(location.pathname == '/documentos') {
			this.menuFiltro.open = !this.menuFiltro.open;
			this.menuFiltro.setMenuFilter({ open: this.menuFiltro.open, page: 'documentos' })
		}
		else {
			this.actionFilter();
		}
	}


	//funções para o funcionamento da lib de notificacoes
	private ENV: any = environment;
	listaNotificacoes: ListaNotificacoesComponent;
	@ViewChild('listaNotificacoes') set contentListaNotificacoes(content: ListaNotificacoesComponent) {
		this.listaNotificacoes = content;
	}

	credenciaisUsuario:any;
	async buscaCredenciaisUsuario() {
		try {
			let ret = await this.apiUsuario.getCredenciais();
			if(ret) {
				// console.log('->',ret)
				this.credenciaisUsuario = ret
				this.listaNotificacoes.setModo(this.ENV.production ? 'prod' : 'dev', this.credenciaisUsuario.token, '', 3);
			}
		} catch (error) {
			console.log('erro ao buscar credenciais do usuario', error)
		}
	}
	
}
