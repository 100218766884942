import { Component, OnInit } from '@angular/core';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-sobre',
	templateUrl: './sobre.component.html',
	styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

	version: any;
	constructor(private icons: RegisterIconsService) {
		icons.registerIcons(['close']);
	}

	ngOnInit(): void {
		this.version = environment.version;
	}



}
