import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorretorasFiltroComponent } from './corretoras-filtro.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { PageModules } from 'src/app/utils/pageModules';


@NgModule({
  declarations: [CorretorasFiltroComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    PageModules
  ],
  exports: [CorretorasFiltroComponent]
})
export class CorretorasFiltroModule { }
