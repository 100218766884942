<div>
    <mat-form-field>
        <input matInput autocomplete="off" type="numeric" placeholder="Número de dias desde a criação" [(ngModel)]="diasCriacao" inputmode="numeric">
    </mat-form-field>
    <mat-form-field>
        <input matInput autocomplete="off" type="numeric" placeholder="Número de dias sem uso" [(ngModel)]="diasUltSessao" inputmode="numeric">
    </mat-form-field>

    <mat-form-field>
        <mat-radio-group>
            <mat-select placeholder="Status" [(ngModel)]="statusCor">
                <mat-option [value]="''">Todas</mat-option>
                <mat-option [value]="1">Ativas</mat-option>
                <mat-option [value]="0">Inativas</mat-option>
            </mat-select>
        </mat-radio-group>
    </mat-form-field>

    <!-- <mat-form-field>
        <mat-radio-group>
            <mat-select placeholder="Plano Contratado" [(ngModel)]="plano">
                <mat-option [value]="''">Todas</mat-option>
                <mat-option [value]="'APC-BASICO'">Ápice Básico</mat-option>
                <mat-option [value]="'APC-INTERMEDIARIO'">Ápice Essencial</mat-option>
                <mat-option [value]="'APC-AVANCADO'">Ápice Total</mat-option>
            </mat-select>
        </mat-radio-group>
    </mat-form-field> -->

    <mat-form-field>
        <mat-radio-group>
            <mat-select placeholder="Situação Atual" [(ngModel)]="statusAtual">
                <mat-option [value]="''">Todas</mat-option>
                <mat-option [value]="'Testando'">Testando</mat-option>
                <mat-option [value]="'Teste encerrado'">Teste encerrado</mat-option>
                <mat-option [value]="'Contrato vigente'">Contrato vigente</mat-option>
                <mat-option [value]="'Contrato expirado'">Contrato expirado</mat-option>
                <mat-option [value]="'Allianz'">Allianz</mat-option>
                <mat-option [value]="'Allianz expirado'">Allianz expirado</mat-option>
            </mat-select>
        </mat-radio-group>
    </mat-form-field>

    <mat-form-field>
        <mat-radio-group>
            <mat-select placeholder="Corretoras Teste" [(ngModel)]="corretoraFake">
                <mat-option [value]="''">Todas</mat-option>
                <mat-option [value]="1">Corretoras Testes / Fakes</mat-option>
                <mat-option [value]="0">Corretoras / Clientes</mat-option>
            </mat-select>
        </mat-radio-group>
    </mat-form-field>


    <form [formGroup]="fg_corretoras">
        <span class="fontSmall">Obs: Filtro de datas específico para as colunas: Quant. Utilizadores, Negócios, Cotações e Clientes</span>
        <mat-form-field>
            <mat-label>Início</mat-label>
            <input autocomplete="off" [mask]="masks.DD_MM_AAAA" [dropSpecialCharacters]="false" matInput
                inputmode="numeric" formControlName="dataCriacaoIni" debounce-validation="500"
                [validate-on-keydown]="true">

            <mat-icon matSuffix [svgIcon]="'calendar'"
                (click)="openCalendar(pickerIni, fg_corretoras.controls.dataCriacaoIni, fg_corretoras.controls.dataCriacaoIniInv, $event)">
            </mat-icon>
            <mat-datepicker #pickerIni></mat-datepicker>

            <input [matDatepicker]="pickerIni" (dateChange)="onChange($event, fg_corretoras.controls.dataCriacaoIni )"
                [ngStyle]="{display: 'none'}" formControlName="dataCriacaoIniInv">

            <mat-error *ngIf="errorMessage(fg_corretoras.controls.dataCriacaoIni)">
                {{errorMessage(fg_corretoras.controls.dataCriacaoIni)}}
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Fim</mat-label>
            <input autocomplete="off" [mask]="masks.DD_MM_AAAA" [dropSpecialCharacters]="false" matInput
                inputmode="numeric" [formControl]="fg_corretoras.controls.dataCriacaoFim" debounce-validation="500"
                [validate-on-keydown]="true">

            <mat-icon matSuffix [svgIcon]="'calendar'"
                (click)="openCalendar(pickerFim, fg_corretoras.controls.dataCriacaoFim, fg_corretoras.controls.dataCriacaoFimInv, $event)">
            </mat-icon>
            <mat-datepicker #pickerFim></mat-datepicker>

            <input [matDatepicker]="pickerFim" (dateChange)="onChange($event, fg_corretoras.controls.dataCriacaoFim )"
                [ngStyle]="{display: 'none'}" [formControl]="fg_corretoras.controls.dataCriacaoFimInv">

            <mat-error *ngIf="errorMessage(fg_corretoras.controls.dataCriacaoFim)">
                {{errorMessage(fg_corretoras.controls.dataCriacaoFim)}}
            </mat-error>
        </mat-form-field>

        <!-- <mat-checkbox [(ngModel)]="checkSelect" [formControl]="fg_corretoras.controls.contratante">
            <span>Assinantes</span></mat-checkbox> -->

    </form>

    <div class="actionButtonsFiltros">
        <button mat-button class="def-b-btn" (click)="buildString()">FILTRAR</button>
        <button mat-button class="def-w-btn" [ngStyle]="{'width':'150px'}" (click)="clearFilter()">LIMPAR
            FILTROS</button>
    </div>
</div>