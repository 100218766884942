import { SeguradoraCalcular } from '../../seguradora/entities/calcular.model';

export class SeguradorasStatus {
    habilitadas: Array<SeguradoraCalcular>
    desabilitadas: Array<SeguradoraCalcular>

    constructor(){
        this.habilitadas = [];
        this.desabilitadas = [];
    }
}