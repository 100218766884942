import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as c3 from 'c3';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-estatisticas-veiculo',
  templateUrl: './estatisticas-veiculo.component.html',
  styleUrls: ['./estatisticas-veiculo.component.scss']
})

export class EstatisticasVeiculoComponent {

  @Input() fipeModelo;
  @Input() valorMedio;
  @Input() automovel: any = {};

  public chart;
  public modelo;
  public arrayPrecoMedio: any = [];
  public valorMedioCopy: any = [];
  public arrayFipe: any = [];
  public fipeValores: any = [];
  public fipeValoresCopy: any = [];
  public datasHistorico: any = [];
  public arrayTaxa: any = [];
  public precoMedio: any = {};
  public precoMedioMes: any;
  public precoMedioUltimoMes: any;
  public fipeValAtual: any;
  public abrangencia: any = { basica: {}, completa: {} };
  public selAbrangencia: any = 70;
  public loadingResults = true;
  public dataMediaSeg: any;

  constructor(private activeModal: NgbActiveModal, private utilsService: UtilsService) { }

  public async updateValues() {

    this.fipeAtual(); //Retorna apenas o valor da fipe do mês atual, formatado (R$)

    this.ultimoPrecoMedio(); //Retorna o preço médio do ultimo mês(R$)

    try {

      if(this.fipeModelo?.length) {
        this.modelo = this.fipeModelo[0].modelo;
        this.fipeValores = this.fipeModelo[0].fipeValores;
      }

      if(this.valorMedio?.length) {
        let exc = null;
        let indice = this.valorMedio.indexOf(exc);

        while (indice >= 0) {
          this.valorMedio.splice(indice, 1);
          indice = this.valorMedio.indexOf(exc);
        }
      } else {
        this.valorMedio = [];
      }
      
      this.valorMedioCopy = JSON.parse(JSON.stringify(this.valorMedio)); //faço uma cópia pois preciso inverter ele

      this.valorMedioCopy.reverse();

      this.criaArrayPrecoMedio();

      this.drawCharts();
      return true;

    } catch (e) {

      console.log(e);

      this.activeModal.close(false);

      await this.utilsService.createAlert("Atenção", "Houve um problema ao buscar as estatisticas deste veículo. Tente novamente mais tarde. Obrigado");


      return false;
    }



  }

  public drawCharts() {
    c3.generate({
      bindto: '#chartTaxa',
      size: {
        height: 200,
        width: 440
      },
      data: {
        columns: [
          this.arrayTaxa
        ],
        axes: {
          y: 'y',
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      axis: {
        x: {
          type: 'category',
          tick: {}
        },
        y: {
          inner: true,
          tick: {
            count: 6,
            format: function (d) {
              return (this.utilsService.numberFormat(d))
            }.bind(this)
          },
          label:
          {
            text: 'Preço médio(R$)',
            position: 'outer-top',
          }
        }
      }
    });

    c3.generate({
      bindto: '#chartFipe',
      size: {
        height: 200,
        width: 440
      },
      data: {
        x: 'x',
        columns: [
          this.datasHistorico,
          this.arrayPrecoMedio,
          this.fipeValoresCopy
        ],
        axes: {
          y: 'y',
          FIPE: 'y2'
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      axis: {
        x: {
          type: 'category',
          tick: {}
        },
        y: {
          inner: true,
          tick: {
            count: 6,
            format: function (d) {
              return (this.utilsService.numberFormat(d))
            }.bind(this)
          },
          label:
          {
            text: 'Preço médio(R$)',
            position: 'outer-top',
          }
        },
        y2: {
          inner: true,
          tick: {
            count: 6,
            format: function (d) {
              return (this.utilsService.numberFormat(d))
            }.bind(this)
          },
          show: true,
          label: {
            text: 'Fipe(R$)',
            position: 'outer-top'
          }
        }
      }
    });

    this.loadingResults = false;

  }

  public calculoAbrangencia(objPrecomedio) {

      this.abrangencia.basica.min = this.utilsService.numberFormat(objPrecomedio.media - objPrecomedio.desvioPadrao, true);
      this.abrangencia.basica.max = this.utilsService.numberFormat(objPrecomedio.media + objPrecomedio.desvioPadrao, true);
      this.abrangencia.completa.min = this.utilsService.numberFormat(objPrecomedio.media - (objPrecomedio.desvioPadrao * 2), true);
      this.abrangencia.completa.max = this.utilsService.numberFormat(objPrecomedio.media + (objPrecomedio.desvioPadrao * 2), true);

  }

  public ultimoPrecoMedio() {

    let anoInicial = 0;
    let mesInicial = 0;

    if (this.valorMedio)
      this.valorMedio.forEach(function (val) {
        if (val != undefined) {
          if (val.ano > anoInicial) {
            anoInicial = val.ano;
            mesInicial = val.mes;
            this.precoMedioUltimoMes = val;

          } else if (val.ano == anoInicial) {
            if (val.mes > mesInicial) {
              mesInicial = val.mes;
              this.precoMedioUltimoMes = val;
            }
          }
        }
      }.bind(this));

    this.dataMediaSeg = this.dataMesAno(mesInicial, anoInicial);
    
    if(this.precoMedioUltimoMes) {
      this.calculoAbrangencia(this.precoMedioUltimoMes);
      this.precoMedioMes = this.utilsService.numberFormat(this.precoMedioUltimoMes.media, true);
    }
  }


  public precoMedioAtual() {

    let data = new Date();
    let mes = data.getMonth();
    let ano = data.getFullYear();
    mes++;

    this.precoMedio = this.valorMedio.filter(function (p) {
      return (p !== undefined) ? p.mes == mes && p.ano == ano : false;
    });

    this.precoMedioMes = this.precoMedio[0];

    this.calculoAbrangencia(this.precoMedioMes);

    this.precoMedioMes = this.utilsService.numberFormat(this.precoMedioMes.media, true);

  }


  public fipeAtual() {

    let fipeValAtual = this.utilsService.getFipeAtualValores(this.fipeModelo);
    this.fipeValAtual = this.utilsService.numberFormat(fipeValAtual, true);

  }


  public calculaTaxa(precoMedio, valFipe) {

    let taxa = (precoMedio / valFipe) * 100;

    return taxa;
  }

  public dataMesAno(mes, ano) {

    switch (mes) {
      case 1:
        mes = "Jan"
        break;
      case 2:
        mes = "Fev"
        break;
      case 3:
        mes = "Mar"
        break;
      case 4:
        mes = "Abr"
        break;
      case 5:
        mes = "Mai"
        break;
      case 6:
        mes = "Jun"
        break;
      case 7:
        mes = "Jul"
        break;
      case 8:
        mes = "Ago"
        break;
      case 9:
        mes = "Set"
        break;
      case 10:
        mes = "Out"
        break;
      case 11:
        mes = "Nov"
        break;
      case 12:
        mes = "Dez"
        break;
    }

    return (mes + " " + ano);

  }

  public buscaUltimasFipes(qty) {

    qty *= -1; //multiplico por -1 para depois cortar os ultimos do array
    let ulltimosFipe = this.fipeValores.slice(qty);

    return ulltimosFipe;

  }

  public criaDatasFipe(ultimasFipe) {

    ultimasFipe.forEach(function (val) {
      this.datasHistorico.push(this.dataMesAno(val.mesVersaoTabela, val.anoVersaoTabela));
    }.bind(this));

  }

  public criaarrayFipe(ultimasFipe) {

    ultimasFipe.forEach(function (val) {
      this.fipeValoresCopy.push(val.valor);
    }.bind(this));

  }

  public ordenaData(a, b) {
    var timestamp1 = new Date(a.anoVersaoTabela, a.mesVersaoTabela).getTime();
    var timestamp2 = new Date(b.anoVersaoTabela, b.mesVersaoTabela).getTime();

    return timestamp1 - timestamp2;
  }

  public criaArrayPrecoMedio() {

    //Estes são os arrays que uso para montar o gráfico
    this.arrayPrecoMedio.push("Preço Médio");
    this.fipeValoresCopy.push("FIPE");
    this.datasHistorico.push("x");
    this.arrayTaxa.push("Preço Médio(R$)");

    var filtered;

    this.fipeValores.sort(this.ordenaData);

    this.criaarrayFipe(this.buscaUltimasFipes(6)); //é passado como parametro o numero de fipes que se deseja buscar

    this.criaDatasFipe(this.buscaUltimasFipes(6));

    this.valorMedioCopy.forEach(function (val) {
      this.arrayPrecoMedio.push(val.media);
      this.datasHistorico.push(this.dataMesAno(val.mes, val.ano));

      filtered = this.fipeValores.filter(function (f) {  //busco o valor da fipe do mes que está no preço médio
        return f.anoVersaoTabela == val.ano && f.mesVersaoTabela == val.mes
      });

      if(filtered.length)
        this.arrayTaxa.push(this.calculaTaxa(val.media, filtered[0].valor));

      //console.log(this.arrayTaxa)

    }.bind(this));

  }

}

