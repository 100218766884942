import { RamosCodes } from '../models/ramosCodes.model';
import { SeguradorasBase } from '../models/seguradoras/factory/base.model';
import { CalcsGrid } from '../models/calculos/calcs-grid';
import { ViewValue } from '../models/view-value.model';
import { AplicacaoIdModel } from '../models/aplicacaoId.model';
import { SeguradoraPathProps } from '../models/seguradora-logo-path.model';

export class IntegracaoConstants {


  public static get motivos() { // Puxa a descricao da seguradora pelo nome.
    return {
      1: 'Cálculo com informações divergentes',
      2: 'Redirecionamento pela extensão',
      3: 'Outros',
      4: 'Sugestões'
    };
  }

  public static get cobsByRamoId(): any {
    const cobs = {};

    cobs[this.ramos.AUTO] = {
      cobExclude: [ // Adicionar as coberturas que não devem ser exibidas
        'modeloSelecionado',
        'tipoFranquia',
        'casco',
        'cascoTxt',
        'percComissao',
        'percDesconto',
        'responsabilidadeCivil',
        'despesasTratamento',
        'isAppMorte',
        'isAppInvalidez',
        'invalidezPermanente',
        "danosPropriosFurtoRoubo",
        "danosPropriosChoqueColisaoCapotamento",
        "danosPropriosRiscoNatureza",
        "danosPropriosGrevesTumultos",
        "danosPropriosAtosTerrorismo"
        // 'isDanosMateriais',
        // 'isDanosMorais',
        // 'isDanosCorporais',
      ],
      cobPriority: { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        responsabilidadeCivilTxtRS: 0,
        despesasTratamentoTxtRS: 1,
        carroReservaTxtLim: 2,
        vidrosTxtLim: 3,
        isAppMorteTxtRS: 4,
        isAppInvalidezTxtRS: 5,
        danosPropriosFurtoRouboTxtRS: 6,
        danosPropriosChoqueColisaoCapotamentoTxtRS: 7,
        danosPropriosRiscoNaturezaTxtRS: 8,
        danosPropriosGrevesTumultosTxtRS: 9,
        danosPropriosAtosTerrorismoTxtRS: 10
        // tipoTxtLim: 0,
        // isDanosMateriaisTxtRS: 1,
        // isDanosCorporaisTxtRS: 2,
        // assist24hsTxtLim: 3,
        // isDanosMoraisTxtRS: 4,
      }
    };
    cobs[this.ramos.VIDA] = {
      cobExclude: [ // Adicionar as coberturas que não devem ser exibidas
        'morteTxt',
        'invalidezPermanenteTxt',
        'doencasGravesTxt',
        'funeralTxt'
      ],
      cobPriority: { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        morteTxtRS: 1,
        morteAcidentalTxtRS: 2,
        invalidezPermanenteTxtRS: 3,
        doencasGravesTxtRS: 4,
        funeralTxtCob: 5
      }
    };
    cobs[this.ramos.RESIDENCIAL] = {
      cobExclude: [ // Adicionar as coberturas que não devem ser exibidas
        'isDanosIncendioRaioExplosao',
        'isDanosIncendioRaioExplosaoTxt',
        'isDanosRoubo',
        'isDanosRouboTxt',
        'isDanosAlagamento',
        'isDanosAlagamentoTxt',
        'isDanosEletricos',
        'isDanosEletricosTxt',
        'isDanosAluguel',
        'isDanosAluguelTxt',
        'isDanosDesmoronamento',
        'isDanosDesmoronamentoTxt',
        'isDanosImpactoVeiculo',
        'isDanosImpactoVeiculoTxt',
        'isDanosVidros',
        'isDanosVidrosTxt',
        'assist24hsTxt'
      ],
      cobPriority: { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        isDanosIncendioRaioExplosaoTxtRS: 1,
        isDanosRouboTxtRS: 2,
        isDanosAlagamentoTxtRS: 3,
        isDanosEletricosTxtRS: 4,
        isDanosAluguelTxtRS: 5,
        isDanosDesmoronamentoTxtRS: 6,
        isDanosImpactoVeiculoTxtRS: 7,
        isDanosVidrosTxtRS: 8,
        assist24hsTxtMod: 9,
      }
    };
    cobs[this.ramos.EMPRESARIAL] = {
      cobExclude: [ // Adicionar as coberturas que não devem ser exibidas
        'isDanosIncendioRaioExplosao',
        'isDanosIncendioRaioExplosaoTxt',
        'isDanosRoubo',
        'isDanosRouboTxt',
        'isDanosEletricos',
        'isDanosEletricosTxt',
        'isDanosAluguel',
        'isDanosAluguelTxt',
        'isDanosImpactoVeiculo',
        'isDanosImpactoVeiculoTxt',
        'isDanosVidros',
        'isDanosVidrosTxt',
        'assist24hsTxt',
      ],
      cobPriority: { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        isDanosIncendioRaioExplosaoTxtRS: 1,
        isDanosRouboTxtRS: 2,
        isDanosEletricosTxtRS: 3,
        isDanosAluguelTxtRS: 4,
        isDanosImpactoVeiculoTxtRS: 5,
        isDanosVidrosTxtRS: 6,
        assist24hsTxtMod: 7,
        isDanosEquipamentosEletronicos: 8,
        isDanosVazamentoTanquesTubulacoes: 9
      }
    };
    cobs[this.ramos.CONDOMINIO] = {
      cobExclude: [ // Adicionar as coberturas que não devem ser exibidas
        'isDanosIncendioRaioExplosao',
        'isDanosIncendioRaioExplosaoTxt',
        'isDanosRoubo',
        'isDanosRouboTxt',
        'isDanosAlagamento',
        'isDanosAlagamentoTxt',
        'isDanosEletricos',
        'isDanosEletricosTxt',
        'isDanosAluguel',
        'isDanosAluguelTxt',
        'isDanosDesmoronamento',
        'isDanosDesmoronamentoTxt',
        'isDanosImpactoVeiculo',
        'isDanosImpactoVeiculoTxt',
        'isDanosVidros',
        'isDanosVidrosTxt',
        'assist24hsTxt'
      ],
      cobPriority: { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        isDanosIncendioRaioExplosaoTxtRS: 1,
        isDanosRouboTxtRS: 2,
        isDanosEletricosTxtRS: 3,
        isDanosAluguelTxtRS: 4,
        isDanosDesmoronamentoTxtRS: 5,
        isDanosImpactoVeiculoTxtRS: 6,
        assist24hsTxtMod: 7,
      }
    };
    return cobs;

  }

  public static get tipoPagamento(): any {
    return {
      1: 'credito',
      2: 'debito',
      3: 'boleto',
    };
  }

  public static get tipoPagamentoTxt(): any {

    return {
      1: 'Cartão de Crédito',
      2: 'Débito',
      3: 'Boleto',
    };

  }

  public static get coberturas(): any {

    return {
      tipoTxtLim: 'Tipo',
      // tipoFranquia: 'Tipo Franquia',
      // isDanosMateriais: 'Danos Materiais',
      // isDanosMateriaisTxt: 'Danos Materiais',
      // isDanosMateriaisTxtRS: 'Danos Materiais',
      // isDanosCorporais: 'Danos Corporais',
      // isDanosCorporaisTxt: 'Danos Corporais',
      // isDanosCorporaisTxtRS: 'Danos Corporais',
      // isDanosMorais: 'Danos Morais',
      // isDanosMoraisTxt: 'Danos Morais',
      // isDanosMoraisTxtRS: 'Danos Morais',
      responsabilidadeCivil: "Resp. Civil",
      responsabilidadeCivilTxt: "Resp. Civil",
      responsabilidadeCivilTxtRS: "Resp. Civil",
      despesasTratamento: "Desp. Tratamento",
      despesasTratamentoTxt: "Desp. Tratamento",
      despesasTratamentoTxtRS: "Desp. Tratamento",
      danosPropriosFurtoRoubo: "Furto ou Roubo",
      danosPropriosFurtoRouboTxt: "Furto ou Roubo",
      danosPropriosFurtoRouboTxtRS: "Furto ou Roubo",
      danosPropriosChoqueColisaoCapotamento: "Choque, Colisão e Capotamento",
      danosPropriosChoqueColisaoCapotamentoTxt: "Choque, Colisão e Capotamento",
      danosPropriosChoqueColisaoCapotamentoTxtRS: "Choque, Colisão e Capotamento",
      danosPropriosRiscoNatureza: "Riscos Catastróficos da Natureza",
      danosPropriosRiscoNaturezaTxt: "Riscos Catastróficos da Natureza",
      danosPropriosRiscoNaturezaTxtRS: "Riscos Catastróficos da Natureza",
      danosPropriosGrevesTumultos: "Greves, Tumultos, Alterações Ordem Pública",
      danosPropriosGrevesTumultosTxt: "Greves, Tumultos, Alterações Ordem Pública",
      danosPropriosGrevesTumultosTxtRS: "Greves, Tumultos, Alterações Ordem Pública",
      danosPropriosAtosTerrorismo: "Atos de Terrorismo, Vandalismo e Sabotagem",
      danosPropriosAtosTerrorismoTxt: "Atos de Terrorismo, Vandalismo e Sabotagem",
      danosPropriosAtosTerrorismoTxtRS: "Atos de Terrorismo, Vandalismo e Sabotagem",
      isAppMorte: 'Morte',
      isAppMorteTxt: 'Morte',
      isAppMorteTxtRS: 'Morte',
      isAppInvalidez: 'Invalidez',
      isAppInvalidezTxt: 'Invalidez',
      isAppInvalidezTxtRS: 'Invalidez',
      carroReserva: 'Veíc. de Substituição',
      carroReservaTxtLim: 'Veíc. de Substituição',
      assist24hs: '24 Horas',
      assist24hsTxtLim: '24 Horas',
      vidrosTxtLim: 'Vidros',
      invalidezPermanenteTxt: 'Invalidez',
      invalidezPermanenteTxtRS: 'Invalidez',
      morteTxt: 'Morte',
      morteTxtRS: 'Morte',
      morteAcidentalTxt: 'Morte Acidental',
      morteAcidentalTxtRS: 'Morte Acidental',
      doencasGravesTxt: 'Doenças graves',
      doencasGravesTxtRS: 'Doenças graves',
      funeralTxt: 'Funeral',
      funeralTxtCob: 'Funeral',
      isDanosAlagamento: 'Alagamento',
      isDanosAlagamentoTxt: 'Alagamento',
      isDanosAlagamentoTxtRS: 'Alagamento',
      isDanosIncendioRaioExplosao: 'Incêndio',
      isDanosIncendioRaioExplosaoTxt: 'Incêndio',
      isDanosIncendioRaioExplosaoTxtRS: 'Incêndio',
      isDanosDesmoronamentoTxt: 'Desmoronamento',
      isDanosDesmoronamentoTxtRS: 'Desmoronamento',
      isDanosVazamentoTanquesTubulacoesTxt: "Vazamento Tanques/Tubulações",
      isDanosVazamentoTanquesTubulacoesTxtRS: "Vazamento Tanques/Tubulações",
      isDanosAluguelTxt: 'Danos aluguel',
      isDanosAluguelTxtRS: 'Danos aluguel',
      isDanosEletricosTxt: 'Danos elétricos',
      isDanosEletricosTxtRS: 'Danos elétricos',
      isDanosImpactoVeiculoTxt: 'Impac. Veículo',
      isDanosImpactoVeiculoTxtRS: 'Impac. Veículo',
      isDanosRouboTxt: 'Roubo',
      isDanosRouboTxtRS: 'Roubo',
      isDanosVidrosTxt: 'Vidros',
      isDanosVidrosTxtRS: 'Vidros',
      assist24hsTxt: 'Assist. 24h ',
      assist24hsTxtMod: 'Assist. 24h ',
      isDanosEquipamentosEletronicos: 'Equip. Eletrônicos',
      isDanosVazamentoTanquesTubulacoes: 'Vazamento Tanques'
    };

  }

  public static get formsTitle() {
    return {
      v: 'Automóvel',
      c: 'Caminhão',
      m: 'Motocicleta'
    };
  }

  public static get calcsGrid(): CalcsGrid {

    return {
      main: {
        id: 1,
        description: {
          desktop: 'PRODUTOS PRINCIPAIS',
          mobile: 'PRINCIPAIS'
        }
      },
      alternatives: {
        id: 2,
        description: {
          desktop: 'PRODUTOS ALTERNATIVOS',
          mobile: 'ALTERNATIVOS'
        }
      },
      all: {
        id: 3,
        description: {
          desktop: 'TODOS',
          mobile: 'TODOS'
        }
      }
    };

  }

  public static get seguradoras(): SeguradorasBase {
    return {
      BRADESCO: {
        id: 1,
        name: 'Bradesco',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'bradescoCodCorretor',
          'bradescoSucursal',
          'bradescoCodUsuario',
          'bradescoInspetoria'
        ]
      },
      LIBERTY: {
        id: 2,
        name: 'Liberty WS',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      MAPFRE: {
        id: 3,
        name: 'Mapfre',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'mapfreCodVc',
          'mapfreCodInt',
          'mapfreSelCompanhia',
          'mapfreTpUsuario',
          'mapfreGrupoAfinidade'
        ]
      },
      HDI: {
        id: 4,
        name: 'HDI',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'hdiCodigoCorretor',
          'hdiCodigoProdutor',
          'hdiSucursal',
          'hdiEmpresa',
        ]
      },
      ALLIANZ: {
        id: 5,
        name: 'Allianz',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'allianzSucursalSelecionada'
        ]
      },
      SOMPO: {
        id: 6,
        name: 'Sompo',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'sompoCodigoCorretor'
        ]
      },
      ZURICH: {
        id: 7,
        name: 'Zurich',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      PORTO_SEGURO: {
        id: 8,
        name: 'Porto Seguro',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'portoSusep'
        ]
      },
      ITAU: {
        id: 9,
        name: 'Itaú',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      AZUL: {
        id: 10,
        name: 'Azul',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      TOKIO: {
        id: 11,
        name: 'Tokio',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'tokioCodigo'
        ]
      },
      LIBERTY_SITE: {
        id: 12,
        name: 'Liberty',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'libertyCodigoCorretor',
          'libertyCodigoFilial',
          'libertyCpfCnpj'
        ]
      },
      MITSUI: {
        id: 13,
        name: 'Mitsui',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'mitsuiCodigoCorretor',
          'mitsuiFilialNome'
        ]
      },
      GENERALI: {
        id: 14,
        name: 'Generali',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'generaliCodigo',
          'generaliSucursalSelecionada'
        ]
      },
      ITAU_PORTAL: {
        id: 15,
        name: 'Itaú',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SOMPO_WS: {
        id: 16,
        name: 'Sompo WS',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SULAMERICA: {
        id: 17,
        name: 'Sul América',
        isCalcAutoAlternative: false,
        particularAttributes: [
          'sulAmericaEstrVenda',
          'sulAmericaEstrApoio',
          'sulAmericaAcaoApoio',
          'sulAmericaSucursal',
          'sulAmericaConvenio'
        ]
      },
      ALFA: {
        id: 18,
        name: 'Alfa',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SANCOR: {
        id: 19,
        name: 'Sancor',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SUHAI: {
        id: 20,
        name: 'Suhai',
        isCalcAutoAlternative: true,
        particularAttributes: []
      },
      TOKIO_AUTO_POPULAR: {
        id: 21,
        name: 'Tokio Auto Popular',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      ALIRO: {
        id: 22,
        name: 'Aliro',
        isCalcAutoAlternative: true,
        particularAttributes: [
          'libertyCodigoCorretor',
          'libertyCodigoFilial',
          'libertyCpfCnpj'
        ]
      },
      ALLIANZ_PACOTE_ECONOMICO: {
        id: 23,
        name: 'Allianz Pacote Econômico',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SURA: {
        id: 24,
        name: 'Sura',
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SEG_TESTE: {
        id: 901,
        name: 'Seguradora de teste',
        isCalcAutoAlternative: false,
        particularAttributes: []
      }
    };
  }

  public static get stepsId(): any {
    return {
      resultados: 0,
      formulario: 1,
      simuladorComissoes: 2,
      enviarCalculo: 3,
      coberturas: 4
    };
  }

  public static get sexoTxt(): any {

    return {
      M: 'Masculino',
      F: 'Feminino'
    };

  }

  public static get tempoHabilitacaoTxt(): any {

    return {
      0: 'Recem habilitado',
      1: 'Até 1 ano',
      2: 'Até 2 anos',
      3: 'Até 3 anos',
      4: 'Até 4 anos',
      5: 'Até 5 anos',
      6: 'Até 6 anos',
      7: 'Até 7 anos',
      8: 'Até 8 anos',
      9: 'Até 9 anos',
      10: 'Até 10 anos',
      11: 'Mais de 10 anos',

    };

  }

  public static get profissoesTxt(): any { // UTILIZADO NO VIDA

    return {
      0: 'ADVOGADO (DIREITO PENAL / CRIMINALISTA)',
      1: 'ADVOGADO (EXCETO ADVOGADO DO DIREITO PENAL / CRIMINALISTA)',
      2: 'ALFAIATE',
      3: 'ANTROPÓLOGO',
      4: 'APOSENTADO POR INVALIDEZ (EXCETO FUNCIONÁRIO PÚBLICO)',
      5: 'APOSENTADO POR TEMPO DE SERVICO (EXCETO FUNCIONÁRIO PÚBLICO)',
      6: 'APOSENTADO POR INVALIDEZ (FUNCIONARIO PUBLICO)',
      7: 'APOSENTADO POR TEMPO DE SERVICO (FUNCIONARIO PUBLICO)',
      8: 'ARQUEÓLOGO',
      9: 'ARQUITETO',
      10: 'ASTRÓLOGO',
      11: 'ASTRÔNOMO',
      12: 'ATENDENTE OU RECEPCIONISTA',
      13: 'ATLETA PROFISSIONAL (OUTRAS MODALIDADES)',
      14: 'ATLETA PROFISSIONAL DE FUTEBOL',
      15: 'ATLETA PROFISSIONAL DE GOLFE',
      16: 'ATLETA PROFISSIONAL DE HOQUEI',
      17: 'ATLETA PROFISSIONAL DE LUTA',
      18: 'ATLETA PROFISSIONAL DE RUGBI',
      19: 'ATLETA PROFISSIONAL DE TENIS',
      20: 'ATLETA PROFISSIONAL DE VOLEI',
      21: 'ATOR',
      22: 'AUXILIAR ADMINISTRATIVO',
      23: 'AUXILIAR OU TÉCNICO DE ENFERMAGEM',
      24: 'BARBEIRO',
      25: 'BARMAN',
      26: 'BIBLIOTECÁRIO',
      27: 'BIOMÉDICO',
      28: 'BIÓLOGO',
      29: 'BOMBEIRO MILITAR (SOLDADO)',
      30: 'BOMBEIRO MILITAR (CABO)',
      31: 'BOMBEIRO MILITAR (SARGENTO)',
      32: 'BOMBEIRO MILITAR (SUBTENENTE)',
      33: 'BOMBEIRO MILITAR (TENENTE)',
      34: 'BOMBEIRO MILITAR (CAPITAO)',
      35: 'BOMBEIRO MILITAR (MAJOR)',
      36: 'BOMBEIRO MILITAR (TENENTE-CORONEL)',
      37: 'BOMBEIRO MILITAR (CORONEL)',
      38: 'CABELEIREIRO',
      39: 'CAMAREIRO',
      40: 'CARTEIRO',
      41: 'CHAVEIRO',
      42: 'CHEFE DE COZINHA',
      43: 'COMERCIANTE',
      44: 'CONTADOR, TÁCNICO OU ANALISTA EM CONTABILIDADE',
      45: 'COREÓGRAFO',
      46: 'CORRETOR DE IMÓVEIS',
      47: 'CORRETOR DE SEGUROS',
      48: 'COSTUREIRA OU ALFAIATE',
      49: 'COZINHEIRO OU AUXILIAR DE COZINHA',
      50: 'DANÇARINO OU BAILARINO',
      51: 'DECORADOR',
      52: 'DENTISTA',
      53: 'DEPUTADO',
      54: 'DESENHISTA',
      55: 'DIGITADOR',
      56: 'ECONOMISTA',
      57: 'ELETRICISTA DE INSTALAÇÕES E MANUTENÇÃO',
      58: 'EMPREGADO DOMÉSTICO',
      59: 'EMPRESÁRIO / DIRETOR GERAL DE EMPRESA',
      60: 'ENCANADOR',
      61: 'ENFERMEIRO PSIQUIATRICO ',
      62: 'ENFERMEIRO - EXCETO ENFERMEIRO PSIQUIATRICO ',
      63: 'ENGENHEIRO CIVIL E AFINS. ',
      64: 'ENGENHEIRO DE MINAS ',
      65: 'ENGENHEIRO DE VOO ',
      66: 'ENGENHEIRO ELETRICISTA ELETRONICO E AFINS ',
      67: 'ENGENHEIRO EM COMPUTACAO ',
      68: 'ENGENHEIRO MECANICO E AFINS - EXCETO ENGENHEIRO MECANICO NUCLEAR ',
      69: 'ENGENHEIRO MECANICO NUCLEAR ',
      70: 'ENGENHEIRO MECATRONICO ',
      71: 'ENGENHEIRO QUIMICO ',
      72: 'ESCRITOR',
      73: 'ESTAGIÁRIO, BOLSISTA OU TRAINEE',
      74: 'ESTUDANTE',
      75: 'FARMACÊUTICO',
      76: 'FOTÓGRAFO',
      77: 'FRENTISTA',
      78: 'GARÇOM',
      79: 'GERENTE ADMINISTRATIVO',
      80: 'GEÓLOGO',
      81: 'GUARDA-CIVIL MUNICIPAL',
      82: 'INSTALADOR DE PRODUTOS OU ACESSÓRIOS',
      83: 'INSTRUTOR DE AUTOESCOLA',
      84: 'JARDINEIRO',
      85: 'JOALHEIRO',
      86: 'JORNALISTA',
      87: 'JUIZ DE DIREITO',
      88: 'LAVADEIRO, EM GERAL',
      89: 'MANICURE',
      90: 'MAQUIADOR',
      91: 'MATEMÁTICO',
      92: 'MECÂNICO DE VEÍCULOS',
      93: 'MODELO',
      94: 'MOTOBOY',
      95: 'MOTORISTA DE CAMINHÃO / TRANSPORTADOR',
      96: 'MOTORISTA DE CARRO, FURGÃO OU VEÍCULO SIMILAR',
      97: 'MOTORISTA DE ONIBUS URBANOS, METROPOLITANOS E RODOVIÁRIOS',
      98: 'MÉDICO',
      99: 'MILITAR EM GERAL',
      100: 'PADEIRO',
      101: 'PARAMÉDICO',
      102: 'PEDREIRO',
      103: 'PILOTO DE AVIÃO',
      104: 'POLICIA CIVIL - AGENTE DE SEGURANÇA PENITENCIÁRIA',
      105: 'POLICIAL CIVIL, EXCETO AGENTE PENITENCIÁRIO',
      106: 'POLICIAL FEDERAL, FERROVIÁRIO E RODOVIÁRIO',
      107: 'POLICIAL MILITAR',
      108: 'PORTEIRO',
      109: 'PRODUTOR AGRÍCOLA',
      110: 'PROFESSOR',
      111: 'PROGRAMADOR',
      112: 'PSICÓLOGO',
      113: 'PUBLICITÁRIO',
      114: 'QUÍMICO',
      115: 'REPÓRTER',
      116: 'TATUADOR',
      117: 'TELEFONISTA',
      118: 'VENDEDOR / VENDEDOR AMBULANTE',
      119: 'VEREADOR',
      120: 'VETERINÁRIO',
      121: 'VIGILANTE OU SEGURANÇA'
      // 122: 'ANALISTA DE AUTOMAÇÃO',
    };

  }

  public static get profissoesAutoTxt(): any {

    return {
      21: 'Administrador',
      1: 'Advogado',
      38: 'Agricultor',
      22: 'Analista de Sistemas',
      2: 'Aposentado',
      3: 'Arquiteto',
      23: 'Atuario',
      4: 'Bombeiro',
      24: 'Caminhoneiro',
      39: 'Comerciante',
      25: 'Corretor de Imóveis',
      26: 'Corretor de Seguros',
      27: 'Dentista',
      28: 'Desempregado',
      5: 'Detetive',
      29: 'Diretor de Empresa',
      43: 'Do Lar',
      37: 'Enfermeiro(a)',
      6: 'Engenheiro',
      7: 'Entregador',
      8: 'Estudante',
      33: 'Fiscal',
      42: 'Funcionario Publico',
      34: 'Gerente',
      10: 'Membro das forças armadas',
      35: 'Metalúrgico',
      9: 'Médico',
      40: 'Nutricionista',
      20: 'Não trabalha',
      19: 'Outras Atividades',
      41: 'Pecuarista',
      30: 'Pensionista',
      11: 'Personal Trainer',
      12: 'Policial',
      32: 'Produtor Rural',
      13: 'Professor',
      14: 'Promotor Vendas',
      15: 'Proprietário de Empresas/Negócios',
      16: 'Químico',
      17: 'Representante Comercial',
      18: 'Servidor Público',
      31: 'Taxista',
      36: 'Técnico em Eletrônica'
    };

  }

  public static get estadoCivilTxt(): any {

    return {
      1: 'Solteiro(a)',
      2: 'Casado(a)',
      3: 'Separado(a)',
      4: 'Divorciado(a)',
      5: 'Viuvo(a)',
      6: 'União Estável'
    };

  }

  public static get tipoResidenciaTxt(): any {

    return {
      1: 'CASA / SOBRADO',
      2: 'CASA EM CONDOMÍNIO',
      3: 'APARTAMENTO / FLAT',
      4: 'CHÁCARA / FAZENDA / SÍTIO',
      5: 'OUTROS',
      6: 'NÃO INFORMADO'
    };

  }

  public static get combustiveisTxt(): any {

    return {
      2: 'ALCOOL',
      9: 'ALCOOL / GNV',
      3: 'DIESEL',
      5: 'ELÉTRICO',
      1: 'GASOLINA',
      7: 'GASOLINA / ALCOOL',
      8: 'GASOLINA / GNV',
      10: 'GASOL. / ALC. / GNV',
      6: 'OUTROS'
    };

  }

  public static get tipoCarroceriaTxt(): any {

    return {
      1: 'Aberta',
      2: 'Baú',
      10: 'Baú Isotérmico',
      11: 'Baú Frigorífico',
      3: 'Basculante',
      4: 'Betoneira',
      5: 'Cegonha',
      6: 'Coletor de Lixo',
      7: 'Graneleira',
      8: 'Tanque',
      12: 'Furgão Lona/Slider',
      13: 'Florestal',
      9: 'Outros',
      14: 'Guincho/Plataforma'
    };

  }

  public static get periodoUsoCaminhaoTxt(): any {

    return {
      1: 'Diurno',
      2: 'Noturno',
      3: 'Ambos'
    };

  }

  public static get contratanteServicosTxt(): any {

    return {
      1: 'Uso Próprio',
      2: 'Transportadora',
      3: 'Outro tipo de empresa',
      4: 'Cooperativa',
      5: 'Motorista autônomo'
    };

  }

  public static get gerenciamentoRiscoTxt(): any {

    return {
      1: 'Cadastro de Motorista',
      2: 'Escolta Armado',
      3: 'Monitoramento',
      4: 'Rotograma',
      7: 'Nenhum'
    };

  }

  public static get dispositivoDafTxt(): any {

    return {
      0: 'Não possui',
      7: 'Alarme Sonoro',
      8: 'Chave Codificada',
      1: 'Ituran',
      2: 'Mult-T-Lock',
      3: 'Sascar',
      4: 'Tracker',
      5: 'Travas Carneiro',
      6: 'Outros'
    };
  }

  public static get isencaoFiscalTxt(): any {

    return {
      4: 'Não se aplica',
      0: 'Sem Isenção',
      2: 'IPI',
      1: 'ICMS',
      3: 'IPI / ICMS'
    };

  }

  public static get localPernoiteTxt(): any {

    return {
      1: 'Com portão manual',
      2: 'Com portão automático',
      3: 'Em local pago (de terceiros)',
      4: 'Não tem garagem'
    };

  }

  public static get localPernoiteCaminhaoTxt(): any {

    return {
      5: 'Posto de Autoatendimento',
      6: 'Posto de Combustível',
      7: 'Estacionamento Fechado',
      8: 'Posto rodoviário',
      9: 'Não viaja'
    };

  }

  public static get sexoJovemCondutorTxt(): any {

    return {
      F: 'Feminino',
      M: 'Masculino',
      A: 'Ambos'
    };
  }

  public static get idadeJovemCondutorTxt(): any {

    return {
      17: '17 anos',
      18: '18 anos',
      19: '19 anos',
      20: '20 anos',
      21: '21 anos',
      22: '22 anos',
      23: '23 anos',
      24: '24 anos',
      25: '25 anos'

    };
  }

  public static get qtdPortasTxt(): any {

    return {
      2: '2 Portas',
      3: '3 Portas',
      4: '4 Portas',
      5: '5 Portas'
    };
  }

  public static get idaVoltaTrabalhoTxt(): any {

    return {
      0: 'Não utiliza para este fim',
      1: 'Sim, estacionamento fechado',
      2: 'Não possui estacionamento'
    };
  }

  public static get idaVoltaEstudoTxt(): any {

    return {
      0: 'Não utiliza para este fim',
      1: 'Sim, estacionamento fechado',
      2: 'Não possui estacionamento'
    };
  }

  public static get relacaoSeguradoTxt(): any {

    return {
      0: { value: 'Sem Perfil', disabled: true },
      1: { value: 'O próprio segurado', disabled: false },
      2: { value: 'Cônjuge do segurado', disabled: false },
      3: { value: 'Filho(a) do segurado', disabled: false },
      4: { value: 'Outra PF', disabled: false },
      5: { value: 'Motorista Contratado', enable: false },
      6: { value: 'Mais jovem, caso haja mais de 1 condutor principal', disabled: false },
      12: { value: 'Pai / Mãe do Segurado', disabled: false }
    };

  }

  public static get zeroKmTxt(): any {

    return {
      0: { value: true, descricao: 'Sim', disabled: false },
      1: { value: false, descricao: 'Não', disabled: false },
    };

  }

  public static get tpCoberturaTxt(): any {

    return {
      1: 'Compreensiva',
      3: 'RCF'
    };

  }

  public static get carroReservaTxt(): any {

    return {
      0: 'Não selecionado',
      1: 'Até 1100 cc',
      2: '1101 a 1200 cc',
      3: '1201 a 1400 cc',
      4: '1401 a 1600 cc',
      5: '1601 a 1800 cc',
      6: '1801 a 2000 cc',
    };

  }

  public static get assist24hsTxt(): any {

    return {
      1: 'Básica',
      2: 'Intermediária',
      3: 'Completa'
    };

  }

  public static get assist24hsPacote(): any {
    return {
      NAO: {
        id: 1,
        name: 'Não'
      },
      BASICA: {
        id: 2,
        name: 'Básica'
      },
      INTERMEDIARIA: {
        id: 3,
        name: 'Intermediária'
      },
      COMPLETA: {
        id: 4,
        name: 'Completa'
      }
    };
  }

  public static get tpUsoTxt(): any {

    return {
      1: 'Apenas Lazer',
      2: 'Locomoção Diária',
      3: 'Prestação de serviços',
      4: 'Representação comercial',
      5: 'Transporte por aplicativos',
      6: 'Outros ramos de atividade'
    };

  }

  public static get tpUsoCaminhaoTxt(): any {

    return {
      8: 'Guincho',
      7: 'Transporte de carga',
      9: 'Auto Escola',
      10: 'Coletor de Lixo',
      11: 'Socorro',
      6: 'Outros ramos de atividade'
    };

  }

  public static get kmMensalTxt(): any {

    return {
      500: 'Até 500 km',
      800: 'De 501 km até 800 km',
      1500: 'De 801 km até 1.500 km',
      1700: 'Mais de 1.500 km',
    };

  }

  public static get kmMensalCaminhaoTxt(): any {

    return {
      2500: 'Até 2.500 km',
      5000: 'De 2.501 km até 5.000 km',
      10000: 'De 5.001 km até 10.000 km ',
      11000: 'Acima de 10.000 km',
    };

  }

  public static get equipamentosCaminhaoTxt(): any {

    return {
      0: 'R$ 0',
      3000: 'R$ 3.000,00',
      5000: 'R$ 5.000,00',
      8000: 'R$ 8.000,00',
      10000: 'R$ 10.000,00',
      12000: 'R$ 12.000,00',
      15000: 'R$ 15.000,00',
      18000: 'R$ 18.000,00',
      20000: 'R$ 20.000,00',
      25000: 'R$ 25.000,00',
      30000: 'R$ 30.000,00',
      35000: 'R$ 35.000,00',
      40000: 'R$ 40.000,00',
      45000: 'R$ 45.000,00',
      50000: 'R$ 50.000,00',
      80000: 'R$ 80.000,00',
      100000: 'R$ 100.000,00',
    };

  }

  public static get ramos(): RamosCodes {
    return {
      AUTO: 32,
      CARRO: 32,
      MOTOCICLETA: 32,
      CAMINHAO: 32,
      VIDA: 91,
      RESIDENCIAL: 2,
      EMPRESARIAL: 18,
      CONDOMINIO: 16
    };
  }

  public static get ramosGeral() {
    return {
      2: 'residencial',
      18: 'empresarial',
      16: 'condominio',
      31: 'auto',
      32: 'auto',
      91: 'vida',
    };
  }

  public static get tipoFranquiaOptions(): any {
    return [
      {
        value: 1,
        viewValue: 'Reduzida'
      },
      {
        value: 2,
        viewValue: 'Obrigatória'

      },
      {
        value: 3,
        viewValue: 'Majorada'
      }
    ];
  }

  public static get carroReservaViewValue(): ViewValue[] {
    return [
      {
        value: 0,
        viewValue: 'Não selecionado'
      },
      {
        value: 1,
        viewValue: 'Até 1100 cc'
      },
      {
        value: 2,
        viewValue: '1101 a 1200 cc'
      },
      {
        value: 3,
        viewValue: '1201 a 1400 cc'
      },
      {
        value: 4,
        viewValue: '1401 a 1600 cc'
      },
      {
        value: 5,
        viewValue: '1601 a 1800 cc'
      },
      {
        value: 6,
        viewValue: '1801 a 2000 cc'
      },
    ];
  }

  // public static get guinchoKmViewValue(): ViewValue[] {
  //   return [
  //     {
  //       value: 1,
  //       viewValue: '200km'
  //     },
  //     {
  //       value: 2,
  //       viewValue: '400km'
  //     },
  //     {
  //       value: 3,
  //       viewValue: '600km'
  //     },
  //     {
  //       value: 4,
  //       viewValue: '800km'
  //     },
  //     {
  //       value: 5,
  //       viewValue: '1000km'
  //     },
  //     {
  //       value: 6,
  //       viewValue: 'Ilimitado'
  //     },
  //     {
  //       value: 7,
  //       viewValue: 'Ilimitado TOP'
  //     }
  //   ];
  // }

  public static get assist24hsViewValue(): ViewValue[] {
    return [
      {
        value: 1,
        viewValue: 'Não'
      },
      {
        value: 2,
        viewValue: 'Básica'
      },
      {
        value: 3,
        viewValue: 'Intermediária'
      },
      {
        value: 4,
        viewValue: 'Completa'
      }
    ];
  }

  public static get assistFuneralViewValue(): ViewValue[] {
    return [
      {
        value: 0,
        viewValue: 'Não'
      },
      {
        value: 1,
        viewValue: 'Funeral Individual'
      },
      {
        value: 2,
        viewValue: 'Funeral Familiar'

      }
    ];
  }

  public static get qtdVagasGaragemTxt(): any {
    return {
      0: 'O Prédio não possui garagens',
      1: 'Até 25 vagas',
      2: 'Até 50 vagas',
      3: 'Até 100 vagas',
      4: 'Mais de 100 vagas'
    }
  }

  public static get danosPropriosFurtoRouboTxt(): any{ 
    return {
      0: 'Não selecionado',
      1:'Franquia 0%',
      2: 'Franquia 2%',
      3: 'Franquia 4%',
      4: 'Franquia 8%',
      5: 'Franquia 12%',
      6: 'Franquia 20%'
    }
  }

  public static get danosPropriosTxt(): any{ 
    return {
      0: 'Não selecionado',
      1:'Franquia 0%',
      2: 'Franquia 1%',
      3: 'Franquia 2%',
      4: 'Franquia 4%',
      5: 'Franquia 8%',
      6: 'Franquia 12%',
      7: 'Franquia 16%',
      8: 'Franquia 20%'
    }
  }

  public static get assistFuneralValorViewValue(): ViewValue[] {
    return [
      {
        value: 0,
        viewValue: 'R$ 0,00'
      },
      {
        value: 2000,
        viewValue: 'R$ 2.000,00'
      },
      {
        value: 3000,
        viewValue: 'R$ 3.000,00'
      },
      {
        value: 4000,
        viewValue: 'R$ 4.000,00'
      },
      {
        value: 5000,
        viewValue: 'R$ 5.000,00'
      },
      {
        value: 6000,
        viewValue: 'R$ 6.000,00'
      },
      {
        value: 7000,
        viewValue: 'R$ 7.000,00'
      },
      {
        value: 8000,
        viewValue: 'R$ 8.000,00'
      },
      {
        value: 9000,
        viewValue: 'R$ 9.000,00'
      },
      {
        value: 10000,
        viewValue: 'R$ 10.000,00'
      },
      {
        value: 15000,
        viewValue: 'R$ 15.000,00'
      }
    ];
  }

  public static get despesasTratamentoViewValue(): ViewValue[] {
    return [
      {
        value: 750,
        viewValue: '750 €'
      },
      {
        value: 1000,
        viewValue: '1 000,00 €'
      },
      {
        value: 1500,
        viewValue: '1 500,00 €'
      },
      {
        value: 3750,
        viewValue: '3 750,00 €'
      },
      {
        value: 5000,
        viewValue: '5 000,00 €'
      }
    ];
  }

  public static get coberturasConfigCfg(): any {
    const cobs = [];

    cobs[this.ramos.AUTO] = [
      {
        value: 'responsabilidadeCivil',
        valueTxt: 'responsabilidadeCivilTxtRS',
        label: 'Resp. Civil',
        optionType: 'intervalViewValue2'
      },
      {
        value: 'despesasTratamento',
        valueTxt: 'despesasTratamentoTxtRS',
        label: 'Despesas Tratamento',
        optionType: 'viewValue',
        arrayOptions: this.despesasTratamentoViewValue
      },
      {
        value: 'isAppMorte',
        valueTxt: 'isAppMorteTxtRS',
        label: 'App Morte',
        optionType: 'intervalViewValue3'
      },
      {
        value: 'isAppInvalidez',
        valueTxt: 'isAppInvalidezTxtRS',
        label: 'App Invalidez',
        optionType: 'intervalViewValue3'
      },
      {
        value: 'carroReserva',
        valueTxt: 'carroReservaTxt',
        label: 'Veíc. de Substituição',
        optionType: 'viewValue',
        arrayOptions: this.carroReservaViewValue
      },
      // {
      //   value: 'guinchoKm',
      //   valueTxt: 'guinchoKm',
      //   label: 'Guincho KM',
      //   optionType: 'viewValue',
      //   arrayOptions: this.guinchoKmViewValue
      // },
      {
        value: 'vidros',
        valueTxt: 'vidrosTxt',
        label: 'Vidros',
        optionType: 'boolean'
      },
      {
        value: 'danosPropriosFurtoRoubo',
        valueTxt: 'danosPropriosFurtoRouboTxt',
        label: 'Furto ou Roubo',
        optionType: this.franquiaRoubo
      },
      {
        value: 'danosPropriosChoqueColisaoCapotamento',
        valueTxt: 'danosPropriosChoqueColisaoCapotamentoTxt',
        label: 'Choque, Colisão, Cap.',
        optionType: this.franquias
      },
      {
        value: 'danosPropriosRiscoNatureza',
        valueTxt: 'danosPropriosRiscoNaturezaTxt',
        label: 'Cat. Natureza',
        optionType: this.franquias
      },
      {
        value: 'danosPropriosGrevesTumultos',
        valueTxt: 'danosPropriosGrevesTumultosTxt',
        label: 'Greves e Tumultos',
        optionType: this.franquias
      },
      {
        value: 'danosPropriosAtosTerrorismo',
        valueTxt: 'danosPropriosAtosTerrorismoTxt',
        label: 'Greves e Tumultos',
        optionType: this.franquias
      },
      // {
      //   value: 'tipoFranquia',
      //   valueTxt: 'tipoFranquiaTxt',
      //   label: 'Franquia',
      //   optionType: 'viewValue',
      //   arrayOptions: this.tipoFranquiaOptions
      // },
      // {
      //   value: 'isDanosMateriais',
      //   valueTxt: 'isDanosMateriaisTxtRS',
      //   label: 'Danos Materiais',
      //   optionType: 'intervalViewValue1'
      // },
      // {
      //   value: 'isDanosCorporais',
      //   valueTxt: 'isDanosCorporaisTxtRS',
      //   label: 'Danos Corporais',
      //   optionType: 'intervalViewValue1'
      // },
      // {
      //   value: 'isDanosMorais',
      //   valueTxt: 'isDanosMoraisTxtRS',
      //   label: 'Danos Morais',
      //   optionType: 'intervalViewValue2'
      // },
      // {
      //   value: 'carroReservaAr',
      //   valueTxt: 'carroReservaAr',
      //   label: 'Carro Reserva c/ Ar',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'despesasExtra',
      //   valueTxt: 'despesasExtra',
      //   label: 'Despesas Extra',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'rouboFurtoEstepe',
      //   valueTxt: 'rouboFurtoEstepe',
      //   label: 'Roubo/ Furto Estepe',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'reparoRapido',
      //   valueTxt: 'reparoRapido',
      //   label: 'Reparo Rápido',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'martelinhoOuro',
      //   valueTxt: 'martelinhoOuro',
      //   label: 'Martelinho de Ouro',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'higienizacao',
      //   valueTxt: 'higienizacao',
      //   label: 'Higienização',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'farolRetrovis',
      //   valueTxt: 'farolRetrovis',
      //   label: 'Farol Retrovisor',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'tetoSolar',
      //   valueTxt: 'tetoSolar',
      //   label: 'Teto Solar',
      //   optionType: 'boolean'
      // },
      // {
      //   value: 'lantFarolAux',
      //   valueTxt: 'lantFarolAux',
      //   label: 'Lanterna Farol Auxiliar',
      //   optionType: 'boolean'
      // }
    ],
      cobs[this.ramos.VIDA] = [
        {
          value: 'assistMorte',
          valueTxt: 'morteTxtRS',
          label: 'Morte',
          optionType: 'multiplicador'
        },
        {
          value: 'isAppMorteAcidental',
          valueTxt: 'morteAcidentalTxtRS',
          label: 'Morte Acidental',
          optionType: 'multiplicador'
        },
        {
          value: 'isAppInvalidezVida',
          valueTxt: 'invalidezPermanenteTxtRS',
          label: 'Invalidez Permanente',
          optionType: 'multiplicador'
        },
        {
          value: 'doencasGraves',
          valueTxt: 'doencasGravesTxtRS',
          label: 'Doenças Graves',
          optionType: 'multiplicador'
        },
        {
          value: 'assistFuneral',
          valueTxt: 'funeralTxt',
          label: 'Assistência Funeral',
          optionType: 'viewValue',
          arrayOptions: this.assistFuneralViewValue
        },
        {
          value: 'isAppFuneral',
          valueTxt: 'funeralTxtCob',
          label: 'Valor',
          optionType: 'viewValue',
          arrayOptions: this.assistFuneralValorViewValue
        }
      ],
      cobs[this.ramos.RESIDENCIAL] = [
        {
          value: 'porResidDanosEletricos',
          valueTxt: 'isDanosEletricosTxtRS',
          label: 'Danos Elétricos',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidDanosImpactoVeiculo',
          valueTxt: 'isDanosImpactoVeiculoTxtRS',
          label: 'Vendaval / Imp. veículos',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidDanosAluguel',
          valueTxt: 'isDanosAluguelTxtRS',
          label: 'Perda / pagto aluguel',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidDanosRoubo',
          valueTxt: 'isDanosRouboTxtRS',
          label: 'Roubo',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidResponsabilidadeCivil',
          valueTxt: 'responsabilidadeCivilTxtRS',
          label: 'Resp. Civil familiar',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidDanosAlagamento',
          valueTxt: 'isDanosAlagamentoTxtRS',
          label: 'Alagamento',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidDanosDesmoronamento',
          valueTxt: 'isDanosDesmoronamentoTxtRS',
          label: 'Desmoronamento',
          optionType: 'porcentagem'
        },
        {
          value: 'porResidVidros',
          valueTxt: 'isDanosVidrosTxtRS',
          label: 'Vidros',
          optionType: 'porcentagem'
        },
        {
          value: 'assist24hsResidencial',
          valueTxt: 'assist24hsTxt',
          label: 'Assistência 24 Horas',
          optionType: 'viewValue',
          arrayOptions: this.assist24hsViewValue
        },
        {
          value: 'porDanosIncendioRaioExplosao',
          valueTxt: 'isDanosIncendioRaioExplosao',
          label: 'Danos Incêndio',
          optionType: 'porcentagem'
        }
      ],
      cobs[this.ramos.EMPRESARIAL] = [
        {
          value: 'porDanosEletricos',
          valueTxt: 'isDanosEletricosTxtRS',
          label: 'Danos Elétricos',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosImpactoVeiculo',
          valueTxt: 'isDanosImpactoVeiculoTxtRS',
          label: 'Vendaval / Imp. veículos',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosAluguel',
          valueTxt: 'isDanosAluguelTxtRS',
          label: 'Perda / pagto aluguel',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosRoubo',
          valueTxt: 'isDanosRouboTxtRS',
          label: 'Roubo',
          optionType: 'porcentagem'
        },
        {
          value: 'porResponsabilidadeCivil',
          valueTxt: 'responsabilidadeCivilTxtRS',
          label: 'Resp. Civil Operações',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosAlagamento',
          valueTxt: 'isDanosAlagamentoTxtRS',
          label: 'Alagamento',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosDesmoronamento',
          valueTxt: 'isDanosDesmoronamentoTxtRS',
          label: 'Desmoronamento',
          optionType: 'porcentagem'
        },
        {
          value: 'porVidrosEmpresarial',
          valueTxt: 'isDanosVidrosTxtRS',
          label: 'Vidros',
          optionType: 'porcentagem'
        },
        {
          value: 'assist24hsPacote',
          valueTxt: 'assist24hsTxt',
          label: 'Assistência 24 Horas',
          optionType: 'viewValue',
          arrayOptions: this.assist24hsViewValue
        },
        {
          value: 'porDanosVazamentoTanquesTubulacoes',
          valueTxt: 'isDanosVazamentoTanquesTubulacoes',
          label: 'Vazamento de Tanques',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosDespesasFixas',
          valueTxt: 'despesaFixa',
          label: 'Danos Despesas Fixas',
          optionType: 'porcentagem'
        },
        {
          value: 'porDanosEquipamentosEletronicos',
          valueTxt: 'isDanosEquipamentosEletronicos',
          label: 'Danos Equip. Eletrônicos',
          optionType: 'porcentagem'
        }
      ],
      cobs[this.ramos.CONDOMINIO] = [
        {
          value: 'isDanosIncendioRaioExplosao',
          valueTxt: 'isDanosIncendioRaioExplosaoTxtRS',
          label: 'Incêndio/raio/explosão',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosIncendioRaioExplosaoCond',
          valueTxt: 'isDanosIncendioRaioExplosaoCondTxtRS',
          label: 'Incêndio de Conteúdo',
          optionType: 'porcentagem'
        },
        {
          value: 'isValorCondominio',
          valueTxt: 'isValorCondominioTxtRS',
          label: 'Valor Condomínio',
          optionType: 'porcentagem'
        },
        {
          value: 'isValorSindico',
          valueTxt: 'isValorSindicoTxtRS',
          label: 'Síndico',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosImpactoVeiculo',
          valueTxt: 'isDanosImpactoVeiculoTxtRS',
          label: 'Vendaval/ Imp. veículos',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosRoubo',
          valueTxt: 'isDanosRouboTxtRS',
          label: 'Roubo, furto qualificado',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosPortoes',
          valueTxt: 'isDanosPortoesTxtRS',
          label: 'Portões',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosMorais',
          valueTxt: 'isDanosMoraisTxtRS',
          label: 'Danos Morais',
          optionType: 'porcentagem'
        },
        {
          value: 'vidros',
          valueTxt: 'isDanosVidrosTxtRS',
          label: 'Vidros',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosDesmoronamento',
          valueTxt: 'isDanosDesmoronamentoTxtRS',
          label: 'Desmoronamento',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosAluguel',
          valueTxt: 'isDanosAluguelTxtRS',
          label: 'Perda/pagto aluguel',
          optionType: 'porcentagem'
        },
        {
          value: 'isDanosVazamentoTanquesTubulacoes',
          valueTxt: 'isDanosVazamentoTanquesTubulacoesTxtRS',
          label: 'Vazamento de tubulações',
          optionType: 'porcentagem'
        },
        {
          value: 'assist24hs',
          valueTxt: 'assist24hsTxt',
          label: 'Assistência 24hs',
          optionType: 'porcentagem'
        },
        {
          value: 'tpGuardaTerceirizada',
          valueTxt: 'tpGuardaTerceirizadaTxtRS',
          label: 'Tipo Guarda Veic. 3ª',
          optionType: 'porcentagem'
        },
        {
          value: 'vidaGrupo',
          valueTxt: 'vidaGrupoTxt',
          label: 'Vida em Grupo',
          optionType: 'porcentagem'
        }
        // {
        //   value: 'imovelAtividade',
        //   valueTxt: 'imovelAtividadeTxt',
        //   label: 'Atividade do Imóvel/Condomínio',
        //   optionType: 'porcentagem'
        // },
      ];

    return cobs;
  }

  public static get cfgStepper(): any {
    const params = [
      {
        title: 'BÁSICO',
        id: 1
      },
      {
        title: 'NORMAL',
        id: 2
      },
      {
        title: 'DIFERENCIADO',
        id: 3
      }
    ];

    return [
      {
        id: this.ramos.AUTO,
        title: 'AUTOMÓVEL',
        icon: 'car',
        parametrizacoes: params
      }
    ];
  }

  public static get caracteristicasResidencial(): ViewValue[] {
    return [
      {
        value: 'tipoImovelTxt',
        viewValue: 'Tipo'
      },
      {
        value: 'objetoSeguradoTxt',
        viewValue: 'Objeto Segurado'
      },
      {
        value: 'tipoConstrucaoTxt',
        viewValue: 'Construção'
      },
      {
        value: 'usoImovelTxt',
        viewValue: 'Uso'
      },
      {
        value: 'imovelPossuiAlarme',
        viewValue: 'Possui alarme'
      },
      {
        value: 'imovelPossuiVigilancia24hs',
        viewValue: 'Possui vigilância 24 horas'
      }
    ];
  }

  public static get franquias(): ViewValue[] {
    return [
      { value: 0, viewValue: 'Não selecionado' },
      { value: 1, viewValue: 'Franquia 0%' },
      { value: 2, viewValue: 'Franquia 1%' },
      { value: 3, viewValue: 'Franquia 2%' },
      { value: 4, viewValue: 'Franquia 4%' },
      { value: 5, viewValue: 'Franquia 8%' },
      { value: 6, viewValue: 'Franquia 12%' },
      { value: 7, viewValue: 'Franquia 16%' },
      { value: 8, viewValue: 'Franquia 20%' }
    ];
  }

  public static get franquiaRoubo(): ViewValue[] {
    return [
      { value: 0, viewValue: 'Não selecionado' },
      { value: 1, viewValue: 'Franquia 0%' },
      { value: 2, viewValue: 'Franquia 2%' },
      { value: 3, viewValue: 'Franquia 4%' },
      { value: 4, viewValue: 'Franquia 8%' },
      { value: 5, viewValue: 'Franquia 12%' },
      { value: 6, viewValue: 'Franquia 20%' }
    ];
  }

  public static get caracteristicasCondominio(): ViewValue[] {
    return [
      {
        value: 'condConstituidoLegalmente',
        viewValue: 'Cond. Constituído Legalmente'
      },
      {
        value: 'tipoCondominioTxt',
        viewValue: 'Tipo de Condomínio'
      },
      {
        value: 'anoFundacao',
        viewValue: 'Ano Fundação'
      },
      {
        value: 'qtdVagasGaragemTxt',
        viewValue: 'Vagas de Garagem'
      },
      {
        value: 'estruturaCondominioTxt',
        viewValue: 'Estrutura'
      },
      {
        value: 'qtdElevadores',
        viewValue: 'Qtd. de Elevadores'
      }
    ];
  }

  public static get tipoCoberturaMultiTxt(): any {

    return {
      1: 'Básica',
      2: 'Normal',
      3: 'Diferenciada',
      4: 'Personalizada'
    };

  }

  public static get tipoFranquiaTxt(): any {

    return {
      1: 'Reduzida',
      2: 'Obrigatória',
      3: 'Majorada'
    };

  }

  public static get areaCirculacaoCaminhaoTxt(): any {

    return {
      1: 'Município e arredores até 80KM',
      2: 'Dentro do próprio estado da sede',
      3: 'América do Sul',
      4: 'Região Centro-Oeste',
      5: 'Região Sul',
      6: 'Região Sudeste',
      7: 'Mercosul',
      8: 'Região Norte',
      9: 'Região Nordeste'
    };

  }

  public static get tiposCargaCaminhaoTxt(): any {

    return {
      1: 'Agua',
      2: 'Auto Peças',
      3: 'Brinquedos',
      4: 'Canavieiro',
      5: 'Carga Viva',
      6: 'Cigarro',
      7: 'Inflamável',
      8: 'Eletrônicos',
      9: 'Alimentos',
      10: 'Frigorífico',
      11: 'Madeira',
      12: 'Mat. de construção',
      13: 'Minério',
      14: 'Armas e Munição',
      15: 'Pneu',
      16: 'Veículo',
      17: 'Confecções',
      18: 'Vidros',
      19: 'Indivisível',
      21: 'Carga Mista',
      22: 'Mudanças',
      23: 'Papel',
      24: 'Plástico',
      25: 'Móveis',
      26: 'Produtos Químicos',
      27: 'Alimentos perecívies/hortifrutigrangeiro',
      28: 'Alimentos não perecíveis',
      29: 'Bebidas',
      30: 'Mel',
      31: 'Metal',
      32: 'Calçados',
      33: 'Couro',
      34: 'Laticinios',
      35: 'Carne',
      36: 'Defensivo agrícola',
      37: 'Jardinagem',
      38: 'Lixo',
      39: 'Máquinas e implementos agrícolas',
      40: 'Grãos',
      41: 'Ração Animal',
      42: 'Supermercado',
      20: 'Outros'
    };

  }

  public static get excluirRegiaoCaminhaoTxt(): any {

    return {
      RJ: 'Rio de Janeiro',
      SP: 'São Paulo',
    };

  }

  public static get utilizacaoCaminhaoTxt(): any {

    return {
      0: 'Viaja em comboio',
      1: 'A carga é segurada',
      2: 'Possui garagem quando fora de serviço',
      3: 'O motorista tem participação na franquia',
    };

  }

  public static get sinistrosAnteriorTxt(): any {

    return {
      0: 'Sem sinistros',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10'
    };

  }

  public static get imovelLocalizacaoTxt(): any {

    return {
      1: 'Térreo, ou até o 1º andar de edifício',
      2: 'Em edifício a partir do 2º andar',
      3: 'Em Shopping Center'

    };
  }

  public static get rotaCorrigida(): any {

    return {
      v: 'carro',
      c: 'caminhao',
      m: 'motocicleta'
    };
  }

  public static get maskValidate(): any {

    return {
      ALTURA: '0.00',
      ANO: '0000',
      CEP: '00000-000',
      CHASSI: 'AAAAAAAAAAAAAAAAA',
      CI: '09999999999999999999999999999999999999999999999999999999999999999',
      CNPJ: '00.000.000/0000-00',
      CPF: '000.000.000-00',
      DATA: '00/00/0000',
      DOUBLE: '09',
      ELEVADORES: '09',
      FIPE: '000000-0',
      FONE: '(00) 0000-00009',
      LIVRE14: '00000000000000',
      MAIL: 'A+@\.A+',
      NUMEROIMOVEL: '09999',
      PESO: '009',
      PESSOAS: '0999999999999',
      PLACA: 'SSS-0A00',
      TRIPLE: '099'
    };

  }

  public static get optionsSelectedPdfSimplificado(): any {
    return {
      SEGURADO: 1,
      CONDUTOR_PRINCIPAL: 2,
      CARACTERISTICAS: 2,
      COBERTURAS: 3,
      CALCULO_COM_ERROS: 4
    };
  }

  public static get subRamosAuto(): any {
    return [
      {
        id: 'c', value: 'caminhao'
      },
      {
        id: 'm', value: 'motocicleta'
      },
      {
        id: 'v', value: 'carro'
      },
    ];
  }

  public static get aplicacaoId(): AplicacaoIdModel {
    return {
      MULTI: 1,
      APICE: 3
    };
  }

  public static get tipoCondominioTxt(): any {
    return {
      1: 'Exclusivamente Residencial',
      2: 'Resid. - Até 15% da área ocupada por comércio',
      3: 'Residencial com escritórios/consultórios',
      4: 'Comercial',
      5: 'Misto',
      6: 'Escritórios/Consultórios',
      7: 'Edifício Garagem',
      8: 'Consultórios e escritórios com comércio',
      9: 'Flat (Exceto Hoteis e moteis)',
      10: 'Flat/Hotel',
      11: 'Flat/Hotel Com comércio',
      12: 'Shopping Center',
    };
  }

  public static get vidaGrupoTxt(): any {
    return {
      0: 'Não contratada',
      1: 'Morte/Ipa',
      2: 'Morte/IPA/IEA',
      3: 'Morte/IPA/IEA/IFPD'
    };
  }

  public static get logoSeguradorasPathList(): SeguradoraPathProps[] {
    return [
      {
        id: 1,
        rotulo: 'Bradesco',
        path: 'assets/images/seguradoras/seguradora1.png'
      },
      {
        id: 2,
        rotulo: 'Liberty',
        path: 'assets/images/seguradoras/seguradora2.png'
      },
      {
        id: 3,
        rotulo: 'Mapfre',
        path: 'assets/images/seguradoras/seguradora3.png'
      },
      {
        id: 4,
        rotulo: 'HDI',
        path: 'assets/images/seguradoras/seguradora4.png'
      },
      {
        id: 5,
        rotulo: 'Allianz',
        path: 'assets/images/seguradoras/seguradora5.png'
      },
      {
        id: 6,
        rotulo: 'Sompo',
        path: 'assets/images/seguradoras/seguradora6.png'
      },
      {
        id: 7,
        rotulo: 'Zurich',
        path: 'assets/images/seguradoras/seguradora7.png'
      },
      {
        id: 8,
        rotulo: 'Porto Seguro',
        path: 'assets/images/seguradoras/seguradora8.png'
      },
      {
        id: 10,
        rotulo: 'Azul',
        path: 'assets/images/seguradoras/seguradora10.png'
      },
      {
        id: 11,
        rotulo: 'Tokio',
        path: 'assets/images/seguradoras/seguradora11.png'
      },
      {
        id: 12,
        rotulo: 'Liberty',
        path: 'assets/images/seguradoras/seguradora12.png'
      },
      {
        id: 13,
        rotulo: 'Mitsui',
        path: 'assets/images/seguradoras/seguradora13.png'
      },
      {
        id: 14,
        rotulo: 'Generali',
        path: 'assets/images/seguradoras/seguradora14.png'
      },
      {
        id: 16,
        rotulo: 'Sompo',
        path: 'assets/images/seguradoras/seguradora16.png'
      },
      {
        id: 17,
        rotulo: 'Sul América',
        path: 'assets/images/seguradoras/seguradora17.png'
      },
      {
        id: 18,
        rotulo: 'Alfa',
        path: 'assets/images/seguradoras/seguradora18.png'
      },
      {
        id: 19,
        rotulo: 'Sancor',
        path: 'assets/images/seguradoras/seguradora19.png'
      },
      {
        id: 20,
        rotulo: 'Suhai',
        path: 'assets/images/seguradoras/seguradora20.png'
      },
      {
        id: 22,
        rotulo: 'Aliro',
        path: 'assets/images/seguradoras/seguradora22.png'
      },
    ];
  }

  public static get iconsCopy() {
    return [
      {
        value: this.ramos.AUTO,
        iconSvg: 'car',
        tooltipText: 'Copiar para Auto'
      },
      {
        value: this.ramos.RESIDENCIAL,
        iconSvg: 'home_variant',
        tooltipText: 'Copiar para Residencial'
      },
      {
        value: this.ramos.VIDA,
        iconSvg: 'cards-heart',
        tooltipText: 'Copiar para Vida'
      },
      {
        value: this.ramos.EMPRESARIAL,
        iconSvg: 'factory',
        tooltipText: 'Copiar para Empresarial',
      },
      {
        value: this.ramos.CONDOMINIO,
        iconSvg: 'city',
        tooltipText: 'Copiar para Condomínio'
      },
    ];
  }
}
