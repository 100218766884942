import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import { Credenciais } from '../models/credenciais.model';
import { ApiUsuarioService } from './api-usuario.service';
import { ApiSeguradoraService } from './api-seguradora.service';
import * as Moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class ApiCotacoesService {

	private ENV:any = environment;
	private URL;
	moment = Moment;

	constructor(private http: HttpClient, private BS: BaseService, private apiUsuario: ApiUsuarioService, private apiSeguradora: ApiSeguradoraService) {
		this.URL = this.ENV.apice.url;
	}

	/**
     * 
     * @param filtroPesquisa objeto podendo conter os seguintes parâmetros:
     *  - limit
     *  - nDias
     *  - filtroBusca
     *  - excluir: array de ids das cotações a serem excluidas da busca
     */
	// async buscaCotacoesPaginas(filtroPesquisa: any): Promise<Cotacao[]> {
	// 	// console.log('FILTRO', JSON.stringify(filtroPesquisa,null,4))
	// 	filtroPesquisa.filtroBusca = filtroPesquisa.pesquisa;
	// 	try {
	// 		let dadosCotacoes = await this.apiBase.post('calculo/cotacao/paginas', filtroPesquisa);
	// 		let calculos: Cotacao[] = [];
	// 		for (let dadosCotacao of dadosCotacoes) {
	// 			let calculo = new Cotacao(dadosCotacao);
	// 			calculos.push(calculo);
	// 		}
	// 		return calculos;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

    /**
    * 
    * @param filtroPesquisa objeto podendo conter os seguintes parâmetros:
    *  - limit
    *  - pagina
    *  - filtroBusca
    */
	// async buscaCotacoesPaginacao(filtroPesquisa: any): Promise<Cotacao[]> {
	// 	// console.log('FILTRO', JSON.stringify(filtroPesquisa,null,4))
	// 	filtroPesquisa.filtroBusca = filtroPesquisa.pesquisa;
	// 	try {
	// 		let dadosCotacoes = await this.apiBase.post('calculo/cotacao/paginacao', filtroPesquisa);
	// 		let calculos: Cotacao[] = [];
	// 		for (let dadosCotacao of dadosCotacoes) {
	// 			let calculo = new Cotacao(dadosCotacao);
	// 			calculos.push(calculo);
	// 		}
	// 		return calculos;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// async buscaCotacoes(filtroPesquisa: any): Promise<Cotacao[]> {

	// 	let obj = {}

	// 	if (filtroPesquisa.limit) {
	// 		obj['limit'] = filtroPesquisa.limit;
	// 	}

	// 	if (filtroPesquisa.nDias) {
	// 		obj['nDias'] = filtroPesquisa.nDias;
	// 	}

	// 	if (filtroPesquisa.pesquisa && filtroPesquisa.pesquisa.trim() != '') {
	// 		obj['filtroBusca'] = filtroPesquisa.pesquisa;
	// 	}

	// 	try {
	// 		let dadosCotacoes = await this.apiBase.get('calculo/cotacao', obj);
	// 		// let dadosCotacoes = await this.apiBase.get(this.segmentoCotacoes, obj );
	// 		let calculos: Cotacao[] = [];
	// 		for (let dadosCotacao of dadosCotacoes) {
	// 			let calculo = new Cotacao(dadosCotacao);
	// 			calculos.push(calculo);
	// 		}
	// 		return calculos;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// async buscaCotacao(cotacaoId): Promise<Cotacao> {
	// 	if (!cotacaoId) throw new Error('cotacaoId não definido.');
	// 	// let url = this.segmentoCotacoes + '/' + cotacaoId;
	// 	try {
	// 		let dadosCalculo = await this.apiBase.get(`calculo/cotacao/${cotacaoId}`, {})
	// 		let calculo = new Cotacao(dadosCalculo);
	// 		//console.log('calculo', calculo);
	// 		return calculo;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// async salvaCotacao(cotacao: Cotacao): Promise<Cotacao> {
	//     debugger
	//     try {
	//         let ret = await this.apiBase.post('calculo/cotacao', cotacao.dados);   
	//         debugger         
	//         let retCotacao = new Cotacao(ret);
	//         return retCotacao;
	//     } catch (error) {
	//         throw error;
	//     }
	// }


	// async salvaFechamento(dadosFechamento: any) {
	// 	try {
	// 		let ret = await this.apiBase.post('calculo/cotacao/fechamento', dadosFechamento);
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// async calcularCotacao(id: any): Promise<any> {
	// 	try {
	// 		let ret = await this.apiBase.post('calculo/calcular/' + id, {});
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// async buscaResultadoCalculo() {
	// 	try {
	// 		let resp = await this.apiBase.get('calculo/cotacao/resultados');
	// 		//   let resp = await this.apiBase.get(this.segmentoCotacoes + '/resultados');
	// 		return resp;
	// 	} catch (error) {
	// 		// console.log('error ao calcular', error)
	// 		//throw error;
	// 	}
	// }

	// async buscaCotacoesVersoes(idIntegracao, versao?): Promise<any> {
	// 	try {
	// 		if (!idIntegracao) {
	// 			throw new Error('idIntegracao indefinido');
	// 		}
	// 		let authPdocs = await this.apiBase.autenticaNoPDocs();
	// 		let url;
	// 		if (versao) {
	// 			url = authPdocs.urlPDocs + 'calculo/cotacao/calculos/' + idIntegracao + '/' + versao;
	// 		}
	// 		else {
	// 			url = authPdocs.urlPDocs + 'calculo/cotacao/versoes/' + idIntegracao;
	// 		}
	// 		try {
	// 			let reqOpts = {
	// 				headers: new HttpHeaders({
	// 					'Content-Type': 'application/json',
	// 					'Authorization': authPdocs.token
	// 				})
	// 			};
	// 			let ret = await this.apiBase.http.get(url, reqOpts).toPromise();
	// 			return ret;
	// 		} catch (error) {
	// 			throw error;
	// 		}
	// 	} catch (error) {
	// 		// console.log('error ao calcular', error)
	// 		throw error;
	// 	}

	// }

	async verNaSeguradora(_calculo, credenciais: Credenciais, ramo?) {
		//console.log('O proprietário das credenciais de acesso deste cálculo não permite que outros usuários utilizem este recurso com suas informações.');      
		let calculo = _calculo.calculo || _calculo;
		let calc: any = credenciais.configsSeg;
		calc.login = credenciais.login;
		calc.senha = credenciais.senha;
		calc.numeroRef = calculo.nroCalculo ? calculo.nroCalculo.toString() : calculo.nroCalculo;
		calc.seguradora = calculo.seguradoraId || calculo.seguradora;

		
		if (calc.seguradora != 19 && calc.seguradora != 24) {
			let type = "VER_CALCULO";
			if (ramo == 2) {
				type = "VER_CALCULO_RESIDENCIAL";
			}
			window.postMessage({ type: type, text: JSON.stringify(calc), aplicacao: 6  }, "*");
		} else {
			console.log("Acesso temporariamente indisponivel para essa seguradora.");
		}
	}

	async verNaSeguradoraParcelas(seguradora) {
		try {
			let credenciaisApice:any = await this.apiSeguradora.buscaConfSeguradora(seguradora);
			let seguradoraCredenciais:any = await this.apiSeguradora.getCredenciaisSeguradora(credenciaisApice?.idIntegracao);
			let type = "VER_PARCELAS";
			window.postMessage({ type: type, text: JSON.stringify(seguradoraCredenciais) }, "*");
		} catch (error) {
			console.log("Erro ao buscar credenciais" + error);
		}

	}

	async logarSeguradora(credenciais: Credenciais) {
		//console.log('O proprietário das credenciais de acesso deste cálculo não permite que outros usuários utilizem este recurso com suas informações.');      
		let calc: any = credenciais.configsSeg;
		calc.login = credenciais.login;
		calc.senha = credenciais.senha;
		calc.seguradora = credenciais.seguradora;

		if (calc.seguradora != 19 && calc.seguradora != 24) {
			window.postMessage({ type: "REALIZAR_LOGIN", text: JSON.stringify(calc), aplicacao: 6 }, "*");
		} else {
			console.log("Acesso temporariamente indisponivel para essa seguradora.");
		}
	}
	
	async buscaNegociosPDocs(textoBusca?: string, page?: number, ramo?: any) {		
		let limit = 40;
		textoBusca = textoBusca || '';
		page = page || 1;		
		let modo = 2;
		if (ramo == 0) ramo = '';
		ramo = ramo || '';
	
		try {
			let authPdocs = await this.apiUsuario.autenticaNoPDocs();
			if(authPdocs?.urlPDocs){			
				this.apiUsuario.setCredenciaisPDocs(authPdocs);
				let url = encodeURI(`${authPdocs.urlPDocs}calculo/negocio/busca?textoBusca=${textoBusca}&modo=${modo}&page=${page}&limit=${limit}&ramo=${ramo}`);
				try {
					let reqOpts = {
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
							'Authorization': authPdocs.token
						})
					};
					return this.http.get(url, reqOpts).toPromise();				
				} catch (error) {
					throw error;
				}
			}
		} catch (error) {
			console.log('error ao buscar cotações pdcos', error);
			throw error;
		}

	}

	// NEGÓCIOS


	// urlFiltroNegocio = '/calculo/negocios?statusList=[5, 6]&criadosApos=2019-10-28 14:08:24&criadosAntesDe=2019-10-28 14:28:35&limit=4&retornarOrdenacoes=true&filtroBusca=8v'

	buscaNegociosPorStatus(usuarioList, status, limit, page?, filtroBusca?, ramo?, criadoAntes?) {
		page = page || 1;
		ramo = ramo || '';
		usuarioList = usuarioList || '[]'
		let url = `calculo/negocios?limit=${limit}&status=${status ? status : 0}&ramo=${ramo}&painel=true&page=${page}&filtroBusca=${filtroBusca || ''}&usuarioList=${usuarioList}`;
		return this.http.get(this.URL + url, this.BS.getHeaders(true)).toPromise();
	}

	buscaNegocioPorCliente(clienteId) {
		let url = `calculo/negocios?clienteId=${clienteId}`;
		return this.http.get(this.URL+url, this.BS.getHeaders(true)).toPromise();
	}

	// async buscaNegocios(params?: any): Promise<any> {
	// 	//retornarOrdenacoes
	// 	try {
	// 		let ret = await this.apiBase.get(`calculo/negocios?retornarOrdenacoes=true&usuarioList=${params}`);
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	// async buscaNegocio(negocioId: string): Promise<any> {
	// 	try {
	// 		let ret = await this.apiBase.get(`calculo/negocio/${negocioId}`);
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	/** Cria ou atualiza um negócio. Adiciona o usuário logado ao negócio no momento da criação deste. */
	// async salvaNegocio(negocio: {
	// 	ramo: number, //obrigatorio na hora de criar. Não alterar depois de criado.
	// 	segmento: string, //obrigatorio na hora de criar. Não alterar depois de criado.
	// 	status: number, //obrigatorio na hora de criar.
	// 	origem: number, //obrigatorio na hora de criar. Não alterar depois de criado.
	// 	premio: number,
	// 	comissao: number,
	// 	percComissao: number,
	// 	tarefa: any,
	// 	prazo: string,
	// 	vigIni: string,
	// 	vigFim: string,
	// 	observacao: string,
	// 	clienteId: string //obrigatório
	// }, negocioAnteriorId, negocioProximoId): Promise<Cotacao> {

	// 	try {
	// 		let url = `calculo/negocio?negocioAnteriorId=${negocioAnteriorId || ''}&negocioProximoId=${negocioProximoId || ''}`;
	// 		let ret = await this.apiBase.post(url, negocio);
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }


	// NEGÓCIO USUÁRIO

	/** Adiciona um usuário a um negócio */
	// async salvaNegocioUsuario(negocioUsuario: {
	// 	id: string,
	// 	usuarioId: string,
	// 	gravacao: boolean,
	// 	leitura: boolean,
	// 	versao: boolean,
	// 	calculo: boolean,
	// 	adicaoUsuario: boolean,
	// 	remocaoUsuario: boolean,
	// 	negocioId: string,
	// }): Promise<Cotacao> {
	// 	try {
	// 		let ret = await this.apiBase.post('calculo/negocio/usuario', negocioUsuario);
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	/** Remove um usuário de um negócio  */
	// async deletaNegocioUsuario(negocioId: string, usuarioId: string): Promise<Cotacao> {
	// 	try {
	// 		let ret = await this.apiBase.delete(`calculo/negocio/${negocioId}/usuario/${usuarioId}`, {});
	// 		return ret;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	/** Cria uma cotação e associa a um negócio */
	// async criarCotacaoNegocio(cotacao: Cotacao, negocio?: {
	// 	ramo: number,
	// 	segmento: string,
	// 	status: number,
	// 	origem: number,
	// 	premio?: number,
	// 	comissao?: number,
	// 	percComissao?: number,
	// 	tarefa?: any,
	// 	prazo?: string,
	// 	vigIni?: string,
	// 	vigFim?: string,
	// 	observacao?: string,
	// 	clienteId?: string
	// }): Promise<Cotacao> {
	// 	try {
	// 		let body = { cotacao: cotacao.dados, negocio };
	// 		// console.log(JSON.stringify(body,null,4));    
	// 		let ret = await this.apiBase.post('calculo/cotacao', body);

	// 		let retCotacao = new Cotacao(ret);
	// 		return retCotacao;
	// 	} catch (error) {
	// 		return error;
	// 	}
	// }

	/** Cria uma cotação e associa a um negócio */
	// criarCotacaoNegocioV2(cotacao): Promise<CotacaoResidencial> {
	// 	let body = cotacao;
	// 	return this.apiBase.post('calculo/cotacao', body);
	// }

	/** Salva um evento. */
	// async salvaEvento(evento: {
	// 	id?: string, // ao atualizar
	// 	tipo?: number, //  2 - Anotacao , 3 - Tarefa, 4 - Agendamento
	// 	descricao?: string,
	// 	concluido?: boolean,
	// 	cancelado?: string,
	// 	dataHoraIni?: string,
	// 	dataHorafim?: string,
	// 	negocioId?: string,
	// 	responsavelId?: string,
	// 	eventoAnteriorId?: string, // id do Evento ao qual o Evento sendo salvo retifica.
	// }) {
	// 	try {
	// 		let retEvento = await this.apiBase.post('calculo/evento', evento);
	// 		return retEvento;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }


	async buscaQtdeNegociosMes(dtStartSubscribe) {
		//Funcao para buscar qtde negocios / cotacao improvisada
		let inicioPagamento = this.moment(dtStartSubscribe);
		// let startMonth = this.moment().startOf('month');
		let today = this.moment();
		let diffDays = today.diff(inicioPagamento, 'days');
		if (diffDays == 0) {
			today.add(1, 'day');
			diffDays = today.diff(inicioPagamento, 'days');
		}
		let limit = 210;
		let textoBusca = '';
		let page = 1;		
		let modo = 2;	
		try {
			let authPdocs = await this.apiUsuario.autenticaNoPDocs();
			if(authPdocs?.urlPDocs){			
				this.apiUsuario.setCredenciaisPDocs(authPdocs);
				let url = encodeURI(`${authPdocs.urlPDocs}calculo/negocio/busca?textoBusca=${textoBusca}&modo=${modo}&page=${page}&limit=${limit}&periodo=${diffDays}`);
				try {
					let reqOpts = {
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
							'Authorization': authPdocs.token
						})
					};
					return this.http.get(url, reqOpts).toPromise();				
				} catch (error) {
					throw error;
				}
			}
		} catch (error) {
			console.log('error ao buscar cotações pdcos', error);
			throw error;
		}
	}

	async buscaParcelasPendentes() {
		try {
			let authPdocs = await this.apiUsuario.autenticaNoPDocs();
			if(authPdocs?.urlPDocs){			
				this.apiUsuario.setCredenciaisPDocs(authPdocs);
				let url = encodeURI(`${authPdocs.urlPDocs}cor/getParcelasPendentes`);
				try {
					let reqOpts = {
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
							'Authorization': authPdocs.token
						})
					};
					return this.http.get(url, reqOpts).toPromise();				
				} catch (error) {
					throw error;
				}
			}
		} catch (error) {
			console.log('error ao buscar cotações pdcos', error);
			throw error;
		}
	}

	buscaDocs(page?, dataIni?, dataFim?, periodo?, tipoPeriodo?, seguradora?,renovacao?, textoBusca?, oculto?) {
		page = page || 1;
		textoBusca = textoBusca || ''
		dataIni = dataIni || ''
		dataFim = dataFim || ''
		periodo = periodo || ''
		tipoPeriodo = tipoPeriodo || ''
		seguradora = seguradora || ''
		renovacao = renovacao || ''
		
		let url = `calculo/documentos?page=${page}&dataIni=${dataIni}&dataFim=${dataFim}&periodo=${periodo}&tipoPeriodo=${tipoPeriodo}&seguradora=${seguradora}&renovacao=${renovacao}&textoBusca=${textoBusca}`;
		if(oculto){
			url = url + `&oculto=true`;
		}
        return this.http.get(this.URL + url, this.BS.getHeaders(true)).toPromise();
	}

	buscaDocumento(documentoId){
		return this.http.get(this.URL + `calculo/documento/${documentoId}`, this.BS.getHeaders(true)).toPromise();
	}

	fechamentoNegocio(body){
		return this.http.post(this.URL + 'calculo/fechaNegocio', body, this.BS.getHeaders(true)).toPromise();
	}

	ocultarDocumento(documentoId, status){
		return this.http.post(this.URL + `calculo/documento/${documentoId}/ocultar/${status}`, {}, this.BS.getHeaders(true)).toPromise();
	}


}
