import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetalhesEditarComponent } from './detalhes-editar.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { GerenciadorArquivosModule } from 'src/app/components/gerenciador-arquivos/gerenciador-arquivos.module';
import { ClienteModule } from '../cliente.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { PageModules } from 'src/app/utils/pageModules';
import { MatListModule } from '@angular/material/list';



@NgModule({
  declarations: [DetalhesEditarComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    GerenciadorArquivosModule,
    ClienteModule,
    MatDialogModule,
    MatMenuModule,
    PageModules,
    MatListModule,
  ],
  exports: [DetalhesEditarComponent]
})
export class DetalhesEditarModule { }
