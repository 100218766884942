import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDocsComponent } from './modal-docs.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentoModule } from '../documento/documento.module';

@NgModule({
  declarations: [ModalDocsComponent],
  imports: [
    CommonModule,   
    MatDialogModule,  
    DocumentoModule
  ],
  exports: [ModalDocsComponent]
})
export class ModalDocsModule { }
