import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { PlatformService } from './platform.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class ExtensaoService {

	status;
	statusExtensao: Subject<boolean>;

	constructor(
		public platformService: PlatformService, 
		public alert: MatSnackBar,
		) {
		this.statusExtensao = new BehaviorSubject<boolean>(false);
	}

	getStatusExtensao(): Observable<boolean> {
		return this.statusExtensao.asObservable();
	}

	setStatusExtensao(status: boolean) {
		this.statusExtensao.next(status);
		this.status = status;
	}

	verificaExtensao() {
		if ((this.platformService.deviceService.browser == 'Chrome' || this.platformService.deviceService.browser == 'MS-Edge-Chromium')  && this.platformService.virtualDeviceType == 'desktop' && this.status) {
			return true;
		}
		else if ((this.platformService.deviceService.browser == 'Chrome' || this.platformService.deviceService.browser == 'MS-Edge-Chromium')  && this.platformService.virtualDeviceType == 'desktop' && !this.status) {
			this.alerta("Instale a extensão para melhor proveito do sistema!", "OK");
			return false;
		} else {
			this.alerta("Extensão disponível apenas para o navegador Google Chrome e o Novo Microsoft Edge!", "OK");
			return false;
		}
	}

	alerta(msg, action?, options?) {
		this.alert.open(msg, action, options);
	}

}
