import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDocsAtravesNegocioComponent } from './modal-docs-atraves-negocio.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DocumentoModule } from '../documento/documento.module';



@NgModule({
  declarations: [ModalDocsAtravesNegocioComponent],
  imports: [
    CommonModule,
    MatDialogModule,  
    DocumentoModule
  ],
  exports: [ModalDocsAtravesNegocioComponent]
})
export class ModalDocsAtravesNegocioModule { }
