import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/services/platform.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { ExtensaoService } from 'src/app/services/extensao.service';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { ApiSeguradoraService } from 'src/app/services/api-seguradora.service';
import { ConfSeguradora } from 'src/app/models/conf-seguradora.model';
import { ApiCotacoesService } from 'src/app/services/api-cotacoes.service';
import { Credenciais } from 'src/app/models/credenciais.model';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { MatDialog } from '@angular/material/dialog';
import { SobreComponent } from '../sobre/sobre.component';
import { ControllerStatusService } from 'src/app/services/controller-status.service';
import { ControllerModalsService } from 'src/app/services/controller-modals.service';
import { TodoControllerService } from 'src/app/services/todo-controller.service';
@Component({
	selector: 'perfil-usuario',
	templateUrl: './perfil-usuario.component.html',
	styleUrls: ['./perfil-usuario.component.scss']
})
export class PerfilUsuarioComponent implements OnInit {

	carregando: boolean = false;
	disable: boolean = false;
	loadImagem: boolean = false;
	confsSeguradoras = [];
	confsSeguradorasInv = [];

	statusExtensao: boolean = false;
	subscriptions: Subscription = new Subscription();
	constructor(
		private modalCtrl: ControllerModalsService,
		public apiUsuario: ApiUsuarioService,
		public apiS3: ApiS3Service,
		public platformService: PlatformService,
		public router: Router,
		public apiSeguradora: ApiSeguradoraService,
		public snackBar: MatSnackBar,
		public apiCotacao: ApiCotacoesService,
		public dialog: MatDialog,
		public extensaoService: ExtensaoService,
		public registerIcons: RegisterIconsService,
		private controllerStatus: ControllerStatusService,
		public todoController: TodoControllerService

	) { 
		let icons = ['account-edit', 'exit-to-app', 'city', 'loading', 'information-outline', 'refresh', 'bell-outline', 'bell','briefcase'];
		registerIcons.registerIcons(icons);
		modalCtrl.setModalContext(dialog);
	}

	ngOnInit() {

		this.subscriptions.add(this.extensaoService.getStatusExtensao().subscribe(status => {
			this.statusExtensao = status
		}));

		this.loadAvatar();		
	}

	async loadAvatar(){
		if(!this.apiUsuario.avatar){
			this.apiUsuario.avatar = await this.apiS3.loadAvatarUsuarioLogado();
		}
	}

	abrirPerfil() {	
		this.router.navigateByUrl('/perfil-usuario');
	}

	logout() {	
		this.apiS3.avatarUsuarioLogado = null;
		this.apiUsuario.avatar = null;
		this.router.navigateByUrl('/login');
		localStorage.setItem('pathRefer', '');
	}

	openMatMenu() {
		this.confsSeguradoras = this.controllerStatus.confsSeguradoras;
		this.confsSeguradorasInv = this.controllerStatus.confsSeguradorasInv;

	}

	menuNotOpen:boolean = false;
	openNotificacao() {
		console.log('kkkk')
		if (this.menuNotOpen === false) {
			this.menuNotOpen = true;
		}
		if (this.menuNotOpen === true){
			this.menuNotOpen = false;
		}
	}

	abrirPainelAdmin() {
		this.router.navigateByUrl('/corretoras');
	}

	abrirPainelNotificacoes() {
		this.router.navigateByUrl('/painel-notificacoes');
	}

	async buscaSeguradoras() {
		debugger
		await this.controllerStatus.buscaSeguradoras();
		this.confsSeguradoras = this.controllerStatus.confsSeguradoras;
		this.confsSeguradorasInv = this.controllerStatus.confsSeguradorasInv;

		// this.carregando = true;
		// this.disable = true;
		// this.confsSeguradoras = [];
		// this.confsSeguradorasInv = [];

		// try {
		// 	let _confsSeguradoras:any = await this.apiSeguradora.buscaConfSeguradoras();
			
		// 	for (let configs of _confsSeguradoras) {
		// 		let confSeguradora = new ConfSeguradora(configs);
		// 		// não exibe a Itaú
		// 		if (confSeguradora.seguradoraId != 15) {
		// 			if (confSeguradora && confSeguradora.ativo && confSeguradora.credenciaisValidas) {
		// 				this.confsSeguradoras.push(confSeguradora);
		// 			}
		// 			else if (!confSeguradora.credenciaisValidas && confSeguradora.ativo) {
		// 				this.confsSeguradorasInv.push(confSeguradora);
		// 			}
		// 		}
		// 	}

		// 	this.carregando = false;			
		// 	this.disable = false;

		// } catch (error) {
		// 	console.error("Falha ao buscar configurações de seguradora", error);
		// 	this.carregando = false;
		// }
	}

	async acessaSeguradora(idIntegracao) {


		this.carregando = true;
		try {
			let credenciais: Credenciais | any = await this.apiSeguradora.getCredenciaisSeguradora(idIntegracao);
			await this.apiCotacao.logarSeguradora(credenciais);
			this.todoController.verificarToDo('acesso_rapido_seguradoras');
		} catch (error) {
			let snack = this.snackBar.open('Falha ao buscar seguradora, tente novamente!', '', { duration: 4000 });
		} finally {
			this.carregando = false;
		}		
	}

	instalarExtensao() {

		if (this.platformService.deviceService.browser == 'Chrome' || this.platformService.deviceService.browser == 'MS-Edge-Chromium') {
			window.open("https://chrome.google.com/webstore/detail/%C3%A1pice/hjgppejgngmlkikfobpjnmdhalailonj", "_blank");
		} else {
			console.log(this.platformService.deviceService.browser)
			this.extensaoService.alerta("Extensão disponível apenas para o navegador Google Chrome e o Novo Microsoft Edge!", "OK");
			// this.todoController.verificarToDo('extensao');
		}
	}

	openAbout(){
		let dialog = this.dialog.open(SobreComponent, {
			panelClass: 'modalDefault'
		});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.modalCtrl.clear();
	}


}
