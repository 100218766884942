import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TarefasComponent } from './tarefas.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [TarefasComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    MatTooltipModule
  ],
  exports: [TarefasComponent]
})
export class TarefasFiltroModule { }
