import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteComponent } from './cliente.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { PageModules } from 'src/app/utils/pageModules';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { DebounceModule } from 'src/app/directives/debounce/debounce.module';
import { TextMaskModule } from 'angular2-text-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';



@NgModule({
  declarations: [ClienteComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    PageModules,
    MatSnackBarModule,
    MatDialogModule,
    DebounceModule,
    TextMaskModule,
    MatDatepickerModule,
    MatMomentDateModule
  ],
  exports: [ClienteComponent]
})
export class ClienteModule { }
