import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MessageHandler } from 'src/app/utils/form-field-message-handler';
import { Mask } from 'src/app/utils/masks';
import { UFS } from 'src/app/utils/ufs';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClienteService } from 'src/app/services/api-cliente.service';
import { ApiEnderecoService } from 'src/app/services/api-endereco.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { onChangeDate, openCalendarDatepicker } from 'src/app/utils/moment-config-datepicker';
import { validaEmail, validaCPF, validaCNPJ, validaDate, mandatorio, validaNIF, validaNIPC } from 'src/app/utils/validadores';
import { Formatter } from 'src/app/utils/formatter';
import { ClienteModel } from 'src/app/models/cliente.model';
import { Endereco } from 'src/app/models/endereco.model';
import { TodoControllerService } from 'src/app/services/todo-controller.service';
import { Subscription } from 'rxjs';
import { ConstantPortugal } from '../../constants/constantPortugal';
@Component({
	selector: 'cliente-form',
	templateUrl: './cliente.component.html',
	styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {
	subscriptions = new Subscription();
	@Input('negocio') negocio: boolean;
	@Input('clienteId') clienteId;
	@Output('dadosClienteNegocio') dadosClienteNegocio = new EventEmitter();
	@Output('voltaTelaNegocio') voltaTelaNegocio = new EventEmitter();
	@ViewChild('content') content;

	errorMessage = MessageHandler.errorMessage;
	constantPortugal = ConstantPortugal;
	masksPortugal = ConstantPortugal.maskValidate
	masks = Mask;
	selects = UFS;
	formCliente: FormGroup;
	FormClienteEditar: FormGroup;
	dadosCliente: any;
	idCliente;
	carregando: boolean = false;
	erro: boolean = false
	buscandoCEP: any;
	salvarEditar: boolean = false;
	salvando: any;
	cliente;
	email2: boolean = false;
	email3: boolean = false;
	fone2: boolean = false;
	fone3: boolean = false;
	formsEnderecos = [];
	escondeFormEndereco = true;
	showPessoa: boolean = true;
	rotuloCpfCnpj = 'NIF/NIPC';
	rotuloNomeRazSocial = 'Nome';
	verificaAlterado;
	travaFormularios;
	formFones = [];
	formEmails = [];
	abaSelecionada = 'cliente';
	estadosCivis = [
		{ nome: 'Solteiro(a)', value: 1 },
		{ nome: 'Casado(a)', value: 2 },
		{ nome: 'Separado(a)', value: 3 },
		{ nome: 'Divorciado(a)', value: 4 },
		{ nome: 'Viúvo(a)', value: 5 },
	]
	estados = ConstantPortugal.estados;


	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public apiCliente: ApiClienteService,
		public apiEndereco: ApiEnderecoService,
		public platformService: PlatformService,
		public apiS3: ApiS3Service,
		public snack: MatSnackBar,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<any>,
		public icons: RegisterIconsService,
		public todoController: TodoControllerService,
	) {
		this.preencheFormVazio();
		icons.registerIcons(['close', 'account', 'card-account-details', 'cake-variant', 'check',
							 'calendar', 'ring', 'email', 'close-circle', 'phone', 'map-marker', 'refresh',
							 'loading', 'magnify', 'plus-circle', 'arrow-left']);
	}

	reload() {
		if (this.idCliente) {
			this.buscaCliente();
		}
	}


	ngOnInit() {
		let clienteId = this.clienteId;
		if (clienteId == 'novo') {
			this.idCliente = null;
			this.salvarEditar = false;

		} else {
			this.idCliente = clienteId;
			this.salvarEditar = true;
		}
		this.getData();
		this.subscriptions.add(this.apiCliente.dadosClienteBuscado.asObservable().subscribe(ret=>{
			if(ret){
				let cliente = new ClienteModel().deserialize(ret);
				this.dadosCliente = cliente;
			
				this.formCliente.controls.nome.setValue(cliente.nome);
				this.formCliente.controls.dataNas.setValue(cliente.dataNas ? Formatter.dateDDMMAAAA(cliente.dataNas) : '');
				this.formCliente.controls.sexo.setValue(cliente.sexo || 'M');
				this.formCliente.controls.email.setValue(cliente.email);
				this.formCliente.controls.fone.setValue(cliente.fone || '');
			}
		}))
	}

	shadowHeader: boolean = false;
	observeScroll() {
		this.content.nativeElement.addEventListener('scroll', (e) => {
			let scroll = e.target.scrollTop;
			if (scroll > 10) {
				this.shadowHeader = true;
			} else {
				this.shadowHeader = false;
			}
		})
	}

	preencheFormVazio() {
		let cpfCnpj = this.apiCliente.validaCpfCnpjClienteDisponivel({ exclude: '' });

		this.formCliente = new FormGroup(
			{
				ativo: new FormControl(true, {}),
				nome: new FormControl('', mandatorio),
				rdPessoa: new FormControl('F'),			
				cpfCnpj: new FormControl('', { validators: [validaNIF]/*, asyncValidators: [cpfCnpj] Comentado até fazer a adaptação para NIF/NIPC*/ }),
				dataNas: new FormControl('', { validators: [validaDate] }),
				dataNasInv: new FormControl(''),
				sexo: new FormControl('M'),
				email: new FormControl(''),			
				fone: new FormControl(''),
				whatsApp: new FormControl(false),				
				estadoCivil: new FormControl(''),

			},
			{
				// updateOn: 'blur'
			}
		)		
	}

	sexoBkp = 'M';
	dataNasBkp = '';
	estadoCivelBkp = '';
	//verificar se é pessoa física ou jurídica
	mudaPessoa() {
		let pessoa = this.formCliente.get('rdPessoa');	
		let cpfCnpj = this.formCliente.get('cpfCnpj');
		let dataNas = this.formCliente.get('dataNas');
		let sexo = this.formCliente.get('sexo');
		let estadoCivil = this.formCliente.get('estadoCivil');
		if (pessoa.value == 'F') {
			this.rotuloCpfCnpj = 'NIF/NIPC';
			this.rotuloNomeRazSocial = 'Nome';
			cpfCnpj.setValidators([validaNIF]);
			dataNas.setValue(this.dataNasBkp);
			sexo.setValue(this.sexoBkp);
			estadoCivil.setValue(this.estadoCivelBkp);
			dataNas.setValidators([validaDate]);
		} else {
			this.sexoBkp = sexo.value;
			this.dataNasBkp = dataNas.value;
			this.estadoCivelBkp = estadoCivil.value;
			this.rotuloCpfCnpj = 'NIPC';
			this.rotuloNomeRazSocial = 'Nome';
			cpfCnpj.setValidators([validaNIF]);
			dataNas.setValue('');
			sexo.setValue('');
			estadoCivil.setValue('');
		}
	}

    /**
     * Múda o endereço principal
     * @param indice índice do endereço selecionado no array de endereços
     */
	mudaEnderecoPrincipal(indice) {
		// TODO: adicionar delay no evento de click dos checkboxes
		let fn = () => {
			let index = indice;
			let i = 0;
			let achouPrincipal = false;
			// para cada formulário de endereço
			for (let formEnd of this.formsEnderecos) {
				// se o formulário de endereço for o selecionado
				if (i == index) {
					achouPrincipal = true;
					// marca como principal
					formEnd.formgroup.controls.principal.setValue(true);
					formEnd.alterado = true;
				}
				// se não for o formulário de endereço selecionado
				else {
					// marca como não sendo principal
					formEnd.formgroup.controls.principal.setValue(false);
					formEnd.alterado = true;
				}
				i++;
			}
			// se não achou principal marca o primeiro
			if (!achouPrincipal && this.formsEnderecos.length > 0) {
				this.formsEnderecos[0].formgroup.get('principal').setValue(true);
			}
		}
		setTimeout(fn, 500);
	}

	async salvarCliente(): Promise<any> {

		this.salvando = true;
		let formData = this.formCliente.getRawValue();
		let arrayEnderecos: any[] = [];

		for (let enderecos of this.formsEnderecos) {

			if (enderecos.alterado) {
				let endereco = enderecos.formgroup.getRawValue();
				endereco.uf = endereco.uf ? endereco.uf.toUpperCase() : endereco.uf;
				let obj = {
					id: endereco.id,
					cep: endereco.cep ? endereco.cep.trim() : '',
					cidade: endereco.residCidade ? endereco.residCidade.trim() : '',
					uf: endereco.residUF ? endereco.residUF.trim() : '',
					logradouro: endereco.residLogradouro ? endereco.residLogradouro.trim() : '',
					bairro: endereco.residBairro ? endereco.residBairro.trim() : '',
					numero: endereco.residNumero ? endereco.residNumero.trim() : '',
					complemento: endereco.residComplemento ? endereco.residComplemento.trim() : '',
					principal: endereco.principal,
					alterado: endereco.alterado
				}
				arrayEnderecos.push(obj);
			}
		}

		let arrayTelefone: any[] = [];
		for (let telefones of this.formFones) {
			let telefone = telefones.formgroup.getRawValue();
			let obj = {
				numero: telefone.numero != '' ? telefone.numero.replace(/\D/g, '') : '',
				principal: false,
				tipo: 'alternativos'
			}
			arrayTelefone.push(obj);
		}

		if (formData.whatsApp) {
			let obj = {
				numero: formData.fone != '' ? formData.fone.replace(/\D/g, '') : '',
				principal: true,
				tipo: 'whatsApp'
			}
			arrayTelefone.push(obj);
		}

		let arrayEmail: any[] = [];
		for (let emails of this.formEmails) {
			let e = emails.formgroup.getRawValue();

			let obj = {
				email: e.email,
				principal: false
			}
			arrayEmail.push(obj);
		}

		let cliente = new ClienteModel();

		let cpfCnpj = formData.cpfCnpj != '' ? formData.cpfCnpj.replace(/\D/g, '') : undefined;
		let data = formData.dataNas != '' ? Formatter.dateAAAAMMDD(formData.dataNas) : '';
		let fone = formData.fone != '' ? formData.fone.replace(/\D/g, '') : '';	
		cliente.ativo = formData.ativo;
		cliente.id = this.idCliente;
		cliente.nome = formData.nome;
		cliente.cpfCnpj = cpfCnpj;
		cliente.dataNas = data;
		cliente.sexo = formData.sexo;
		cliente.email = formData.email ? formData.email : undefined;	
		cliente.ddd = formData.ddd;
		cliente.fone = fone;
		cliente.cidade = formData.residCidade;
		cliente.uf = formData.residUF;
		cliente.logradouro = formData.residLogradouro;
		cliente.bairro = formData.residBairro;
		cliente.numero = formData.residNumero;
		cliente.complemento = formData.residComplemento;
		cliente.estadoCivil = formData.estadoCivil;	
		cliente.enderecosSalvar = arrayEnderecos.length > 0 ? arrayEnderecos : [];
		cliente.tipoPessoa = formData.rdPessoa;
		cliente.fones = arrayTelefone;
		cliente.emails = arrayEmail;

		try {
			let resp = await this.apiCliente.salvarClientes(cliente);			
			if (this.negocio) {
				this.dadosClienteNegocio.emit(resp);
			} else {
				this.dialogRef.close(resp?.id);
			}
			this.apiCliente.updateClienteModal = true;
			this.todoController.verificarToDo('clientes');
		} catch (error) {
			this.snack.open('Falha ao salvar cliente.', 'OK', {duration: 6000});
			console.error('Erro ao salvar um cliente', error);
		}
		this.salvando = false;
	}

	voltarTelaNegocio() {
		this.voltaTelaNegocio.emit(true);
	}

	dadosCopiaEndereco = [];
	async buscaCliente() {
		this.carregando = true;

		if (this.idCliente) {

			try {
				let ret = await this.apiCliente.buscaCliente(this.idCliente);
				let cliente = new ClienteModel().deserialize(ret);

				let cpfCnpj = cliente.cpfCnpj;
				let asyncValidatorCpfCnpj = this.apiCliente.validaCpfCnpjClienteDisponivel({ exclude: cpfCnpj });
				
				this.dadosCliente = cliente;

				this.formCliente.controls.rdPessoa.setValue(cliente.tipoPessoa);
				this.formCliente.controls.ativo.setValue(!!cliente.ativo);
				this.formCliente.controls.nome.setValue(cliente.nome);
				this.formCliente.controls.cpfCnpj.setAsyncValidators([asyncValidatorCpfCnpj]);
				this.formCliente.controls.cpfCnpj.setValue(cpfCnpj || '');
				this.formCliente.controls.dataNas.setValue(cliente.dataNas ? Formatter.dateDDMMAAAA(cliente.dataNas) : '');
				this.formCliente.controls.sexo.setValue(cliente.sexo || 'M');
				this.formCliente.controls.estadoCivil.setValue(cliente.estadoCivil);
				this.formCliente.controls.email.setValue(cliente.email);
				this.formCliente.controls.fone.setValue(cliente.fone || '');

				if (cliente.tipoPessoa && cliente.tipoPessoa == 'J') {
					this.rotuloCpfCnpj = 'NIF/NIPC';
					this.rotuloNomeRazSocial = 'Nome';
					this.formCliente.controls.cpfCnpj.setValidators([validaNIF]);
				} else {
					this.rotuloCpfCnpj = 'NIF/NIPC';
					this.rotuloNomeRazSocial = 'Nome';
					this.formCliente.controls.cpfCnpj.setValidators([validaNIF]);
				}			

				// Busca endereços preenchidos 
				for (let enderecos of cliente.enderecos) {
					let end = new Endereco();				
					end.setData(enderecos);

					let endereco = new FormGroup({
						id: new FormControl(end.id),
						cep: new FormControl(end.cep || ''),
						residCidade: new FormControl(end.cidade),
						residUF: new FormControl(end.uf),
						residLogradouro: new FormControl(end.logradouro),
						residBairro: new FormControl(end.bairro),
						residNumero: new FormControl(end.numero),
						residComplemento: new FormControl(end.complemento),
						principal: new FormControl(end.principal)						
					})

					let obj = { formgroup: endereco, alterado: false, habilitarFuncao: true }

					this.formsEnderecos.push(obj);
					const copia = JSON.parse(JSON.stringify(end.dados));
					this.dadosCopiaEndereco.push(copia);
				}

				if (cliente.enderecos.length > 0) {
					this.verificaAlterado = true;
					const copy = this.formsEnderecos;

				}

				this.escondeFormEndereco = cliente.enderecos.length > 0 ? false : true;

				// Busca e-mails Alternativos preenchidos

				for (let emails of cliente.emails) {
					if (!emails.principal == true) {
						let e = new FormGroup({
							email: new FormControl(emails.email || ''),
							principal: new FormControl(emails.principal)
						})

						let obj = { formgroup: e }

						this.formEmails.push(obj);
					}
				}


				// Busca telefones Alternativos preenchidos
				for (let telefone of cliente.fones) {
					if (!telefone.principal) {
						let formTelefone = new FormGroup({
							numero: new FormControl(telefone.numero || ''),
							principal: new FormControl(telefone.principal)
						})
						let obj = { formgroup: formTelefone };
						this.formFones.push(obj);
					}
					if (telefone.principal) {
						this.formCliente.get('whatsApp').setValue(true);
					}
				}

				if (cliente.cpfCnpj) {
					this.formCliente.controls.cpfCnpj.setValue(cliente.cpfCnpj || '');
				}

				if (cliente.dataNas) {
					this.formCliente.get('dataNas').setValue((Formatter.dateDDMMAAAA(cliente.dataNas) || ''));
				}

				if (cliente.fone) {
					this.formCliente.get('fone').setValue(cliente.fone || '');
				}

				if (cliente.cep) {
					this.formCliente.get('cep').setValue(this.createCepMask(cliente.cep) || '');
				}
				this.carregando = false;

				setTimeout(() => {
					this.observeScroll();
				})
			} catch (error) {
				// console.log('error', error);
				this.carregando = false;
			}

		} else {
			this.preencheFormVazio();
			this.carregando = false;
			let asyncValidatorCpfCnpj = this.apiCliente.validaCpfCnpjClienteDisponivel();
			this.formCliente.controls.cpfCnpj.setAsyncValidators([asyncValidatorCpfCnpj]);
		}

	};

	//buscarCEP
	async buscaCEP(formEnd) {
		let form = formEnd;
		if (form) {		
			if (form.formgroup.get('cep').valid) {
				this.buscandoCEP = true;
				let cep = form.formgroup.get('cep').value;				

				try {
					let result:any = await this.apiEndereco.buscaEndereco(cep);
					let endereco = result && result.length ? this.apiEndereco.decodeEndereco(result[0]) : null;
					if (endereco) {
						let { logradouro, bairro, cidade, uf } = endereco;
						form.formgroup.get('residLogradouro').setValue(logradouro || '');
						form.formgroup.get('residBairro').setValue(bairro || '');
						form.formgroup.get('residCidade').setValue(cidade || '');
						form.formgroup.get('residUF').setValue(uf || '');
					}
				} catch (error) {
					console.log('erro', error);
				}
				this.buscandoCEP = false;
			}
		}
	}

	createCepMask(cep) {
		return Formatter.formatCEP(cep);
	}

	fecharPagina() {
		this.router.navigateByUrl('/clientes');
	}

	addEmail() {
		if (!this.email2) {
			this.formCliente.addControl('email2', new FormControl('', validaEmail));
			this.email2 = true;
		} else if (this.email2 && !this.email3) {
			this.formCliente.addControl('email3', new FormControl('', validaEmail));
			this.email3 = true;
		}
	}	

	removeFone(valor) {
		if (valor == 2) {
			this.fone2 = false;
			this.formCliente.removeControl('fone2');
		}

		if (valor == 3) {
			this.fone3 = false;
			this.formCliente.removeControl('fone3')
		}
	}

	criaFones() {
		let fones = new FormGroup({
			numero: new FormControl(''),
			principal: new FormControl(''),
			tipo: new FormControl('')
		})
		let obj = { formgroup: fones }
		this.formFones.push(obj);	
	}

	criaEmails() {
		let emailFormGroup = new FormGroup({
			email: new FormControl(''),
			principal: new FormControl('')
		})
		let formEmail = { formgroup: emailFormGroup }
		this.formEmails.push(formEmail);	
	}

	criarEndereco() {
		this.escondeFormEndereco = false;

		let endereco = new FormGroup({
			id: new FormControl(''),
			cep: new FormControl(''),
			residCidade: new FormControl(''),
			residUF: new FormControl(''),
			residLogradouro: new FormControl(''),
			residBairro: new FormControl(''),
			residNumero: new FormControl(''),
			residComplemento: new FormControl(''),
			principal: new FormControl(false),
			alterado: new FormControl(false)

		})

		let obj = { formgroup: endereco, alterado: true, habilitarFuncao: false }
		this.formsEnderecos.push(obj);		

		// verifica se existe endereço principal
		let achouPrincipal = false;
		for (let formEnd of this.formsEnderecos) {
			if (formEnd.formgroup.get('principal').value) {
				achouPrincipal = true;
			}
		}

		if (!achouPrincipal && this.formsEnderecos.length > 0) {
			this.formsEnderecos[0].formgroup.get('principal').setValue(true);
		}
	}

	async removeEndereco(index, formulario) {
		let i = index;
		let form = formulario;
		form.removendoEndereco = false;
		let id = form.formgroup.get('id').value;

		let marcaPrincipal = () => {
			// se endereço removido for o principal
			if (form.formgroup.get('principal').value) {
				// se houver mais algum endereço
				if (this.formsEnderecos.length > 0) {
					// marca o primeiro endereço como principal
					this.formsEnderecos[0].formgroup.get('principal').setValue(true);				
					this.formsEnderecos[0].alterado = true;
				}
			}
		}

		try {
			let resp = await this.apiCliente.deletaEndereco(id);			
			await this.formsEnderecos.splice(i, 1);		
			marcaPrincipal();
		} catch (error) {
			this.snack.open('Falha ao remover endereço.', 'OK', {duration: 6000});
			console.log('Erro ao deletar um endereco', error);
		}
		form.removendoEndereco = false;
	}

	removeTelefone(index) {
		let i = index;
		this.formFones.splice(i, 1);
	}

	removeEmail(index) {
		let i = index;
		this.formEmails.splice(i, 1);
	}

	//quebra de linha para options se preciso
	optionLargura = {
		cssClass: 'selectLarguraOption'
	}

	VerificaCampoEditado(formulario, index) {
		
		let i = index;
		let form = formulario;

		if (form.formgroup.get('residLogradouro').value != this.dadosCopiaEndereco[i].logradouro) {
			form.alterado = true;
		}

		if (form.formgroup.get('residNumero').value != this.dadosCopiaEndereco[i].numero) {
			form.alterado = true;
		}

		if (form.formgroup.get('residComplemento').value != this.dadosCopiaEndereco[i].complemento) {
			form.alterado = true;
		}

		if (form.formgroup.get('residBairro').value != this.dadosCopiaEndereco[i].bairro) {
			form.alterado = true;
		}

		if (form.formgroup.get('residCidade').value != this.dadosCopiaEndereco[i].cidade) {
			form.alterado = true;
		}

		if (form.formgroup.get('residUF').value != this.dadosCopiaEndereco[i].uf) {
			form.alterado = true;			
		}

		if (form.formgroup.get('principal').value != this.dadosCopiaEndereco[i].principal) {
			form.alterado = true;			
		}

	}	

	async getData() {
		if (this.idCliente) {
			this.buscaCliente();
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	compareFn(o1, o2) {		
		return o1 == o2;
	}

	onChange = onChangeDate;
	openCalendar = openCalendarDatepicker;

}
