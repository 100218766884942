import { SeguradorasStatus } from './seguradoras/entities/status.model';

export class ServicesInfosBase {

  calculos: {
    auto: {
      car: SeguradorasStatus,
      moto: SeguradorasStatus,
      truck: SeguradorasStatus
    },
    pacote: {
      residencial: SeguradorasStatus,
      empresarial: SeguradorasStatus
    },
    vida: SeguradorasStatus
  }
  buscarDocumentos: {
    auto: SeguradorasStatus
  }
  parcelasPendentes: SeguradorasStatus
  acompanhamento: SeguradorasStatus

  constructor() {

    this.calculos = {
      auto: {
        car: new SeguradorasStatus(),
        moto: new SeguradorasStatus(),
        truck: new SeguradorasStatus()
      },
      pacote: {
        residencial: new SeguradorasStatus(),
        empresarial: new SeguradorasStatus()
      },
      vida: new SeguradorasStatus()
    }

    this.buscarDocumentos = {
      auto: new SeguradorasStatus()
    }

    this.parcelasPendentes = new SeguradorasStatus()
    this.acompanhamento = new SeguradorasStatus()

  }
  
}
