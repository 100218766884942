import { Component, OnInit, Inject } from '@angular/core';

import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
})
export class DialogConfirmComponent implements OnInit {

  status: number = 3;
  observacaoStatus: String = null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private registerIcons: RegisterIconsService, public dialogRef: MatDialogRef<any>) {
    let icons = ['close'];
		registerIcons.registerIcons(icons)
    this.status = data ? data.status : 3;
  }

  ngOnInit() {}

  confirmar(){
    this.dialogRef.close({tipo: 'confirmar', observacaoStatus: this.observacaoStatus});
  }  

}
