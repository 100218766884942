import { Injectable } from '@angular/core';
// import { Gtag } from 'angular-gtag';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GAnalyticsService {

  private ENV: any = environment;

  constructor(/*private gtag: Gtag*/) { }

  analyticsPageMonitor(title, page) {
    // if (this.ENV.production) {
    //   this.gtag.pageview({
    //     page_title: title,
    //     page_path: page
    //   })

    // }

  }

  analyticsMonitor(type, category, label) {
    // if (this.ENV.production) {
    //   this.gtag.event(type, {
    //     event_category: category,
    //     event_label: label
    //   })
      
    // }

  }

}
