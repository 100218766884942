import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-file-upload-multi',
	templateUrl: './file-upload-multi.component.html',
	styleUrls: ['./file-upload-multi.component.scss']
})
export class FileUploadMultiComponent {

	@Input('anexo') anexo;

	@Output('onEnterEdition') onEnterEdition = new EventEmitter();
	@Output('onSaveEdition') onSaveEdition = new EventEmitter();
	@Output('onCancelEdition') onCancelEdition = new EventEmitter();
	@Output('onRemove') onRemove = new EventEmitter();
	@Output('onError') onError = new EventEmitter();

	constructor() {

	}

	enterEdition() {
		if (this.anexo) {
			this.anexo.editMode = true;
			this.anexo.tempName = this.anexo.fileName;
		}
		this.onEnterEdition.emit('this.anexo');
	}

	saveEdition() {
		if (this.anexo) {
			this.anexo.editMode = false;
			this.anexo.fileName = this.anexo.tempName;
		}
		this.onSaveEdition.emit('this.anexo');
	}

	cancelEdition() {
		if (this.anexo) {
			this.anexo.editMode = false;
		}
		this.onCancelEdition.emit('this.anexo');
	}

	remove() {
		this.onRemove.emit('this.anexo');
	}

}
