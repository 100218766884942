import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { MenusService } from 'src/app/services/menus.service';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';

@Component({
	selector: 'filtro',
	templateUrl: './filtro.component.html',
	styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent implements OnInit, OnDestroy {

	@Input('page') page: any;
	numFilter = 0;
	listaUsuarios = [];

	constructor(private menusService: MenusService,private icons: RegisterIconsService, private apiUsuario: ApiUsuarioService, private apiS3: ApiS3Service,
				public filtroController: FiltroControllerService) {
		icons.registerIcons(['filter', 'filter-remove', 'close']);
	}

	ngOnInit(): void {
		setTimeout(()=>{this.getUsuarios()})
	}	

	async getUsuarios() {		
		try {		
			let usuarios:any;	
			if (this.apiUsuario.listaUsuarios.length){
				usuarios = this.apiUsuario.listaUsuarios;
			}else{
				usuarios = await this.apiUsuario.buscaUsuarios2();		
				this.apiUsuario.listaUsuarios = usuarios;
			}

			let userSessao = this.apiUsuario.getCredenciais();
			usuarios = usuarios.filter(u => u.ativo == true && u.confirmacao == false && u.tipoRedefinicaoSenha != 'novo');

			for (let user of usuarios) {
				if (!this.apiUsuario.avatares[user.id]) {
					this.apiUsuario.avatares[user.id] = await this.getAvatarUsuario(user.id);
				}
				user['img'] = this.apiUsuario.avatares[user.id];

				if (userSessao && userSessao == user.id) {
					user['selectedDefault'] = true;
				}
			}

			
			this.listaUsuarios = usuarios;
		} catch (error) {
			console.log('error buscar lista usuários', error);
		}
	}

	async getAvatarUsuario(usuarioId) {
		try {
			let ret = await this.apiS3.getAvatarUsuario(usuarioId);
			return ret
		} catch (error) {
			return ''
		}
	}

	closeFilter() {
		this.menusService.open = false;
		this.menusService.setMenuFilter({ open: false, page: this.page })
	}

	sendFilter(ev) {
		let objEnvio = {
			page: this.page,
			filtro: ev
		};
		
		this.menusService.enviaFiltro(objEnvio);
		this.closeFilter();
	}

	clearFilter() {
		this.numFilter = 0;
	}

	ngOnDestroy(): void {
		/** FiltroComponent */
		this.menusService.open = false;
		this.menusService.setMenuFilter({ open: false, page: '' })
	}

}
