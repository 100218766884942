import { Formatter } from '../utils/formatter';

export class ClienteModel {

    ativo: boolean;
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string;
    cpfCnpj: string;
    createdAt: string;
    dataNas: string;
    dataNasc: string;
    dataPrimHabil: string;
    ddd: string;
    email: string;
    emails: Array<any>;
    enderecos: Array<any>;
    enderecosSalvar: Array<any>;
    estadoCivil: string;
    favorito: boolean;
    fone: boolean;
    fones: Array<any>;   
    id: string;
    lembrete: any;
    logradouro: string;
    nome: string;
    numero: any;
    observacao: any;
    sexo: string;
    tipoPessoa: string;
    uf: string;
    updatedAt: string;
    usuarioCriadorId: string;
    usuarioEditorId: string;

    
    isFavorito(){
        if(this.favorito) {
			return "Favorito";
		}
		else {
			return "Não Favorito";
		}
    }

    getIconFavorito(){
        if(this.favorito) {
			return "star";
		}
		else {
			return "star-outline";
		}
    }

    getFone(){
        return this.fone ? Formatter.formatFone(this.fone) : '';
    }

    getEndPrincipal(){
        let endPrincipal = this.enderecos?.filter(e => e.princiapl)  || [];
        return endPrincipal.length ? endPrincipal[0].cidade + ' (' +  endPrincipal[0].uf + ')' : '';
    }

    getWhatsApp(){
        let whatsApp = this.fones?.filter( f => f.tipo == 'whatsApp' ) || [];
        return whatsApp.length ? whatsApp[0].numero : '';
    }

    deserialize(input: any): this {        
        Object.assign(this, input);
        return this;
    }

}

export class ClienteModelForm {

    ativo: boolean;
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string;
    cpfCnpj: string;
    createdAt: string;
    dataNas: string;
    dataNasc: string;
    dataPrimHabil: string;
    ddd: string;
    email: string;
    emails: Array<any>;
    enderecos: Array<any>;
    enderecosSalvar: Array<any>;
    estadoCivil: any;
    favorito: boolean;
    fone: boolean;
    fones: Array<any>;   
    id: string;
    lembrete: any;
    logradouro: string;
    nome: string;
    numero: any;
    observacao: any;
    sexo: string;
    tipoPessoa: string;
    uf: string;
    updatedAt: string;
    usuarioCriadorId: string;
    usuarioEditorId: string;    
    
    isFavorito(){
        if(this.favorito) {
			return "Favorito";
		}
		else {
			return "Não Favorito";
		}
    }

    getIconFavorito(){
        if(this.favorito) {
			return "star";
		}
		else {
			return "star-outline";
		}
    }

    getFone(){
        return this.fone ? Formatter.formatFone(this.fone) : '';
    }

    getEndPrincipal(){
        let endPrincipal = this.enderecos?.filter(e => e.princiapl)  || [];
        return endPrincipal.length ? endPrincipal[0].cidade + ' (' +  endPrincipal[0].uf + ')' : '';
    }

    getWhatsApp(){
        let whatsApp = this.fones?.filter( f => f.tipo == 'whatsApp' ) || [];
        return whatsApp.length ? whatsApp[0].numero : '';
    }

    deserialize(input: any): this {        
        Object.assign(this, input);
        this.dataNasc = this.dataNas;
        this.estadoCivil = this.estadoCivil ? parseInt(this.estadoCivil) : this.estadoCivil;
        return this;
    }

}