import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfilUsuarioComponent } from './perfil-usuario.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { SobreComponentModule } from '../sobre/sobre.module';

@NgModule({
  declarations: [PerfilUsuarioComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    SobreComponentModule
  ],
  exports: [PerfilUsuarioComponent]
})
export class PerfilUsuarioModule { }
