import { Component, OnInit } from '@angular/core';
import { MenusService } from 'src/app/services/menus.service';

@Component({
	selector: 'menu-hamburger',
	templateUrl: './menu-hamburger.component.html',
	styleUrls: ['./menu-hamburger.component.scss']
})
export class MenuHamburgerComponent implements OnInit {

	toggleMenu: boolean = false;
	constructor(private stateToggleMenu: MenusService) { }

	ngOnInit() {
		this.stateToggleMenu.toggleMenu().subscribe(ret => {
			this.toggleMenu = ret;
		})
	}

	toggle() {
		//console.log('toggle')
		this.toggleMenu = !this.toggleMenu;
		this.stateToggleMenu.setToggleMenu(this.toggleMenu)
	}

}
