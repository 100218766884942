import { FormGroup, FormControl } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Mask } from 'src/app/utils/masks';
import { onChangeDate, openCalendarDatepicker } from 'src/app/utils/moment-config-datepicker';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';
import { getIiconeSeguradora } from 'src/app/utils/FormatterSeguradoras';

@Component({
  selector: 'filtro-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss']
})
export class DocumentosComponent implements OnInit {

  masks = Mask;


  
  seguradora:any = this.filtroController.filtroDocumentos?.seguradora ? this.filtroController.filtroDocumentos?.seguradora :  0;
  periodo:any = this.filtroController.filtroDocumentos?.periodo ? this.filtroController.filtroDocumentos?.periodo : 'I';
  tipoPeriodo:any = this.filtroController.filtroDocumentos?.tipoPeriodo ? this.filtroController.filtroDocumentos?.tipoPeriodo : 'E';
  vencidas:any = this.filtroController.filtroDocumentos?.renovacao ? this.filtroController.filtroDocumentos?.renovacao : 0;

  fg_datas:FormGroup = new FormGroup({
    dataIni: new FormControl(this.filtroController.filtroDocumentos?.dataIni ? this.filtroController.filtroDocumentos?.dataIni : ''),
    dataIniInv: new FormControl(''),
    dataFim: new FormControl(this.filtroController.filtroDocumentos?.dataFim ? this.filtroController.filtroDocumentos?.dataFim :''),
    dataFimInv: new FormControl('')
  });

  seguradoras:any = [
    {
      nome: 'Todas',
      value: 0,
      id: ''
    },
    {
      nome: 'Aliro',
      value: 22,
      id: ''
    },
    {
      nome: 'Allianz',
      value: 5,
      id: ''
    },
    {
      nome: 'Azul',
      value: 10,
      id: ''
    },
    {
      nome: 'Bradesco',
      value: 1,
      id: ''
    },
    {
      nome: 'HDI',
      value: 4,
      id: ''
    },
    {
      nome: 'Liberty',
      value: 12,
      id: ''
    },
    {
      nome: 'Mapfre',
      value: 3,
      id: ''
    },
    {
      nome: 'Mitsui',
      value: 13,
      id: ''
    },
    {
      nome: 'Porto',
      value: 8,
      id: ''
    },
    {
      nome: 'Sompo',
      value: 6,
      id: ''
    },
    {
      nome: 'Sul América',
      value: 17,
      id: ''
    },
    {
      nome: 'Tokio',
      value: 11,
      id: ''
    },
    {
      nome: 'Zurich',
      value: 7,
      id: ''
    }
  ]

  openCalendar = openCalendarDatepicker;
  onChange = onChangeDate;

  
  @Output('eventFilter') eventFilter = new EventEmitter();
  @Output('clearFilter') limpaFiltro = new EventEmitter();

  constructor(
    public filtroController: FiltroControllerService,
    private icons: RegisterIconsService,
  ) { 
    icons.registerIcons(['calendar']);
  }

  ngOnInit(): void {
    this.getImagesSeguradoras();
    this.verificaPeriodo();
  }

  getImagesSeguradoras() {
    this.seguradoras.forEach(e => {
      e.img = getIiconeSeguradora(e.value);
    });
  }


  buildString(oculto?:boolean) {
    let jsonEnvio = {
      dataIni: this.fg_datas.controls.dataIni.value ? this.formatarData(this.fg_datas.controls.dataIni.value) : '',
      dataFim: this.fg_datas.controls.dataFim.value ? this.formatarData(this.fg_datas.controls.dataFim.value) : '',
      seguradora: this.seguradora ? this.seguradora : '',
      periodo: this.periodo === 'p' || this.periodo === 'I' ? '' : this.periodo,
      periodoInv: this.periodo,
      tipoPeriodo: this.tipoPeriodo ? this.tipoPeriodo : '',
      renovacao: this.vencidas != 0 ? this.vencidas : ''
    }
    if(jsonEnvio.periodoInv != 'p') {
      jsonEnvio.dataIni = "";
      jsonEnvio.dataFim = "";
    }
    if(oculto){
      jsonEnvio['oculto'] = true;
    }
    
    let filtros = { values: jsonEnvio }
    this.filtroController.addFiltrosExtras('documentos', filtros);
    this.eventFilter.emit(filtros)

  }

  formatarData(data) {
    if(data.match("/")) {
      return data;
    } else {
      let dataArr = data.split('');
      console.log(dataArr)
      let formatData = `${dataArr[0]}${dataArr[1]}/${dataArr[2]}${dataArr[3]}/${dataArr[4]}${dataArr[5]}${dataArr[6]}${dataArr[7]}`;
      return formatData;
    }
  }

  clearFilter() {
    this.fg_datas = new FormGroup({
      dataIni: new FormControl(''),
      dataIniInv: new FormControl(''),
      dataFim: new FormControl(''),
      dataFimInv: new FormControl('')
    });
    this.seguradora = 0;
    this.periodo = 'I';
    this.tipoPeriodo = 'E';
    this.vencidas = 0;
    this.showDatas = false;
    this.filtroController.limparFiltro('documentos');
    this.limpaFiltro.emit();
    this.filtroController.verificaFiltro();
    this.buildString();
  }

  showDatas:boolean = false;
  verificaPeriodo(e?) {
    if(e) {
      this.showDatas = e.value === 'p' ? true : false;
    } else {
      this.showDatas = this.periodo === 'p' ? true : false;
    }
  }

  verificaVencimentos(e) {
    if(e.value != '0') {
      this.tipoPeriodo = 'V';
      this.periodo = 'I';
    } else {
      this.tipoPeriodo = 'E';
    }
  }

  getSeguradora(e) {
    this.seguradora = e.value;
  }

  aVencer() {
    this.fg_datas = new FormGroup({
      dataIni: new FormControl(''),
      dataIniInv: new FormControl(''),
      dataFim: new FormControl(''),
      dataFimInv: new FormControl('')
    });
    this.seguradora = 0;
    this.periodo = '';
    this.tipoPeriodo = 'V';
    this.vencidas = '15';
    this.showDatas = false;
    this.buildString();
  }

  vencidas7() {
    this.fg_datas = new FormGroup({
      dataIni: new FormControl(''),
      dataIniInv: new FormControl(''),
      dataFim: new FormControl(''),
      dataFimInv: new FormControl('')
    });
    this.seguradora = 0;
    this.periodo = '7';
    this.tipoPeriodo = 'V';
    this.vencidas = 'V7';
    this.showDatas = false;
    this.buildString();
  }

  ocultos(){
    this.fg_datas = new FormGroup({
      dataIni: new FormControl(''),
      dataIniInv: new FormControl(''),
      dataFim: new FormControl(''),
      dataFimInv: new FormControl('')
    });
    this.seguradora = 0;
    this.periodo = '';
    this.tipoPeriodo = '';
    this.vencidas = '';
    this.showDatas = false;
    this.buildString(true);
  }

}
