import { Injectable, ɵConsole } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, EMPTY, of, BehaviorSubject } from 'rxjs';
import { ApiUsuarioService } from './api-usuario.service';
import { environment } from 'src/environments/environment';
import { switchMap, take, filter, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ControllerModalsService } from './controller-modals.service';

@Injectable({
	providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

	private ENV = environment;
	isRefreshToken: boolean = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private apiUsuario: ApiUsuarioService,
		private modalCtrl: ControllerModalsService,
		private router: Router,
		public snackBar: MatSnackBar) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.headers.get('check')) {
			let credenciais = this.apiUsuario.getCredenciais();
			let newHeaders: any = req.headers;
			newHeaders.headers.delete('check');
			let token;
			let currentTime = new Date().getTime();
			let tempoAteExpirar = credenciais.expires - currentTime;
			// verifica se o token precisa ser atualizado (menos de um minuto para a expiração do token)
			if (tempoAteExpirar < this.ENV.apice.tempoReautenticacao && !this.isRefreshToken && !credenciais.usuarioQueLogou) {
				let _credenciais = this.apiUsuario.getCredenciaisCrypto();
				return this.refreshToken(req, _credenciais, next);
			} else if (this.isRefreshToken && tempoAteExpirar < this.ENV.apice.tempoReautenticacao && !credenciais.usuarioQueLogou) {
				// console.log('request await');
				return this.refreshTokenSubject.pipe(
					filter(token => token != null),
					take(1),
					switchMap(jwt => {
						let newHeaders: any = req.headers;
						newHeaders.headers.delete('check');
						newHeaders = newHeaders.append('Authorization', jwt);
						const authReq = req.clone({ headers: newHeaders });
						return next.handle(authReq);
					}));
			} else {
				token = credenciais.token;
				newHeaders = newHeaders.append('Authorization', token);
				const authReq = req.clone({ headers: newHeaders });
				return next.handle(authReq);
			}

		} else {
			return next.handle(req)
		}
	}

	private refreshToken(request: HttpRequest<any>, credenciais, next: HttpHandler): any {
		this.isRefreshToken = true;
		this.refreshTokenSubject.next(null);
		let body = { email: credenciais.email, senha: credenciais.senha, tokenAtual: credenciais.token };

		return this.apiUsuario.refreshToken(body).pipe(
			switchMap((authResponse: any) => {
				let auth = authResponse;
				if (auth?.message) {
					setTimeout(() => {
						this.modalCtrl.closeAllModals();
						this.router.navigateByUrl('/login');
					}, 1500)
					this.mostraMensagem(auth.message);
					this.apiUsuario.forceRefresh = false;
					this.refreshTokenSubject.next(null);
					this.isRefreshToken = false;
					return EMPTY
				} else {
					auth['senha'] = credenciais.senha;
					this.apiUsuario.armazenaLogin(auth);
					let newHeaders: any = request.headers;
					newHeaders.headers.delete('check');
					newHeaders = newHeaders.append('Authorization', auth.token);
					this.refreshTokenSubject.next(auth.token);
					const authReq = request.clone({ headers: newHeaders });
					this.isRefreshToken = false;
					let validacao: any = this.apiUsuario.verificaLicenca();
					this.apiUsuario.forceRefresh = false;
					if (validacao?.prazo < 0 && !validacao.periodoTeste) {
						this.router.navigateByUrl('/dashboard').finally(() => {
							window.location.reload();
						});

						return EMPTY;
					}

					return next.handle(authReq);
				}

			}),
			catchError(this.handleError('refreshToken', [], next))
		);
	}

	private handleError<T>(operation = 'operation', result?: T, next?: any) {
		return (error: any): Observable<T> => {
			setTimeout(() => {
				this.modalCtrl.closeAllModals();
			}, 1000);
			this.refreshTokenSubject.next(null);
			this.isRefreshToken = false;
			this.router.navigateByUrl('/login');
			return next.handle(EMPTY);
		};
	}

	mostraMensagem(mensagem: string) {
		this.snackBar.open(mensagem, 'Ok', {
			duration: 6000,
		});
	}

}
