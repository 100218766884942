import { MatFormFieldModule } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentosComponent } from './documentos.component';
import { PageModules } from 'src/app/utils/pageModules';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';

@NgModule({
  declarations: [DocumentosComponent],
  imports: [
    CommonModule,
    PageModules,
    ModulosDeFormulario,
    MatFormFieldModule
  ],
  exports: [DocumentosComponent]
})
export class DocumentosModule { }
