import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';

@Component({
  selector: 'filtro-cotacoes-ramo',
  templateUrl: './cotacoes-ramo.component.html',
  styleUrls: ['./cotacoes-ramo.component.scss']
})
export class CotacoesRamoComponent implements OnInit {
  @Output('eventFilter') eventFilter = new EventEmitter();
  @Output('clearFilter') limpaFiltro = new EventEmitter();

  filtroRamo = 0;

  constructor(public filtroController: FiltroControllerService) { }

  ngOnInit() {
    this.filtroRamo = this.filtroController.filtroCotacoes;
  }

  buildString() {
    this.filtroController.addFiltrosExtras('cotacoes',this.filtroRamo);
    let rawValue = {
      ramo: this.filtroController.filtroCotacoes,
    };
    let filtros = { values: rawValue };
    this.eventFilter.emit(filtros);
    this.filtroController.verificaFiltro();
  }

  clearFilter() {
    this.filtroRamo = 0;
    this.filtroController.limparFiltro('cotacoes');
    this.limpaFiltro.emit();
    this.filtroController.verificaFiltro();
    this.buildString();
  }

}
