export const listaMenus =  [ 
    {
        titulo: 'Painel Administrativo',
        url: '/corretoras',
        icone: 'briefcase',
        open: false,
        active: false,
        subList: []
    },   
    {
        titulo: 'Dashboard', 
        url: '/dashboard', 
        icone: 'monitor-dashboard', 
        open: false,
        active: false,
        subList: []
    },   
    {
        titulo: 'Cotações', 
        url: '', 
        icone: 'calculator', 
        active: false,
        open: false,
        subList: [
            { titulo: 'Cotar Auto',    url: '/cotacao/auto/formulario', icone: 'car-hatchback', active: false },
            { titulo: 'Ver cotações',  url: '/cotacoes',  icone: 'table-eye', active: false },
        ]
    },   
    {
        titulo: 'Negócios',
        url: '',
        icone: 'business',
        active: false,
        open: false,
        subList: [
            { titulo: 'Funil de Vendas',    url: '/funil-de-vendas', icone: 'funnel',        active: false },
            { titulo: 'Lista de Negócios',  url: '/lista-negocios',  icone: 'cash-multiple', active: false },
            // { titulo: 'Cotações',           url: '/cotacoes',        icone: 'calculator',    active: false },
        ]
    },
    {
        titulo: 'Tarefas', 
        url: '/lista-tarefas', 
        icone: 'menu-tarefas', 
        open: false,
        active: false,
        subList: []
    },   
    {
        titulo: 'Operacional',
        url: '',
        icone: 'cog',
        active: false,
        open: false,
        subList: [
            { titulo: 'Clientes',   url: '/clientes',   icone: 'account-group',         active: false },
            // { titulo: 'Parcelas',   url: '/parcelas',   icone: 'currency-usd-off',      active: false },
            // { titulo: 'Documentos', url: '/documentos', icone: 'file-download-outline', active: false },
        ]
    },
    {
        titulo: 'Configurações',
        url: '/configuracoes',
        icone: 'wrench',
        active: false,
        open: false,
        subList: []
    },
    // {
    //     titulo: 'Suporte',
    //     url: '/suporte',
    //     icone: 'lifebuoy',
    //     open: false,
    //     active: false,
    //     external: false,
    //     subList: []
    // },
    {
        titulo: 'Sair',
        url: '/login',
        icone: 'logout-variant',
        open: false,
        subList: []
    }
];

export const menuColaborador = [
    {
        titulo: 'Dashboard', 
        url: '/dashboard', 
        icone: 'monitor-dashboard', 
        open: false,
        active: false,
        subList: []
    },  
    {
        titulo: 'Tarefas', 
        url: '/lista-tarefas', 
        icone: 'menu-tarefas', 
        open: false,
        active: false,
        subList: []
    },  
    {
        titulo: 'Negócios',
        url: '',
        icone: 'business',
        active: false,
        open: false,
        subList: [
            { titulo: 'Funil de Vendas',    url: '/funil-de-vendas',        icone: 'funnel', active: false },
            { titulo: 'Lista de Negócios',  url: '/lista-negocios',         icone: 'cash-multiple', active: false },
            { titulo: 'Cotações',           url: '/cotacoes',               icone: 'calculator',    active: false },
        ]
    },  
    {
        titulo: 'Operacional',
        url: '',
        icone: 'cog',
        active: false,
        open: false,
        subList: [
            { titulo: 'Clientes',   url: '/clientes',   icone: 'account-group',         active: false },
            // { titulo: 'Parcelas',   url: '/parcelas',   icone: 'currency-usd-off',      active: false },
            // { titulo: 'Documentos', url: '/documentos', icone: 'file-download-outline', active: false },
        ]
    },                           
    {
        titulo: 'Configurações',
        url: '/configuracoes',
        icone: 'wrench',
        active: false,
        open: false,
        subList: []
    },
    {
        titulo: 'Suporte',
        url: '/suporte',
        icone: 'lifebuoy',
        open: false,
        active: false,
        external: false,
        subList: []
    },
    {
        titulo: 'Sair',
        url: '/login',
        icone: 'logout-variant',
        open: false,
        subList: []
    }
];

export const listaReduzida = [   
    {
        titulo: 'Dashboard', 
        url: '/dashboard', 
        icone: 'monitor-dashboard', 
        open: false,
        active: false,
        subList: []
    },               
    {
        titulo: 'Configurações',
        url: '/configuracoes',
        icone: 'cog',
        active: false,
        open: false,
        subList: []
    }, 
    // {
    //     titulo: 'Corretora',
    //     url: '',
    //     icone: 'office-building',
    //     open: false,
    //     subList: [
    //         { titulo: 'Cadastro', url: '/config-corretora', icone: 'file-document', active: false },
    //         { titulo: 'Planos', url: '/config-plano', icone: 'credit-card-multiple', active: false },
    //         // { titulo: 'Usuários', url: '/config-usuarios', icone: 'account-multiple', active: false },
    //         // {titulo: 'Plano', url: '/config-preferencias', icone: 'mdi mdi-tune mdi-18'},
    //     ]
    // }, 
    {
        titulo: 'Suporte',
        url: '/suporte',
        icone: 'lifebuoy',
        open: false,
        active: false,
        external: false,
        subList: []
    },
    {
        titulo: 'Sair',
        url: '/login',
        icone: 'logout-variant',
        open: false,
        subList: []
    }
];