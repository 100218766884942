import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { CookieService } from 'ngx-cookie-service';
import { PdocsSession } from '../models/pdocs-session.model';

@Injectable({
  providedIn: 'root',
})

export class PdocsService {

  private _pdocsApiUrl : string = environment.multi.url;
  private _wakApiBaseURL : string = '/';
  private auth : PdocsSession = new PdocsSession();

  constructor(private http: HttpClient){

    // if(!this.checkSession()) {
    //   let url = this._wakApiBaseURL + 'getAuthPdocs';
    //   this.http.get(url).toPromise().then(function(objAuth:any){
    //     //let tok = objAuth.data.token ? objAuth.data.token : null;
    //     this.updateSession(objAuth.data);      
    //   }.bind(this));

    // }

  }

  // public async getCalculoDescricaoModelo(tipo, modelo, ano) {
  //   await this.validateSession();
  //   const url = this._pdocsApiUrl + `calculo/fipeModelo?tipo=${tipo}&modelo=${modelo}&ano=${ano}`;
  //   return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

  // }

//   public async getNegocios(limit=100, contator=0, page=1, modo=2, periodo=3, ramo=31, textoBusca='') {    
//     // https://api.multicalculo.net/calculo/negocio/busca?limit=100&contador=1&page=1&modo=2&periodo=3
//     await this.validateSession();

//     let url = this._pdocsApiUrl + `calculo/negocio/busca?limit=${limit}&contador=${contator}&page=${page}&modo=${modo}&periodo=${periodo}&ramo=${ramo}`;
//     if (textoBusca && textoBusca.trim() != '') {
//       url += `&textoBusca=${textoBusca}`;
//     }

//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();
//   } 

//   public async cotacaoAutoToResid(postData) {
//     // https://multigestor.net/cotacaoAutoToResid
//     await this.validateSession();

//     let url = this._pdocsApiUrl + 'cotacaoAutoToResid';
//     return this.http.post(url, postData, {headers: this.getHeadersDefaultApi()}).toPromise();
//   } 

//   public async getNegocio(idIntegracao: string) {
//     // https://api.multicalculo.net/calculo/negocio/df405728-9651-442a-bfd7-aec60f2a4356
//     await this.validateSession();

//     let url = this._pdocsApiUrl + `calculo/negocio/${idIntegracao}`;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();
//   }

//   public async copiarNegocio(idIntegracao: string) {
//     // https://api.multicalculo.net/calculo/negocio/copiar/3dd49880-2b7b-4626-8f37-8f33e87abb3e
//     await this.validateSession();

//     let url = this._pdocsApiUrl + `calculo/negocio/copiar/${idIntegracao}`;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();
//   }

//   public async getSegurado(texto) {
//     await this.validateSession();
//     const url = this._pdocsApiUrl + `cor/segurados/?texto=${texto}&simplificado=false`;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async getEndereco(texto) {
//     await this.validateSession();
//     const url = this._pdocsApiUrl + `calculo/cep?cep=${texto}`;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async getCalculoValorMedio(fipe, ano, cep, maior25, historico) {
//     await this.validateSession();
//     const url = this._pdocsApiUrl + `calculo/valorMedio?fipe=${fipe}&ano=${ano}&cep=${cep}&maior25=${maior25}&historico=${historico}`;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async getCalculoFipeModelo(fipe, ano) {
//     await this.validateSession();
//     const url = this._pdocsApiUrl + `calculo/fipeModelo?ano=${ano}&fipe=${fipe}`;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async getCalculoCotacaoCalculos(idIntegracao, version){
//     await this.validateSession();
//     const url = this._pdocsApiUrl + 'calculo/cotacao/calculos/'+idIntegracao+'/'+version;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async corGetCredentials(){

//     await this.validateSession();
//     const url = this._pdocsApiUrl + 'cor/getCredentials';
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async calculoNegocioCopiar(idIntegracao){

//     await this.validateSession();
//     const url = this._pdocsApiUrl + 'calculo/negocio/copiar/'+ idIntegracao;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async getCalculoCotacaoVersoes(idIntegracao){

//     await this.validateSession();
//     const url = this._pdocsApiUrl + 'calculo/cotacao/versoes/'+idIntegracao;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   public async getCalculoNegocio(idIntegracao){

//     await this.validateSession();
//     const url = this._pdocsApiUrl + 'calculo/negocio/'+idIntegracao;
//     return this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();
 
//   }

  public async saveCalculoNegocio(postData){

    // await this.validateSession();
    // const url = this._pdocsApiUrl + 'calculo/negocio/';
    // return this.http.post(url, postData, {headers: this.getHeadersDefaultApi()}).toPromise();
 
  }

//   public async sendCalculoCalcular(postData){

//     debugger;
//     await this.validateSession();
//     const url = this._pdocsApiUrl + 'calculo/calcular/';
//     return this.http.post(url, postData, {headers: this.getHeadersDefaultApi()}).toPromise();
 
//   }

//   private checkSession(){
 
//     let now = new Date();
//     let nowTime = now.getTime();
//     let cookieExpires = parseInt(this.cookieService.get("pdocsVdAuthMultiExpires"));

//     this.auth.token = this.cookieService.get("pdocsVdAuthMulti");
//     this.auth.expires = isNaN(cookieExpires) ? 0 : cookieExpires;

//     if((this.auth.expires <= nowTime) || !this.auth.token) {
//       this.auth.token = null;
//       this.auth.expires = null;
//       return false;
//     }

//     return true;

//   }

//   private async validateSession(){
//     if(!this.checkSession()) 
//         await this.getSession();
    
//   }

//   private async getSession() {

//     var objAuth;
//     try{
//       let url = this._wakApiBaseURL + 'getAuthPdocs';
//       objAuth = await this.http.get(url).toPromise();
//     } catch(e){
//       return false;
//     }

//     try {
//       this.updateSession(objAuth.data);
//       return objAuth.data.token ? true : false;
//     } catch(e){
//       return false;
//     };

//   }

//   private updateSession(objTok) {

//     let tok = objTok.token ? objTok.token : null;

//     let now = new Date();
//     let expiresTime = now.getTime() + (this._expires*60000);
//     if(objTok.expires)
//       expiresTime = objTok.expires;

//     this.cookieService.set("pdocsVdAuthMulti", tok, expiresTime, "/");
//     this.cookieService.set("pdocsVdAuthMultiExpires", expiresTime.toString(), expiresTime, "/");

//     this.auth.token = tok;
//     this.auth.expires = expiresTime;

//   }

//   private async getApi(url){

//     await this.checkSession();
//     return await this.http.get(url, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   private async postApi(url, postData){

//     await this.checkSession();
//     return await this.http.post(url, postData, {headers: this.getHeadersDefaultApi()}).toPromise();

//   }

//   private getHeadersDefaultApi(){

//     let headers = new HttpHeaders();
//     headers = headers.set('authorization', this.cookieService.get("pdocsVdAuthMulti"));
//     return headers;

//   }



}
