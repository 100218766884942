import { Component, Input } from '@angular/core';
import { ControllerStatusService } from 'src/app/services/controller-status.service';


@Component({
	selector: 'link-video',
	templateUrl: './link-video.component.html',
	styleUrls: ['./link-video.component.scss']
})
export class LinkVideoComponent {
	@Input() toolTip: string = 'Tutorial';
	private linksVideos = {};

	constructor(private controller: ControllerStatusService) {

		this.linksVideos = controller.linksVideos;

	}
	
	open(key){
		console.log('OPEN LINK', key);		
		if(this.linksVideos.hasOwnProperty(key)){
			window.open(this.linksVideos[key], '_blank');
		}
	}

}
