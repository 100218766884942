import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GerenciaArquivoMultiComponent } from './gerencia-arquivo-multi.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { FileUploadMultiModule } from '../file-upload-multi/file-upload-multi.module';



@NgModule({
  declarations: [GerenciaArquivoMultiComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    FileUploadMultiModule
  ],
  exports: [GerenciaArquivoMultiComponent]
})
export class GerenciaArquivoMultiModule { }
