let seguradoras = [
    { 
        seguradoraId : 18, 
        rotulo : 'Alfa', 
        icone:  '../../assets/img/seguradoras/alfa.png' 
    },
    { 
        seguradoraId : 22, 
        rotulo : 'Aliro', 
        icone:  '../../assets/img/seguradoras/aliro.png' 
    },
    { 
        seguradoraId : 5, 
        rotulo : 'Allianz', 
        icone:  '../../assets/img/seguradoras/allianz.png' 
    },
    { 
        seguradoraId : 10, 
        rotulo : 'Azul', 
        icone:  '../../assets/img/seguradoras/azul.png' 
    },
    { 
        seguradoraId : 1, 
        rotulo : 'Bradesco', 
        icone:  '../../assets/img/seguradoras/bradesco.png' 
    },
    { 
        seguradoraId : 14, 
        rotulo : 'Generali', 
        icone:  '../../assets/img/seguradoras/generali.png' 
    },
    { 
        seguradoraId : 4, 
        rotulo : 'HDI', 
        icone:  '../../assets/img/seguradoras/hdi.png' 
    },
    { 
        seguradoraId : 15, 
        rotulo : 'Itaú', 
        icone:  '../../assets/img/seguradoras/itau.png' 
    },
    { 
        seguradoraId : 12, 
        rotulo : 'Liberty', 
        icone:  '../../assets/img/seguradoras/liberty.png' 
    },
    { 
        seguradoraId : 3, 
        rotulo : 'Mapfre', 
        icone:  '../../assets/img/seguradoras/mapfre.png' 
    },
    { 
        seguradoraId : 13, 
        rotulo : 'Mitsui', 
        icone:  '../../assets/img/seguradoras/mitsui.png' 
    },
    { 
        seguradoraId : 8, 
        rotulo : 'Porto', 
        icone:  '../../assets/img/seguradoras/porto.png' 
    },
    { 
        seguradoraId : 6, 
        rotulo : 'Sompo', 
        icone:  '../../assets/img/seguradoras/sompo.png' 
    },
    { 
        seguradoraId : 17, 
        rotulo : 'SulAmérica', 
        icone:  '../../assets/img/seguradoras/sulamerica.png' 
    },
    { 
        seguradoraId : 11, 
        rotulo : 'Tokio', 
        icone:  '../../assets/img/seguradoras/tokio.png' 
    },
    { 
        seguradoraId : 7, 
        rotulo : 'Zurich', 
        icone:  '../../assets/img/seguradoras/zurich.png' 
    },
    { 
        seguradoraId : 19, 
        rotulo : 'Sancor', 
        icone:  '../../assets/img/seguradoras/sancor.png' 
    },
    { 
        seguradoraId : 20, 
        rotulo : 'Suhai', 
        icone:  '../../assets/img/seguradoras/suhai.png' 
    },
    { 
        seguradoraId : 9, 
        rotulo : 'Itaú', 
        icone:  '../../assets/img/seguradoras/itau.png' 
    },
];

export function getSeguradoras() {
    return seguradoras;
}

export function getSeguradora(seguradoraId) {
    let seguradora = seguradoras.find(seguradora=>{
        if (seguradora && seguradora.seguradoraId == seguradoraId) {
            return true;
        }
        return false;
    });
    return seguradora;
}

export function getRotuloSeguradora (seguradoraId) {
    let rotulo;
    let seguradora = getSeguradora (seguradoraId);
    if (seguradora) {
        rotulo = seguradora.rotulo;
    }  
    return rotulo || '';
}

export function getIiconeSeguradora (seguradoraId) {
    let icone;
    let seguradora = getSeguradora (seguradoraId);
    if (seguradora) {
        icone = seguradora.icone;
    }  
    return icone || '';       
}