import { FormControl, AbstractControl, ValidatorFn } from "@angular/forms";
import * as Moment from 'moment';
import { ParceirosService } from "../services/parceiros.service";
const parceiros = new ParceirosService();

/**
 * Aplica uma mensagem de de validação a um controle de formulário
 * @param formControl controle de formulário.
 * @param erroCampo erro recebido da api.
 */
export function aplicaErroCampo(formControl: AbstractControl, erroCampo: any) {
    formControl.setErrors({
        nomeInvalido: {
            valid: false,
            message: erroCampo.message
        }
    });

    formControl.markAsDirty();
    formControl.markAsTouched();
    //formControlNome.markAsPristine();			
}

export function validaDateOLD(c: FormControl) {
    if (!c.value) {
        return null;
    }
    if ((typeof c.value) == 'object' && c.value.constructor && c.value.constructor.name == 'Date') {
        // console.log(c.value.constructor.name, c.value)
        return null;
    }
    else {
        // console.log(typeof c.value, c.value)
    }
    let date = c.value
    //let expr = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
    let expr = /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/;
    // if(date.length == 10){
    if (date) {
        // console.log(date)
        // console.log(expr.test(date)? 'válido' : 'inválido' )
        // TODO: verifica se dqata possível. Ex.: 01/22/2018 é invalido
        return expr.test(date) ? null : {
            validateDate: {
                valid: false,
                message: 'Data inválida.'
            }
        }
        // }else{
        //     return null
        // }
    } else {
        // console.log('dfsg')
        return null;
    }
}

export function validaDate(c: FormControl) {
    if (!c.value) {
        return null;
    }
    if ((typeof c.value) == 'object' && c.value.constructor && c.value.constructor.name == 'Date') {
        // console.log(c.value.constructor.name, c.value)
        return null;
    }
    else {
        // console.log(typeof c.value, c.value)
    }

    let date = c.value
    //let expr = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
    let expr = /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/;
    // if(date.length == 10){
    if (date) {
        // console.log(date)
        // console.log(expr.test(date)? 'válido' : 'inválido' )
        // TODO: verifica se dqata possível. Ex.: 01/22/2018 é invalido
        if (date.match(/^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/)) {
            let dateInt = date.split("/").reverse().map(item => { return parseInt(item) });

            dateInt[1] = dateInt[1] - 1;
            if (Moment(dateInt).isValid()) {
                return null
            }
            else {
                return {
                    validaDate: {
                        valid: false,
                        message: 'Data inexistente.'
                    }
                };
            }
        }

        return expr.test(date) ? null : {
            validaDate: {
                valid: false,
                message: 'Data inválida.'
            }
        }

    } else {
        // console.log('dfsg')
        return null;
    }
}

export function comparaDataInieFim(c1: AbstractControl): ValidatorFn {
    return function comparaDatas(c2: FormControl) {

        let dateIni = c1.value;
        let dateFim = c2.value;

        if (dateIni && dateFim) {
            if (dateIni.match(/^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/) && dateFim.match(/^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/)) {
                let dateIniM = dateIni.split("/").reverse().map(item => { return parseInt(item) });
                dateIniM[1] = dateIniM[1] - 1;
                let dateFimM = dateFim.split("/").reverse().map(item => { return parseInt(item) });
                dateFimM[1] = dateFimM[1] - 1;

                dateIniM = Moment(dateIniM);
                dateFimM = Moment(dateFimM);

                let diferencaDias = dateFimM.diff(dateIniM, 'days');
                if (diferencaDias <= 0) {
                    return {
                        comparaDatas: {
                            valid: false,
                            message: 'Data final menor que data inicial.'
                        }
                    }

                }

            } else {
                return null;
            }

        } else {
            return null;
        }
    }

}

export function vigenciavalida(vigIni: string) {
    let vigenciaDesatualizada = true;
    let res = { vigIni: '', vigfim: '' };
    let dataAtual = new Date();
    let diaAtual = dataAtual.getDate();
    let mesAtual = dataAtual.getMonth() + 1;
    let anoAtual = dataAtual.getFullYear();
    if (vigIni) {
        let tokens = vigIni.split('/'); // dd/mm/aaaa
        if (tokens.length == 3) {
            try {
                let dia = parseInt(tokens[0]);
                let mes = parseInt(tokens[1]);
                let ano = parseInt(tokens[2]);
                if (ano < anoAtual) {
                    vigenciaDesatualizada = false;
                }
                else if (ano == anoAtual && mes < mesAtual) {
                    vigenciaDesatualizada = false;
                }
                else if (ano == anoAtual && mes == mesAtual && dia < diaAtual) {
                    vigenciaDesatualizada = false;
                }
            } catch (error) {
                vigenciaDesatualizada = false;
            }
        } else {
            vigenciaDesatualizada = false;
        }
    } else {
        vigenciaDesatualizada = false;
    }
    return vigenciaDesatualizada;
}


export function validaMinDate(c: FormControl) {
    if (!c.value) {
        return null;
    }
    if ((typeof c.value) == 'object' && c.value.constructor && c.value.constructor.name == 'Date') {
        // console.log(c.value.constructor.name, c.value)
        return null;
    }
    else {
        // console.log(typeof c.value, c.value)
    }
    let date = c.value
    //let expr = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
    let expr = /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/;
    if (date) {
        // TODO: verifica se dqata possível. Ex.: 01/22/2018 é invalido
        if (!vigenciavalida(date)) {
            return {
                validateDate: {
                    valid: false,
                    message: 'Data anterior a data atual.'
                }
            }
        }
        return expr.test(date) ? null : {
            validateDate: {
                valid: false,
                message: 'Data inválida.'
            }
        }
    } else {
        // console.log('dfsg')
        return null;
    }
}



export function validaEmail(c: FormControl) {
    let value = c.value;



    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value == '' || value == null || value == undefined) return null;

    return re.test(value) ? null : {
        validateEmail: {
            valid: false,
            message: 'Endereço de e-mail inválido.'
        }
    };

}

export function mandatorio(c: FormControl) {
    let value;
    if (!isNaN(c.value)) {
        value = c.value && c.value != null && c.value != undefined ? c.value.toString().trim() : '';
    } else {
        value = c.value && c.value != null && c.value != undefined ? c.value.trim() : '';
    }
    var re = /^$/;

    return re.test(value) ? {
        validateMandatorio: {
            valid: false,
            message: 'Campo obrigatório.'
        }
    } : null;
}

export function mandatorio2(c: FormControl) {
    let value;
    if (!isNaN(c.value)) {
        value = c.value != undefined && c.value >= 0 && c.value != null ? c.value.toString().trim() : '';
    } else {
        value = c.value != undefined && c.value >= 0 && c.value != null ? c.value.trim() : '';
    }
    var re = /^$/;

    return re.test(value) ? {
        validateMandatorio: {
            valid: false,
            message: 'Campo obrigatório.'
        }
    } : null;
}

export function validaCodeParceiros(c: FormControl) {
    let value = c.value ? c.value.trim() : false;
    let codes = parceiros ? parceiros.getCodesParceiros() : [];

    if (value.length == 6) {
        let index = codes.findIndex(c => c == value.toUpperCase());
        if (index > -1) {
            return null
        } else {
            return {
                validaCodeParceiro: {
                    valid: false,
                    message: 'Código inválido'
                }
            }
        }
    } else if (value.length > 0 && value.length < 6) {
        return {
            validaCodeParceiro: {
                valid: false,
                message: 'Código inválido'
            }
        }
    } else {
        return null
    }
}


export function validaTelefone(c: FormControl) {
    let value = c.value ? c.value.trim() : false;


    var re = /^\s*(0800|\(0800\))?\*?(\(0?\d{2}\)|0?\d{2})?\s*(\d{3,5}\s*\-?\s*\d{4}|\d{3,4}\s*\-?\s*\d{4,5})\s*$/;

    return re.test(value) ? null : {
        validaTelefone: {
            valid: false,
            message: 'Número de telefone inválido.'
        }
    };
}

export function validaCPF(c: FormControl) {
    let error = {
        validaCPF: {
            valid: false,
            message: 'CPF inválido.'
        }
    };

    let strCPF = c.value ? c.value.trim() : '';
    if (!strCPF) {
        return null;
    }

    let regexp = /^\s*(\d{11}|\d{3}\.\d{3}\.\d{3}(\-|\.)\d{2})\s*$/;
    if (!regexp.test(strCPF)) {
        return error;
    }

    strCPF = strCPF.replace(/\D/g, '');

    var Soma;
    var Resto;
    let result = false;
    Soma = 0;
    if (strCPF == "00000000000") return error;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return error;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return error;
    return null;
}

export function comprimentoExato(tamanho: number, trim: boolean) {
    return comprimento(tamanho, tamanho, trim);
}

export function comprimento(min: number, max: number, trim?: boolean) {
    return function (c: FormControl) {
        let value = c.value != null ? c.value.toString() : '';
        if (trim) {
            value = value.trim();
        }

        if (min != null && max != null && (value.length < min || value.length > max)) {
            if (min == max) {
                return {
                    validaComprimento: {
                        valid: false,
                        message: 'O campo deve ter exatamente ' + min + ' caracteres.'
                    }
                };
            } else {
                return {
                    validaComprimento: {
                        valid: false,
                        message: 'O campo deve ter entre ' + min + ' e ' + max + ' caracteres.'
                    }
                };
            }
        } else if (min != null && value.length < min) {
            if (min == 1) {
                return {
                    validaComprimento: {
                        valid: false,
                        message: 'O campo deve ter pelo menos ' + min + ' caracter.'
                    }
                };
            } else {
                return {
                    validaComprimento: {
                        valid: false,
                        message: 'O campo deve ter pelo menos ' + min + ' caracteres.'
                    }
                };
            }
        } else if (max != null && value.length > max) {
            if (max == 1) {
                return {
                    validaComprimento: {
                        valid: false,
                        message: 'O campo deve ter no máximo ' + max + ' caracter.'
                    }
                };
            } else {
                return {
                    validaComprimento: {
                        valid: false,
                        message: 'O campo deve ter no máximo ' + max + ' caracteres.'
                    }
                };
            }
        } else {
            return null;
        }
    }
}


export function validaCNPJ(c: FormControl) {
    let cnpj = c.value;
    let error = {
        validaCNPJ: {
            valid: false,
            message: 'CNPJ inválido.'
        }
    };

    let strCPF = c.value != null && c.value && c.value != undefined ? c.value.trim() : '';

    let regexp = /^\s*(\d{14}|\d{2}\.\d{3}.\d{3}\/\d{4}\-\d{2})\s*$/;
    if (!regexp.test(strCPF)) {
        return error;
    }


    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return error;

    if (cnpj.length != 14)
        return error;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return error;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return error;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return error;

    return null;

}

export function validaNIF(c: FormControl) {
    let nif = c.value;
    let error = {
        validaNIF: {
            valid: false,
            message: 'NIF/NIPC inválido.'
        }
    };
    if (!nif || typeof nif !== "string" || nif.length !== 9) {
        return error;
    }
    /*
    if(nif[0] == '5' || nif[0] == '6' || nif[0] == '8' || nif[0] == '9'){
        return error;
    }
    */

    let cont = 0;
    for (let i = 9; i >= 2; i--) {
        cont += i * (parseInt(nif[nif.length - i]) || 0);
    }

    const module = cont % 11;

    const semUltimoDigt = nif.slice(0, nif.length - 1);

    if (module === 0 || module === 1) {
        if(`${semUltimoDigt}0` === nif){
        return null;
        }else{
            return error;
        }
        
    } else {
        if(`${semUltimoDigt}${11 - module}` === nif){
        return null;
        } else{
            return error;
        }
    }
}

export function validaNIPC(c: FormControl) {
    let nif = c.value;
    let error = {
        validaNIF: {
            valid: false,
            message: 'NIPC inválido.'
        }
    };
    if (!nif || typeof nif !== "string" || nif.length !== 9) {
        return error;
    }
    
    if(nif[0] != '5' && nif[0] != '6' && nif[0] != '8' && nif[0] != '9'){
        return error;
    }

    let cont = 0;
    for (let i = 9; i >= 2; i--) {
        cont += i * (parseInt(nif[nif.length - i]) || 0);
    }

    const module = cont % 11;

    const semUltimoDigt = nif.slice(0, nif.length - 1);

    if (module === 0 || module === 1) {
        if(`${semUltimoDigt}0` === nif){
        return null;
        }else{
            return error;
        }
        
    } else {
        if(`${semUltimoDigt}${11 - module}` === nif){
        return null;
        }else{
            return error;
        }
    }

}





export function exibeErro(control: FormControl) {
    if (control && control.errors) {
        for (let key in control.errors) {
            return control.errors[key].message;

        }
    }
}

export function validaCep(c: FormControl) {
    let cep = c.value ? c.value.replace(/[\D]/g, '') : null;
    let regex = /^[0-9]{8}$/;
    if (cep && !regex.test(cep)) {
        return {
            validateCep: {
                valid: false,
                message: 'cep não é válido'
            }
        };
    }
    return null
}

export function validaCodigoPostal(c: FormControl) {
    let cep = c.value ? c.value.replace(/[\D]/g, '') : null;
    let regex = /^[0-9]{7}$/;
    if (cep && !regex.test(cep)) {
        return {
            validateCep: {
                valid: false,
                message: 'Código Postal não é válido'
            }
        };
    }
    return null
}

export function validaPerc(c: FormControl) {
    if (c.value > 100) {
        return {
            validaPerc: {
                valid: false,
                message: 'A porcentagem não pode ser maior que 100%.'
            }
        };
    }

    return null
}
export function validaApenasNumeros(c: FormControl) {
    // TODO: não faz sentido
    let regex = /%/;
    let regexLetra = /[a-z]/

    if (c.value && regex.test(c.value) || regexLetra.test(c.value)) {
        return {
            validaPorc: {
                valid: false,
                message: 'Digite apenas números'
            }
        }
    }
    return null
}
export function validaCpfCnpj(c: FormControl) {

    let regex = /^\s*(\d{11}|\d{3}\.\d{3}\.\d{3}(\-|\.)\d{2})\s*$/;
    console.log('validacpfcnpj', c.value)
    if (c.value >= 11 || regex.test(c.value)) {
        let error = {
            validaCpfCnpj: {
                valid: false,
                message: 'CPF inválido.'
            }
        };

        let strCPF = c.value ? c.value.trim() : '';

        let regexp = /^\s*(\d{11}|\d{3}\.\d{3}\.\d{3}(\-|\.)\d{2})\s*$/;
        if (!regexp.test(strCPF)) {
            return error;
        }

        strCPF = strCPF.replace(/\D/g, '');

        var Soma;
        var Resto;
        let result = false;
        Soma = 0;
        if (strCPF == "00000000000") return error;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return error;

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return error;
        return null;

    } else {
        let cnpj = c.value;
        let error = {
            validaCpfCnpj: {
                valid: false,
                message: 'CNPJ inválido.'
            }
        };

        let strCPF = c.value ? c.value.trim() : '';

        let regexp = /^\s*(\d{14}|\d{2}\.\d{3}.\d{3}\/\d{4}\-\d{2})\s*$/;
        if (!regexp.test(strCPF)) {
            return error;
        }


        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return error;

        if (cnpj.length != 14)
            return error;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return error;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return error;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return error;

        return null;
    }
}
export function validaCpfCnpj2(c: FormControl) {

    let regex = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;
    // console.log('validacpfcnpj', c.value)    
    if (c.value.length == 11 && regex.test(c.value)) {
        let error = {
            validaCpfCnpj: {
                valid: false,
                message: 'CPF/CNPJ inválido.'
            }
        };

        let strCPF = c.value ? c.value.trim() : '';

        let regexp = /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/;
        if (!regexp.test(strCPF)) {
            return error;
        }

        strCPF = strCPF.replace(/\D/g, '');

        var Soma;
        var Resto;
        let result = false;
        Soma = 0;
        if (strCPF == "00000000000") return error;

        for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return error;

        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return error;
        return null;

    } else {
        let cnpj = c.value;
        let error = {
            validaCpfCnpj: {
                valid: false,
                message: 'CPF/CNPJ inválido.'
            }
        };

        let strCPF = c.value ? c.value.trim() : '';

        let regexp = /^\s*(\d{14}|\d{2}\.\d{3}.\d{3}\/\d{4}\-\d{2})\s*$/;
        if (!regexp.test(strCPF)) {
            return error;
        }


        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return error;

        if (cnpj.length != 14)
            return error;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return error;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return error;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return error;

        return null;
    }
}

export function validadeMesAnoCartao(c: FormControl) {
    let regex = /(0[1-9]|1[0-2])[\/](202[0-9])/;

    if (c.value && !regex.test(c.value)) {
        return {
            validadeMesAnoCartao: {
                valid: false,
                message: 'Data inválida'
            }
        };
    }
    return null

}

export function validadeCVVCartao(c: FormControl) {
    let regex = /[0-9]{3,4}/g;

    if (c.value && !regex.test(c.value)) {
        return {
            validadeCVVCartao: {
                valid: false,
                message: 'CVV inválido'
            }
        };
    }
    return null

}

export function validaRepitaSenha(c: FormControl) {
    return function (c2: FormControl) {

        if (c.value != c2.value) {
            return {
                validaRepeteSenha: {
                    valid: false,
                    message: 'Palavras-passe não coincidem.'
                }
            };
        }
        return null
    }


}


export function validaUrlSite(c: FormControl) {
    let regexUrl = /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/
    // let regexW = /^((www?|ftp):\/)?\/?([^:\/\s]+)/

    if (!regexUrl.test(c.value)) {
        return {
            validaUrlSite: {
                valid: false,
                message: 'URL inválida'
            }
        };
    }
    return null

}

export function forcaSenha(senha: string) {
    if (!senha || senha.length < 6 || senha.length > 8) return

    let forca = 0;

    if (senha.length == 6) {
        forca += 20;
    }
    if (senha.length == 7) {
        forca += 20;
    }
    if (senha.length == 8) {
        forca += 20;
    }

    if (senha.length >= 6 && senha.length <= 8 && /[0-9]/g.test(senha)) {
        forca += 20;
    }
    if (senha.length >= 6 && senha.length <= 8 && /[a-z]/g.test(senha)) {
        forca += 20;
    }
    if (senha.length >= 6 && senha.length <= 8 && /[A-Z]/g.test(senha)) {
        forca += 20;
    }
    if (senha.length >= 6 && senha.length <= 8 && /[!@#$%&*?]/g.test(senha)) {
        forca += 20;
    }

    if (forca <= 40) {
        return { forca: forca, message: 'Palavra-passe Fraca' };
    } else if (forca > 40 && forca <= 60) {
        return { forca: forca, message: 'Palavra-passe Mediana' };
    } else if (forca > 60 && forca <= 80) {
        return { forca: forca, message: 'Palavra-passe Boa' };
    } else {
        return { forca: forca, message: 'Palavra-passe Muito Boa' };
    }

}


