<div
    mat-dialog-title [ngStyle]="{'display':'flex','align-items':'center'}">   
    <h3 [ngStyle]="{'margin': '10px 0 10px 6px'}">
        Bem Vindo!
    </h3>
    <button      
        mat-icon-button
        mat-dialog-close
        tabindex="-1"
        [ngStyle]="{'margin': '4px 4px 0 auto'}">
        <mat-icon [svgIcon]="'close'"></mat-icon>
       
    </button>
</div>
<div style="width: 100%;height:calc(100% - 73px)">
    <iframe  width="100%" height="100%" [src]="intro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div class="input-intro">
        <input type="checkbox" id="check" placeholder="não mostrar novamente" [(ngModel)]="naoMostrar" (ngModelChange)="change()">
        <label for="check" class="label-intro-input">não mostrar novamente</label>
    </div>
</div>