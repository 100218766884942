import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {


  constructor(private serviceWorker: SwUpdate, public snackBar: MatSnackBar) {

  }

  checkUpdate() {

    /**
     * esta funcionalidade deleta todos caches que existirem do service worker
     * usar quando os caches começarem a quebrar as atualizações
     */
    // if (caches) {
    //   try {
    //     caches.keys().then(function (names) {
    //       for (let name of names)
    //         caches.delete(name);
    //     });
    //   } catch (error) {
    //     console.log('erro delete caches', error)
    //   }      
    // }

    // console.log('check navigator')
    // if ('serviceWorker' in navigator) {
    //   // console.log('existe serviceWorker navigator')
    //   navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //     //returns installed service workers
    //     // console.log('existe registrations', registrations);
    //     if (registrations.length) {

          
    //       for (let registration of registrations) {
    //         // console.log('service worker UNREGISTER')
    //         registration.unregister();
    //       }
    //     }
    //   });
    // }

    let bc = new BroadcastChannel('apice_channel_update_tabs');
    bc.onmessage = function (ev) {
      console.log(ev)
      if (ev && ev.data == 'atualizar_update_tabs') {
        window.location.reload();
      }

    }

    if (this.serviceWorker.isEnabled) {
      interval(450000).subscribe(() => this.serviceWorker.checkForUpdate()
        .then(() => console.log('checking for updates')));

      this.serviceWorker.available.subscribe(event => {
        console.log('available version is', event.available);
        let snack = this.snackBar.open('Nova Versão Disponível', 'Atualizar', {
          duration: 1000000
        });

        snack.onAction().subscribe(() => {
          bc.postMessage('atualizar_update_tabs');
          window.location.reload();
        })

      });

    }

  }

}