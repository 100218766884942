
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ModalFechamentoComponent } from './modal-fechamento.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { GerenciadorArquivosModule } from '../gerenciador-arquivos/gerenciador-arquivos.module';
import { MatButtonModule } from '@angular/material/button';
import { NgxCurrencyModule } from "ngx-currency";
import { TextMaskModule } from 'angular2-text-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { GerenciaArquivoMultiModule } from '../gerencia-arquivo-multi/gerencia-arquivo-multi.module';


@NgModule({
    declarations: [
        ModalFechamentoComponent
    ],
    entryComponents: [ModalFechamentoComponent],
    imports: [
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        CommonModule,
        GerenciaArquivoMultiModule,
        MatButtonModule,
        NgxCurrencyModule,
        TextMaskModule,
        MatDialogModule
    ],
    exports: [
        ModalFechamentoComponent
    ]
})

export class ModalFechamentoModule { }