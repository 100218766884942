import {
    trigger,
    style,
    animate,
    transition,
    sequence,
    state,
    query,
    stagger,
    keyframes,
} from '@angular/animations';

export const animationNgIfEnter =
    trigger('animNgIfEnter', [
        transition(':enter', [
            style({ minHeight: '0', maxHeight: '0' }),
            animate('.4s', style({ minHeight: '50px', maxHeight: '350px' })),
        ])
])

export const animationNgIf =
    trigger('animNgIf', [
        transition(':enter', [
            style({ minHeight: '0', maxHeight: '0' }),
            animate('.4s', style({ minHeight: '50px', maxHeight: '350px' })),
        ]),
        transition(':leave', [
            style({ minHeight: '50px', maxHeight: '350px' }),
            animate('.4s', style({ minHeight: '0', maxHeight: '0' }))
        ])
    ])

export const animationKanban =
    trigger('animKanban', [
        transition('* => void', [
            style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }),
            sequence([
                animate(".45s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
                animate(".2s ease", style({ height: '0', opacity: 0, transform: 'translateX(20px)', 'box-shadow': 'none' }))
            ])
        ]),
        transition('void => novo', [
            style({ height: '0', opacity: '0', transform: 'translateX(20px)', 'box-shadow': 'none' }),
            sequence([
                animate("0.555s ease", style({ height: '*', background: 'rgba(255, 153, 0, 0.13)', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
                animate("0.666s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }))
            ])
        ])
    ])

export const animationNgFor =
    trigger('animNgFor', [
        transition(':leave', [
            style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }),
            sequence([
                animate(".45s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
                animate(".2s ease", style({ height: '0', opacity: 0, transform: 'translateX(20px)', 'box-shadow': 'none' }))
            ])
        ]),
        transition(':enter', [
            style({ height: '0', opacity: '0', transform: 'translateX(20px)', 'box-shadow': 'none' }),
            sequence([
                animate(".555s ease", style({ height: '*', background: 'rgba(255, 153, 0, 0.13)', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
                animate(".666s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }))
            ])
        ])
])

export const fabButtonsAnimation = [
    trigger('fabToggler', [
      state('inactive', style({
        transform: 'rotate(0deg)'
      })),
      state('active', style({
        transform: 'rotate(225deg)'
      })),
      transition('* <=> *', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('speedDialStagger', [
      transition('* => *', [
  
        query(':enter', style({ opacity: 0 }), {optional: true}),
  
        query(':enter', stagger('40ms',
          [
            animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
              keyframes(
                [
                  style({opacity: 0, transform: 'translateY(10px)'}),
                  style({opacity: 1, transform: 'translateY(0)'}),
                ]
              )
            )
          ]
        ), {optional: true}),
  
        query(':leave',
          animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            keyframes([
              style({opacity: 1}),
              style({opacity: 0}),
            ])
          ), {optional: true}
        )
  
      ])
    ])
  ];