import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { Corretora } from '../models/corretora.model';
import { CadastroUsuario } from '../models/cadastro-usuario.model';

@Injectable({
	providedIn: 'root'
})
export class ApiCorretoraService {

	private ENV: any = environment;
	private URL;

	iconesRamos = [
		"home",
		"home-group",
		"briefcase",
		"cash-refund",
		"wrench",
		"alert",
		"shield-plus",
		"flash-alert",
		"account-group",
		"pine-tree",
		"monitor-lock",
		"account-alert",
		"account-tie",
		"credit-card",
		"cash-100",
		"currency-usd",
		"home-city-outline",
		"shield-account",
		"car-side",
		"truck",
		"cash-usd",
		"account-multiple",
		"home-group",
		"tractor",
		"account",
		"ferry",
		"airplane",
		"oil",
		"hospital-box",
		"clipboard-text",
		"car-hatchback",
		"motorbike"
	]

	constructor(private http: HttpClient, private BS: BaseService) {
		this.URL = this.ENV.apice.url;
	}
	cadastraCorretora(dadosCadastro: CadastroUsuario): Promise<any> {
		return this.http.post(this.URL + 'cfg/corretora/registro', dadosCadastro.dados, this.BS.getHeaders()).toPromise();
	}

	/**
	 * Verifica se uma corretora já existe na base do NEXT por CPF/CNPJ.
	 * @param cpfCnpj objeto contendo o campo cpfCnpj. Ex.: { cpfCnpj:'59.517.575-33' }.
	 */
	verificaCorretoraExistente(param: {}, corretoraId?: string): Promise<any> {
		// console.warn('O resultado desta função foi modificado. Favor refatorar seu código');
		let dados: any = {};
		if (param) {
			dados = param;
		}		
		if (corretoraId) {
			dados.corretoraId = corretoraId;
		}

		return this.http.post(this.URL + 'cfg/corretora/verificacao', dados, this.BS.getHeaders()).toPromise();
	}

	verificaCorretoraExistenteAllianz(param: {}, corretoraId?: string): Promise<any> {
		// console.warn('O resultado desta função foi modificado. Favor refatorar seu código');
		let dados: any = {};
		if (param) {
			dados = param;
		}		
		dados['parceria'] = true;
		if (corretoraId) {
			dados.corretoraId = corretoraId;
		}

		return this.http.post(this.URL + 'cfg/corretora/verificacao', dados, this.BS.getHeaders()).toPromise();
	}

	dadosCorretora;
	buscaCorretora() {
		return this.http.get(this.URL + 'cfg/corretora', this.BS.getHeaders(true)).toPromise();
		// return this.apiBase.get('cfg/corretora', {}).then(dadosCorretora => {
		// 	let corretora = new Corretora(dadosCorretora);
		// 	return corretora;
		// });		
	}

	salvaCorretora(corretora: Corretora): Promise<any> {
		return this.http.post(this.URL + 'cfg/corretora', corretora.dados, this.BS.getHeaders(true)).toPromise();
	}

	validandoCorretora: boolean = false;
	validateTimeOut;
	validaCorretoraPorCpfCnpj(param?: string, corretoraId?: string): AsyncValidatorFn {
		return (control: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
			let params = {}
			if (param == "email") {
				params['email'] = control.value;
			} else {
				params['cpfCnpj'] = control.value;
			}
			clearTimeout(this.validateTimeOut);
			return new Promise((resolve, reject) => {

					this.validateTimeOut = setTimeout(()=>{

					
					return this
						.verificaCorretoraExistente(params, corretoraId)
						.then((ret: any) => {
							// se não recebeu validação do servidor
							if (!ret) {
								resolve({
									validaCorretoraPorCpfCnpj: {
										valid: false,
										message: 'Falha na validação'
									}
								});
							}
							else if (ret.pertenceACorretora) {
								resolve(null);
							}
							// se CPF/CNPJ disponível
							else if (ret.corretoraDisponivel) {
								resolve(null);
							}
							else if (ret.cpfCnpjDisponivel) {
								resolve(null);
							}
							else if (ret.emailDisponivel) {
								resolve(null);
							}

							else if (!ret.corretoraDisponivel) {
								if (ret.hasOwnProperty('cpfCnpjDisponivel') && !ret.cpfCnpjDisponivel) {
									resolve(
										{
											validaCorretoraPorCpfCnpj: {
												valid: false,
												message: 'Corretora já cadastrada com esse cpf ou cnpj.'
											}
										}
									);
								}

								else if (ret.hasOwnProperty('emailDisponivel') && !ret.emailDisponivel) {
									resolve(
										{
											validaCorretoraPorCpfCnpj: {
												valid: false,
												message: 'Corretora já cadastrada com esse email.'
											}
										}
									);
								}

							}

							else {
								resolve(
									{
										validaCorretoraPorCpfCnpj: {
											valid: false,
											message: 'Corretora já cadastrada.'
										}
									}
								);
							}
						})
						.catch(error => {
							resolve(
								{
									validaUsuarioPorEmail: {
										valid: false,
										message: 'Falha na validação'
									}
								}
							);
						})

					},600);
				
			});
		}
	}

	buscarListaProdutosRamos() {
		return this.http.get(this.URL + 'cfg/produto?apiV2=true&filtroTipo=merge', this.BS.getHeaders(true)).toPromise();
	}

	buscarTodosProdutosRamos() {
		return this.http.get(this.URL + 'cfg/produto?apiV2=true&filtroTipo=padrao', this.BS.getHeaders(true)).toPromise();
	}

	// async buscarRamos(): Promise<any> {
	// 	return this.apiBase.get('cfg/produto', {});
	// }

	buscarRamos() {
		return this.http.get(this.URL + 'cfg/produto?apiV2=true', this.BS.getHeaders(true)).toPromise();
	}

	salvaProduto(produto: any): Promise<any> {
		return this.http.post(this.URL + 'cfg/produto', produto, this.BS.getHeaders(true)).toPromise();
	}

	deleteProduto(produtoId) {
		return this.http.delete(this.URL + `cfg/produto/${produtoId}?apiV2=true`, this.BS.getHeaders(true)).toPromise();
	}

	listaCorretorasRoot(): Promise<any> {
		return this.http.get(this.URL + 'cfg/corretora/root/', this.BS.getHeaders(true)).toPromise();
	}

	buscaCorretoraRoot(id): Promise<any> {
		return this.http.get(this.URL + 'cfg/corretora/root/' + id, this.BS.getHeaders(true)).toPromise();
	}

	salvarCorretoraRoot(body) {
		return this.http.post(this.URL + 'cfg/corretora/root/', body, this.BS.getHeaders(true)).toPromise();
	}

	async getCorretoras(filtroBusca?, status?, teste?, diasCriacao?, diasSemUso?, situation?, plano?, dateIni?, dateFim?): Promise<any> {
		filtroBusca = filtroBusca || ''
		dateIni = dateIni || ''
		dateFim = dateFim || ''
		status = status == 0 ? status : status == 1 ? status : ''
		teste = teste == 0 ? teste : teste == 1 ? teste : ''
		diasCriacao = diasCriacao || ''
		diasSemUso = diasSemUso || ''
		situation = situation || ''
		plano = plano || ''
		let url = `cfg/corretora/root?limit=10000&filtroBusca=${filtroBusca}&status=${status}&teste=${teste}&diasCriacao=${diasCriacao}&diasSemUso=${diasSemUso}&situation=${situation}&plano=${plano}&dateIni=${dateIni}&dateFim=${dateFim}`;
		return this.http.get(this.URL + url, this.BS.getHeaders(true)).toPromise();
	}

	getCorretoraSessao() {
		return this.http.get(this.URL + 'cfg/corretora', this.BS.getHeaders(true)).toPromise();
	}

	salvaLead(body) {
		if (this.ENV.production) {
			return this.http.post(this.URL + 'cfg/capturaLead', body, this.BS.getHeaders()).toPromise();
		}
	}

	criarOrganizacaoMoviDesk(){
		this.http.post(this.URL + 'cfg/corretora/criarRegistroOrganizacao', {}, this.BS.getHeaders(true)).toPromise().then(ret =>{
			console.log('--moviDesk');
		})
		.catch(err => console.log(err));
	}

}