import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { adicionarUsuariosModel } from '../models/pagamento.model';

@Injectable({
  providedIn: 'root'
})
export class ApiInfocapebsVindiService {

  private ENV: any = environment;
  private URL;

  constructor(private http: HttpClient, private BS: BaseService) {
    this.URL = this.ENV.apice.url;
  }

  cadastroCorretroaVindi(body) {
    return this.http.post(this.URL + `cadastros/cadastroCorretroa`, body, this.BS.getHeaders(true)).toPromise();
  }

  buscaPlanos() {
    return this.http.get(this.URL + `cadastros/getPlanos`, this.BS.getHeaders(true)).toPromise();
  }

  buscaProdutos() {
    return this.http.get(this.URL + `cadastros/getProdutos`, this.BS.getHeaders(true)).toPromise();
  }

  criaPerfilPagto(body) {
    let headers = {
      "headers": {
        "Authorization": "Basic " + btoa(this.ENV.vindi.publicKey)
      }

    }

    return this.http.post(this.ENV.vindi.url + `public/payment_profiles`, body, headers).toPromise();
  }

  getAssinatura(assinaturaId){
    return this.http.get(this.URL + `cadastros/assinatura/${assinaturaId}`, this.BS.getHeaders(true)).toPromise();
  }

  cancelaAssinatura(assinaturaId){
    return this.http.delete(this.URL + `cadastros/assinatura/${assinaturaId}`, this.BS.getHeaders(true)).toPromise();
  }

  cancelaCartao(idCartao) {
    return this.http.post(this.URL + `cadastros/cancelaCartao/${idCartao}`, {}, this.BS.getHeaders(true)).toPromise();
  }

  confirmaFormaPagto(body) {
    return this.http.post(this.URL + `cadastros/confirmaFormaPagto`, body, this.BS.getHeaders(true)).toPromise();
  }

  salvarAssinatura(body) {
    return this.http.post(this.URL + `cadastros/assinatura`, body, this.BS.getHeaders(true)).toPromise();
  }

  adicionarUsuariosExtras(body: adicionarUsuariosModel){
    return this.http.post(this.URL + `cadastros/usuariosAdicionais`, body, this.BS.getHeaders(true)).toPromise();
  }

  dadosAssinaturaDBApice(){
    return this.http.get(this.URL + `cadastros/assinatura-apice`, this.BS.getHeaders(true)).toPromise();
  }

  checkStatusPagamento(faturaAvulsa?:boolean){
    return this.http.get(this.URL + `cadastros/checkPagamento${faturaAvulsa ? '?faturaAvulsa=true' : ''}`, this.BS.getHeaders(true)).toPromise();
  }

  cobrancaManual(body: any){
    return this.http.post(this.URL + `cadastros/cobrancaManual`, body, this.BS.getHeaders(true)).toPromise();
  }

  buscaFaturas() {
    return this.http.get(`${this.URL}cadastros/buscaFaturas`, this.BS.getHeaders(true)).toPromise();
  }

}