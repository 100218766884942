import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiUsuarioService } from '../services/api-usuario.service';


@Injectable({
  providedIn: 'root'
})
export class ActionGuard implements CanActivate {

	constructor(private apiUsuario: ApiUsuarioService, private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.verificaStatus();
  }

  private verificaStatus() {
    let result : any = this.apiUsuario.verificaLicenca();
    if (result?.prazo < 0 && !result.periodoTeste) {
      this.router.navigate(['/dashboard']);
      return false;
    }

    return true;
	}
  
}
