import {
    MomentDateAdapter
} from '@angular/material-moment-adapter';

import * as Moment from 'moment';
import { FormControl, AbstractControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

export const MOMENT_LOCALE = {
    provide: MAT_DATE_LOCALE,
    useValue: 'pt-BR'
};

export const MOMENT_ADAPTER = {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE]
};

export const MOMENT_FORMATS = {
    provide: MAT_DATE_FORMATS,
    useValue: MY_FORMATS
};

/**
 * Metodo chamado quando a data muda no campo invisivel para refletir no campo visivel.
 * @param event evento de mudança da data no campo invisivel.
 * @param control campo de data visivel.
 * 
 */

export function onChangeDate(event: MatDatepickerInputEvent<any>, control: AbstractControl) {
    let date: Moment.Moment = event.value;
    control.setValue(date.format('L'));
}

/**
 * Metodo chamado para abrir datepicker e setar a data do campo visivel no invisivel.
 * @param picker datepicker.
 * @param control campo de data visivel.
 * @param controlInv campo de data invisivel.
 */

export function openCalendarDatepicker(picker, control: AbstractControl, controlInv: AbstractControl, event?: Event) {
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    let dateStr = control.value;
    if (dateStr && dateStr.match(/^([0-9]{2}\/[0-9]{2}\/[0-9]{4})$/)) {
        let dateInt = dateStr.split("/").reverse().map(item => { return parseInt(item) });

        dateInt[1] = dateInt[1] - 1;

        if (Moment(dateInt).isValid()) {
            controlInv.setValue(Moment(dateInt));
        }
        else {
            controlInv.setValue(null);
        }

    }
    else {
        controlInv.setValue(null);
    }
    //moment([2017 , 0 , 22]);
    picker.open();
}
