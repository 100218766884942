<div>
    <div [ngStyle]="{'padding-top':'10px', 'max-height':'67px'}">
        <mat-form-field>
            <mat-radio-group [formControl]="fg_clientes.controls.ativo">
                <mat-select placeholder="Ativos" [formControl]="fg_clientes.controls.ativo">
                    <mat-option [value]="'todos'">Todos</mat-option>
                    <mat-option [value]="'ativos'">Ativos</mat-option>
                    <mat-option [value]="'inativos'">Inativos</mat-option>
                </mat-select>
            </mat-radio-group>
        </mat-form-field>
    </div>
    <div [ngStyle]="{'padding-top':'10px', 'max-height':'67px'}">
        <mat-form-field>
            <mat-radio-group [formControl]="fg_clientes.controls.favorito">
                <mat-select placeholder="Favoritos" [formControl]="fg_clientes.controls.favorito">
                    <mat-option [value]="'todos'">Todos</mat-option>
                    <mat-option [value]="'favoritos'">Favoritos</mat-option>
                    <mat-option [value]="'nao_favoritos'">Não favoritos</mat-option>
                </mat-select>
            </mat-radio-group>
        </mat-form-field>
    </div>
    <div [ngStyle]="{'padding-top':'10px', 'max-height':'67px'}">
        <mat-form-field>
            <mat-radio-group [formControl]="fg_clientes.controls.orderBy">
                <mat-select placeholder="Ordernar Por" [formControl]="fg_clientes.controls.orderBy">
                    <mat-option [value]="'nome'">Nome</mat-option>
                    <mat-option [value]="'createdAtAsc'">Data de criação crescente</mat-option>
                    <mat-option [value]="'createdAtDesc'">Data de criação decrescente</mat-option>
                    <mat-option [value]="'updatedAtAsc'">Data de modificação crescente</mat-option>
                    <mat-option [value]="'updatedAtDesc'">Data de modificação decrescente</mat-option>
                </mat-select>
            </mat-radio-group>
        </mat-form-field>
    </div>

    <div class="actionButtonsFiltros">
        <button mat-button class="btn-primary-ap" (click)="buildString()">FILTRAR</button>
        <button mat-button class="btn-back" (click)="clearFilter()">LIMPAR FILTROS</button>
    </div>
</div>