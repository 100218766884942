import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageHandler } from 'src/app/utils/form-field-message-handler';
import { FormGroup, FormControl } from '@angular/forms';
import { Mask } from 'src/app/utils/masks';
import { Formatter } from 'src/app/utils/formatter';
import { onChangeDate, openCalendarDatepicker } from 'src/app/utils/moment-config-datepicker';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';

@Component({
  selector: 'filtro-corretoras',
  templateUrl: './corretoras-filtro.component.html',
  styleUrls: ['./corretoras-filtro.component.scss']
})
export class CorretorasFiltroComponent implements OnInit {

  errorMessage = MessageHandler.errorMessage;
  masks = Mask;
  fg_corretoras: FormGroup;
  plano = "";
  statusAtual = "";
  statusCor = "";
  corretoraFake = "";
  diasCriacao;
  diasUltSessao;

  // Diz que vai enviar um evento para 'eventFilter'
  @Output('eventFilter') eventFilter = new EventEmitter();
  @Output('clearFilter') limpaFiltro = new EventEmitter();

  constructor(
    private icons: RegisterIconsService,
    public filtroController: FiltroControllerService
  ) {
    icons.registerIcons(['calendar'])

    this.fg_corretoras = new FormGroup({
      dataCriacaoIni: new FormControl(''),
      dataCriacaoIniInv: new FormControl(''),
      dataCriacaoFim: new FormControl(''),
      dataCriacaoFimInv: new FormControl(''),
    });			
   }

  ngOnInit() {
    // this.verificaCheck();
  }

  onChange = onChangeDate;
  openCalendar = openCalendarDatepicker;

  buildString() {
    let rawValue = this.fg_corretoras.getRawValue();			
    let dataIni = (Formatter.dateAAAAMMDD(rawValue.dataCriacaoIni));
    let dataFim = (Formatter.dateAAAAMMDD(rawValue.dataCriacaoFim));
    if (dataIni) dataIni=`${dataIni}T00:00:00.000Z`;
    else ""
    if (dataFim) dataFim=`${dataFim}T23:59:59.999Z`;
    else ""
    let jsonEnvio = {
      status: this.statusCor,
      teste: this.corretoraFake,
      diasCriacao: this.diasCriacao,
      diasSemUso: this.diasUltSessao,
      situation: this.statusAtual,
      plano: this.plano,
      dateIni: dataIni,
      dateFim: dataFim,
    }

    let filtros = { values: jsonEnvio }
    this.filtroController.addFiltrosExtras('corretoras', filtros);
    this.eventFilter.emit(filtros)
  }

  clearFilter() {
    this.diasCriacao = null;
    this.diasUltSessao = null;
    this.plano = "";
    this.statusAtual = "";
    this.fg_corretoras = new FormGroup({
      // Filtro config-corretoras
      dataCriacaoIni: new FormControl(''),
      dataCriacaoIniInv: new FormControl(''),
      dataCriacaoFim: new FormControl(''),
      dataCriacaoFimInv: new FormControl(''),
    });		
    this.limpaFiltro.emit("");
    this.filtroController.limparFiltro('corretoras')
    this.buildString();
  }

  // checkSelect:boolean;
  // verificaCheck() {
  //   this.checkSelect = this.filtroController.filtroCorretoras?.contratante === true ? true : false;
  // }

}
