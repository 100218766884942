import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebouncingDirective } from './debounce.directive';
import { NgxDebouncingDirective } from './ngx-debounce.directive';
import { DebouncerValidationDirective } from './debouncer-validation.directive';

@NgModule({
	declarations: [
		DebouncingDirective,
		NgxDebouncingDirective,
		DebouncerValidationDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		DebouncingDirective,
		NgxDebouncingDirective,
		DebouncerValidationDirective
	]
})
export class DebounceModule { }
