import { ViewValue } from 'src/app/models/form-portugal/view-value.model';
import { FabricanteJson } from '../utils/fabricantes';

export class ConstantPortugal {
  
  // OPTIONS SELECTS
  static sexo: ViewValue[] = [
    { value: 'F', viewValue: 'Feminino' },
    { value: 'M', viewValue: 'Masculino' }
  ];

  static combustivel: ViewValue[] = [
    { value: 1, viewValue: 'Gasolina' },
    { value: 2, viewValue: 'Gasóleo'},
    { value: 3, viewValue: 'GLP'},
    { value: 4, viewValue: 'Gás Natual'},
    { value: 5, viewValue: 'Bio Gás'},
    { value: 6, viewValue: 'Híbrido'},
    { value: 7, viewValue: 'Célula de Hidrogénio'},
    { value: 8, viewValue: 'Electrico'},
    { value: 9, viewValue: 'Outro'}
  ]

  static dispAntiFurto: ViewValue[] = [
    { value: 1, viewValue: 'Alarme'},
    { value: 2, viewValue: 'Alarme e Garagem'},
    { value: 3, viewValue: 'Garagem'},
    { value: 4, viewValue: 'Sem Medidas Dissuasoras'}
  ]

  static zeroKm: ViewValue[] = [
    { value: true, viewValue: 'Sim'},
    { value: false, viewValue: 'Não'}
  ]

  static tpUso: ViewValue[] = [
    { value: 1, viewValue: 'Apenas lazer'},
    { value: 2, viewValue:'Locomoção diária'},
    { value: 3, viewValue: 'Outros ramos de atividade'},
    { value: 4, viewValue: 'Prestação de serviços'},
    { value: 5, viewValue: 'Representação comercial'},
    { value: 6, viewValue: 'Transporte por aplicativos'}
  ]

  static tpLocalPernoite: ViewValue[] = [
    { value: 1, viewValue: 'Com portão manual'},
    { value: 2, viewValue: 'Com portão automático'},
    { value: 3, viewValue: 'Em local pago (de terceiros)'},
    { value: 4, viewValue: 'Não tem garagem'}
  ]

  static tpCobertura: ViewValue[] = [
    { value: 1, viewValue: 'Compreensiva'},
    { value: 3, viewValue: 'RCF'}
  ]

  static condutores: ViewValue[] = [
    { value: 1, viewValue: 'Próprio tomador'},
    { value: 2, viewValue: 'Outro'}
  ]

  static seguradoraAnterior: ViewValue[] = [
    { value: 'ageas', viewValue: 'Ageas Seguros'},
    { value: 'tranquilidade', viewValue: 'Tranquilidade Seguros'},
    { value: 'zurich', viewValue: 'Zurich Portugal'},
    { value: '1', viewValue: 'Bradesco'},
    { value: '3', viewValue: 'Mapfre'},
    { value: '4', viewValue: 'HDI'},
    { value: '5', viewValue: 'Allianz'},
    { value: '6', viewValue: 'Sompo'},
    { value: '7', viewValue: 'Zurich'},
    { value: '8', viewValue: 'Porto Seguro'},
    { value: '9', viewValue: 'Itaú'},
    { value: '10', viewValue: 'Azul'},
    { value: '11', viewValue: 'Tokio'},
    { value: '12', viewValue: 'Liberty'},
    { value: '13', viewValue: 'Mitsui'},
    { value: '17', viewValue: 'Sul América'},
    { value: '18', viewValue: 'Alfa'},
    { value: '19', viewValue: 'Sancor'},
    { value: '20', viewValue: 'Suhai'},
    { value: '22', viewValue: 'Aliro'},
    { value: '22', viewValue: 'Sura'},
  ]

  static sinistros: ViewValue[] = [
    { value: 0, viewValue: 'Sem Sinistros'},
    { value: 1, viewValue: '1'},
    { value: 2, viewValue: '2'},
    { value: 3, viewValue: '3'},
    { value: 4, viewValue: '4'},
    { value: 5, viewValue: '5'},
    { value: 6, viewValue: '6'},
    { value: 7, viewValue: '7'},
    { value: 8, viewValue: '8'},
    { value: 9, viewValue: '9'},
    { value: 10, viewValue: '10'}
  ]

  static bonusRenovacao: ViewValue[] = [
    { value: 0, viewValue: 'Sem bónus'},
    { value: 1, viewValue: '1'},
    { value: 2, viewValue: '2'},
    { value: 3, viewValue: '3'},
    { value: 4, viewValue: '4'},
    { value: 5, viewValue: '5'},
    { value: 6, viewValue: '6'},
    { value: 7, viewValue: '7'},
    { value: 8, viewValue: '8'},
    { value: 9, viewValue: '9'},
    { value: 10, viewValue: '10'}
  ]

  static estados: ViewValue[] = [
    { value: 'VIANA-DO-CASTELO', viewValue: 'Viana do Castelo'},
    { value: 'BRAGA', viewValue: 'Braga'},
    { value: 'VILA-REAL', viewValue: 'Vila Real'},
    { value: 'BRAGANÇA', viewValue: 'Bragança'},
    { value: 'PORTO', viewValue: 'Porto'},
    { value: 'AVEIRO', viewValue: 'Aveiro'},
    { value: 'GUARDA', viewValue: 'Guarda'},
    { value: 'VISEU', viewValue: 'Viseu'},
    { value: 'COIMBRA', viewValue: 'Coimbra'},
    { value: 'CASTELO-BRANCO', viewValue: 'Castelo Branco'},
    { value: 'LEIRIA', viewValue: 'Leiria'},
    { value: 'LISBOA', viewValue: 'Lisboa'},
    { value: 'SANTAREM', viewValue: 'Santarém'},
    { value: 'SETUBAL', viewValue: 'Santúbal'},
    { value: 'BEJA', viewValue: 'Beja'},
    { value: 'EVORA', viewValue: 'Évora'},
    { value: 'PORTALEGRE', viewValue: 'Portalegre'},
    { value: 'FARO', viewValue: 'Faro'},
  ]

  public static get tempoHabilitacaoTxt(): any {
    return {
      0: 'Recem habilitado',
      1: 'Até 1 ano',
      2: 'Até 2 anos',
      3: 'Até 3 anos',
      4: 'Até 4 anos',
      5: 'Até 5 anos',
      6: 'Até 6 anos',
      7: 'Até 7 anos',
      8: 'Até 8 anos',
      9: 'Até 9 anos',
      10: 'Até 10 anos',
      11: 'Mais de 10 anos',
    };
  }

  // MASCARAS
  public static get maskValidate(): any {

    return {
      ANO: '0000',
      CEP: '0000-000',
      NIF: '000000000',
      DATA: '00/00/0000',
      PLACA: 'AA-AA-AA',
      PASSAGEIROS: '0',
      DATA_MATRICULA: '00/0000',
    };
  }
}
