import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { ConfSeguradora } from '../models/conf-seguradora.model';
import { ApiUsuarioService } from './api-usuario.service';

@Injectable({
	providedIn: 'root'
})
export class ApiSeguradoraService {
	
	private ENV:any = environment;
	private URL;

	constructor(private http: HttpClient, private BS: BaseService, private apiUsuario: ApiUsuarioService) {
		this.URL = this.ENV.apice.url;
	}

	/**
	 * Lista as configurações de seguradora do usuário.
	 */
	buscaConfSeguradoras() {
		return this.http.get(this.URL + 'cfg/seguradora/config', this.BS.getHeaders(true)).toPromise();		
	}

	/**
	 * Busca uma configuração de seguradora do usuário.
	 * @param seguradoraId id da seguradora.
	 */
	buscaConfSeguradora(seguradoraId): Promise<any> {
		// if (!seguradoraId) throw new Error('seguradoraId inválido: "' + seguradoraId + '"');	
		// let dados = await this.apiBase.get('cfg/seguradora/config', { seguradoraId: seguradoraId });
		// let confSeguradora = new ConfSeguradora(dados);
		// return confSeguradora;		
		return this.http.get(this.URL + 'cfg/seguradora/config', this.BS.getHeaders(true, {seguradoraId: seguradoraId})).toPromise();
	}

	/**
	 * Salva uma configuração de seguradora do usuário.
	 * @param confSeguradora configuração de seguradora do usuário.
	 */
	salvaConfSeguradora(confSeguradora: ConfSeguradora): Promise<any> {
		let dados = confSeguradora.dados;
		return this.http.post(this.URL + 'cfg/seguradora/config', dados, this.BS.getHeaders(true)).toPromise();		
	}

	validaCredenciais(seguradoraId, usuario, senha): Promise<any> {
		let dados = {
			seguradora: seguradoraId,
			login: usuario,
			senha: senha
		}
		return this.http.post(this.URL + 'cfg/seguradora/config/credenciais', dados, this.BS.getHeaders(true)).toPromise();
	}

	validaCredenciaisMapfre(seguradoraId, usuario, senha, codSusep, codInterno): Promise<any> {
		let dados = {
			seguradora: seguradoraId,
			login: usuario,
			senha: senha,
			mapfreCodVc: codSusep,
			mapfreCodInt: codInterno
		}
		return this.http.post(this.URL + 'cfg/seguradora/config/credenciais', dados, this.BS.getHeaders(true)).toPromise()
	}

	validaCredenciaisGenerali(seguradoraId, usuario, senha, generaliCodigo) {
		let dados = {
			seguradora: seguradoraId,
			login: usuario,
			senha: senha,
			generaliCodigo: generaliCodigo
		}
		return this.http.post(this.URL + 'cfg/seguradora/config/credenciais', dados, this.BS.getHeaders(true)).toPromise();
	}

	pegarUsuariosMapfre(seguradoraId, codSusep, codInterno): Promise<any> {
		let dados = {
			seguradora: seguradoraId,
			mapfreCodVc: codSusep,
			mapfreCodInt: codInterno,
			acao: "2"
		}
		return this.http.post(this.URL + 'cfg/seguradora/config/credenciais', dados, this.BS.getHeaders(true)).toPromise();		
	}

	validaCredenciaisGrupoPorto(seguradoraId, usuario, senha, susep): Promise<any> {
		let dados = {
			seguradora: seguradoraId,
			login: usuario,
			senha: senha,
			portoSusep: susep
		}
		return this.http.post(this.URL + 'cfg/seguradora/config/credenciais', dados, this.BS.getHeaders(true)).toPromise();		
	}

	obterSusepGrupoPorto(seguradoraId, usuario, senha) {
		let dados = {
			seguradora: seguradoraId,
			login: usuario,
			senha: senha,
			acao: "2"
		}
		return this.http.post(this.URL + 'cfg/seguradora/config/credenciais', dados, this.BS.getHeaders(true)).toPromise();		
	}

	async getCredenciaisSeguradora(idIntegracaoCfg) {
		return this.http.get(this.URL + `cfg/seguradora/credencial/${idIntegracaoCfg}`, this.BS.getHeaders(true)).toPromise();		
	}

	loadingCargaInicial: boolean = false;
	async cargaInicialDocumentos(idIntegracaoCfg){
		if(idIntegracaoCfg){
			this.loadingCargaInicial = true;
			try {
				let authPdocs = await this.apiUsuario.autenticaNoPDocs();
				if(authPdocs?.urlPDocs){			
					this.apiUsuario.setCredenciaisPDocs(authPdocs);
					let url = encodeURI(`${authPdocs.urlPDocs}app/rest/corretorasegcfg/${idIntegracaoCfg}/cargaTotal`);
					try {
						let reqOpts = {
							headers: new HttpHeaders({
								'Content-Type': 'application/json',
								'Authorization': authPdocs.token
							})
						};
						
						return this.http.get(url, reqOpts).toPromise();		
						
					} catch (error) {
						throw error;
					}
				}
			} catch (error) {
				console.log('error ao setar carga inicial documentos', error);
				throw error;
			}
			
		}
	}

}
