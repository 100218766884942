import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';

@Component({
	selector: 'filtro-clientes',
	templateUrl: './clientes.component.html',
	styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

	@Output('eventFilter') eventFilter = new EventEmitter();
	@Output('clearFilter') limpaFiltro = new EventEmitter();
	@Output('closeFilter') fechaFiltro = new EventEmitter();

	fg_clientes: FormGroup;

	constructor(public filtroController: FiltroControllerService) {
		this.fg_clientes = new FormGroup({
			filtroBusca: new FormControl(this.filtroController.filtroClientes?.filtroBusca ? this.filtroController.filtroClientes?.filtroBusca : ''),
			orderBy: new FormControl(this.filtroController.filtroClientes?.orderBy ? this.filtroController.filtroClientes?.orderBy : 'nome'),
			ativo: new FormControl(this.filtroController.filtroClientes?.ativo ? this.filtroController.filtroClientes?.ativo : 'ativos'),
			favorito: new FormControl(this.filtroController.filtroClientes?.favorito ? this.filtroController.filtroClientes?.favorito : 'todos'),
			periodo: new FormControl(this.filtroController.filtroClientes?.periodo ? this.filtroController.filtroClientes?.periodo : 'qualquer'),
			rotuloPeriodo: new FormControl(this.filtroController.filtroClientes?.rotuloPeriodo ? this.filtroController.filtroClientes?.rotuloPeriodo : 'Período'),
			// data de atualização mais antiga
			dataIni: new FormControl(this.filtroController.filtroClientes?.dataIni ? this.filtroController.filtroClientes?.dataIni : ''),
			// data de atualização mais recente
			dataFim: new FormControl(this.filtroController.filtroClientes?.dataFim ? this.filtroController.filtroClientes?.dataFim : ''),
			// data de atualização mais antiga (SQL)
			creationPeriodBegin: new FormControl(this.filtroController.filtroClientes?.creationPeriodBegin ? this.filtroController.filtroClientes?.creationPeriodBegin : undefined),
			// data de atualização mais recente (SQL)
			creationPeriodEnd: new FormControl(this.filtroController.filtroClientes?.creationPeriodEnd ? this.filtroController.filtroClientes?.creationPeriodEnd : undefined),
			// data de atualização mais antiga (SQL)
			updatePeriodBegin: new FormControl(this.filtroController.filtroClientes?.updatePeriodBegin ? this.filtroController.filtroClientes?.updatePeriodBegin : undefined),
			// data de atualização mais recente (SQL)
			updatePeriodEnd: new FormControl(this.filtroController.filtroClientes?.updatePeriodEnd ? this.filtroController.filtroClientes?.updatePeriodEnd :  undefined),
			page: new FormControl(1),
			limit: new FormControl(40),
		});
	}

	ngOnInit() { }

	buildString() {
		this.filtroController.addFiltrosExtras('clientes',this.fg_clientes.getRawValue());
		let filtros = { values: this.fg_clientes.getRawValue() };
		this.eventFilter.emit(filtros);
		this.filtroController.verificaFiltro();
	}

	clearFilter() {
		this.fg_clientes = new FormGroup({
			ativo: new FormControl('ativos'),
			favorito: new FormControl('todos'),
			orderBy: new FormControl('nome')
		});
		this.filtroController.limparFiltro('clientes');
		this.filtroController.verificaFiltro();
		this.limpaFiltro.emit();
		this.buildString();
	}

}
