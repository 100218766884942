import { Injectable } from '@angular/core';
import { EventosModel } from '../models/eventos.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ApiEventosService {

	private ENV:any = environment;
	private URL;

	constructor(private http: HttpClient, private BS: BaseService) {
		this.URL = this.ENV.apice.url;
	}
	
	/** Salva um evento. */
	criarEditarEvento(evento: EventosModel) {		
		return this.http.post(this.URL + 'calculo/evento', evento, this.BS.getHeaders(true)).toPromise();		
	}

	buscarListaTarefas(status?, usuarios?){
		return this.http.get(this.URL + `calculo/tarefa?status=${status}&usuarios=${usuarios}`, this.BS.getHeaders(true)).toPromise();
	}

	/** Salva um evento. */
	async salvaEvento(evento: {
		id?: string, // ao atualizar
		tipo?: number, //  2 - Anotacao , 3 - Tarefa, 4 - Agendamento
		descricao?: string,
		concluido?: boolean,
		cancelado?: string,
		dataHoraIni?: string,
		dataHorafim?: string,
		negocioId?: string,
		responsavelId?: string,
		eventoAnteriorId?: string, // id do Evento ao qual o Evento sendo salvo retifica.
	}) {
		return this.http.post(this.URL + 'calculo/evento', evento, this.BS.getHeaders(true)).toPromise();
	}

}
