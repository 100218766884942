import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentoComponent } from './documento.component';
import { PageModules } from 'src/app/utils/pageModules';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { MatListModule } from '@angular/material/list';



@NgModule({
  declarations: [DocumentoComponent],
  imports: [
    CommonModule,
    PageModules,
    MatTabsModule,
    MatDialogModule,
    MatFormFieldModule,
    ModulosDeFormulario,
    MatListModule
  ],
  exports: [DocumentoComponent]
})
export class DocumentoModule { }
