import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiUsuarioService } from '../services/api-usuario.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private apiUsuario: ApiUsuarioService, private router: Router){}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.verificarAcesso();
	}

	private verificarAcesso() {
		if (this.apiUsuario.getCredenciais()) return true

		this.router.navigate(['/login']);
		return false
	}

}
