import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MenusService {

	toggle: Subject<boolean>;
	menuContraido: Subject<boolean>;
	menuFiltro: Subject<boolean>;

	esconderBotao: Subject<boolean>

	onFiltragem = new Subject<any>();
	
	// open usado para fazer bind para mostrar/ocultar o menu de filtro da tela 
	open: boolean = false;
	//page é usado para passar a página e mostrar filtro respectivo a ela
	page: string = '';

	constructor() {

		this.esconderBotao = new BehaviorSubject<boolean>(false);
		this.toggle = new BehaviorSubject<boolean>(false);
		this.menuContraido = new BehaviorSubject<boolean>(false);
		this.menuFiltro = new BehaviorSubject<any>({});
	}

	toggleMenu(): Observable<boolean> {
		return this.toggle.asObservable();
	}

	setToggleMenu(value: boolean): void {
		this.toggle.next(value);
	}

	menuDesktop(): Observable<boolean> {
		return this.menuContraido.asObservable();
	}

	setMenuDesktop(value: boolean): void {
		this.menuContraido.next(value);
	}

	menuFilter(): Observable<any> {
		return this.menuFiltro.asObservable();
	}

	setMenuFilter(ev: any): void {		
		this.menuFiltro.next(ev);
	}

	enviaFiltro(value: any) {
		this.onFiltragem.next(value);
	}

	hiddenButton(): Observable<boolean> {
		return this.esconderBotao.asObservable();
	}

	setButton(newValue: boolean): void {	
		this.esconderBotao.next(newValue);
	}

}
