<div>

    <!-- <div [ngStyle]="{'display':'flex', 'margin-bottom': '8px'}">
        <button mat-button (click)="aVencer()" [ngStyle]="{'padding': '0px 6px', 'background': '#E5E5E5'}" >A renovar em 15 dias</button>
        <button mat-button (click)="vencidas7()" [ngStyle]="{'padding': '0px  6px', 'margin-left': '6px', 'background': '#E5E5E5'}">Vencidos a 7 dias</button>       
    </div> -->
    <!-- <div [ngStyle]="{'display':'flex', 'margin-bottom': '16px'}">
        <button mat-button (click)="ocultos()" [ngStyle]="{'padding': '0px  6px','background': '#E5E5E5'}">Ocultos</button>
    </div> -->

    <mat-form-field>
        <mat-label>Seguradoras</mat-label>
        <mat-select [(ngModel)]="seguradora">
            <mat-option *ngFor="let seguradora of seguradoras" [value]="seguradora.value"><img *ngIf="seguradora.img"
                    class="imgSeg" [ngStyle]="{'width':'30px','height':'30px', 'margin-right':'4px'}"
                    [src]="seguradora.img">
                {{ seguradora.nome }} </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Tipo do periodo</mat-label>
        <mat-select [(ngModel)]="tipoPeriodo" [disabled]="vencidas != 0">
            <mat-option value="E">Emissão</mat-option>
            <mat-option value="V">Fim da Vigência</mat-option>
            <mat-option value="I">Importação</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Periodo</mat-label>
        <mat-select [(ngModel)]="periodo" (selectionChange)="verificaPeriodo($event)" [disabled]="vencidas != 0">
            <mat-option value="I">Indefinido</mat-option>
            <mat-option value="7">Últimos 7 dias</mat-option>
            <mat-option value="15">Últimos 15 dias</mat-option>
            <mat-option value="30">Últimos 30 dias</mat-option>
            <mat-option value="p">Personalizado</mat-option>
        </mat-select>
    </mat-form-field>

    <form [formGroup]="fg_datas">
        <mat-form-field *ngIf="showDatas">
            <mat-label>Data Inicial</mat-label>
            <input autocomplete="off" [mask]="masks.DD_MM_AAAA" matInput inputmode="numeric" debounce-validation="500"
                [validate-on-keydown]="true" [formControl]="fg_datas.controls.dataIni">
            <mat-icon matSuffix [svgIcon]="'calendar'"
                (click)="openCalendar(pickerIni, fg_datas.controls.dataIni, fg_datas.controls.dataIniInv, $event)">
            </mat-icon>
            <mat-datepicker #pickerIni></mat-datepicker>
            <input [matDatepicker]="pickerIni" (dateChange)="onChange($event, fg_datas.controls.dataIni)"
                [ngStyle]="{display: 'none'}" [formControl]="fg_datas.controls.dataIniInv">
        </mat-form-field>

        <mat-form-field *ngIf="showDatas">
            <mat-label>Data Final</mat-label>
            <input autocomplete="off" [mask]="masks.DD_MM_AAAA" matInput inputmode="numeric" debounce-validation="500"
                [validate-on-keydown]="true" [formControl]="fg_datas.controls.dataFim">
            <mat-icon matSuffix [svgIcon]="'calendar'"
                (click)="openCalendar(pickerFim, fg_datas.controls.dataFim, fg_datas.controls.dataFimInv, $event)">
            </mat-icon>
            <mat-datepicker #pickerFim></mat-datepicker>
            <input [matDatepicker]="pickerFim" (dateChange)="onChange($event, fg_datas.controls.dataFim)"
                [ngStyle]="{display: 'none'}" [formControl]="fg_datas.controls.dataFimInv">
        </mat-form-field>
    </form>

    <mat-form-field>
        <mat-label>Renovação</mat-label>
        <mat-select [(ngModel)]="vencidas" (selectionChange)="verificaVencimentos($event)">
            <mat-option value="0">Nenhum</mat-option>
            <mat-option value="15">A vencer em 15 dias</mat-option>
            <mat-option value="30">A vencer em 30 dias</mat-option>
            <mat-option value="60">A vencer em 60 dias</mat-option>
            <mat-option value="V15">Vencidas a 15 dias</mat-option>
        </mat-select>
    </mat-form-field>
    

    <div class="actionButtonsFiltros">
        <button mat-button class="def-b-btn" (click)="buildString()">FILTRAR</button>
        <button mat-button class="def-w-btn" [ngStyle]="{'width':'150px'}" (click)="clearFilter()">LIMPAR
            FILTROS</button>
    </div>
</div>