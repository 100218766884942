import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Formatter } from '../utils/formatter';
import { FabricanteJson } from '../utils/fabricantes';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ApiVeiculoService {	

	private ENV:any = environment;
	private URL;

	constructor(private http: HttpClient, private BS: BaseService) {

	}

	/**
	 * Busca modelo de veículo.
	 * @param body 
	 */
	buscaVeiculo(ano, modelo, tipo): Promise<any> {
		if (!modelo && !ano) return;		
		let environment = this.ENV;
		let url = encodeURI(`${environment.multi.url}calculo/ptModelo?modelo=${modelo}&tipo=${tipo}&ano=${ano}`);
		return this.http.get(url, this.getOptions()).toPromise();
	}

	/**
	 * Busca modelo de veículo.
	 * @param body 
	 * 
	async buscaVeiculo(body): Promise<any> {
		try {
			let busca = await this.apiBase.http.post(environment.veiculo.modelo.url, body).toPromise();
			return busca
		} catch (error) {
			// console.log('erro buscar veiculo', error)
		}
	}

	/**
	 * Busca veículo por placa. ASD-1234.
	 * @param body 
	 */
	async buscaVeiculoPlaca(placa): Promise<any> {
		if (!placa) return;
		placa = placa.replace(/\W/g, '');
		if (placa.length != 7) return;
		try {
			let environment = this.ENV;
			let url = encodeURI(`${environment.multi.url}calculo/buscaPlaca?placa=${placa}`);
			// busca veículo por placa
			let veiculoPlaca: any = await this.http.get(url, this.getOptions()).toPromise();
			// busca veículo por fipe (para obter tipo de veículo)
			let veiculoFipe = veiculoPlaca ? await this.buscaVeiculoFipe(veiculoPlaca.fipe) : null;
			// faz merge dos resultados
			let resultado: Veiculo;

			if (veiculoPlaca) {
				resultado = {
					portas: veiculoPlaca.portas,
					passageiros: veiculoPlaca.passageiros,
					fipe: veiculoPlaca.fipe,
					anoFab: veiculoPlaca.anoFab,
					anoMod: veiculoPlaca.anoMod,
					placa: veiculoPlaca.placa,
					combustivel: veiculoPlaca.combustivel,
					chassi: veiculoPlaca.chassi,
					modelo: veiculoPlaca.modelo,
					fabricante: veiculoPlaca.codFabr ? parseInt(veiculoPlaca.codFabr) : null,
					fabricanteTexto: veiculoPlaca.fabricante || '',
					tipo: veiculoFipe ? veiculoFipe.tipo : null
				}
			}
			return resultado;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * Busca veículo por chassi. Ex.:9BF-ZH55SX-K8/218495.
	 * @param body 
	 */
	async buscaVeiculoChassi(chassi: string): Promise<any> {
		if (!chassi) return;
		chassi = chassi.replace(/\W/g, '');
		if (chassi.length != 17) return;
		try {
			let environment = this.ENV;
			let url = encodeURI(`${environment.multi.url}calculo/chassiDecoder?chassi=${chassi}`);
			let veiculos: any = await this.http.get(url, this.getOptions()).toPromise();
			let veiculo = veiculos && veiculos.length ? veiculos[0] : null;
			let resultado: Veiculo;

			if (veiculo) {
				resultado = {
					portas: veiculo.portas,
					passageiros: veiculo.passageiros,
					fipe: veiculo.fipe,
					anoFab: veiculo.anoFab,
					anoMod: veiculo.anoMod,
					tipo: veiculo.tipoVeic,
					combustivel: veiculo.combustivel,
					chassi: veiculo.chassi,
					placa: veiculo.placa,
					modelo: veiculo.modelo,
					fabricante: veiculo.codFabr ? parseInt(veiculo.codFabr) : null,
					fabricanteTexto: veiculo.fabricante || '',
				};
			}
			return resultado;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * Busca veículo por fipe. Ex.: 005275-2.
	 * @param body 
	 */
	async buscaVeiculoFipe(fipe): Promise<any> {
		if (!fipe) return;
		fipe = Formatter.fipe(fipe);
		let fipeLimpo = fipe.replace(/\D/g, '');
		if (fipeLimpo.length < 3) return;
		try {
			let environment = this.ENV;
			let url = encodeURI(`${environment.multi.url}calculo/fipeModelo?fipe=${fipe}`);
			let veiculos: any = await this.http.get(url, this.getOptions()).toPromise();
			// remove lixo			
			let veiculo = (veiculos || []).find((veiculo) => {
				let fipeEncontrado = (veiculo.id || '').replace(/\D/g, '');
				return fipeEncontrado == fipeLimpo;
			});
			// let veiculo = veiculos && veiculos.length ? veiculos[0] : null;
			if (!veiculo) return;
			let fabricante = FabricanteJson.getFabricanteByValor(veiculo.fipeFabricanteId);
			// console.log('veiculo', veiculo)
			// console.log('fabricante', fabricante fabricante.nome)

			let resultado: Veiculo = {
				portas: null,
				passageiros: null,
				fipe: null,
				anoFab: null,
				anoMod: null,
				combustivel: null,
				chassi: null,
				placa: null,
				fabricante: veiculo ? parseInt(veiculo.fipeFabricanteId) : null,
				fabricanteTexto: fabricante ? fabricante.nome : '',
				modelo: veiculo ? veiculo.modelo : null,
				tipo: veiculo ? veiculo.tipo : null
			};
			return resultado;
		} catch (error) {
			// console.log('error ao buscar placa', error)
			throw error;
		}
	}

	/**
	 * REMOVER FUTURAMENTE
	 * Busca veículo por placa. ASD-1234
	 * @param body 
	 */
	async buscaVeiculoPlacaOLD(body): Promise<any> {
		try {
			try {
				let reqOpts = {
					headers: new HttpHeaders({
						'Content-Type': 'application/json',
						'x-api-key': 'Z4syr0pY8t3AM8u5qaYv34u3ibHCcSkZ10Lyr1ih'
					})
				};
				let environment = this.ENV;
				let ret = await this.http.post(environment.veiculo.placa.url, body, reqOpts).toPromise();
				return ret;
			} catch (error) {
				throw error;
			}
		} catch (error) {
			// console.log('error ao buscar placa', error)
			throw error;
		}
	}

	getOptions() {
		let reqOpts = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
		return reqOpts;
	}

}


class Veiculo {
	portas
	passageiros
	fipe
	anoFab
	anoMod
	tipo
	combustivel
	chassi
	placa
	modelo
	fabricante
	fabricanteTexto
}