import { NgModule } from '@angular/core';
import { MenuHamburgerModule } from '../components/menu-hamburger/menu-hamburger.module';
import { ToolBarActionsModule } from '../components/tool-bar-actions/tool-bar-actions.module';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LoadingPageModule } from '../components/loading-page/loading-page.module';
import { ErrorPageModule } from '../components/error-page/error-page.module';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DebounceModule } from 'src/app/directives/debounce/debounce.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
    exports: [
        MenuHamburgerModule,
        ToolBarActionsModule,
        MatCardModule,
        MatToolbarModule,
        LoadingPageModule,
        ErrorPageModule,
        MatListModule,
        MatTooltipModule,
        DebounceModule,
        FlexLayoutModule,
        MatSliderModule,
    ]
})

export class PageModules { }