import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiNegociosService } from 'src/app/services/api-negocios.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';

@Component({
	selector: 'documento',
	templateUrl: './documento.component.html',
	styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {

	@Input('data') data: any;
	@Output('fecharModal') fecharModalEvent = new EventEmitter();
	@Output('abrirModalNegocio') abrirModalNegocioEvent = new EventEmitter();

	disabled: boolean = true;
	formFake: FormGroup;
	formFake2: FormGroup;
	formFake3: FormGroup;
	formFake4: FormGroup;
	anexos: any = [];

	constructor(
		public registerIcons: RegisterIconsService,
		public apiNegocios: ApiNegociosService,
		public snackBar: MatSnackBar,
	) {
		let icons = ['file-download-outline', 'credit-card-multiple', 'barcode', 'cash', 'close', 'plus', 'business'];
		registerIcons.registerIcons(icons);

		
	}

	ngOnInit(): void {
		this.anexos = this.data?.anexos;

		this.formFake = new FormGroup({
			seguradora: new FormControl(this.data.extras.tooltip || ''),
			apolice: new FormControl(this.data.apolice || ''),
			vigencia: new FormControl(this.data.datasVig.ini + ' à ' + this.data.datasVig.fim),
			dataEmissao: new FormControl(this.data.dataEmissaoF || ''),
			dataImportacao: new FormControl(this.data.dataImportacaoF || ''),
			proposta: new FormControl(this.data.proposta || ''),
			dataProposta: new FormControl(this.data.dataPropostaF || ''),
			premioLiquido: new FormControl(this.data.premios.liquido || ''),
			premioTotal: new FormControl(this.data.premios.total || ''),
			status: new FormControl(this.data.statusTxt || ''),
		});

		this.formFake2 = new FormGroup({
			bairro: new FormControl(this.data.segurado.bairro || ''),
			cep: new FormControl(this.data.segurado.cep || ''),
			cidade: new FormControl(this.data.segurado.cidade || ''),
			cpfCnpj: new FormControl(this.data.segurado.cpfCnpj || ''),
			dataNascimento: new FormControl(this.data.segurado.dataNascF || ''),
			email: new FormControl(this.data.segurado.email || ''),
			fone: new FormControl(this.data.segurado.fone || ''),
			logradouro: new FormControl(this.data.segurado.logradouro || ''),
			nome: new FormControl(this.data.segurado.nome || ''),
			numero: new FormControl(this.data.segurado.numero || ''),
			sexo: new FormControl(this.data.segurado.sexoF || ''),
			uf: new FormControl(this.data.segurado.uf || ''),
		});


		if (this.data.ramo === 31) {
			this.formFake3 = new FormGroup({
				CI: new FormControl(this.data?.itens?.length ? this.data.itens[0]['CI'] : ''),
				anoFabricacao: new FormControl(this.data?.itens?.length ? this.data.itens[0]['anoFabricacao'] : ''),
				anoModelo: new FormControl(this.data?.itens?.length ? this.data.itens[0]['anoModelo'] : ''),
				chassi: new FormControl(this.data?.itens?.length ? this.data.itens[0]['chassi'] : ''),
				codigoFipe: new FormControl(this.data?.itens?.length ? this.data.itens[0]['codigoFipe'] : ''),
				combustivel: new FormControl(this.data?.itens?.length ? this.data.itens[0]['combustivel'] : ''),
				danosCorporais: new FormControl(this.data?.itens?.length ? this.data.itens[0]['danosCorporais'] : ''),
				danosMateriais: new FormControl(this.data?.itens?.length ? this.data.itens[0]['danosMateriais'] : ''),
				danosMorais: new FormControl(this.data?.itens?.length ? this.data.itens[0]['danosMorais'] : ''),
				eixos: new FormControl(this.data?.itens?.length ? this.data.itens[0]['eixos'] : ''),
				fabricante: new FormControl(this.data?.itens?.length ? this.data.itens[0]['fabricante'] : ''),
				passageiros: new FormControl(this.data?.itens?.length ? this.data.itens[0]['passageiros'] : ''),
				placa: new FormControl(this.data?.itens?.length ? this.data.itens[0]['placa'] : ''),
				portas: new FormControl(this.data?.itens?.length ? this.data.itens[0]['portas'] : ''),
				veiculo: new FormControl(this.data?.itens?.length ? this.data.itens[0]['veiculo'] : ''),
			});
		}

		this.formFake4 = new FormGroup({
			bairro: new FormControl(this.data?.itens?.length ? this.data?.itens[0]?.bairro : ''),
			cep: new FormControl(this.data?.itens?.length ? this.data?.itens[0]?.cep : ''),
			cidade: new FormControl(this.data?.itens?.length ? this.data?.itens[0]?.cidade : ''),
			logradouro: new FormControl(this.data?.itens?.length ? this.data?.itens[0]?.logradouro : ''),
			numero: new FormControl(this.data?.itens?.length ? this.data?.itens[0]?.numero : ''),
			uf: new FormControl(this.data?.itens?.length ? this.data?.itens[0]?.uf : '')
		});
	}

	openDocument(doc) {
		window.open(doc.url, '_blank')
	}

	loadingCriaNegocio: boolean = false;
	negocioCriado: boolean = false;
	async criaNovoNegocio() {
		this.loadingCriaNegocio = true;
		//console.log(this.data)
		try {
			let docToNegocio: any = await this.apiNegocios.criaNovoNegocio(this.data);
			if (docToNegocio) {
				console.log(docToNegocio);
				if (docToNegocio.message === 'Já existe um negócio criado com este documento!') {
					this.mostraMensagem(docToNegocio.message)
				} else {
					this.data.negocioId = docToNegocio.id;
					this.mostraMensagem('Negócio criado com sucesso!')
				}
				this.loadingCriaNegocio = false;
				this.negocioCriado = true;
			}
		} catch (e) {
			this.loadingCriaNegocio = false;
			this.mostraMensagem('Falha ao criar negócio!')
			console.error('erro ao criar negócio ->', e);
		}
	}

	abrirModalNegocio() {
		if (this.data.negocioId) {
			this.abrirModalNegocioEvent.emit(this.data.negocioId);
		}
	}


	mostraMensagem(mensagem: string) {
		this.snackBar.open(mensagem, 'Ok', {
			duration: 10000,
		});
	}

	fecharModal() {
		this.fecharModalEvent.emit(this.negocioCriado)
		//emitir o evento para fechar o modal
		//this.dialogRef.close(this.negocioCriado);
	}

}
