import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuHamburgerComponent } from './menu-hamburger.component';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [MenuHamburgerComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [MenuHamburgerComponent]
})
export class MenuHamburgerModule { }
