import { Component, OnInit } from '@angular/core';
import { PlatformService } from './services/platform.service';
import { RegisterIconsService } from './services/register-icons.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MenusService } from './services/menus.service';
import { ApiUsuarioService } from './services/api-usuario.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalFormComponent } from './pages/cliente/modal-form/modal-form.component';
import { ApiS3Service } from './services/api-s3.service';
import { ModalNegocioComponent } from './pages/negocios/modal-negocio/modal-negocio.component';
import { ModalTarefaComponent } from 'src/app/components/tarefa/modal-tarefa/modal-tarefa.component';
import { ExtensaoService } from './services/extensao.service';
import { listaMenus, menuColaborador, listaReduzida } from './utils/menus-apice';
import { SobreComponent } from './components/sobre/sobre.component';
import { environment } from 'src/environments/environment';
import { ServiceWorkerService } from './services/service-worker.service';
import { ConnectionService } from 'ng-connection-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GAnalyticsService } from 'src/app/services/g-analytics.service';
import { ControllerStatusService } from './services/controller-status.service';
import { ApiInterfaceService } from 'src/app/services/api/api-interface.service';
import { ModalVideoIntroComponent } from './components/modal-video-intro/modal-video-intro.component';
import { TodoControllerService } from './services/todo-controller.service';
import { filter } from 'rxjs/operators';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    isAdmin: boolean = false;
    isRoot: boolean = false;
    title = 'Ápice';
    sessao;
    showFabButton: boolean = false;
    validacaoCorretora;
    mostraPeriodoTeste;
    
	public listaMenus      = listaMenus;    
    public menuColaborador = menuColaborador;
    public listaReduzida   = listaReduzida;
	
    menus = this.listaMenus;
    
    menuContraido: boolean = false;
    effectLogo: boolean = false;
    menuToggle: boolean = false;
    autenticado: boolean = false;
    fezCheckStatusSistema: boolean = false;
    callUpdateServicesInfos: boolean = false;
    showAlert: boolean = true;

    //mostrar/ocultar filtro
    filtro = {
        open: false,
        page: ''
    };

    version = environment.version;

    fabButtons = [
        {
            icone: 'menu-tarefas',
            titulo: 'Nova Tarefa',
            url: '/lista-tarefas',
            open: async (urlPage)=>{
                let url = this.router.url;
                let dialog = await this.dialog.open(ModalTarefaComponent, {
                    data: {tarefa: null, visualizar:false},
                    panelClass: 'modalDefaultP'
                })

                dialog.afterClosed().subscribe(ret=>{
                    if(ret){
                        this.apiUsuario.refreshListaTarefas.next(true);
                    }
                })

                this.gAnalytics.analyticsMonitor('click', 'nova_tarefa', 'Iniciando nova tarefa');
            },
            calcular: false
        },  
        {
            icone: 'business',
            titulo: 'Novo Negócio',
            url: '/funil-de-vendas',
            open: async (urlPage)=>{
                let url = this.router.url;
                let dialog = await this.dialog.open(ModalNegocioComponent, {
                    data: {idFirstItemOportunidades: null, negocio: null},
                    minWidth: 300,
                    minHeight: 300,
                    panelClass: ['modalNegocio', 'novoNegocio']
                })

                dialog.afterClosed().subscribe(ret=>{
                    if(ret == 'atualizar'){                        
                        //console.log('url', url);
                        // if(url != "/funil-de-vendas"){
                        //     this.loadingPage = true;
                        //     this.router.navigate([urlPage]).then(()=>{
                        //         this.loadingPage = false;
                        //     })
                        //     .catch(()=> {this.loadingPage = false;});
                        // }else{
                        //     this.updateNegocio.setUpdateNegocio(true);
                        // }                        
                    }
                })

                this.gAnalytics.analyticsMonitor('click', 'novo_negocio', 'Iniciando novo negócio');
            },
            calcular: false
        },   
        {
            icone: 'account',
            titulo: 'Novo Cliente',
            action: 'novo_cliente',
            open: async (urlPage?)=>{
                let widthModal = window.innerWidth;
                let modal = await this.dialog.open(ModalFormComponent, {
                    data: {id: 'novo'},
                    panelClass: 'modalDefaultP',
                    minHeight: 200,
			        minWidth: 250
                })
                modal.afterClosed().subscribe(data=>{
                    // if(data){
                    //     let modalCliente = this.dialog.open(ModalClienteComponent, {
                    //         data: {clienteId: data},
                    //         minHeight: 200,
                    //         panelClass: ['modalDefault']
                    //     })
                    // }
                })

                this.gAnalytics.analyticsMonitor('click', 'novo_cliente', 'Iniciando novo cliente');
            },
            // url: '/cliente-form/novo',
            url: './',
            calcular: false
        },   
        // {
        //     icone: 'motorbike',
        //     titulo: 'Cotar Moto',
        //     url: '/cotacao-form/tipo/moto',
        //     open: async (urlPage)=>{
                // this.loadingPage = true;
                // this.router.navigate([urlPage]).then(()=>{
                //     this.loadingPage = false;
                // })
                // .catch(()=> {this.loadingPage = false;});
        //     },
        //     calcular: true
        // },
        // {
        //     icone: 'cards-heart',
        //     titulo: 'Cotar Vida',
        //     url: '/cotacao/vida/formulario',
        //     open: async (urlPage)=>{
        //         // this.loadingPage = true;
        //         this.router.navigate([urlPage]).then(()=>{
        //             // this.loadingPage = false;
        //         })
        //         // .catch(()=> {this.loadingPage = false;});
        //     },
        //     calcular: true
        // },
        // {
        //     icone: 'factory',
        //     titulo: 'Cotar Empresarial',
        //     url: '/cotacao/empresarial/formulario',
        //     open: async (urlPage)=>{
        //         // this.loadingPage = true;
        //         this.router.navigate([urlPage]).then(()=>{
        //             // this.loadingPage = false;
        //         })
        //         // .catch(()=> {this.loadingPage = false;});
        //     },
        //     calcular: true
        // },
        // {
        //     icone: 'home',
        //     titulo: 'Cotar Residencial',
        //     url: '/cotacao/residencial/formulario',
        //     open: async (urlPage)=>{
        //         // this.loadingPage = true;
        //         this.router.navigate([urlPage]).then(()=>{
        //             // this.loadingPage = false;
        //         })
        //         // .catch(()=> {this.loadingPage = false;});
        //     },
        //     calcular: true
        // },
        {
            icone: 'car-hatchback',
            titulo: 'Cotar Automóvel',
            url: '/cotacao/auto/formulario',
            open: async (urlPage)=>{
                // this.loadingPage = true;
                this.router.navigate([urlPage]).then(()=>{
                    // this.loadingPage = false;
                })
                // .catch(()=> {this.loadingPage = false;});
                this.gAnalytics.analyticsMonitor('click', 'nova_cotacao_auto', 'Iniciando nova cotação Auto');
            },
            calcular: true,
        },
        // {
        //     icone: 'mdi mdi-truck',
        //     titulo: 'Cotar Caminhão',
        //     url: '/cotacao-form/tipo/caminhao',
        //     calcular: true
        // },
        
        // { icone: 'inf-orcamento', rotulo: 'Orçamento', segmento: 'orcamento', params: { orcamentoId: 'novo' } },
    ];

    iconsMulti = [
        'cards-heart',
        'arrow-left',
        'close',
        'briefcase-edit',
        'barcode',
        'credit-card-outline',
        'bank',
        'cash-usd',
        'file-pdf',
        'search-web',
        'alert-circle-outline',
        'calculator',
        'home',
        'content-copy',
        'content-save',
        'car-info',
        'paperclip',
        'delete',
        'dots-vertical',
        'pencil',
        'file-document-outline',
        'file-document-edit',
        'file-eye',
        'file-multiple',
        'text-box-plus',
        'folder-image',
        'handshake',
        'chevron-left',
        'chevron-right',
        'checkbox-marked-circle',
        'plus',
        'progress-clock',
        'check-circle-outline',
        'swap-horizontal',
        'circle-off-outline',
        'circle-outline',
        'content-save-outline',
        'negocio-fechado',
        'negocio-fechado-home',
        'negocio-perdido',
        'chevron-double-up',
        'negocio-nao-fechado',
        'cash-usd-outline',
        'thumbs-up-down',
        'chevron-double-up',
        'thumb-down-outline',
        'human-male',
        'human-female',
        'cards-heart',
        'factory',
        'phone',
        'whatsapp',
        'email',
        'video',
        'youtube',
        'truck',
        'pdf-box',
        'editando',
        'eye-outline',
        'table-eye'
      ];

    status;
    existParceiro: boolean = false;
	constructor(            
            private apiUsuario: ApiUsuarioService,
		    public apiS3: ApiS3Service,
            private router: Router,
            private api: ApiInterfaceService,
			public platformService: PlatformService,
            public icons: RegisterIconsService,
            private menusService: MenusService,
            public extensaoService: ExtensaoService,
            private gAnalytics: GAnalyticsService,
            private dialog: MatDialog,
            public snackBar: MatSnackBar,
            public sw: ServiceWorkerService,
            private controllerStatus: ControllerStatusService,
            private connectionService: ConnectionService,
            public todoController: TodoControllerService,
		) {
			icons.registerIcons(['alert-circle-outline','backup-restore','menu', 'monitor-dashboard', 'account-group', 'business', 'menu-tarefas', 'account', 'swap-horizontal',
			'calculator', 'office-building', 'cog', 'lifebuoy', 'plus', 'briefcase', 'credit-card-multiple', 'alert-octagram',
			'logout-variant', 'funnel', 'cash-multiple', 'calendar-text-outline', 'tune', 'file-download-outline', 'wrench',
		    'file-document', 'account-multiple', 'city', 'source-branch', 'format-list-checks', 'motorbike', 'car-hatchback','information-outline', 'close', 'currency-usd-off','corretor_digital2', ...this.iconsMulti]);

            this.connectionService.monitor().subscribe(currentState => {
                if(!currentState) {
                    this.snackBar.open('Sem conexão!', 'Ok', {duration: 6000});
                }
                else  {
                    this.snackBar.open('Conectado novamente!', 'Ok', {duration: 6000});
                }
                this.status = !!status;
              });
            
        }
    
    manterChat: boolean = false;
    ngOnInit(){      
        this.controllerStatus.getJsonPlanos(); 
        this.controllerStatus.getJsonLinksVideos(); 
        //observa mudanças de abertura/fechamento no menu mobile
        this.menusService.toggleMenu().subscribe(ret=> this.menuToggle = ret);
        //observa mudança se usuário esta autenticado ou não no sistema
        this.apiUsuario.autenticado.asObservable().subscribe(ret=> {
            this.autenticado = ret
            if(this.autenticado){
                if(this.apiUsuario.getCredenciais().admin) {
                    this.isAdmin = true;
                    if (this.apiUsuario.getCredenciais().root) {
                        this.isRoot = true;
                    }
                    this.menus = this.listaMenus;
                }else{
                    this.isAdmin = false;
                    this.menus = this.menuColaborador;
                }
                this.sessao = this.apiUsuario.getCredenciais();
                if(this.apiUsuario.getCredenciais()?.parceiro_id != "PARCEIRO-PT"){
                    this.validacaoCorretora = this.apiUsuario.verificaLicenca();
                }else{
                    this.validacaoCorretora = this.apiUsuario.verificaLicencaParceiro();
                }
                if (this.validacaoCorretora?.prazo < 0 && !this.validacaoCorretora.periodoTeste) {
                    this.menus = this.listaReduzida;
                }
                this.mostraPeriodoTeste = true;    

                if(!this.callUpdateServicesInfos){                    
                    this.callUpdateServicesInfos = true;
                    //** BUSCA INFORMAÇÕES DE STATUS DAS SEGURADORAS */
                    // this.api.getServicesInfos();
               }
               
                //console.log('fezCheck', this.fezCheckStatusSistema);
                //se autenticado faz 1-uma verificação do check status de pagamento
                if(!this.fezCheckStatusSistema){
                    this.fezCheckStatusSistema = true;
                    this.controllerStatus.buscaSeguradoras();                    
                    if(this.apiUsuario.getCredenciais()?.parceiro_id != "PARCEIRO-PT"){                        
                        this.controllerStatus.checkStatusPagamento(true);
                    }else{
                        this.existParceiro = true;
                    }
                    /*setTimeout(()=>{
                        this.openVideoIntro();
                    },1500)*/

                    let count = 0;
                    let interval2 = setInterval(()=> {
                        this.atualizaStatusExtensao();
                        count++;
                        if(count > 120000 || this.extensaoInstalada){
                            clearInterval(interval2);
                        }

                    }, 100);                   
                }
                
            }            
            this.mostraPeriodoTeste = false;
        });

        this.menusService.menuFilter().subscribe(ret => {
            this.filtro.open = ret.open;
            this.filtro.page = ret.page;          
        });

        this.menusService.hiddenButton().subscribe(ret=> this.showFabButton = ret);
        
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            let url = this.router.routerState.snapshot.url            
            if (url == "/corretor-digital" || /\/orcamento/g.test(url) || /\/preview-orcamento/g.test(url)) {
                this.menuContraido = true;
                this.showAlert = false;
                this.menusService.setMenuDesktop(this.menuContraido);

            } else {
                this.menuContraido = false;
                this.showAlert = true;
                this.menusService.setMenuDesktop(this.menuContraido);
            }         
            
            let invervalCheca;
            let count = 0;

            let minimizado:any = document.getElementsByClassName('minimized');
            if (minimizado.length) {
                this.manterChat = false;
            } else {
                this.manterChat = true;
            }

            // if(this.autenticado && this.platformService.virtualDeviceType == 'desktop' && this.manterChat || url == "/suporte" && this.autenticado){
            //     invervalCheca = setInterval(()=>{
            //         let elemChat:any = document.querySelector('#md-app-widget');
            //         if(elemChat){
            //             clearInterval(invervalCheca);
            //             let styles = elemChat.attributes.style.value;                                                
            //             if(/hidden/gm.test(styles)) {
            //                 elemChat.attributes.style.value = elemChat.attributes.style.value.replace('hidden', 'visible');
            //             }else{
            //                 elemChat.attributes.style.value = "visibility: visible;";
            //             }      
                        
            //             if(this.manterChat && url != "/suporte") {
            //                 let divChat:any = document.getElementsByClassName('RightSide');
            //                 let styles = divChat[0]?.attributes?.style?.value;
            //                 if(styles){
            //                     divChat[0].attributes.style.value = styles + "right: 75px; margin-bottom: 10px;";
            //                 }
            //             }
            //         }
            //         count++;
            //         if(count > 100){
            //             clearInterval(invervalCheca);
            //         }
            //     },500);
            // }else if(url == "/suporte" && this.autenticado && this.platformService.virtualDeviceType != 'desktop'){       
            //     invervalCheca = setInterval(()=>{
            //         let elemChat:any = document.querySelector('#md-app-widget');
            //         debugger
            //         if(elemChat){
            //             clearInterval(invervalCheca);
            //             let styles = elemChat.attributes.style.value;                                                
            //             if(/hidden/gm.test(styles)) {
            //                 elemChat.attributes.style.value = elemChat.attributes.style.value.replace('hidden', 'visible');
            //             }else{
            //                 elemChat.attributes.style.value = "visibility: visible;";
            //             }                        
            //         }
            //         count++;
            //         if(count > 100){
            //             clearInterval(invervalCheca);
            //         }
            //     },10);
            // }else {
            //     invervalCheca = setInterval(()=>{
            //         let elemChat:any = document.querySelector('#md-app-widget');
            //         if(elemChat){
            //             clearInterval(invervalCheca);
            //             let styles = elemChat.attributes.style.value;                        
            //             if(/visible/gm.test(styles)) {
            //                 elemChat.attributes.style.value = elemChat.attributes.style.value.replace('visible', 'hidden');
            //             }else if(!/visibility/gm.test(styles)){
            //                 elemChat.attributes.style.value = "visibility: hidden;";
            //             }
            //         }
            //         count++;
            //         if(count > 100){
            //             clearInterval(invervalCheca);
            //         }
            //     },10);                  
            // }
        });
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((data:any) => { 
            if(data.url != '/login' && !/\/redefinir-senha/g.test(data.url) && !/\/criar-senha/g.test(data.url)
            && data.url != '/recuperar-senha' && data.url != '/criar-conta' && data.url != '/confirmacao'
            && data.url != '/ens'
            ){
                this.apiUsuario.pathHistory = data.url;
            }
        });
        /**
         * Chama service-work para verificar novos builds
         */
        setTimeout(()=>{
            this.sw.checkUpdate();              
        },60000)

    }

    abrirPerfil() {
        this.router.navigateByUrl('/perfil-usuario');
        this.closeMenuToggle();
    }

    extensaoInstalada: boolean = false;
    timeOutExtensao;
    atualizaStatusExtensao(){       
        let input:any = document.querySelector('#bExtensaoExterno');
        clearTimeout(this.timeOutExtensao);
        if(input && input.value == 'mudei'){
            console.log('##extension');
            this.extensaoInstalada = true;
            this.extensaoService.setStatusExtensao(true);
            this.todoController.extensaoInstalada = true;
            this.timeOutExtensao = setTimeout(()=>{              
                this.todoController.verificarToDo('extensao');
            },1000)
        }
    }
    
    //controller menu
    alternaMenu() {
        this.menuContraido = !this.menuContraido;
        this.effectLogo = !this.menuContraido;
        if(this.platformService.virtualDeviceType == 'desktop'){
            this.menusService.setMenuDesktop(this.menuContraido);
        }
    }

    //fecha menu mobile backdrop
    closeMenuToggle(){
        this.menuToggle = false;
        this.menusService.setToggleMenu(false);
    }

    //navega para a rota e também deixa a opção do menu ativa
	rotaLinkMenu(rota, index, j?) {        
        if (rota) {
            if(rota === '/corretor-digital' && this.platformService.deviceType != 'desktop') {
                return this.snackBar.open('Painel do Corretor Digital ainda não disponível para dispositivos móveis.', 'Ok', {duration: 6000});
            }
            if (this.menus[index].subList.length > 0) {
                for (let [s, sub] of Object.entries(this.menus[index].subList)) {
                    if (j == s) {
                        this.menus[index].subList[j].active = !sub.active;
                    } else {
                        this.menus[index].subList[s].active = false;
                    }
                }
                for (let i = 0; i < this.menus.length; i++) { if (this.menus[i].active) { this.menus[i].active = false; } }
            } else {
                for (let [m, menu] of Object.entries(this.menus)) {
                    if (m == index) {
                        this.menus[index].active = !menu.active;
                    } else {
                        this.menus[m].active = false;
                    }
                    if (menu.subList.length > 0) { for (let i = 0; i < menu.subList.length; i++) { menu.subList[i].active = false; } }
                }

            }
            
            // if (this.menus[index].external) {
            //     window.open(rota, '_blank');
            // } else {
                this.router.navigateByUrl(rota).finally(()=>{
                    if (this.platformService.virtualDeviceType == 'phone' || this.platformService.virtualDeviceType == 'tablet') {                        
                        this.closeMenuToggle();
                    }
                })
            // }   
            // this.apiS3.setStateMenuPerfil(false);
        }
    }
    
    openVideoIntro(){
        if(!localStorage.getItem('INTRO-VIDEO') && this.autenticado){
            let dialog = this.dialog.open(ModalVideoIntroComponent, {
                panelClass: 'modal-video-intro'
            });
        }        
    }
    
    //abre opções do menu
	toggleSubMenu(index) {  
        for (let [i, menu] of Object.entries(this.menus)) {
            if (index == i) {
                this.menus[index].open = !menu.open
            } else {
                this.menus[i].open = false
            }
        }

        for (let i = 0; i < this.menus.length; i++) {
            if (this.menus[i].subList.length > 0) {
                for (let j = 0; j < this.menus[i].subList.length; j++) {
                    if (this.router.url == this.menus[i].subList[j].url) {
                        this.menus[i].subList[j].active = true;
                    } else {
                        this.menus[i].subList[j].active = false;
                    }
                }
            }
        }

    }

    async loadAvatar(){
		if(!this.apiUsuario.avatar){
			this.apiUsuario.avatar = await this.apiS3.loadAvatarUsuarioLogado();
		}
    }
    
    esconderPeriodo() {
        let divPeriodoTest = document.querySelector('.periodoTeste');
        divPeriodoTest.classList.add('periodoHidden');
        this.mostraPeriodoTeste = false;
    }

    openAbout(){
		let dialog = this.dialog.open(SobreComponent, {
			panelClass: 'modalDefault'
		});
    }
    
    contratar() {
        this.router.navigateByUrl('/contratar');
        this.closeMenuToggle();
    }

    observaRota() {
      this.router.events.forEach(e => {
        if(e instanceof NavigationEnd) {
        //   console.log('-> ->',e.url);
          return e.url
        }
      });  
    }

}
