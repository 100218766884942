import { Formatter } from '../utils/formatter';

/**
 * Modelo de Configuração de Seguradora
 */
export class ConfSeguradora {

    dados: any =
        {
            id: undefined,
            login: undefined,
            senha: undefined,
            ativo: undefined,
            seguradora: undefined,           
            configsSeg: {},
            idIntegracao: undefined,
            nomeSeguradora: undefined,
            credenciaisValidas: undefined,
            status: undefined,
            apolicesBaixar: undefined,
            parcelasBaixar: undefined
        }

    imgIcone: string;
    admin: boolean;

    constructor(dados?: any) {
        this.setData(dados);
    }

    setData(dados) {
        if (dados) {
            this.id = dados.id;
            this.login = dados.login;
            this.senha = dados.senha;
            this.ativo = dados.ativo;
            this.seguradoraId = dados.seguradora;                        
            this.configsSeg = dados.configsSeg ? dados.configsSeg : {};
            this.dados.nomeSeguradora = dados.nomeSeguradora;
            this.dados.idIntegracao = dados.idIntegracao;
            this.admin = dados.admin;
            this.status = dados.status;
            this.apolicesBaixar = dados.apolicesBaixar;
            this.parcelasBaixar = dados.parcelasBaixar;
        }
        else {
            this.dados = { configsSeg: {} };
        }

    }


    set id(id: string) {
        this.dados.id = id;

    }
    get id(): string {
        return this.dados.id;
    }

    get login (): string {
        return this.dados.login;
    }

    set login (login) {
        this.dados.login = login;
    }

    get senha (): string {
        return this.dados.senha;
    }

    set senha (senha) {
        this.dados.senha = senha;
    }

    set ativo(ativo: boolean) {
        this.dados.ativo = ativo;
    }

    get ativo(): boolean {
        return this.dados.ativo;
    }

    set credenciaisValidas(credenciaisValidas: boolean) {
        this.dados.configsSeg.credenciaisValidas = credenciaisValidas;
    }

    get credenciaisValidas(): boolean {
        return this.dados.configsSeg.credenciaisValidas;
    } 

    get seguradoraId() {
        return this.dados.seguradora;
    }

    set seguradoraId(seguradoraId) {
        this.dados.seguradora = seguradoraId;
        this.imgIcone = Formatter.iconeSeguradora(seguradoraId);
    }

    set configsSeg(configsSeg: any) {
        this.dados.configsSeg = configsSeg;
    }
    get configsSeg(): any {
        return this.dados.configsSeg;
    }

    get nomeSeguradora(): string {
        let nome = Formatter.rotuloSeguradora(this.seguradoraId);
        return nome || this.dados.nomeSeguradora;
    }  

    get idIntegracao () : string {
        return this.dados.idIntegracao;
    }

    get configsGlobais () : boolean {
        return this.configsSeg && this.configsSeg.configsGlobais ? true : false;
    }

    get status() : any {
        return this.dados.status;
    }

    get apolicesBaixar(): boolean {
        return this.dados.apolicesBaixar;
    }

    set apolicesBaixar(apolicesBaixar: boolean) {
        this.dados.apolicesBaixar = apolicesBaixar;
    }

    get parcelasBaixar(): boolean {
        return this.dados.parcelasBaixar;
    }

    set parcelasBaixar(parcelasBaixar: boolean) {
        this.dados.parcelasBaixar = parcelasBaixar;
    }    

    motoImplementado: boolean;
    autoImplementado: boolean;
    caminhaoImplementado: boolean;
    residImplementado: boolean;
    vidaImplementado: boolean;
    empreImplementado: boolean;
    parcelasImplementado: boolean;
    documentosImplementado: boolean;
    acompanhamentoImplementado: boolean;

    motoHabilitado: boolean;
    autoHabilitado: boolean;
    caminhaoHabilitado: boolean;
    residHabilitado: boolean;
    vidaHabilitado: boolean;
    empreHabilitado: boolean;
    parcelasHabilitado: boolean;
    documentosHabilitado: boolean;
    acompanhamentoHabilitado: boolean;
    set status(status : any) {
        if(status){
            this.motoImplementado           = status.motoStatus             != -1 && status.motoStatus              != null;
            this.autoImplementado           = status.autoStatus             != -1 && status.autoStatus              != null;
            this.caminhaoImplementado       = status.caminhaoStatus         != -1 && status.caminhaoStatus          != null;
            this.residImplementado          = status.residStatus            != -1 && status.residStatus             != null;
            this.vidaImplementado           = status.vidaStatus             != -1 && status.vidaStatus              != null;
            this.empreImplementado          = status.empresarialStatus      != -1 && status.empresarialStatus       != null;
            this.parcelasImplementado       = status.parcelasStatus         != -1 && status.parcelasStatus          != null;
            this.documentosImplementado     = status.documentosStatus       != -1 && status.documentosStatus        != null;
            this.acompanhamentoImplementado = status.acompanhamentoStatus   != -1 && status.acompanhamentoStatus    != null;

            this.motoHabilitado             = status.motoStatus             == 1;
            this.autoHabilitado             = status.autoStatus             == 1;
            this.caminhaoHabilitado         = status.caminhaoStatus         == 1;
            this.residHabilitado            = status.residStatus            == 1;
            this.vidaHabilitado             = status.vidaStatus             == 1;
            this.empreHabilitado            = status.empresarialStatus      == 1;
            this.parcelasHabilitado         = status.parcelasStatus         == 1;
            this.documentosHabilitado       = status.documentosStatus       == 1;
            this.acompanhamentoHabilitado   = status.acompanhamentoStatus   == 1;
        }

        this.dados.status = status;
    }

}