import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClienteModel } from '../models/cliente.model';

@Injectable({
	providedIn: 'root'
})
export class ApiClienteService {

	private ENV: any = environment;
	private URL;

	/** CONTROLLER PARA ATUALIZAR O CLIENTE NA LISTA DE CLIENTES CASO SEJA EDITADO */
	updateClienteModal: boolean = false;

	constructor(private http: HttpClient, private BS: BaseService) {
		this.URL = this.ENV.apice.url;
	}

	buscaCliente(clienteId?: string): Promise<any> {
		if (!clienteId) throw new Error('ClienteId não definido.');
		return this.http.get(this.URL + 'cadastros/cliente' + (clienteId ? '/' + clienteId.toString() : ''), this.BS.getHeaders(true)).toPromise();		
	}

	buscarClientes(busca?: string): Promise<any> {		
		return this.http.get(this.URL + `cadastros/cliente?filtroBusca=${busca || ''}`, this.BS.getHeaders(true)).toPromise();
	}

	// async buscarClientes(busca?: string): Promise<Cliente[]> {
	// 	let parametros = {
	// 		filtroBusca: busca
	// 	}
	// 	try {
	// 		// let url = this.segmentoCliente;
	// 		let dadosClientes = await this.apiBase.get('cadastros/cliente', parametros)
	// 		let clientes = dadosClientes.map(dadosCliente => {
	// 			return new Cliente(dadosCliente);
	// 		});
	// 		return clientes;
	// 	} catch (error) {
	// 		return error;
	// 		// console.log('Erro ao buscar lista de clientes', error);
	// 	}
	// }

	buscarClientesPaginacao(textoBusca?: string, page?: number, params?: any): Promise<any> {
		let limit = 40;
		textoBusca = textoBusca || '';
		page = page || 1;
		let path = `cadastros/cliente?filtroBusca=${textoBusca}&page=${page}&limit=${limit}`;
		if (params) {
			let queryParams: any = {};
			if (params.page) queryParams.page = params.page;
			if (params.limit) queryParams.limit = params.limit;
			if (params.ativo != undefined) queryParams.ativo = !!params.ativo;
			if (params.favorito != undefined) queryParams.favorito = !!params.favorito;
			if (params.creationPeriodBegin) queryParams.creationPeriodBegin = params.creationPeriodBegin;
			if (params.creationPeriodEnd) queryParams.creationPeriodEnd = params.creationPeriodEnd;
			if (params.updatePeriodBegin) queryParams.updatePeriodBegin = params.updatePeriodBegin;
			if (params.updatePeriodEnd) queryParams.updatePeriodEnd = params.updatePeriodEnd;
			if (params.orderBy) queryParams.orderBy = params.orderBy;
			if (params.aniver) queryParams.aniver = params.aniver;
			if (params.excel) queryParams.excel = params.excel;

			let keys = Object.keys(queryParams).filter(key => queryParams[key]); //filtra pelas keys existentes
			if (queryParams.ativo != undefined && !queryParams.ativo) { keys.push('ativo') }
			if (queryParams.favorito != undefined) { keys.push('favorito') }
			let queryString = keys
				.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
				.join('&');
			path = `cadastros/cliente?filtroBusca=${textoBusca}&page=${page}&limit=${limit}&${queryString}`;
		}


		return this.http.get(this.URL + path, this.BS.getHeaders(true)).toPromise();
	}

	async buscaClientePorCpfCnpj(cpfCnpj?: string, pessoa?: 'F' | 'J', apiInterface?:boolean): Promise<any> {
		cpfCnpj = (cpfCnpj || '').replace(/\D/g, '');
		if (pessoa == 'F' && cpfCnpj.length != 11) {
			return null;
		}
		else if (pessoa == 'J' && cpfCnpj.length != 14) {
			return null;
		}
		if (!cpfCnpj) {
			throw new Error('CPF/CNPJ não especifícada');
		}
		let parametros = {
			cpfCnpj: cpfCnpj
		}
		try {
			let dadosCliente = await this.http.get(this.URL + 'cadastros/cliente', this.BS.getHeaders(true, parametros)).toPromise();
			if (dadosCliente) {
				let cliente = new ClienteModel().deserialize(dadosCliente);
				return apiInterface ? [cliente] : cliente;
			} else {
				return null;
			}
		} catch (error) {
			console.log('Erro ao buscaClientePorCpfCnpj', error);
			throw error;
		}
	}


	salvarClientes(cliente): Promise<any> {
		let cpfCnpj = cliente.cpfCnpj ? cliente.cpfCnpj.replace(/\D/g, '') : cliente.cpfCnpj;
		let dados = { ...cliente, cpfCnpj: cpfCnpj };
		return this.http.post(this.URL + 'cadastros/cliente', dados, this.BS.getHeaders(true)).toPromise();
	}

	editaCliente(cliente, clienteId): Promise<any> {		
		return this.http.post(this.URL + 'cadastros/cliente', cliente, this.BS.getHeaders(true)).toPromise();			
	}

	deletaEndereco(enderecoId?: string): Promise<any> {
		if (!enderecoId) throw new Error('Endereço não definido.');
		let path = `cadastros/cliente/endereco/${enderecoId}`;
		return this.http.delete(this.URL + path, this.BS.getHeaders(true)).toPromise();
	}

	dadosClienteBuscado: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	/**
	 * Validação assíncrona. Verifica se um CPF/CNPJ já está cadastrado na seguradora para algum cliente.
	 * @param cpfCnpj CPF/CNPJ a ser validado como inexistente na tabela de clientes.
	 */
	validaCpfCnpjClienteDisponivel(options?: { exclude: string }): AsyncValidatorFn {
		return (control: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
			return new Promise((resolve, reject) => {
				// busca o cliente por CPF/CNPJ.
				let cpfCnpj = (control.value || '').replace(/\D/g, '');
				let excludedCpfCnpj = options && options.exclude ? options.exclude.replace(/\D/g, '') : null;
				// console.log(options, excludedCpfCnpj, cpfCnpj)
				if (excludedCpfCnpj == cpfCnpj) {
					// console.log('CPF Identico');
					resolve(null);
				}
				else if (!cpfCnpj) {
					resolve(null);
				}
				else {
					// console.log('CPF Diferente');
					return this.buscaClientePorCpfCnpj(cpfCnpj)
						.then((cliente) => {
							// se CPF/CNPJ indisponível
							if (cliente && !cliente.externo) {
								resolve({ validaCpfCnpjClienteDisponivel: { valid: false, message: 'CPF/CNPJ já cadastrado' } });
							}
							// se CPF/CNPJ disponível
							else {
								if(cliente?.externo){
									this.dadosClienteBuscado.next(cliente);
								}
								resolve(null);
							}
						}).catch((error) => {
							resolve({ validaCpfCnpjClienteDisponivel: { valid: false, message: 'Falha na validação' } });
						});

				}

			});
		}
	}

	salvarFavorito(clienteId, favorito) {		
		return this.http.post(this.URL + `cadastros/cliente/favorito/${clienteId}`, { favorito: favorito }, this.BS.getHeaders(true)).toPromise();			
	}


}
