import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.guard';
import { ActionGuard } from './guards/action-guard.guard';
import { AuthDashboardGuard } from './guards/auth-dashboard.guard';
import { AuthMulticalculoGuard } from './guards/auth-multicalculo.guard';
import { AuthCrmMarketingGuard } from './guards/auth-crm-marketing.guard';
import { UserAdminGuard } from './guards/user-admin.guard';
import { UserRootGuard } from './guards/user-root.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'login',
    loadChildren: ()=> import('./pages/login/login.module').then(m => m.LoginModule)
  },
  { 
    path: 'redefinir-senha/:chaveRedefinicao',
    loadChildren: ()=> import('./pages/redefinir-senha/redefinir-senha.module').then(m => m.RedefinirSenhaModule)
  },
  { //para novo usuário
    path: 'criar-senha/:chaveCriacao',
    loadChildren: ()=> import('./pages/redefinir-senha/redefinir-senha.module').then(m => m.RedefinirSenhaModule)
  },
  { 
    path: 'recuperar-senha',
    loadChildren: ()=> import('./pages/recuperar-senha/recuperar-senha.module').then(m => m.RecuperarSenhaModule)
  },
  { 
    path: 'criar-conta',
    loadChildren: ()=> import('./pages/criar-conta/criar-conta.module').then(m => m.CriarContaModule)
  },
  { 
    path: 'allianz',
    loadChildren: ()=> import('./pages/criar-conta-allianz/criar-conta-allianz.module').then(m => m.CriarContaAllianzModule)
  },
  { 
    path: 'Allianz',
    loadChildren: ()=> import('./pages/criar-conta-allianz/criar-conta-allianz.module').then(m => m.CriarContaAllianzModule)
  },
  { 
    path: 'allians',
    loadChildren: ()=> import('./pages/criar-conta-allianz/criar-conta-allianz.module').then(m => m.CriarContaAllianzModule)
  },
  { 
    path: 'Allians',
    loadChildren: ()=> import('./pages/criar-conta-allianz/criar-conta-allianz.module').then(m => m.CriarContaAllianzModule)
  },
  { 
    path: 'confirmacao',
    loadChildren: ()=> import('./pages/confirmacao/confirmacao.module').then(m => m.ConfirmacaoModule)
  },
  {
    path: 'perfil-usuario',
    loadChildren: ()=> import('./pages/perfil-usuario/perfil-usuario.module').then(m => m.PerfilUsuarioEditarModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'corretoras',
    loadChildren: ()=> import('./pages/corretoras/corretoras.module').then(m => m.CorretorasModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  {
    path: 'dashboard',
    loadChildren: ()=> import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lista-tarefas',
    loadChildren: ()=> import('./pages/tarefas/tarefas.module').then(m => m.TarefasModule),
    canActivate: [AuthGuard, ActionGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'clientes',
    loadChildren: ()=> import('./pages/clientes/clientes.module').then(m => m.ClientesModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  {
    path: 'funil-de-vendas',
    loadChildren: ()=> import('./pages/negocios/kanban/kanban.module').then(m => m.KanbanModule),
    canActivate: [AuthGuard, ActionGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'lista-negocios',
    loadChildren: ()=> import('./pages/negocios/lista/lista.module').then(m => m.ListaModule),
    canActivate: [AuthGuard, ActionGuard,  AuthCrmMarketingGuard]
  },
  {
    path: "cotacao/:ramo/:page/:idIntegracao",
    loadChildren: () => import("./pages/cotacao/cotacao.module").then(m => m.CotacaoModule),
    canActivate: [AuthGuard, ActionGuard, AuthMulticalculoGuard]
  },
  {
    path: "cotacao/:ramo/:page",
    loadChildren: () => import("./pages/cotacao/cotacao.module").then(m => m.CotacaoModule),
    canActivate: [AuthGuard, AuthMulticalculoGuard, ActionGuard]
  },
  {
    path: 'cotacoes',
    loadChildren: ()=> import('./pages/cotacoes/cotacoes.module').then(m => m.CotacoesModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  {
    path: 'parcelas',
    loadChildren: ()=> import('./pages/parcelas/parcelas.module').then(m => m.ParcelasModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'configuracoes',
    loadChildren: ()=> import('./pages/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'config-corretora',
    loadChildren: ()=> import('./pages/corretora/cadastro/cadastro.module').then(m => m.CadastroModule),
    canActivate: [AuthGuard, UserAdminGuard]
  },
  {
    path: 'config-usuarios',
    loadChildren: ()=> import('./pages/corretora/usuarios/usuarios.module').then(m => m.UsuariosModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard]
  },
  {
    path: 'config-plano',
    loadChildren: ()=> import('./pages/corretora/plano/plano.module').then(m => m.PlanoModule),
    canActivate: [AuthGuard, UserAdminGuard]
  },
  {
    path: 'contratar',
    loadChildren: ()=> import('./pages/configuracoes/contratacao/contratacao.module').then(m => m.ContratacaoModule),
    canActivate: [AuthGuard, UserAdminGuard]
  },
  {
    path: 'config-preferencias',
    loadChildren: ()=> import('./pages/configuracoes/preferencias/preferencias.module').then(m => m.PreferenciasModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard]
  },
  {
    path: 'config-coberturas',
    loadChildren: ()=> import('./pages/configuracoes/coberturas/coberturas.module').then(m => m.CoberturasModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard]
  },
  {
    path: 'config-ramos',
    loadChildren: ()=> import('./pages/configuracoes/ramos/ramos.module').then(m => m.RamosModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard]
  },
  {
    path: 'config-seguradoras',
    loadChildren: ()=> import('./pages/configuracoes/seguradoras/seguradoras.module').then(m => m.SeguradorasModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  {
    path: 'suporte',
    loadChildren: ()=> import('./pages/suporte/suporte.module').then(m => m.SuporteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orcamento',
    loadChildren: ()=> import('./pages/orcamento/orcamento.module').then(m => m.OrcamentoModule),
    canActivate: [AuthGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'preview-orcamento',
    loadChildren: ()=> import('./pages/preview-orcamento/preview-orcamento.module').then(m => m.PreviewOrcamentoPageModule),
    canActivate: [AuthGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'ens',
    loadChildren: ()=> import('./components/aluno-ens/aluno-ens.module').then(m => m.AlunoEnsModule)
  },
  // {
  //   path: 'teste',
  //   loadChildren: ()=> import('./pages/teste/teste.module').then(m => m.TesteModule),
  // },
  // {
  //   path: 'atualizar-plano',
  //   loadChildren: ()=> import('./pages/update-plano/update-plano.module').then(m => m.UpdatePlanoModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'busca',
    loadChildren: ()=> import('./pages/busca/busca.module').then(m => m.BuscaModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  {
    path: 'faturas-nfe',
    loadChildren: ()=> import('./pages/configuracoes/faturas-nfe/faturas-nfe.module').then(m => m.FaturasNfeModule),
    canActivate: [AuthGuard, UserAdminGuard]
  },
  {
    path: 'corretor-digital',
    loadChildren: ()=> import('./pages/corretor-digital/corretor-digital.module').then(m => m.CorretorDigitalModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'documentos',
    loadChildren: ()=> import('./pages/documentos/documentos.module').then(m => m.DocumentosModule),
    canActivate: [AuthGuard, ActionGuard, UserAdminGuard, AuthCrmMarketingGuard]
  },
  {
    path: 'orcamento-cliente',
    loadChildren: ()=> import('./pages/orcamento-cliente/orcamento-cliente.module').then(m => m.OrcamentoClienteModule),
    canActivate: []
  },
  {
    path: 'painel-notificacoes',
    loadChildren: ()=> import('./pages/painel-notificacoes/painel-notificacoes.module').then(m => m.PainelNotificacoesPageModule),
    canActivate: [AuthGuard, UserRootGuard]
  },
  {
    path: 'notificacoes',
    loadChildren: ()=> import('./pages/notificacoes/notificacoes.module').then(m => m.NotificacoesModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  {
    path: 'minerador-de-oportunidades',
    loadChildren: ()=> import('./pages/minerador-de-oportunidades/minerador-de-oportunidades.module').then(m => m.MineradorDeOportunidadesModule),
    canActivate: [AuthGuard, ActionGuard]
  },
  { path: '**', redirectTo: 'dashboard' }// error route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
