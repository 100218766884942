import { FormControl, AbstractControl, FormGroup } from "@angular/forms";

export class MessageHandler {

    /**
     * Obtém o primeiro erro de um controle de formulário. 
     * @param control controle de formulário.
     * @returns erro, se houver. Null caso o controle seja null ou se não houver erro neste.
     */
    static getFirstError (control:AbstractControl) {
        if (control && control.errors) {
            for (let key in control.errors) {
                let error = control.errors[key];
                return error;
            }
        }
        return null;
    }

    static mustShowError (control:AbstractControl, dirty?:boolean, touched?:boolean) {
        dirty = dirty == null ? true : !!dirty;        
        let showDirty = dirty ? !!control.dirty : true;

        touched = touched == null ? true : !!touched;
        let showTouched = touched == true ? !!control.touched : true;

        let showError = showDirty && showTouched;
        return showError;
    }

    /**
     * Verifica se há erro em um controle de formulário.
     * @param control controle de formulário.
     * @returns true se há erro. False caso o controle seja null ou não haja erro.
     */
    static hasError(control:AbstractControl) {
        return MessageHandler.getFirstError(control);
    }


    /**
     * Obtém a primeira mensagem erro de um controle de formulário. 
     * @param control controle de formulário.
     * @returns erro, se houver mensagem. string vazia caso o controle seja null ou se não houver erro neste.
     */
    static errorMessage(control:AbstractControl, dirty?:boolean, touched?:boolean) {
        let error = MessageHandler.getFirstError(control);        
        if (error && MessageHandler.mustShowError(control, dirty, touched)) {           
            return error ? error.message : '';
        }
        return '';
    }

    /**
     *  Verifica se há validação pendente em um controle de formulário.
     * @param control controle de formulário.
     * @returns true se há validação pendente. False caso o controle seja null ou não haja validação pendente.
     */
    static isPending(control:AbstractControl) {            
        return control && control.pending;
    }

}