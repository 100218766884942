import { Injectable } from '@angular/core';
import { WakUser } from '../models/wak-user.model';
import { BehaviorSubject } from 'rxjs';
import { PdocsSession } from '../models/pdocs-session.model';
import { ServicesInfosBase } from '../models/services-infos.model';

@Injectable({
  providedIn: 'root'
})

export class SharedMemoryService {

  private wakUser: BehaviorSubject<WakUser> = new BehaviorSubject<WakUser>(null);
  private wakUserValue;
  private pdocsSession: BehaviorSubject<PdocsSession> = new BehaviorSubject<PdocsSession>(null);
  private pdocsSessionValue;
  private servicesInfos: BehaviorSubject<ServicesInfosBase> = new BehaviorSubject<ServicesInfosBase>(null);
  private servicesInfosValue;
  private contRefreshHome: BehaviorSubject<Number> = new BehaviorSubject<Number>(null);
  private contRefreshHomeValue;


  constructor() {}

  //http://localhost:4200/#/login?path=%2Fapp%2Fhome%2Fvida

  public set(name: 'pdocsSession', val: any)
  public set(name: 'servicesInfos', val: any)
  public set(name: 'wakUser', val: any)
  public set(name: 'contRefreshHome', val: any)
  public set(name: string, val: any){

    let nameValue = name + "Value";
    if(this[nameValue] !== val) {
      this[nameValue] = val;
      this[name].next(val);
    }

  }

  private subscribe(name, cb){
    return this[name].asObservable().subscribe(cb);
  }

  public addSubscription(ctx: any, property: 'contRefreshHome', cb: (data: Number) => any)
  public addSubscription(ctx: any, property: 'wakUser', cb: (data: WakUser) => any)
  public addSubscription(ctx: any, property: 'pdocsSession', cb: (data: PdocsSession) => any)
  public addSubscription(ctx: any, property: 'servicesInfos', cb: (data: ServicesInfosBase) => any)
  public addSubscription(ctx: any, property: string, cb: Function) {
    //let serviceName = SharedMemoryService.name.charAt(0).toLowerCase() + SharedMemoryService.name.slice(1);
    ctx.subscriptions.add(ctx["sharedMemoryService"].subscribe(property, cb));
  }

}
