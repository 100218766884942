import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientesComponent } from './clientes.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';



@NgModule({
  declarations: [ClientesComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario
  ],
  exports: [ClientesComponent]
})
export class ClientesFiltroModule { }
