import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { FiltroControllerService } from 'src/app/services/filtro-controller.service';

@Component({
  selector: 'filtro-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Output('eventFilter') eventFilter = new EventEmitter();
  @Output('clearFilter') limpaFiltro = new EventEmitter();

  erroBuscarUsuarios: boolean = false;
  userSessao;
  checkboxSelected:any;
  avatares = {};
  listaUsuarios = [];
  filterUsersSelected = [];
  filtroStatus = 1;
  arrayPage:any = 'dashboard';
  constructor(public apiUsuario: ApiUsuarioService, private apiS3: ApiS3Service, public filtroController: FiltroControllerService) { }

  ngOnInit() {
    this.getUsuarios();
    this.existeUsuarioSelecionado();
		this.verificaCheck()
  }

  existeUsuarioSelecionado() {
    //seleciona os usuarios
		for(let user of this.listaUsuarios) {
			for(let id of this.filtroController.usersDashboard) {
				if(user.id === id) {
					user['selected'] = true;
				}
			}
		}
	}

  async getUsuarios() {
    this.erroBuscarUsuarios = false;
    try {
      let usuarios: any;
      if (this.apiUsuario.listaUsuarios.length) {
        usuarios = this.apiUsuario.listaUsuarios;
      } else {
        this.userSessao = await this.apiUsuario.getCredenciais();
        usuarios = await this.apiUsuario.buscaUsuarios2();
        this.apiUsuario.listaUsuarios = usuarios;
      }

      usuarios = usuarios.filter(u => u.ativo == true && u.confirmacao == false && u.tipoRedefinicaoSenha != 'novo');
      for (let user of usuarios) {
        if (!this.apiUsuario.avatares[user.id]) {
          this.apiUsuario.avatares[user.id] = await this.getAvatarUsuario(user.id);
        }
        user['img'] = this.apiUsuario.avatares[user.id];
        if (this.userSessao && this.userSessao == user.id) {
          user['selectedDefault'] = true;
        }
      }
      // console.log('filtro dash', usuarios)  
      this.listaUsuarios = usuarios;
      for (let _user of this.listaUsuarios) {
				_user['selected'] = false;
			}
    } catch (error) {
      this.erroBuscarUsuarios = true;
    }
  }

  async getAvatarUsuario(usuarioId) {
    try {
      let ret = await this.apiS3.getAvatarUsuario(usuarioId);
      return ret
    } catch (error) {
      return ''
    }
  }

  filterUserSelect(user) {
		for (let _user of this.listaUsuarios) {
			if (_user.id == user.id) {
				if (user.hasOwnProperty('selected')) {
					_user['selected'] = !user['selected'];
					if (_user['selected']) {
						this.filterUsersSelected.push(user.id);
						this.filtroController.addUser(user.id, this.arrayPage);
					} else {
						let index = this.filterUsersSelected.findIndex(u => u == user.id);
						this.filterUsersSelected.splice(index, 1);
						this.filtroController.removeUser(user.id, this.arrayPage);
					}
				} else {
					_user['selected'] = true;
					this.filterUsersSelected.push(user.id);
					this.filtroController.addUser(user.id, this.arrayPage);
				}
			}
			_user['selectedDefault'] = false;
		}
		this.verificaCheck();
	}

  buildString() {
    let rawValue = {
      status: this.filtroStatus,
      userSelected: this.filtroController.usersDashboard
    };
    let filtros = { values: rawValue };
    this.eventFilter.emit(filtros);
    this.filtroController.verificaFiltro();

  }

  clearFilter() {
    this.filterUsersSelected = [];
    this.checkboxSelected = false;
    for (let user of this.listaUsuarios) {
      user['selected'] = false;
      user['selectedDefault'] = user.id == this.userSessao ? true : false;
    }
    this.filtroStatus = 1;
    this.filtroController.limparFiltro(this.arrayPage);
    this.limpaFiltro.emit();
    this.filtroController.verificaFiltro();
    this.buildString();
  }

  selectAll() {
		if(this.checkboxSelected) {
			for (let _user of this.listaUsuarios) {
				_user['selected'] = true;
				this.filtroController.addUser(_user.id, this.arrayPage)
				this.filterUsersSelected.push(_user.id);
			}
			this.verificaCheck()
		} else {
			for (let _user of this.listaUsuarios) {
				_user['selected'] = false;
				this.filtroController.removeUser(_user.id, this.arrayPage);
			}
			this.verificaCheck()
			this.filterUsersSelected = [];
		}
	}

  verificaCheck() {
		this.checkboxSelected = this.filtroController.usersDashboard.length === this.listaUsuarios.length ? true : false;
	}

}