export const environment = {
    "production": false,
    "version": require('../../package.json').version,
    "baseURL": "/",
    "apice": {
        // "url": "http://localhost:3000/", 
        "url": "https://api.dev.apicept.infocap.link/", 
        "tempoReautenticacao": 60000
    },
    "relativeURL": "https://api.dev.apicept.infocap.link/",
    "vindi": {
        "url": "https://sandbox-app.vindi.com.br/api/v1/",
        "publicKey": "XI4IP95jpUBePjaOkHZSYQJxqi0Jc07U17q6AfELm-Y"
    },
    "pDocs": {
        "tempoReautenticacao": 60000
    },
    "s3": {
        "enabled": true,
        "url": "https://apice-files-dev.s3.amazonaws.com/",
        "bucketName": "apice-files-dev",
        "bucketRegion": "us-east-1",
        "tempoReautenticacao": 60000
    },
    "CEP": {
        "url": " https://dwdnxlwncns0f.cloudfront.net/ws/lb/cep.php?cep="
    },
    "veiculo": {
        "modelo": {
            "url": "https://274uu654ha.execute-api.us-east-1.amazonaws.com/prod/veicbusca"
        },
        "placa": {
            "url": "https://xfn0g111qf.execute-api.us-east-1.amazonaws.com/prod/getplaca"
        }
    },
    "multi": {
        "url": "https://dev-api.multicalculo.net/"
    }
};
