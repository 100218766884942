import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})



export class Masks {

  constructor() { }

  public validate() {

    return {
      HORA: this.getMask('dd:dd'),
      CNPJ: this.getMask('dd.ddd.ddd/dddd-dd'),
      CPF: this.getMask('ddd.ddd.ddd-dd'),
      LIVRE14: this.getMask('dddddddddddddd'),
      CEP: this.getMask('ddddd-ddd'),
      DD_MM_AAAA: this.dataBrasil(),
      AAAA: this.getMask('dddd'),
      PLACA: this.getMask('aaa-dAdd'),
      FIPE: this.getMask('dddddd-d'),
      ANO: this.getMask('dddd'),
      FONE: this.getMask('(dd) dddd-ddddd'),
      CHASSI: this.getMask('AAAAAAAAAAAAAAAAA'),
      ALTURA: this.getMask('d.dd'),
      PESO: this.getMask('ddd'),
      DINHEIRO: this.getMask('ddd.ddd,dd'),
      PERC: this.getMask('ddd'),
      NUMERO_IMOVEL: this.getMask('ddddd'),
      PORTAS: this.getMask('d'),
      PASSAGEIROS: this.getMask('dd'),
      EIXOS: this.getMask('dd'),
    }
  }

  public dataBrasil() {
    return {
      guide: false,
      mask: [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    };

  }

  public getMask(exp, options?: any) {
    let guide = false;
    if (options) {
      guide == !!options.guide;
    }
    let mask = [];
    let tokens = exp.split('');
    for (let token of tokens) {

      if (token == 'd') {
        mask.push(/[0-9]/);
      }
      else if (token == 'n') {
        mask.push(/[0-9]+/);
      }
      else if (token == 'A') {
        mask.push(/[a-zA-Z0-9]/);
      }
      else if (token == 'a') {
        mask.push(/[a-zA-Z]/);
      }
      else {
        mask.push(token)
      }
    }

    return {
      guide: guide,
      mask: mask
    };
  }


}
