import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GlobalConstants } from '../constants/global';
import { ConstantPortugal } from '../constants/constantPortugal';
import { environment } from '../../environments/environment';
import { SeguradorasBase } from '../models/seguradoras/factory/base.model';
import { ViewValue } from '../models/view-value.model';
import { IntervalNumberView } from '../models/interval-number-view.model';
import { AlertConfirmService } from './alert-confirm.service';
import { ModalPersonalizadaService } from './modal-personalizada.service';
import { ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { SnackbarProps } from '../models/snackbarProps.model';
import * as Moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  private _baseUrl: string = environment.apice.url;
  private _seguradorasBase: SeguradorasBase = GlobalConstants.seguradoras;
  private seguradorasById: any = {};
  private _franquiaBase: SeguradorasBase = GlobalConstants.franquia;
  private franquiaById: any = {};
  private carroResById: any = {};
  private _carroReservaBase: SeguradorasBase = GlobalConstants.carroReserva;

  constructor(
    private alertConfirmService: AlertConfirmService,
    private modalPersonalizadaService: ModalPersonalizadaService,
    private _snackBar: MatSnackBar
  ) { }

  public deepEqual(object1, object2) {

    delete object1.status;
    delete object1.fechamento;
    delete object2.status;
    delete object2.fechamento;

    let keys1 = Object.keys(object1);
    let keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      let maiorKey;
      let menorKey;
      if (keys1 > keys2) {
        maiorKey = keys1;
        menorKey = keys2;
      } else {
        maiorKey = keys2;
        menorKey = keys1;
      }
      maiorKey.forEach(element => {
        if (typeof object1[element] == "undefined") {
          delete object1[element];
        }
      });

      keys1 = Object.keys(object1);
      keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length)
        return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }

    return true;
  }

  public setCoberturasUndefinedFields(cob) {
    let newFieldsBoolean = [
      // 'carroReservaAr',
      // 'rouboFurtoEstepe',
      // 'reparoRapido',
      // 'martelinhoOuro',
      // 'higienizacao',
      // 'tetoSolar',
      // 'lantFarolAux'
    ];

    newFieldsBoolean.forEach(element => {
      if (!cob[element]) // Se for undefined, setar de forma explicita o false
        cob[element] = false;
    });

    // if (!cob.guinchoKm) {
    //   switch (cob.assist24hs) {
    //     case 1: cob.guinchoKm = 1; break;
    //     case 2: cob.guinchoKm = 2; break;
    //     case 3: cob.guinchoKm = 6; break;
    //   }
    // }
  }

  public isObject(object) {
    return object != null && typeof object === 'object';
  }

  public fileExists(url) {
    try {
      if (url) {
        var req = new XMLHttpRequest();
        req.open('GET', url, false);
        req.send();
        return req.status == 200;
      } else {
        return false;
      }
    } catch (e) { return false; }
  }

  public limitaString(testes, tamanho) {

    try {
      if (testes != null && testes.length > tamanho) {
        return testes.substring(0, (tamanho - 3)) + "...";
      } else {
        return testes;
      }
    } catch (error) {
      return "";
    }
  }

  public assist24h(): ViewValue[] {

    return [
      { value: 0, viewValue: 'Não' },
      { value: 1, viewValue: 'Básica' },
      { value: 2, viewValue: 'Intermediária' },
      { value: 3, viewValue: 'Completa' }
    ];

  }

  public tipoResidencia(tipo) {

    try {
      let r = this.getTipoResidencia().filter(function (f) {
        return f.value == tipo;
      });
      return r ? r[0].viewValue : false;
    } catch (error) {
      return false;
    }

  }

  public usoResidencia(uso) {

    try {
      let r = this.getTipoUso().filter(function (f) {
        return f.value == uso;
      });
      return r ? r[0].viewValue : false;
    } catch (error) {
      return false;
    }

  }

  public tipoAssistencia24h(tipo) {

    try {
      let r = this.assist24h().filter(function (f) {
        return f.value == tipo;
      });
      return r ? r[0].viewValue : false;
    } catch (error) {
      return false;
    }

  }

  public tipoConstrucao(tipo) {

    try {
      let r = this.getTipoConstrucao().filter(function (f) {
        return f.value == tipo;
      });
      return r ? r[0].viewValue : false;
    } catch (error) {
      return false;
    }

  }

  public imovelObjetoSegurado(tipo) {

    try {
      let r = this.getTipoObjSegurado().filter(function (f) {
        return f.value == tipo;
      });
      return r ? r[0].viewValue : false;
    } catch (error) {
      return false;
    }

  }

  setResultsAll(v) {
    v.results.all = { successes: this.getResultsAll(v, "successes"), errors: this.getResultsAll(v, "errors") };
  }

  getResultsAll(v, status) {
    return [...v.results.main[status], ...v.results.alternatives[status]];
  }

  sortResults(v) {

    let types = ["main", "alternatives", "all"];
    types.forEach(function (t) {

      v.results[t].successes.sort(function (a, b) {
        if (!a.premio)
          a.premio = 9999 * 9999;
        if (!b.premio)
          b.premio = 9999 * 9999;
        let conta = a.premio - b.premio;
        return conta;
      });

      v.results[t].errors.sort(function (a, b) {
        if (a.nome > b.nome) {
          return 1;
        }
        if (a.nome < b.nome) {
          return -1;
        }
        return 0;
      });

    }.bind(this));

  }

  public getFipeAtualValores(fipeModelo) {

    if (Array.isArray(fipeModelo) && fipeModelo.length) {
      try {
        let data: any = new Date();
        let mes = data.getMonth();
        let ano = data.getFullYear();
        mes++;

        let fipeVal = fipeModelo[0].fipeValores;

        let fipeValAtual = fipeVal.filter(function (f) {
          return f.mesVersaoTabela == mes && f.anoVersaoTabela == ano;
        });

        if (!fipeValAtual.length) {
          data = this.editarData({ "data": data, operacao: "-", dia: 0, mes: 1, ano: 0 });
          mes = data.getMonth();
          ano = data.getFullYear();
          mes++;
          fipeValAtual = fipeVal.filter(function (f) {
            return f.mesVersaoTabela == mes && f.anoVersaoTabela == ano;
          });
        }

        if (!fipeValAtual.length) {
          data = this.editarData({ "data": data, operacao: "-", dia: 0, mes: 1, ano: 0 });
          mes = data.getMonth();
          ano = data.getFullYear();
          mes++;
          fipeValAtual = fipeVal.filter(function (f) {
            return f.mesVersaoTabela == mes && f.anoVersaoTabela == ano;
          });
        }

        return fipeValAtual.length ? fipeValAtual[0].valor : "";

      } catch (e) {
        console.log(e);
        //return '';
      }

    }

    return '';

  }

  public getClearVidaObject(obj): any {


    obj = JSON.parse(JSON.stringify(obj));

    var altura;

    try {
      obj.segurado.altura = parseInt(obj.segurado.altura.toString().padEnd(3, "0"));
    } catch (error) {
      console.log(error);
      obj.segurado.altura = "";
    }


    let propertiesToDelete = ['automoveis', 'placa', 'chassi', 'modelo'];
    propertiesToDelete.map(function (pd) {
      delete obj[pd];
    });
    return obj;

  }

  public getClearPacoteObject(obj): any {

    obj = JSON.parse(JSON.stringify(obj));
    let propertiesToDelete = ['automoveis', 'placa', 'chassi', 'modelo', 'funeral'];
    propertiesToDelete.map(function (pd) {
      delete obj[pd];
    });
    return obj;

  }

  public getClearAutoObject(obj): any {

    obj = JSON.parse(JSON.stringify(obj));
    let propertiesToDelete = ['configuracoes'];
    propertiesToDelete.map(function (pd) {
      delete obj[pd];
    });
    return obj;

  }

  public getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  public getPathLogoById(idSeg: number): string {
    return 'assets/images/seguradoras/seguradora' + idSeg + '.png';
  }

  public getPathLogoApiceById(idSeg: number): string {    
    return 'assets/images/seguradoras/seguradora' + idSeg + '.png';
  }

  public getSegNameById(idSeg: number): string {

    try {

      if (!this.seguradorasById[idSeg])
        this.generateSeguradorasById();

      return this.seguradorasById[idSeg].name;

    } catch (e) {
      return "";
    };

  }

  public getSegFranquiaById(id: number): string {

    try {

      if (!this.franquiaById[id])
        this.generateFranquiaById();

      return this.franquiaById[id].name;

    } catch (e) {
      return "";
    };

  }


  public async createConfirm(title: string, question: string, btnOk?, btnCancel?) {
    return await this.alertConfirmService.confirm(title, question, btnOk, btnCancel);
  }

  public async createAlert(title, message) {
    return await this.alertConfirmService.alert(title, message);
  }

  public async createModalPersonalizada(veiculos) {
    try {
      return await this.modalPersonalizadaService.alert(veiculos);
    } catch (error) {
      console.log(error)
    }

  }

  public fixString(str: any): any {

    if (typeof str === 'string') {

      if (str.charAt(str.length - 1) === "-")
        str = str.substr(0, str.length - 1);

      str = str.replace("Ã", "Í");
      str = str.replace("㯠", "não ");


      return str;

    } else
      return str;

  }

  public isCalcAutoAlternative(idSeg: number): string {

    if (!this.seguradorasById[idSeg])
      this.generateSeguradorasById();

    return this.seguradorasById[idSeg].isCalcAutoAlternative;
  }

  private generateSeguradorasById(): void {

    for (var idxSeg in this._seguradorasBase) {
      this.seguradorasById[this._seguradorasBase[idxSeg].id] = this._seguradorasBase[idxSeg];
    }

  }

  private generateFranquiaById(): void {

    for (var idxfranquia in this._franquiaBase) {

      this.franquiaById[this._franquiaBase[idxfranquia].id] = idxfranquia;
    }

  }

  limparString(srt) {
    try {
      return srt.replace(/[\.\-\/(\/)\s)]/g, '')
    } catch (error) {
      false
    }
  }

  public parseFloat(val) {

    try {
      return parseFloat(val.replace(".", "").replace(",", "."));
    } catch (error) {
      return val;
    }
  }

  public moedaBrasil(val) {

    try {
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    } catch (error) {
      return val;
    }
  }

  public formataFipe(str) {

    try {
      if (str)
        return str.substr(0, 6) + "-" + str.substr(6, 7);
    } catch (error) {
      return str;
    }

  }

  dataAmericaToDate(data) {
    return data ? (new Date(data.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1 "))).toISOString() : '';
  }

  isoStringToBrDate(data) {

    if (!data)
      return '';

    if (data.match(/\d{2}\/\d{2}\/\d{4}/))
      return data;

    let now = new Date(data);

    let mes: any = now.getMonth() + 1; // (now.getMonth()+1)

    if ((now.getMonth() + 1) < 10)
      mes = "0" + mes;

    let dia: any = now.getDate(); // (now.getMonth()+1)

    if (now.getDate() < 10)
      dia = "0" + dia;

    let novadata = dia + "/" + mes + "/" + now.getFullYear();

    return novadata;

  }

  getUrlParams() :any {    //adaptado by juca
    let ret = {};
    try{
      let hash = window.location.hash.substring(1);
      if(hash){
        let hashMatch = hash.match(/\?(.*)/);
        if(hashMatch)
          hashMatch[1].split("&").forEach(function(c){
            let ci = c.split("=");
            ret[ci[0]] = ci[1];
          }); 
      }else{
        let params = window.location.search.match(/\?(.*)/);
        if (params) {
          params[1].split("&").forEach(function(c){
            let ci = c.split("=");
            ret[ci[0]] = ci[1];
          }); 

        }

      }
      
    } catch(e) {console.log(e)}
    
    return ret;
  }

  formataPlaca(str) {

    try {
      if (str) {
        str = str.toUpperCase();
        return str.substr(0, 3) + "-" + str.substr(3, 6);
      }
    } catch (error) {
      return str;
    }

  }

  idade(nascimento) {

    let hoje = new Date();

    nascimento = new Date(nascimento);

    var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
    if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
      new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
      diferencaAnos--;
    return diferencaAnos;
  }

  maiorIdade(data) {

    if (data) {

      let idade = this.idade(this.converteDataAmerica(data));

      if (idade >= 18)
        return true;
      else
        return false;
    }

  }

  public tempoHabilitacaoOptionsPelaIdade(dataNascimento, _tempoHabilitacaoTxt: any[]) {
    let dataNascimentoConvertida = this.converteDataAmerica(dataNascimento);
    let optionsIndexMax = this.idade(dataNascimentoConvertida) - 18;
    let options = [];

    if (dataNascimento) {

      if (this.maiorIdade(dataNascimento)) {
        for (const key in _tempoHabilitacaoTxt) {
          if (parseInt(key) <= optionsIndexMax || key == '0') {
            options.push(_tempoHabilitacaoTxt[key]);
          }
        }
        return this.objectToViewValue(options, true);
      }
      else {
        return [];
      }
    }
  }

  calcTempCnh(dataChh) {

    try {

      if (!dataChh)
        return 0;

      var dataPrimHabil = new Date(dataChh);
      var timeDiff = dataPrimHabil.getTime() - new Date().getTime();
      return Math.abs(Math.ceil((timeDiff / (1000 * 3600 * 24)) / 365));

    } catch (error) {
      return null;
    }
  }

  verifyDiffDays(
    dataVigenciaIni: any,
    dataVigenciaFim: any,
    dataVigenciaAnteriorIni?: any,
    dataVigenciaAnteriorFim?: any
  ) {
    let isDiffDays: boolean;
    let isDiffDaysAnterior: boolean;

    // converteDataAmerica()

    dataVigenciaIni = dataVigenciaIni.split('/');
    dataVigenciaFim = dataVigenciaFim.split('/');

    if (dataVigenciaAnteriorIni && dataVigenciaAnteriorFim) {
      dataVigenciaAnteriorIni = dataVigenciaAnteriorIni.split('/');
      dataVigenciaAnteriorFim = dataVigenciaAnteriorFim.split('/');

      const diasIniAnterior = parseInt(dataVigenciaAnteriorIni[0], 10);
      const mesIniAnterior = parseInt(dataVigenciaAnteriorIni[1], 10);
      const anoIniAnterior = parseInt(dataVigenciaAnteriorIni[2], 10);
      var dateIniAnterior: any = new Date(anoIniAnterior, mesIniAnterior, diasIniAnterior); // ??

      if (this.isAnoBissexto(anoIniAnterior))
        dateIniAnterior = this.editarData({
          data: dateIniAnterior,
          operacao: "-",
          dia: 1,
          mes: 0,
          ano: 0
        })

      if (dateIniAnterior.getUTCHours() === 2)
        dateIniAnterior = this.editarData({
          data: dateIniAnterior,
          operacao: "+",
          dia: 0,
          mes: 0,
          ano: 0,
          hora: 1
        })

      const diaFimAnterior = parseInt(dataVigenciaAnteriorFim[0], 10);
      const mesFimAnterior = parseInt(dataVigenciaAnteriorFim[1], 10);
      const anoFimAnterior = parseInt(dataVigenciaAnteriorFim[2], 10);
      var dateFimAnterior: any = new Date(anoFimAnterior, mesFimAnterior - 1, diaFimAnterior); // ??

      if (this.isAnoBissexto(anoFimAnterior))
        dateFimAnterior = this.editarData({
          data: dateFimAnterior,
          operacao: "-",
          dia: 1,
          mes: 0,
          ano: 0
        })

      if (dateFimAnterior.getUTCHours() === 2)
        dateFimAnterior = this.editarData({
          data: dateFimAnterior,
          operacao: "+",
          dia: 0,
          mes: 0,
          ano: 0,
          hora: 1
        })



      var timeDiffAnterior = Math.abs(dateIniAnterior.getTime() - dateFimAnterior.getTime());
      var diffDaysAnterior = Math.ceil(timeDiffAnterior / (1000 * 3600 * 24));
    }

    const diasIni = parseInt(dataVigenciaIni[0], 10);
    const mesIni = parseInt(dataVigenciaIni[1], 10);
    const anoIni = parseInt(dataVigenciaIni[2], 10);
    var dateIni: any = new Date(anoIni, mesIni - 1, diasIni); // ??

    if (this.isAnoBissexto(anoIni))
      dateIni = this.editarData({
        data: dateIni,
        operacao: "-",
        dia: 1,
        mes: 0,
        ano: 0
      })

    if (dateIni.getUTCHours() === 2)
      dateIni = this.editarData({
        data: dateIni,
        operacao: "+",
        dia: 0,
        mes: 0,
        ano: 0,
        hora: 1
      })

    const diaFim = parseInt(dataVigenciaFim[0], 10);
    const mesFim = parseInt(dataVigenciaFim[1], 10);
    const anoFim = parseInt(dataVigenciaFim[2], 10);
    var dateFim: any = new Date(anoFim, mesFim - 1, diaFim); // ??

    if (this.isAnoBissexto(anoFim))
      dateFim = this.editarData({
        data: dateFim,
        operacao: "-",
        dia: 1,
        mes: 0,
        ano: 0
      })

    if (dateFim.getUTCHours() === 2)
      dateFim = this.editarData({
        data: dateFim,
        operacao: "+",
        dia: 0,
        mes: 0,
        ano: 0,
        hora: 1
      })

    var timeDiff = Math.abs(dateIni.getTime() - dateFim.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    diffDays > 365 ? isDiffDays = true : isDiffDays = false;

    diffDaysAnterior && diffDaysAnterior > 365
      ? isDiffDaysAnterior = true
      : isDiffDaysAnterior = false

    return { isDiffDays, isDiffDaysAnterior };

  }

  calIntervalAnos(ano, ini, fim) {
    try {
      if (ano < ini || ano > fim)
        return false
      else
        return true
    } catch (error) {
      return false
    }
  }

  public isMaiorIdade(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let dataValid = this.maiorIdade(control.value);
      return !dataValid ? { validaMaiorIdade: { value: control.value } } : null;
    };
  }

  public validIntervaloAnos(ini, fim): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let dataValid = this.calIntervalAnos(control.value, ini, fim);
      return !dataValid ? { intervalAnos: { value: control.value } } : null;
    };
  }

  public validarData(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let dataValid = this.dataValida(control.value);
      return !dataValid ? { validaData: { value: control.value } } : null;
    };
  }

  public validarDataMaximaVigencia(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let dataValid = this.validaAnoMaximoVigencia(control.value);
      return !dataValid ? { validaData: { value: control.value } } : null;
    };
  }

  public validaAnoMaximoVigencia(date) {
    if (date) {

      var data = date.split('/');
      var ano = parseInt(data[2]);

      var dateNow = new Date();
      var yearLimit = dateNow.getFullYear() + 2;


      if (ano <= yearLimit) {
        return true;
      }
      return false;
    }
  }

  isBissexto(ano) {
    if (ano) {
      try {
        if ((ano % 4 == 0) && ((ano % 100 != 0) || (ano % 400 == 0))) {
          return 29
        } else {
          return 28
        }
      } catch (error) {
        return false;
      }
    }
  }

  isAnoBissexto(ano) {
    return (ano % 4 == 0) && (ano % 100 != 0) || (ano % 400 == 0) ? true : false;
  }

  dataValida(data) {

    if (data) {

      var data = data.split('/');
      var dia = parseInt(data[0]);
      var mes = parseInt(data[1]);
      var ano = parseInt(data[2]);

      var meses = {
        "1": { descricao: "Janeiro", dias: 31 },
        "2": { descricao: "Fevereiro", dias: this.isBissexto(ano) },
        "3": { descricao: "Março", dias: 31 },
        "4": { descricao: "Abril", dias: 30 },
        "5": { descricao: "Maio", dias: 31 },
        "6": { descricao: "Junho", dias: 30 },
        "7": { descricao: "Julho", dias: 31 },
        "8": { descricao: "Agosto", dias: 31 },
        "9": { descricao: "Setembro", dias: 30 },
        "10": { descricao: "Outubro", dias: 31 },
        "11": { descricao: "Novembro", dias: 30 },
        "12": { descricao: "Dezembro", dias: 31 }
      };

      if (mes > 0 && mes <= 12) {
        if (ano > 1920) {
          if (dia > 0 && dia <= meses[mes].dias) {
            return true;
          } else {
            return false
          }
        } else {
          return false;
        }
      }
    }
  }

  converteDataAmerica(data) {

    let novadata;

    if (data !== "" && data !== undefined && data !== null) {
      try {

        const regex = /(\d\/)/gm;//data com barras

        let m;

        if (regex.exec(data) !== null) {
          let split = data.split('/');
          novadata = split[2] + "-" + split[1] + "-" + split[0] + "T17:47:20.353Z";

          // novadata = (new Date(novadata)).toISOString();

        } else {

          let dataString = /(\d{4}-\d{2}-\d{2}$)/gm;

          if (dataString.exec(data) !== null)
            data = data + "T17:47:20.353Z"




          let now = new Date(data);
          // let dataBrasil = new Date(now.valueOf() + now.getTimezoneOffset() * 60000);

          let mes: any = now.getMonth() + 1; // (now.getMonth()+1)

          if ((now.getMonth() + 1) < 10)
            mes = "0" + mes;

          let dia: any = now.getDate(); // (now.getMonth()+1)

          if (now.getDate() < 10)
            dia = "0" + dia;

          novadata = dia + "/" + mes + "/" + now.getFullYear();

        }

        return novadata;

      } catch (error) {

        return "";
      }
    } else {
      return "";
    }
  }

  converteDataPadrao(data) {

    let novadata;

    if (data !== "" && data !== undefined && data !== null) {
      try {

        const regex = /(\d\/)/gm;

        let m;

        if (regex.exec(data) !== null) {
          let split = data.split('/');
          novadata = split[2] + "-" + split[1] + "-" + split[0] + "T17:47:20.353Z";
        } else {
          novadata = data
        }

        return novadata;

      } catch (error) {

        return "";
      }
    } else {
      return "";
    }
  }

  private generateCarroReservaById(): void {

    for (var idxcarReserva in this._franquiaBase) {

      this.carroResById[this._carroReservaBase[idxcarReserva].id] = idxcarReserva;
    }

  }

  clearCfgSegToCalc(objCalc) {

    delete objCalc['id'];
    delete objCalc['logo'];
    delete objCalc['usuario'];
    delete objCalc['percComissaoResid'];
    delete objCalc['percDescontoResid'];
    delete objCalc['percComissaoEmpres'];
    delete objCalc['percDescontoEmpres'];
    delete objCalc['percComissaoVida'];
    delete objCalc['percDescontoVida'];
    delete objCalc['percCom'];
    delete objCalc['percDesc'];
  }

  formatCnpjCpf(value) {

    try {
      const cnpjCpf = value.replace(/\D/g, '');

      if (cnpjCpf.length === 11) {
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
      }

      return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    } catch (error) {
      return "";
    }
  }

  verificaTipoPessoa(value) {

    try {
      let cnpjCpf = value.replace(/\D/g, '');

      if (cnpjCpf.length == 11)
        return "f";
      else if (cnpjCpf.length == 14)
        return "j";
      else
        return null;
    } catch (error) {
      return null;
    }
  }

  formatAltura(value) {

    try {
      if (value !== null) {
        const altura = value.toString();
        return altura.replace(/^(\d{1})(\d{1,2})$/g, "\$1.\$2");
      } else {
        return "";
      }

    } catch (error) {

      return "";
    }
  }

  formatCep(value) {

    try {

      const cep = value.toString();

      return cep.replace(/(\d{5})(\d{3})/g, "\$1-\$2");

    } catch (error) {

      return "";
    }

  }

  formatCodigoPostal(value) {

    try {

      const cep = value.toString();

      return cep.replace(/(\d{4})(\d{3})/g, "\$1-\$2");

    } catch (error) {

      return "";
    }

  }

  formatInteger(number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };

  formatFone(value) {

    try {

      const fone = value.toString();

      return fone.replace(/(\d{2})(\d{4})(\d{4,5})/g, "(\$1) \$2-\$3");

    } catch (error) {

      return "";
    }

  }

  formatFoneNovo(value) {

    try {

      const fone = value.toString();

      return fone.replace(/(\d{2})(\d{4,5})(\d{4})/g, "(\$1) \$2-\$3");

    } catch (error) {

      return "";
    }

  }

  public dateStringToTxt(date): any {
    return new Date(date).toLocaleDateString();
  }

  public formatFipe(val): any {
    val = val + "";
    return val.replace(/(\d{6})(\d{1})/, "$1-$2");
  }

  // ignorarOrdenacao=true : o codigo segue a ordem 1 valor , 2 valor, 3 valor, 4...
  // ignorarOrdenacao = false: O codigo irá tratar os valores como strings e ordenar por alfabetização.
  //  Exemplo: this.sinistrosAnterior = this.utilsService.objectToViewValue(this._sinistrosAnteriorTxt, true);

  public objectToViewValue(val: any, ignorarOrdenacao?: boolean): ViewValue[] {

    let arr = [];
    for (let idx in val) {
      let formatIdx: any = parseInt(idx);
      formatIdx = isNaN(formatIdx) ? idx : (isFinite(formatIdx) ? formatIdx : idx);
      arr.push({ value: formatIdx, viewValue: val[idx] });
    }

    return ignorarOrdenacao ? arr : arr.sort(function (a, b) {
      return (a.viewValue > b.viewValue) ? 1 : ((b.viewValue > a.viewValue) ? -1 : 0);
    });

  }

  public idNameObjectToViewValue(val: any): ViewValue[] {

    let arr = [];
    for (let idx in val) {
      arr.push({ value: val[idx].id, viewValue: val[idx].name || val[idx].nome });
    }

    return arr;

  }

  public getBooleanViewValue(): ViewValue[] {

    return [
      { value: true, viewValue: 'Sim' },
      { value: false, viewValue: 'Não' }
    ];

  }

  public getAssistFuneral(): ViewValue[] {

    return [
      { value: 0, viewValue: 'Não' },
      { value: 1, viewValue: 'Funeral Individual' },
      { value: 2, viewValue: 'Funeral Familiar' }
    ];

  }

  public getTipoConstrucao(): ViewValue[] {

    return [
      { value: 1, viewValue: 'Alvenaria' },
      { value: 2, viewValue: 'Mista' },
      { value: 3, viewValue: 'Madeira' }
    ];

  }

  public getTipoObjSegurado(): ViewValue[] {

    return [
      { value: 1, viewValue: 'Prédio' },
      { value: 2, viewValue: 'Conteúdo' },
      { value: 3, viewValue: 'Prédio + Conteúdo' }
    ];

  }

  public getTipoResidencia(): ViewValue[] {

    return [
      { value: 1, viewValue: 'Casa' },
      { value: 2, viewValue: 'Casa em condomínio Fechado' },
      { value: 3, viewValue: 'Apartamento' }
    ];

  }

  public getTipoUso(): ViewValue[] {

    return [
      { value: 1, viewValue: 'Habitual' },
      { value: 2, viewValue: 'Veraneio' },
      { value: 3, viewValue: 'Desocupado' }
    ];

  }

  public validateProperty(property: string, validators: ValidatorFn[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // get the value and assign it to a new form control
      const propertyVal = control.value && control.value[property];
      const newFc = new FormControl(propertyVal);
      // run the validators on the new control and keep the ones that fail
      const failedValidators = validators.map(v => v(newFc)).filter(v => !!v)
      // if any fail, return the list of failures, else valid
      return failedValidators.length ? { 'invalidProperty': failedValidators } : null;
    };
  }

  public getGrupoAtividade(): ViewValue[] {

    return [
      { value: 1, viewValue: 'Comercio e Serviço' },
      // { value: 2, viewValue: 'Industria' },
    ];

  }

  public getAtividades(): ViewValue[] {

    return [
      { value: 1, viewValue: 'Academia de ginástica' },
      { value: 2, viewValue: 'Açougue' },
      { value: 3, viewValue: 'Agua - Dep./Loja' },
      { value: 4, viewValue: 'Artigos Religiosos - Dep./Loja' },
      { value: 5, viewValue: 'Asilo/Orfanato' },
      { value: 6, viewValue: 'Atelier de pintura e artesanato' },
      { value: 7, viewValue: 'Automóveis - Loja/Depósito' },
      { value: 8, viewValue: 'Automóveis - Oficina' },
      { value: 9, viewValue: 'Automóveis - Peças - Loja/Depósito' },
      { value: 10, viewValue: 'Bar, pastelaria e lanchonete' },
      { value: 11, viewValue: 'Bazar - Depósito ou loja' },
      { value: 12, viewValue: 'Bebidas - Depósito ou loja' },
      { value: 13, viewValue: 'Bicicletas - Dep/Loja' },
      { value: 14, viewValue: 'Bijuterias - Depósito ou loja' },
      { value: 15, viewValue: 'Bilhar, Boliche - Salão' },
      { value: 16, viewValue: 'Bolsas, cintos, malas e congêneres - Depósito ou loja' },
      { value: 17, viewValue: 'Borracha - Dep./Loja de artigos' },
      { value: 18, viewValue: 'Brinquedos - Depósito ou Loja' },
      { value: 19, viewValue: 'Cabeleireiro' },
      { value: 20, viewValue: 'Cafeteria, doceria' },
      { value: 21, viewValue: 'Calçado - Depósito ou loja' },
      { value: 22, viewValue: 'Cama, mesa, Banho - Depósito ou loja' },
      { value: 23, viewValue: 'Cartório' },
      { value: 24, viewValue: 'Chaveiro/Carimbo - Depósito ou loja' },
      { value: 25, viewValue: 'Cirurgicos, hospitalares e dentários - Depósito ou loja de equipamentos' },
      { value: 26, viewValue: 'Clínica Estética' },
      { value: 27, viewValue: 'Clínica Veterinária' },
      { value: 28, viewValue: 'Clube' },
      { value: 29, viewValue: 'Conservas - Massas Alimentícias - Depósito ou loja' },
      { value: 30, viewValue: 'Consultórios médicos' },
      { value: 31, viewValue: 'Consultórios odontológicos' },
      { value: 32, viewValue: 'Copiadora de documentos' },
      { value: 33, viewValue: 'Couros e peles - Depósito ou loja' },
      { value: 34, viewValue: 'Despachante' },
      { value: 35, viewValue: 'Discos - Loja' },
      { value: 36, viewValue: 'Doces - Depósito ou loja' },
      { value: 37, viewValue: 'Edificio Desocupado' },
      { value: 38, viewValue: 'Eletrônico, Dep./Loja' },
      { value: 39, viewValue: 'Escola de Informática' },
      { value: 40, viewValue: 'Escola Particular' },
      { value: 41, viewValue: 'Escola Pública' },
      { value: 42, viewValue: 'Escritórios - Andar Superior' },
      { value: 43, viewValue: 'Escritórios - Térreo' },
      { value: 44, viewValue: 'Estúdio de tatuagem' },
      { value: 45, viewValue: 'Farmácia - Loja/Dep.' },
      { value: 46, viewValue: 'Ferragem e Ferramenta - Depósito ou loja' },
      { value: 47, viewValue: 'Floricultura' },
      { value: 48, viewValue: 'Frutas e verduras - Dep/Loja' },
      { value: 49, viewValue: 'Gráfica' },
      { value: 50, viewValue: 'Hospital' },
      { value: 51, viewValue: 'Hotéis e Pousadas' },
      { value: 52, viewValue: 'Igreja' },
      { value: 53, viewValue: 'Informática - Dep/Loja' },
      { value: 54, viewValue: 'Instrumentos Musicais - Loja/Depósito' },
      { value: 55, viewValue: 'Laboratório de pesquisas e análises clínicas' },
      { value: 56, viewValue: 'Laticinios - Depósito ou loja' },
      { value: 57, viewValue: 'Lavanderia' },
      { value: 58, viewValue: 'Livraria - Loja/Dep.' },
      { value: 59, viewValue: 'Loja de Departamento' },
      { value: 60, viewValue: 'Lotérica' },
      { value: 61, viewValue: 'Máquinas Agrícolas - Dep/loja' },
      { value: 62, viewValue: 'Marmore/Granito - Dep/Loja' },
      { value: 63, viewValue: 'Materiais de construção - Depósito ou loja' },
      { value: 64, viewValue: 'Artigos de ótica' },
      { value: 65, viewValue: 'Confecções e varejo' },
      { value: 66, viewValue: 'Roupas e depósitos' },
      { value: 67, viewValue: 'Salão de beleza' },
      { value: 68, viewValue: 'Churrascaria' },
      { value: 69, viewValue: 'Farmácia - Dep' },
      { value: 70, viewValue: 'Ferragem e Ferramenta - Depósito' },
      { value: 71, viewValue: 'Frutas e verduras - Dep' },
      { value: 72, viewValue: 'Metal (não precioso) - Artigos, Depósito ou loja' },
      { value: 73, viewValue: 'Mini-mercado, mercearia, secos e molhados' },
      { value: 74, viewValue: 'Móveis Madeira - Dep/Loja' },
      { value: 75, viewValue: 'Móveis Metal - Dep/Loja' },
      { value: 76, viewValue: 'Ortopédicos, artigos de, depósito ou loja' },
      { value: 77, viewValue: 'Padaria e confeitaria' },
      { value: 78, viewValue: 'Papelaria - Loja/Dep.' },
      { value: 79, viewValue: 'Peixaria' },
      { value: 80, viewValue: 'Pet Shop' },
      { value: 81, viewValue: 'Pneus - Depósito ou loja' },
      { value: 82, viewValue: 'Produtos de limpeza - Depósito ou loja' },
      { value: 83, viewValue: 'Restaurante' },
      { value: 84, viewValue: 'Roupas, oficina' },
      { value: 85, viewValue: 'Sorveteria' },
      { value: 86, viewValue: 'Tapete, carpete e cortina - Loja' },
      { value: 87, viewValue: 'Tapete, carpete e cortina - Depósito' },
      { value: 88, viewValue: 'Tecidos - Loja' },
      { value: 89, viewValue: 'Tecidos - Depósito' },
      { value: 90, viewValue: 'Video Locadora' },
      { value: 91, viewValue: 'Vidros - Loja' },
      { value: 92, viewValue: 'Vidros - Depósito' }
    ];

  }

  public getBonusRenovacaoResid(): ViewValue[] {

    return [
      { value: 0, viewValue: 'Sem bónus' },
      { value: 1, viewValue: '1 ano - 5% de desconto' },
      { value: 2, viewValue: '2 anos - 10% de desconto' },
      { value: 3, viewValue: '3 anos - 15% de desconto' },
      { value: 4, viewValue: '4 anos - 20% de desconto' },
      { value: 5, viewValue: '5 anos - 25% de desconto' }
    ];

  }

  public getBonusRenovacaoAuto(): ViewValue[] {

    return [
      { value: 0, viewValue: 'Sem bónus' },
      { value: 1, viewValue: '1' },
      { value: 2, viewValue: '2' },
      { value: 3, viewValue: '3' },
      { value: 4, viewValue: '4' },
      { value: 5, viewValue: '5' },
      { value: 6, viewValue: '6' },
      { value: 7, viewValue: '7' },
      { value: 8, viewValue: '8' },
      { value: 9, viewValue: '9' },
      { value: 10, viewValue: '10' }
    ];

  }

  public getReturnNumeric(inicio, fim): ViewValue[] {


    let i;
    let arrayObj = [];

    try {
      for (i = inicio; i <= fim; i++)
        arrayObj.push({ value: i, viewValue: i });
    } catch (error) {
      return [{ value: 0, viewValue: "0" }];
    }

    return arrayObj;

  }

  public seguradoras(): ViewValue[] {

    return [
      { value: "651", viewValue: 'ACE Seguradora S/A' },
      { value: "673", viewValue: 'Aig Brasil' },
      { value: "646", viewValue: 'Alfa Seguros e Previdência S.A.' },
      { value: "517", viewValue: 'Allianz' },
      { value: "535", viewValue: 'Azul Companhia de Seguros Gerais' },
      { value: "661", viewValue: 'BEMGE Seg. S.A.' },
      { value: "523", viewValue: 'Bancred Seguradora S.A' },
      { value: "600", viewValue: 'Banerj Seg. S.A.' },
      { value: "527", viewValue: 'Banestes Seguros' },
      { value: "574", viewValue: 'Banorte Seg. S.A.' },
      { value: "507", viewValue: 'Bozano Simonsen Seg. S/A' },
      { value: "531", viewValue: 'Bradesco Auto/RE Cia de Seg.' },
      { value: "618", viewValue: 'Brasil Veiculos Cia de Seguros' },
      { value: "554", viewValue: 'CCF Brasil Seguros S/A' },
      { value: "564", viewValue: 'CGU Cia de Seguros' },
      { value: "568", viewValue: 'COSESP - Cia Seg. do Estado de SP' },
      { value: "552", viewValue: 'Caixa Geral Seguradora S.A' },
      { value: "553", viewValue: 'Caixa Seguradora S/A' },
      { value: "501", viewValue: 'Chubb do Brasil Cia Seg.' },
      { value: "569", viewValue: 'Cia Excelsior de Seguros' },
      { value: "664", viewValue: 'Cia Real Brasileira Seg.' },
      { value: "504", viewValue: 'Cia Seg. Alianca da Bahia' },
      { value: "519", viewValue: 'Companhia de Seguros Previdencia do Sul' },
      { value: "590", viewValue: 'Generali do Brasil Cia Nacional Seg.' },
      { value: "680", viewValue: 'Gente Seguradora' },
      { value: "657", viewValue: 'HDI' },
      { value: "318", viewValue: 'ITAU Seg. S.A.' },
      { value: "514", viewValue: 'Icatu Seg. S/A' },
      { value: "584", viewValue: 'Indiana Cia Seg. Gerais' },
      { value: "552", viewValue: 'Interbrazil Seguradora S/A' },
      { value: "636", viewValue: 'Kyoei do Brasil Companhia de Seguros' },
      { value: "518", viewValue: 'Liberty Seguros S/A' },
      { value: "623", viewValue: 'Mapfre Vera Cruz Seguradora S/A' },
      { value: "542", viewValue: 'Martinelli Seguradora' },
      { value: "660", viewValue: 'Mitsui Marine e Kyoei Fire Seguros S.A' },
      { value: "665", viewValue: 'PANAMERICANA Seg. S.A.' },
      { value: "509", viewValue: 'PHENIX Seguradora' },
      { value: "540", viewValue: 'PQ Seguros S/A' },
      { value: "588", viewValue: 'Porto Seguro Cia Seg. Gerais' },
      { value: "644", viewValue: 'SAFRA Seg. S.A.' },
      { value: "606", viewValue: 'SANTOS Seg. S.A.' },
      { value: "295", viewValue: 'Sancor Seguros do Brasil' },
      { value: "548", viewValue: 'Santander Brasil Seguros' },
      { value: "576", viewValue: 'Santos Cia de Seguros' },
      { value: "506", viewValue: 'Seg. OCEANICA S.A.' },
      { value: "675", viewValue: 'Seguro Sura S.A.' },
      { value: "641", viewValue: 'Sompo Seguros' },
      { value: "495", viewValue: 'Suhai Seguradora' },
      { value: "511", viewValue: 'Sul America Cia Nac. de Seguros' },
      { value: "619", viewValue: 'Tokio Marine Seguradora S.A' },
      { value: "620", viewValue: 'ZURICH - MINAS BRASIL' }
    ];

  }

  public uf(): ViewValue[] {

    return [
      { value: 'AC', viewValue: 'AC' },
      { value: 'AL', viewValue: 'AL' },
      { value: 'AP', viewValue: 'AP' },
      { value: 'AM', viewValue: 'AM' },
      { value: 'BA', viewValue: 'BA' },
      { value: 'CE', viewValue: 'CE' },
      { value: 'DF', viewValue: 'DF' },
      { value: 'ES', viewValue: 'ES' },
      { value: 'GO', viewValue: 'GO' },
      { value: 'MA', viewValue: 'MA' },
      { value: 'MT', viewValue: 'MT' },
      { value: 'MS', viewValue: 'MS' },
      { value: 'MG', viewValue: 'MG' },
      { value: 'PA', viewValue: 'PA' },
      { value: 'PB', viewValue: 'PB' },
      { value: 'PR', viewValue: 'PR' },
      { value: 'PE', viewValue: 'PE' },
      { value: 'PI', viewValue: 'PI' },
      { value: 'RJ', viewValue: 'RJ' },
      { value: 'RN', viewValue: 'RN' },
      { value: 'RS', viewValue: 'RS' },
      { value: 'RO', viewValue: 'RO' },
      { value: 'RR', viewValue: 'RR' },
      { value: 'SC', viewValue: 'SC' },
      { value: 'SP', viewValue: 'SP' },
      { value: 'SE', viewValue: 'SE' },
      { value: 'TO', viewValue: 'TO' }
    ];

  }

  public getAssistFuneralValor(): ViewValue[] {

    return [
      { value: 0, viewValue: 'R$ 0,00' },
      { value: 2000, viewValue: 'R$ 2.000,00' },
      { value: 3000, viewValue: 'R$ 3.000,00' },
      { value: 4000, viewValue: 'R$ 4.000,00' },
      { value: 5000, viewValue: 'R$ 5.000,00' },
      { value: 6000, viewValue: 'R$ 6.000,00' },
      { value: 7000, viewValue: 'R$ 7.000,00' },
      { value: 8000, viewValue: 'R$ 8.000,00' },
      { value: 9000, viewValue: 'R$ 9.000,00' },
      { value: 10000, viewValue: 'R$ 10.000,00' },
      { value: 15000, viewValue: 'R$ 15.000,00' }
    ];

  }

  public editarData(objDate) {

    try {
      let dt = new Date(objDate.data);

      if (objDate.operacao == "+") {
        dt.setFullYear(dt.getFullYear() + objDate.ano);
        dt.setDate(dt.getDate() + objDate.dia);
        dt.setMonth(dt.getMonth() + objDate.mes);
        objDate.hora && dt.setHours(dt.getHours() + objDate.hora);
      } else if (objDate.operacao == "-") {
        dt.setFullYear(dt.getFullYear() - objDate.ano);
        dt.setDate(dt.getDate() - objDate.dia);
        dt.setMonth(dt.getMonth() - objDate.mes);
        objDate.hora && dt.setHours(dt.getHours() - objDate.hora);
      }

      return dt;
    } catch (error) {
      return false;
    }
  }

  public numberFormat(val, rs = false): any {
    try {
      if (typeof val == "string")
        val = parseFloat(val);
      if (rs)
        return val.toLocaleString('pt-pt', { style: 'currency', currency: 'EUR' });
      else
        return val.toLocaleString('pt-pt', { style: 'currency', currency: 'EUR' }).replace("\u00A0€", "");
    } catch (e) {
      return '';
    }
  }

  public intervalNumberToViewValue(val: IntervalNumberView | IntervalNumberView[]): ViewValue[] {

    let generateInterval = function (obj) {
      let valAtual = obj.min;
      let limite = obj.max;
      let interval = obj.interval;
      let arr = [];
      while (valAtual <= limite) {
        arr.push(new ViewValue(valAtual, valAtual.toLocaleString('pt-pt', { style: 'currency', currency: 'EUR' })));
        valAtual = valAtual + interval;
      }
      return arr;
    }

    if (Array.isArray(val)) {
      let arr = [];
      val.map(function (v) {
        arr = [...arr, ...generateInterval(v)];
      });
      return arr;
    } else {
      return generateInterval(val);
    }
  }

  public intervalViewValue(intervalType: number) {
    if (intervalType == 1) {
      return this.intervalNumberToViewValue(
        [{
          min: 10000,
          max: 75000,
          interval: 5000
        }, {
          min: 80000,
          max: 100000,
          interval: 25000
        }, {
          min: 100000,
          max: 300000,
          interval: 50000
        }, {
          min: 400000,
          max: 1000000,
          interval: 100000
        }]
      );
    }
    if (intervalType == 2) {
      return this.intervalNumberToViewValue(
        [{
          min: 0,
          max: 1000,
          interval: 1000
        }, {
          min: 1500,
          max: 5000,
          interval: 500
        }, {
          min: 6000,
          max: 9000,
          interval: 1000
        }, {
          min: 10000,
          max: 20000,
          interval: 1000
        }, {
          min: 25000,
          max: 30000,
          interval: 5000
        }, {
          min: 40000,
          max: 50000,
          interval: 10000
        }, {
          min: 75000,
          max: 100000,
          interval: 25000
        }]
      );
    }
    if (intervalType == 3) {
      return this.intervalNumberToViewValue(
        [{
          min: 0,
          max: 50000,
          interval: 5000
        }]
      );
    }

  }

  public intervalToViewValue(
    val: IntervalNumberView | IntervalNumberView[],
    isPorcentagem?: boolean
  ): ViewValue[] {

    let generateInterval = function (obj) {
      let valAtual = obj.min;
      let limite = obj.max;
      let interval = obj.interval;
      let arr = [];

      while (valAtual <= limite) {
        if (isPorcentagem) {
          arr.push(new ViewValue(valAtual, valAtual + '%'));
          valAtual = valAtual + interval;
        } else {
          arr.push(new ViewValue(valAtual, valAtual + 'X'));
          valAtual = valAtual + interval;
        }
      }
      return arr;
    }

    if (Array.isArray(val)) {
      let arr = [];
      val.map(function (v) {
        arr = [...arr, ...generateInterval(v)];
      });
      return arr;
    } else {
      return generateInterval(val);
    }
  }

  public getIdade(dataNasc: Date) {

    let ano_aniversario = dataNasc.getFullYear();
    let mes_aniversario = dataNasc.getMonth() + 1;
    let dia_aniversario = dataNasc.getDate();

    var d = new Date,
      ano_atual = d.getFullYear(),
      mes_atual = d.getMonth() + 1,
      dia_atual = d.getDate();

    ano_aniversario = +ano_aniversario,
      mes_aniversario = +mes_aniversario,
      dia_aniversario = +dia_aniversario;

    let quantos_anos = ano_atual - ano_aniversario;

    if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
      quantos_anos--;
    }

    return quantos_anos < 0 ? 0 : quantos_anos;

  }

  public cpfCnpj(cpfCnpj) {

    cpfCnpj = cpfCnpj.replace(/\D/g, "");

    if (cpfCnpj.length <= 14) { //CPF
      cpfCnpj = cpfCnpj.replace(/(\d{3})(\d)/, "$1.$2");
      cpfCnpj = cpfCnpj.replace(/(\d{3})(\d)/, "$1.$2");
      cpfCnpj = cpfCnpj.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else { //CNPJ
      cpfCnpj = cpfCnpj.replace(/^(\d{2})(\d)/, "$1.$2")
      cpfCnpj = cpfCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
      cpfCnpj = cpfCnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
      cpfCnpj = cpfCnpj.replace(/(\d{4})(\d)/, "$1-$2");
    }

    return cpfCnpj

  }

  public getStrTxtLimChars(str, lim) {

    try {
      if (str && str.length >= lim) {
        str = str.substring(0, lim) + "...";
      }

      str = str.replace(/(\w)(-)(\w)/i, "$1 $2 $3");
      str = str.replace(/(\w)(\+)(\w)/i, "$1 $2 $3");
      str = str.replace(/(7)(dias)/i, "$1 $2");
      str = str.replace(/(15)(dias)/i, "$1 $2");
      str = str.replace(/(30)(dias)/i, "$1 $2");
      str = str.replace(/(45)(dias)/i, "$1 $2");
    } catch (e) { }

    return str;

  }

  md5 = (string) => {

    function RotateLeft(lValue, iShiftBits) {
      return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    }

    function AddUnsigned(lX, lY) {
      var lX4, lY4, lX8, lY8, lResult;
      lX8 = (lX & 0x80000000);
      lY8 = (lY & 0x80000000);
      lX4 = (lX & 0x40000000);
      lY4 = (lY & 0x40000000);
      lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
      if (lX4 & lY4) {
        return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
      }
      if (lX4 | lY4) {
        if (lResult & 0x40000000) {
          return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
        } else {
          return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
        }
      } else {
        return (lResult ^ lX8 ^ lY8);
      }
    }

    function F(x, y, z) {
      return (x & y) | ((~x) & z);
    }

    function G(x, y, z) {
      return (x & z) | (y & (~z));
    }

    function H(x, y, z) {
      return (x ^ y ^ z);
    }

    function I(x, y, z) {
      return (y ^ (x | (~z)));
    }

    function FF(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function GG(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function HH(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function II(a, b, c, d, x, s, ac) {
      a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
      return AddUnsigned(RotateLeft(a, s), b);
    };

    function ConvertToWordArray(string) {
      var lWordCount;
      var lMessageLength = string.length;
      var lNumberOfWords_temp1 = lMessageLength + 8;
      var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
      var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
      var lWordArray = Array(lNumberOfWords - 1);
      var lBytePosition = 0;
      var lByteCount = 0;
      while (lByteCount < lMessageLength) {
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition));
        lByteCount++;
      }
      lWordCount = (lByteCount - (lByteCount % 4)) / 4;
      lBytePosition = (lByteCount % 4) * 8;
      lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
      lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
      lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
      return lWordArray;
    };

    function WordToHex(lValue) {
      var WordToHexValue = "",
        WordToHexValue_temp = "",
        lByte, lCount;
      for (lCount = 0; lCount <= 3; lCount++) {
        lByte = (lValue >>> (lCount * 8)) & 255;
        WordToHexValue_temp = "0" + lByte.toString(16);
        WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length - 2, 2);
      }
      return WordToHexValue;
    };

    function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";

      for (var n = 0; n < string.length; n++) {

        var c = string.charCodeAt(n);

        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if ((c > 127) && (c < 2048)) {
          utftext += String.fromCharCode((c >> 6) | 192);
          utftext += String.fromCharCode((c & 63) | 128);
        } else {
          utftext += String.fromCharCode((c >> 12) | 224);
          utftext += String.fromCharCode(((c >> 6) & 63) | 128);
          utftext += String.fromCharCode((c & 63) | 128);
        }

      }

      return utftext;
    };

    var x = Array();
    var k, AA, BB, CC, DD, a, b, c, d;
    var S11 = 7,
      S12 = 12,
      S13 = 17,
      S14 = 22;
    var S21 = 5,
      S22 = 9,
      S23 = 14,
      S24 = 20;
    var S31 = 4,
      S32 = 11,
      S33 = 16,
      S34 = 23;
    var S41 = 6,
      S42 = 10,
      S43 = 15,
      S44 = 21;

    string = Utf8Encode(string);

    x = ConvertToWordArray(string);

    a = 0x67452301;
    b = 0xEFCDAB89;
    c = 0x98BADCFE;
    d = 0x10325476;

    for (k = 0; k < x.length; k += 16) {
      AA = a;
      BB = b;
      CC = c;
      DD = d;
      a = FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
      d = FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
      c = FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
      b = FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
      a = FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
      d = FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
      c = FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
      b = FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
      a = FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
      d = FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
      c = FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
      b = FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
      a = FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
      d = FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
      c = FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
      b = FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
      a = GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
      d = GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
      c = GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
      b = GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
      a = GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
      d = GG(d, a, b, c, x[k + 10], S22, 0x2441453);
      c = GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
      b = GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
      a = GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
      d = GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
      c = GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
      b = GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
      a = GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
      d = GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
      c = GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
      b = GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
      a = HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
      d = HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
      c = HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
      b = HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
      a = HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
      d = HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
      c = HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
      b = HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
      a = HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
      d = HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
      c = HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
      b = HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
      a = HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
      d = HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
      c = HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
      b = HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
      a = II(a, b, c, d, x[k + 0], S41, 0xF4292244);
      d = II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
      c = II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
      b = II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
      a = II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
      d = II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
      c = II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
      b = II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
      a = II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
      d = II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
      c = II(c, d, a, b, x[k + 6], S43, 0xA3014314);
      b = II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
      a = II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
      d = II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
      c = II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
      b = II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
      a = AddUnsigned(a, AA);
      b = AddUnsigned(b, BB);
      c = AddUnsigned(c, CC);
      d = AddUnsigned(d, DD);
    }

    var temp = WordToHex(a) + WordToHex(b) + WordToHex(c) + WordToHex(d);

    return temp.toLowerCase();
  };

  public formBuilderCpfCnpj(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let cpfCnpjValid = this.validaCpfCnpj(control.value);
      return !cpfCnpjValid ? { validaCpfCpnj: { value: control.value } } : null;
    };
  }

  public getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
  }

  public setCookieByDays(cname, cvalue, exdays, path) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + (`; path=${path};`);
  }

  public setCookieRootByMin(cname, cvalue, min, path) {

    var d = new Date();
    d.setTime(d.getTime() + (min * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + (`; path=${path};`);

  }

  validaCpfCnpj(val) {

    try {
      if (val.length == 14) {
        var cpf = val.trim();

        cpf = cpf.replace(/\./g, '');
        cpf = cpf.replace('-', '');
        cpf = cpf.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
            aux = true;
          }
        }

        if (aux == false) {
          return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
          v1 = 0;
        }

        if (v1 != cpf[9]) {
          return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
          v2 = 0;
        }

        if (v2 != cpf[10]) {
          return false;
        } else {
          return true;
        }
      } else if (val.length == 18) {
        var cnpj = val.trim();

        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cnpj.length > i; i++) {
          if (cnpj[i - 1] != cnpj[i]) {
            aux = true;
          }
        }

        if (aux == false) {
          return false;
        }

        for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
          if (p1 >= 2) {
            v1 += cnpj[i] * p1;
          } else {
            v1 += cnpj[i] * p2;
          }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
          v1 = 0;
        } else {
          v1 = (11 - v1);
        }

        if (v1 != cnpj[12]) {
          return false;
        }

        for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
          if (p1 >= 2) {
            v2 += cnpj[i] * p1;
          } else {
            v2 += cnpj[i] * p2;
          }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
          v2 = 0;
        } else {
          v2 = (11 - v2);
        }

        if (v2 != cnpj[13]) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }

  }

  fabricantesDuplicados(fabricante) {

    switch (fabricante) {
      case 67:
        return 7;
      default:
        return fabricante;
    }

  }

  retornaValorAproximado(dados, valor, pedido) {

    try {
      var retorno = false

      if (typeof dados === 'string')
        dados = JSON.parse(dados)

      if (pedido == "paraBaixo") {
        if (valor != undefined) {
          for (let i = 0; i < dados.length; ++i) {

            let val = dados[i];

            if (dados[i].value)
              val = dados[i].value;

            var resu = val - valor;
            if (resu < 0) {
              retorno = val;
              break
            }
          }
        }

      } else if (pedido == "paraCima") {
        if (valor != undefined) {
          for (let i = 0; i < dados.length; ++i) {

            let val = dados[i];

            if (dados[i].value)
              val = dados[i].value;

            var resu = val - valor;
            if (resu >= 0) {
              retorno = val;
              break
            }
          }
        }
      }
    } catch (error) {
      console.log("erro ao definir valor apoximado:" + error);
      return false;
    }


    return retorno ? retorno : Math.max.apply(null, dados);
  }

  openSnackbarModal(options: SnackbarProps) {
    const config = new MatSnackBarConfig();
    config.panelClass = options.cssClasses;
    config.duration = options.duration;
    config.horizontalPosition = options.horizontalPosition;
    config.verticalPosition = options.verticalPosition;

    this._snackBar.open(options.message, options.action, config);
  }

  openSnackbarModalErroValidacaoForm() {
    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar', 'red-snackbar'];
    config.duration = 3000;
    // config.horizontalPosition = 'left';

    this._snackBar.open(
      "Preencha todas informações obrigatórias antes de prosseguir.",
      "Fechar",
      config
    );
  }

  geraStringEndereco(endereco) {
		let stringEndereco = '';
		if (endereco?.logradouro && endereco?.numero)
			stringEndereco += `${endereco.logradouro} ${endereco.numero}`;

		if (endereco?.bairro && stringEndereco == '')
			stringEndereco += `${endereco.bairro}`;
		else if (endereco?.bairro && stringEndereco != '')
			stringEndereco += `, ${endereco.bairro}`;

		if (endereco?.cep && stringEndereco == '')
			stringEndereco += `${this.formatCodigoPostal(endereco.cep)}`;
		else if (endereco?.cep && stringEndereco != '')
			stringEndereco += `, ${this.formatCodigoPostal(endereco.cep)}`;

		if (endereco?.cidade && stringEndereco == '')
			stringEndereco += `${endereco.cidade}`;
		else if (endereco?.cidade && stringEndereco != '')
			stringEndereco += `, ${endereco.cidade}`;
      
		if (endereco?.uf && stringEndereco == '')
			stringEndereco += `${ConstantPortugal.estados.find(estado => estado.value === endereco.uf)?.viewValue || ''}`;
		else if (endereco?.uf && stringEndereco != '') {
      let estado = ConstantPortugal.estados.find(estado => estado.value === endereco.uf)?.viewValue;
      stringEndereco += estado ? `, ${estado}` : '';
    }

		return stringEndereco;
	}
}
