import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPersonalizadaComponent } from '../components/modal-personalizada/modal-personalizada.component';

@Injectable()

export class ModalPersonalizadaService {

  constructor(private modalService: NgbModal) { }

  public async alert(
    veiculos: any,
    btnOkText: string = 'OK') : Promise<boolean> {
    var modalRef = await this.modalService.open(ModalPersonalizadaComponent, {
      size: "680px",
    });

    modalRef.componentInstance.title = "Selecione o Veículo";
    modalRef.componentInstance.veiculos = veiculos;
    modalRef.componentInstance.btnOkText = btnOkText;

    let ret;
    try{
      ret = modalRef.result;
    } catch(e){
      console.log(modalRef);
      console.log(e)
    }

    return ret;




  }

}
