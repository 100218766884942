import { Directive, EventEmitter, Output, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxDebouncing]'
})
export class NgxDebouncingDirective {
	@Output() debounceFunction = new EventEmitter();	
	@Input('debounceTimer') debounceTimer: any; //receber o timer do debounce

	time: any;
	timerDebounce: any;

	constructor() {
	}

	// @HostListener('change', ['$event'])
	// onchange() {
	// 	console.log('fgrdthj')
	// 	this.timerDebounce = this.debounceTimer > 0 ? this.debounceTimer : 500;
	// 	this.deboucingFunction(this.timerDebounce);
	// }

	@HostListener('keydown', ['$event'])
	ontype(event) {
		if([9, 37, 39].includes(event.keyCode)) {
			return;
		}
		else if (event.keyCode == 13) {
			event.preventDefault();
			event.stopPropagation();
		}
		// console.log(event.keyCode)
		this.timerDebounce = this.debounceTimer > 0 ? this.debounceTimer : 500;
		this.deboucingFunction(this.timerDebounce);
		
	}
	
	deboucingFunction(timer) {
		clearTimeout(this.time);
		this.time = setTimeout(() => {			
			this.debounceFunction.emit(); //emitir a função recebida			
		}, timer);
	}

}
