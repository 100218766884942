import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-tarefa',
  templateUrl: './modal-tarefa.component.html',
  styleUrls: ['./modal-tarefa.component.scss']
})
export class ModalTarefaComponent implements OnInit {

  concluindo;
  status;
  visualizar;
  negocio;
  tarefa;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>) {
    if (data && data.tarefa) {
      this.tarefa = data.tarefa;
      if (data.visualizar) {
        this.visualizar = data.visualizar;
        if (data.concluindo) {
          this.concluindo = data.concluindo;
        }

        if (data.status) {
          this.status = data.status;
        }

      }

    } else if (data && data.negocio) {
      this.negocio = data.negocio;
    }

  }

  ngOnInit() { }

  atualizarLista(ev) {
    this.dialogRef.close('atualizar');
  }

  ngOnDestroy(){
    document.querySelector('body').classList.remove('tiraScrollBody');
  }

}
