<div class="anexosBox" *ngIf="showTitle">
    <h3>
      {{title}} {{anexos && anexos.length ? '(' + anexos.length + ')' : ''}}
        <!-- {{title}} {{anexos && anexos.length ? '(' + anexos.length + ')' : ''}} -->
    </h3>
  </div>
  
  <!-- FILA DE ENVIO DE ANEXOS -->
  
  <ul *ngIf="anexosTemp && mostrarAnexos && anexos.length"
  margin-left
  no-padding
  no-margin
  [ngStyle]="{'width':'100%'}"
  [ngClass]="['lista-anexos']">
  
  <li
  ion-col
  size="12"
  *ngFor="let anexo of anexosTemp"
  [ngStyle]="{'width':'100%'}"
  [ngClass]="['lista-anexos-item']">
  <app-file-upload-multi
      [anexo]="anexo">
  </app-file-upload-multi>
  </li>
  
  </ul>
  
  <!-- <ion-row
    *ngIf="anexosTemp && mostrarAnexos"
    margin-left
    no-padding
    no-margin
    [ngStyle]="{'width':'100%'}"
    [ngClass]="['lista-anexos']">
    <ion-row
        ion-col
        size="12"
        *ngFor="let anexo of anexosTemp"
        [ngStyle]="{'width':'100%'}"
        [ngClass]="['lista-anexos-item']">
        <file-upload
            [anexo]="anexo"
            (onEnterEdition)="entraEdicaoAnexo(anexo)"
            (onSaveEdition)="concluiEdicaoAnexo(anexo)"
            (onCancelEdition)="cancelaEdicaoAnexo(anexo)"
            (onRemove)="deletaAnexo(anexo)"
            (onError)="erroAnexo(anexo)">
        </file-upload>
    </ion-row>
  </ion-row> -->
  <!-- ANEXOS ENVIADOS -->
  
  <mat-list-item *ngFor="let anexo of anexos" [ngClass]="['listaAx', anexo.editMode ? 'mb-32' : '', 'gerenciamento-arquivos']" >
  
    <div class="inputEdit" *ngIf="anexo.editMode">
        <!-- Campo de edição do nome -->
        <mat-form-field  class="editMode" >
            
            <!-- Campo texto de edição do nome -->
            <input autocomplete="off" matInput
              placeholder="Nome do arquivo" type="text"
              [(ngModel)]="anexo.tempName"
              [disabled]="anexo.salvandoAnexo">                
              <mat-hint *ngIf="anexo.editMode && anexo.error">{{anexo?.error}}</mat-hint>
        </mat-form-field>
  
        <!-- Cancelar -->
        <button mat-icon-button
        [disabled]="anexo.salvandoAnexo"
        [ngStyle]="{'margin-right':'5px'}">
            <mat-icon
            [ngClass]="['mdi mdi-close mdi-18px']"
            ></mat-icon>
        </button>
  
        <!-- Salvar -->
        <button mat-icon-button [disabled]="anexo.salvandoAnexo">
            <mat-icon
            [ngClass]="['mdi mdi-check mdi-18px']"></mat-icon>
        </button>         
    </div>          
  
    <!-- Link anexo visível quando não está em edição -->
    <div class="anexoLink">
  
        <a [ngClass]="['link', anexo.salvandoAnexo? 'salvandoAnexo': '']" [href]="anexo.salvando? null: anexo.fileUrl" target="_blank" *ngIf="!anexo.editMode">
          <mat-icon [svgIcon]='anexo.icone'></mat-icon>
            <!-- <mat-icon
                [ngStyle]="{'color': anexo.icon?.color}"
                matListIcon
                class="buttonIconSvgCustom"
                [svgIcon]="anexo.icon?.name"></mat-icon>             -->
            <p
                matLine
                class="fontDefault">
                {{anexo.showName}}
            </p>
        </a>
  
       
    </div>
   
    <!-- Ativa botão de opções (quando o anexo visível não está em edição) -->
    <button
        *ngIf="!anexo.editMode && !anexo.salvandoAnexo"
        [ngClass]="['menuAnexo', anexo.open ? 'menuAnexoShow' : '', 'btnMenuAnexo']" 
        [matMenuTriggerFor]="menuAnexo"
        (menuOpened)="anexo.open = true"
        (menuClosed)="anexo.open =  false"
        [disabled]="editandoAnexo"
        mat-icon-button>
        <mat-icon [svgIcon]="'dots-vertical'"></mat-icon>
        <!-- <mat-icon [ngClass]="['mdi mdi-dots-vertical mdi-18px']"></mat-icon> -->
    </button>
  
    <!-- Icone salvandoAnexo -->
    <mat-icon *ngIf="anexo.salvandoAnexo" [ngClass]="['mdi mdi-loading mdi-spin']"></mat-icon>
  
    <!-- Menu opções anexo -->
    <mat-menu  #menuAnexo="matMenu">
        <button mat-menu-item>
          <mat-icon [svgIcon]="'delete'"></mat-icon>
            <!-- <mat-icon [ngClass]="['mdi mdi-delete mdi-18px']"></mat-icon> -->
            Excluir anexo
        </button>
        <button mat-menu-item>
          <mat-icon [svgIcon]="'pencil'"></mat-icon>
            <!-- <mat-icon [ngClass]="['mdi mdi-pencil mdi-18px']"></mat-icon> -->
            Editar anexo
        </button>
    </mat-menu>      
  </mat-list-item>
  
  <mat-nav-list  class="anexosBox-list anexosBox">
    <mat-list-item class="listaAnexos" >
        <span class="fontDefault btnPlus">
            <!-- <mat-icon class="mdi mdi-paperclip mdi-18px"></mat-icon> -->
            <mat-icon [svgIcon]="'paperclip'"></mat-icon>
            Adicionar Anexo
            <input
            #arquivosSelecionados
            padding
            ion-input
            title
            ng2FileSelect
            autocomplete="off"
            id="inputFile"
            type="file"
            [disabled]="salvandoAnexo"
            [ngClass]="'seletor-de-arquivos inputFile__input btn btn-success fileinput-button'"           
            [uploader]="uploader"      
            (change)="fileChangeEvent($event)">
        </span>
    </mat-list-item>
  </mat-nav-list>
  
  <!-- <mat-nav-list
    class="anexosBox-list"
    *ngIf="anexos.length else listaAnexoVazio">         -->
  <mat-nav-list
    class="anexosBox-list"
    *ngIf="anexos.length">
  
  
  </mat-nav-list>
  