import { Erros } from '../models/erros.model';
import { SeguradorasBase } from '../models/seguradoras/factory/base.model';
import { CalcsGrid } from '../models/calculos/calcs-grid';
import { RamosCodes } from '../models/ramosCodes.model';

export class GlobalConstants {

  public static get errorMessages(): Erros { // Puxa a descricao da seguradora pelo nome.
    return {
      LOGOUT_WAK: {
        message: "Ocorreu um erro ao realizar logout.",
        code: 101
      }
    };
  }

  public static get motivos() { // Puxa a descricao da seguradora pelo nome.
    return {
      1: "Cálculo com informações divergentes",
      2: "Redirecionamento pela extensão",
      3: "Outros",
      4: "Sugestões"
    };
  }

  public static get seguradoras(): SeguradorasBase {
    return {
      BRADESCO: {
        id: 1,
        idGenerico: 531,
        name: "Bradesco",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "bradescoCodCorretor",
          "bradescoSucursal",
          "bradescoCodUsuario",
          "bradescoInspetoria"
        ]
      },
      LIBERTY: {
        id: 2,
        idGenerico: 518,
        name: "Liberty WS",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      MAPFRE: {
        id: 3,
        idGenerico: 623,
        name: "Mapfre",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "mapfreCodVc",
          "mapfreCodInt",
          "mapfreSelCompanhia",
          "mapfreTpUsuario",
          "mapfreGrupoAfinidade"
        ]
      },
      HDI: {
        id: 4,
        idGenerico: 657,
        name: "HDI",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "hdiCodigoCorretor",
          "hdiCodigoProdutor",
          "hdiSucursal",
          "hdiEmpresa",
        ]
      },
      ALLIANZ: {
        id: 5,
        idGenerico: 517,
        name: "Allianz",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "allianzSucursalSelecionada"
        ]
      },
      SOMPO: {
        id: 6,
        idGenerico: 641,
        name: "Sompo",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "sompoCodigoCorretor"
        ]
      },
      ZURICH: {
        id: 7,
        idGenerico: 620,
        name: "Zurich",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      PORTO_SEGURO: {
        id: 8,
        idGenerico: 588,
        name: "Porto Seguro",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "portoSusep"
        ]
      },
      ITAU: {
        id: 9,
        idGenerico: 318,
        name: "Itaú",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      AZUL: {
        id: 10,
        idGenerico: 535,
        name: "Azul",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      TOKIO: {
        id: 11,
        idGenerico: 619,
        name: "Tokio",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "tokioCodigo"
        ]
      },
      LIBERTY_SITE: {
        id: 12,
        name: "Liberty",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "libertyCodigoCorretor",
          "libertyCodigoFilial",
          "libertyCpfCnpj"
        ]
      },
      MITSUI: {
        id: 13,
        idGenerico: 660,
        name: "Mitsui",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "mitsuiCodigoCorretor",
          "mitsuiFilialNome"
        ]
      },
      GENERALI: {
        id: 14,
        idGenerico: 590,
        name: "Generali",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "generaliCodigo",
          "generaliSucursalSelecionada"
        ]
      },
      ITAU_PORTAL: {
        id: 15,
        name: "Itaú",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SOMPO_WS: {
        id: 16,
        name: "Sompo WS",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SULAMERICA: {
        id: 17,
        idGenerico: 101,
        name: "Sul América",
        isCalcAutoAlternative: false,
        particularAttributes: [
          "sulAmericaEstrVenda",
          "sulAmericaEstrApoio",
          "sulAmericaAcaoApoio",
          "sulAmericaSucursal",
          "sulAmericaConvenio"
        ]
      },
      ALFA: {
        id: 18,
        idGenerico: 646,
        name: "Alfa",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SANCOR: {
        id: 19,
        idGenerico: 295,
        name: "Sancor",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SUHAI: {
        id: 20,
        idGenerico: 495,
        name: "Suhai",
        isCalcAutoAlternative: true,
        particularAttributes: []
      },
      TOKIO_AUTO_POPULAR: {
        id: 21,
        name: "Tokio Auto Popular",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      ALIRO: {
        id: 22,
        name: "Aliro",
        isCalcAutoAlternative: true,
        particularAttributes: [
          "libertyCodigoCorretor",
          "libertyCodigoFilial",
          "libertyCpfCnpj"
        ]
      },
      ALLIANZ_PACOTE_ECONOMICO: {
        id: 23,
        name: "Allianz Pacote Econômico",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SURA: {
        id: 24,
        name: "Sura",
        isCalcAutoAlternative: false,
        particularAttributes: []
      },
      SEG_TESTE: {
        id: 901,
        name: "Seguradora de teste",
        isCalcAutoAlternative: false,
        particularAttributes: []
      }
    };
  }

  public static get formsTitle() {
    return {
      v: 'Automóvel',
      c: 'Caminhão',
      m: 'Moto'
    }
  }

  public static get calcsGrid(): CalcsGrid {

    return {
      main: {
        id: 1,
        description: {
          desktop: "PRODUTOS PRINCIPAIS",
          mobile: "PRINCIPAIS"
        }
      },
      alternatives: {
        id: 2,
        description: {
          desktop: "PRODUTOS ALTERNATIVOS",
          mobile: "ALTERNATIVOS"
        }
      },
      all: {
        id: 3,
        description: {
          desktop: "TODOS",
          mobile: "TODOS"
        }
      }
    }

  }

  public static get franquia(): any {
    return {
      REDUZIDA: {
        id: 1,
        name: "Reduzida"
      },
      NORMAL: {
        id: 2,
        name: "Obrigatória"
      },
      MAJORADA: {
        id: 3,
        name: "Majorada"
      }
    }
  }

  public static get carroReservaTxt(): any {

    return {
      0: "Não contratar",
      1: "Básico 7 dias",
      2: "Básico 15 dias",
      3: "Básico 30 dias"
    }

  }

  public static get carroReserva(): any {
    return {
      NAO_CONTRATAR: {
        id: 0,
        name: "Não contratar"
      },
      BASICO_7_DIAS: {
        id: 1,
        name: "Básico 7 dias"
      },
      BASICO_15_DIAS: {
        id: 2,
        name: "Básico 15 dias"
      },
      BASICO_30_DIAS: {
        id: 3,
        name: "Básico 30 dias"
      }
    }
  }

  public static get assist24hsTxt(): any {

    return {
      1: "Não / Min",
      2: "400km",
      3: "Max / Ilim"
    }

  }

  public static get assist24h(): any {
    return {
      MAX_ILIMITADO: {
        id: 3,
        name: "Básica"
      },
      INTERMEDIARIO: {
        id: 2,
        name: "Intermediária"
      },
      MINIMO: {
        id: 1,
        name: "Completa"
      }
    }
  }

  //Não modificar, no apice são esses nomes
  public static get pacotesCoberturas(): any {
    return {
      PRATA: {
        id: 1,
        name: "Prata"
      },
      OURO: {
        id: 2,
        name: "Ouro"
      },
      DIAMANTE: {
        id: 3,
        name: "Diamante"
      },
      PERSONALIZADA: {
        id: 4,
        name: "Personalizada"
      }
    }
  }


  public static get coberturas(): any {

    return {
      tipoTxtLim: "Tipo",
      tipoFranquia: "Tipo Franquia",
      // isDanosMateriais: "Danos Materiais",
      // isDanosMateriaisTxt: "Danos Materiais",
      // isDanosMateriaisTxtRS: "Danos Materiais",
      // isDanosCorporais: "Danos Corporais",
      // isDanosCorporaisTxt: "Danos Corporais",
      // isDanosCorporaisTxtRS: "Danos Corporais",
      // isDanosMorais: "Danos Morais",
      // isDanosMoraisTxt: "Danos Morais",
      // isDanosMoraisTxtRS: "Danos Morais",
      responsabilidadeCivil: "Responsabilidade Civil",
      responsabilidadeCivilTxt: "Responsabilidade Civil",
      responsabilidadeCivilTxtRS: "Responsabilidade Civil",
      despesasTratamento: "Despesas Tratamento",
      despesasTratamentoTxt: "Despesas Tratamento",
      despesasTratamentoTxtRS: "Despesas Tratamento",
      danosPropriosFurtoRoubo: "Furto ou Roubo",
      danosPropriosFurtoRouboTxt: "Furto ou Roubo",
      danosPropriosFurtoRouboTxtRS: "Furto ou Roubo",
      danosPropriosChoqueColisaoCapotamento: "Choque, Colisão e Capotamento",
      danosPropriosChoqueColisaoCapotamentoTxt: "Choque, Colisão e Capotamento",
      danosPropriosChoqueColisaoCapotamentoTxtRS: "Choque, Colisão e Capotamento",
      danosPropriosRiscoNatureza: "Riscos Catastróficos da Natureza",
      danosPropriosRiscoNaturezaTxt: "Riscos Catastróficos da Natureza",
      danosPropriosRiscoNaturezaTxtRS: "Riscos Catastróficos da Natureza",
      danosPropriosGrevesTumultos: "Greves, Tumultos, Alterações Ordem Pública",
      danosPropriosGrevesTumultosTxt: "Greves, Tumultos, Alterações Ordem Pública",
      danosPropriosGrevesTumultosTxtRS: "Greves, Tumultos, Alterações Ordem Pública",
      danosPropriosAtosTerrorismo: "Atos de Terrorismo, Vandalismo e Sabotagem",
      danosPropriosAtosTerrorismoTxt: "Atos de Terrorismo, Vandalismo e Sabotagem",
      danosPropriosAtosTerrorismoTxtRS: "Atos de Terrorismo, Vandalismo e Sabotagem",
      isAppMorte: "Morte",
      isAppMorteTxt: "Morte",
      isAppMorteTxtRS: "Morte",
      isAppInvalidez: "Invalidez",
      isAppInvalidezTxt: "Invalidez",
      isAppInvalidezTxtRS: "Invalidez",
      carroReserva: "Veículo de Substituição por Acidente",
      carroReservaTxtLim: "Veículo de Substituição por Acidente",
      assist24hs: "24 Horas",
      assist24hsTxtLim: "24 Horas",
      vidrosTxtLim: "Vidros",
      invalidezPermanenteTxt: "Invalidez",
      invalidezPermanenteTxtRS: "Invalidez",
      morteTxt: "Morte",
      morteTxtRS: "Morte",
      morteAcidentalTxt: "Morte Acidental",
      morteAcidentalTxtRS: "Morte Acidental",
      doencasGravesTxt: "Doenças graves",
      doencasGravesTxtRS: "Doenças graves",
      funeralTxt: "Funeral",
      funeralTxtCob: "Funeral",
      isDanosAlagamento: "Alagamento",
      isDanosAlagamentoTxt: "Alagamento",
      isDanosAlagamentoTxtRS: "Alagamento",
      isDanosIncendioRaioExplosao: "Incêndio",
      isDanosIncendioRaioExplosaoTxt: "Incêndio",
      isDanosIncendioRaioExplosaoTxtRS: "Incêndio",
      isDanosDesmoronamentoTxt: "Desmoronamento",
      isDanosDesmoronamentoTxtRS: "Desmoronamento",
      isDanosAluguelTxt: "Danos aluguel",
      isDanosAluguelTxtRS: "Danos aluguel",
      isDanosEletricosTxt: "Danos elétricos",
      isDanosEletricosTxtRS: "Danos elétricos",
      isDanosImpactoVeiculoTxt: "Impac. Veículo",
      isDanosImpactoVeiculoTxtRS: "Impac. Veículo",
      isDanosRouboTxt: "Roubo",
      isDanosRouboTxtRS: "Roubo",
      isDanosVidrosTxt: "Vidros",
      isDanosVidrosTxtRS: "Vidros",
      assist24hsTxt: "Assis. 24h ",
      assist24hsTxtMod: "Assis. 24h "
    }

  }

  public static get tipoPagamento(): any {
    return {
      1: "credito",
      2: "debito",
      3: "boleto",
    }
  }

  public static get tipoPagamentoTxt(): any {
    return {
      1: "Cartão de crédito",
      2: "Débito em conta",
      3: "Boleto / Ficha de compensação",
    }
  }

  public static get tipoFranquiaTxt(): any {

    return {
      1: "Reduzida",
      2: "Obrigatória",
      3: "Majorada"
    }

  }

  //Não modificar, no apice são esses nomes
  public static get tipoCoberturaMultiTxt(): any {

    return {
      1: "Prata",
      2: "Ouro",
      3: "Diamante",
      4: "Personalizada"
    }

  }

  public static get tpUsoTxt(): any {

    return {
      1: "Apenas Lazer",
      2: "Locomoção Diária",
      3: "Prestação de serviços",
      4: "Representação comercial",
      5: "Transporte por aplicativos",
      6: "Outros ramos de atividade"
    }

  }



  public static get estadoCivilTxt(): any {

    return {
      "1": "Solteiro(a)",
      "2": "Casado(a)",
      "3": "Separado(a)",
      "4": "Divorciado(a)",
      "5": "Viuvo(a)",
      "6": "União Estável"
    }

  }

  public static get sexoTxt(): any {

    return {
      "M": "Masculino",
      "F": "Feminino"
    }

  }

  public static get profissoesTxt(): any {

    return {
      0: "ADVOGADO (DIREITO PENAL / CRIMINALISTA)",
      1: "ADVOGADO (EXCETO ADVOGADO DO DIREITO PENAL / CRIMINALISTA)",
      2: "ALFAIATE",
      3: "ANTROPÓLOGO",
      4: "APOSENTADO POR INVALIDEZ (EXCETO FUNCIONÁRIO PÚBLICO)",
      5: "APOSENTADO POR TEMPO DE SERVICO (EXCETO FUNCIONÁRIO PÚBLICO)",
      6: "APOSENTADO POR INVALIDEZ (FUNCIONARIO PUBLICO)",
      7: "APOSENTADO POR TEMPO DE SERVICO (FUNCIONARIO PUBLICO)",
      8: "ARQUEÓLOGO",
      9: "ARQUITETO",
      10: "ASTRÓLOGO",
      11: "ASTRÔNOMO",
      12: "ATENDENTE OU RECEPCIONISTA",
      13: "ATLETA PROFISSIONAL (OUTRAS MODALIDADES)",
      14: "ATLETA PROFISSIONAL DE FUTEBOL",
      15: "ATLETA PROFISSIONAL DE GOLFE",
      16: "ATLETA PROFISSIONAL DE HOQUEI",
      17: "ATLETA PROFISSIONAL DE LUTA",
      18: "ATLETA PROFISSIONAL DE RUGBI",
      19: "ATLETA PROFISSIONAL DE TENIS",
      20: "ATLETA PROFISSIONAL DE VOLEI",
      21: "ATOR",
      22: "AUXILIAR ADMINISTRATIVO",
      23: "AUXILIAR OU TÉCNICO DE ENFERMAGEM",
      24: "BARBEIRO",
      25: "BARMAN",
      26: "BIBLIOTECÁRIO",
      27: "BIOMÉDICO",
      28: "BIÓLOGO",
      29: "BOMBEIRO MILITAR (SOLDADO)",
      30: "BOMBEIRO MILITAR (CABO)",
      31: "BOMBEIRO MILITAR (SARGENTO)",
      32: "BOMBEIRO MILITAR (SUBTENENTE)",
      33: "BOMBEIRO MILITAR (TENENTE)",
      34: "BOMBEIRO MILITAR (CAPITAO)",
      35: "BOMBEIRO MILITAR (MAJOR)",
      36: "BOMBEIRO MILITAR (TENENTE-CORONEL)",
      37: "BOMBEIRO MILITAR (CORONEL)",
      38: "CABELEIREIRO",
      39: "CAMAREIRO",
      40: "CARTEIRO",
      41: "CHAVEIRO",
      42: "CHEFE DE COZINHA",
      43: "COMERCIANTE",
      44: "CONTADOR, TÁCNICO OU ANALISTA EM CONTABILIDADE",
      45: "COREÓGRAFO",
      46: "CORRETOR DE IMÓVEIS",
      47: "CORRETOR DE SEGUROS",
      48: "COSTUREIRA OU ALFAIATE",
      49: "COZINHEIRO OU AUXILIAR DE COZINHA",
      50: "DANÇARINO OU BAILARINO",
      51: "DECORADOR",
      52: "DENTISTA",
      53: "DEPUTADO",
      54: "DESENHISTA",
      55: "DIGITADOR",
      56: "ECONOMISTA",
      57: "ELETRICISTA DE INSTALAÇÕES E MANUTENÇÃO",
      58: "EMPREGADO DOMÉSTICO",
      59: "EMPRESÁRIO / DIRETOR GERAL DE EMPRESA",
      60: "ENCANADOR",
      61: "ENFERMEIRO PSIQUIATRICO ",
      62: "ENFERMEIRO - EXCETO ENFERMEIRO PSIQUIATRICO ",
      63: "ENGENHEIRO CIVIL E AFINS. ",
      64: "ENGENHEIRO DE MINAS ",
      65: "ENGENHEIRO DE VOO ",
      66: "ENGENHEIRO ELETRICISTA ELETRONICO E AFINS ",
      67: "ENGENHEIRO EM COMPUTACAO ",
      68: "ENGENHEIRO MECANICO E AFINS - EXCETO ENGENHEIRO MECANICO NUCLEAR ",
      69: "ENGENHEIRO MECANICO NUCLEAR ",
      70: "ENGENHEIRO MECATRONICO ",
      71: "ENGENHEIRO QUIMICO ",
      72: "ESCRITOR",
      73: "ESTAGIÁRIO, BOLSISTA OU TRAINEE",
      74: "ESTUDANTE",
      75: "FARMACÊUTICO",
      76: "FOTÓGRAFO",
      77: "FRENTISTA",
      78: "GARÇOM",
      79: "GERENTE ADMINISTRATIVO",
      80: "GEÓLOGO",
      81: "GUARDA-CIVIL MUNICIPAL",
      82: "INSTALADOR DE PRODUTOS OU ACESSÓRIOS",
      83: "INSTRUTOR DE AUTOESCOLA",
      84: "JARDINEIRO",
      85: "JOALHEIRO",
      86: "JORNALISTA",
      87: "JUIZ DE DIREITO",
      88: "LAVADEIRO, EM GERAL",
      89: "MANICURE",
      90: "MAQUIADOR",
      91: "MATEMÁTICO",
      92: "MECÂNICO DE VEÍCULOS",
      93: "MODELO",
      94: "MOTOBOY",
      95: "MOTORISTA DE CAMINHÃO / TRANSPORTADOR",
      96: "MOTORISTA DE CARRO, FURGÃO OU VEÍCULO SIMILAR",
      97: "MOTORISTA DE ONIBUS URBANOS, METROPOLITANOS E RODOVIÁRIOS",
      98: "MÉDICO",
      99: "MILITAR EM GERAL",
      100: "PADEIRO",
      101: "PARAMÉDICO",
      102: "PEDREIRO",
      103: "PILOTO DE AVIÃO",
      104: "POLICIA CIVIL - AGENTE DE SEGURANÇA PENITENCIÁRIA",
      105: "POLICIAL CIVIL, EXCETO AGENTE PENITENCIÁRIO",
      106: "POLICIAL FEDERAL, FERROVIÁRIO E RODOVIÁRIO",
      107: "POLICIAL MILITAR",
      108: "PORTEIRO",
      109: "PRODUTOR AGRÍCOLA",
      110: "PROFESSOR",
      111: "PROGRAMADOR",
      112: "PSICÓLOGO",
      113: "PUBLICITÁRIO",
      114: "QUÍMICO",
      115: "REPÓRTER",
      116: "TATUADOR",
      117: "TELEFONISTA",
      118: "VENDEDOR / VENDEDOR AMBULANTE",
      119: "VEREADOR",
      120: "VETERINÁRIO",
      121: "VIGILANTE OU SEGURANÇA"
    }

  }

  public static get ramos(): RamosCodes {
    return {
      "AUTO": 32,
      "CARRO": 32,
      "MOTOCICLETA": 32,
      "CAMINHAO": 32,
      "VIDA": 91,
      "RESIDENCIAL": 2,
      "EMPRESARIAL": 18,
      "CONDOMINIO": 16
    }
  }

  public static get cobsByRamoId(): any {
    let cobs = {};

    cobs[this.ramos.AUTO] = {
      "cobExclude": [ // Adicionar as coberturas que não devem ser exibidas
        "modeloSelecionado",
        "tipoFranquia",
        "casco",
        "cascoTxt",
        "percComissao",
        "percDesconto",
        // "isDanosMateriais",
        // "isDanosMorais",
        // "isDanosCorporais",
        "responsabilidadeCivil",
        "despesasTratamento",
        "isAppMorte",
        "isAppInvalidez",
        "invalidezPermanente",
        "danosPropriosFurtoRoubo",
        "danosPropriosChoqueColisaoCapotamento",
        "danosPropriosRiscoNatureza",
        "danosPropriosGrevesTumultos",
        "danosPropriosAtosTerrorismo"
      ],
      "cobPriority": { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        "tipoTxtLim": 0,
        "responsabilidadeCivilTxtRS": 1,
        "despesasTratamentoTxtRS": 2,
        "assist24hsTxtLim": 3,
        "veiculoSubAcidenteLim": 4,
        "vidrosTxtLim": 5,
        "isDanosMoraisTxtRS": 6,
        "isAppMorteTxtRS": 7,
        "isAppInvalidezTxtRS": 8,
        "danosPropriosFurtoRouboTxtRS": 9,
        "danosPropriosChoqueColisaoCapotamentoTxtRS": 10,
        "danosPropriosRiscoNaturezaTxtRS": 11,
        "danosPropriosGrevesTumultos": 12,
        "danosPropriosAtosTerrorismoTxtRS": 13
        // "isDanosMateriaisTxtRS": 1,
        // "isDanosCorporaisTxtRS": 2,
      }
    };

    cobs[this.ramos.VIDA] = {
      "cobExclude": [ // Adicionar as coberturas que não devem ser exibidas
        "morteTxt",
        "invalidezPermanenteTxt",
        "doencasGravesTxt",
        "funeralTxt"
      ],
      "cobPriority": { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        "morteTxtRS": 1,
        "morteAcidentalTxtRS": 2,
        "invalidezPermanenteTxtRS": 3,
        "doencasGravesTxtRS": 4,
        "funeralTxtCob": 5
      }
    };

    cobs[this.ramos.RESIDENCIAL] = {
      "cobExclude": [ // Adicionar as coberturas que não devem ser exibidas
        'isDanosIncendioRaioExplosao',
        'isDanosIncendioRaioExplosaoTxt',
        'isDanosRoubo',
        'isDanosRouboTxt',
        'isDanosAlagamento',
        'isDanosAlagamentoTxt',
        'isDanosEletricos',
        'isDanosEletricosTxt',
        'isDanosAluguel',
        'isDanosAluguelTxt',
        'isDanosDesmoronamento',
        'isDanosDesmoronamentoTxt',
        'isDanosImpactoVeiculo',
        'isDanosImpactoVeiculoTxt',
        'isDanosVidros',
        'isDanosVidrosTxt',
        'assist24hsTxt'
      ],
      "cobPriority": { // É possível rever esta logica depois, fiz desta maneira pois é a mais facil, rapida e intuitiva pro momento
        'isDanosIncendioRaioExplosaoTxtRS': 1,
        'isDanosRouboTxtRS': 2,
        'isDanosAlagamentoTxtRS': 3,
        'isDanosEletricosTxtRS': 4,
        'isDanosAluguelTxtRS': 5,
        'isDanosDesmoronamentoTxtRS': 6,
        'isDanosImpactoVeiculoTxtRS': 7,
        'isDanosVidrosTxtRS': 8,
        'assist24hsTxtMod': 9,
      }
    };

    return cobs;

  }

  public static get fechamentoS3(): any {
    return {
      enabled: true,
      url: "https://multicalc-logs-resid-br.s3.amazonaws.com/",
      bucketName: "multicalc-logs-resid-br",
      bucketRegion: "sa-east-1",
      tempoReautenticacao: 60000,
    };
  }

}
