import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'filtro-ramos',
	templateUrl: './ramos.component.html',
	styleUrls: ['./ramos.component.scss']
})
export class RamosComponent implements OnInit {

	/** Saída de evento do Filtro */
	@Output('eventFilter') eventFilter = new EventEmitter();
	@Output('clearFilter') limpaFiltro = new EventEmitter();

	// Filtro
	filtroTipo = 0;
	filtroGrupo = 0;
	filtroStatus = 0;

	constructor() { }

	ngOnInit() { }

	buildString() {
		let rawValue = {
			tipo: this.filtroTipo,
			grupo: this.filtroGrupo,
			status: this.filtroStatus
		};
		let filtros = { values: rawValue };
		this.eventFilter.emit(filtros);
	}

	clearFilter() {
		this.filtroTipo = 0;
		this.filtroStatus = 0;
		this.filtroGrupo = 0;
		this.limpaFiltro.emit();
		this.buildString();
	}

}
