<div>
    <div [ngStyle]="{'padding-top':'10px', 'max-height':'67px'}">
        <mat-form-field>
            <mat-radio-group>
                <mat-select placeholder="Ramos" [(ngModel)]="filtroRamo">
                    <mat-option [value]="0">Todos os ramos</mat-option>
                    <mat-option [value]="31">Auto</mat-option>
                    <mat-option [value]="2">Residencial</mat-option>
                    <mat-option [value]="91">Vida</mat-option>
                    <mat-option [value]="18">Empresarial</mat-option>
                </mat-select>
            </mat-radio-group>
        </mat-form-field>
    </div>

    <div class="actionButtonsFiltros">
        <button mat-button class="btn-primary-ap" (click)="buildString()">FILTRAR</button>
        <button mat-button class="btn-back" (click)="clearFilter()">LIMPAR FILTROS</button>
    </div>
</div>