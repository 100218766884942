import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-personalizada',
  templateUrl: './modal-personalizada.component.html',
  styleUrls: ['./modal-personalizada.component.scss']
})

export class ModalPersonalizadaComponent implements OnInit {

  public displayedColumns: string[] = ['fipe', 'modelo', 'ano'];

  @Input() title: string;
  @Input() veiculos: any[];
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  public veicSel;
  private elemMainMenuHover;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public selecionaVeiculo(row, e){

    let click = e.currentTarget.classList.contains("active");

    if (this.elemMainMenuHover)
      this.elemMainMenuHover.classList.remove("active");

    if (!click) {
      e.currentTarget.classList.add("active")
      this.elemMainMenuHover = e.currentTarget;
    } else
      e.currentTarget.classList.remove("active");

    

    this.veicSel = row;
  }

  async decline() {
    await this.activeModal.close(this.veicSel);
  }

  async accept() {
    await this.activeModal.close(this.veicSel);
  }

  async dismiss() {
    await this.activeModal.dismiss(this.veicSel);
  }

}
