<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()" *ngIf="btnCancelText">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
        <table mat-table [dataSource]="veiculos" class="mat-elevation-z8">
    
            <ng-container matColumnDef="fipe">
              <th mat-header-cell *matHeaderCellDef> Fipe </th>
              <td mat-cell *matCellDef="let element"> {{element.fipe}} </td>
            </ng-container>
          
            <ng-container matColumnDef="modelo">
              <th mat-header-cell *matHeaderCellDef> Modelo </th>
              <td mat-cell *matCellDef="let element"> {{element.modelo}} </td>
            </ng-container>

            <ng-container matColumnDef="ano" class="anoModelo">
              <th mat-header-cell *matHeaderCellDef> Ano do Modelo </th>
              <td mat-cell *matCellDef="let element"> {{element.ano}} </td>
            </ng-container>
                  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selecionaVeiculo(row, $event)" (dblclick)="accept()"></tr>
          </table>
</div>

<div class="modal-footer">
    <!-- <button type="button" class="btn btn-danger" *ngIf="btnCancelText" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button> -->

    <button mat-stroked-button class="mr-2" *ngIf="btnCancelText" (click)="decline()">{{ btnCancelText }}</button>
    <button mat-stroked-button color="primary" class="px-5 btn-selecionar-veiculo" (click)="accept()">{{ btnOkText }}</button>
</div>