import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AuthGuard } from './guards/auth-guard.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FiltroModule } from './components/filtro/filtro.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from './utils/moment-config-datepicker';
import { FabButtonModule } from './components/fab-button/fab-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalFormModule } from './pages/cliente/modal-form/modal-form.module';
import { ModalNegocioModule } from './pages/negocios/modal-negocio/modal-negocio.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfirmComponent } from './components/alert-confirm/alert-confirm.component';
import { EstatisticasVeiculoComponent } from './components/estatisticas-veiculo/estatisticas-veiculo.component';
import { AlertConfirmService } from './services/alert-confirm.service';
import { EstatisticasVeiculoService } from './services/estatisticas-veiculo.service';
import { FechamentoService } from './services/fechamento.service';
// import { GtagModule } from 'angular-gtag';
import { SobreComponentModule } from './components/sobre/sobre.module';
import { ModalPersonalizadaComponent } from './components/modal-personalizada/modal-personalizada.component';
import { ModalPersonalizadaService } from './services/modal-personalizada.service';
import { ApiInterfaceService } from 'src/app/services/api/api-interface.service';
import { ApiApiceService } from 'src/app/services/api/api-apice.service';
import { ModalVideoIntroModule } from './components/modal-video-intro/modal-video-intro.module';
import { LinkVideoModule } from './components/link-video/link-video.module';
import { ModalFechamentoModule } from './components/modal-fechamento/modal-fechamento.module';
import { ModalDocsModule } from './pages/documentos/modal-docs/modal-docs.module';
import { ModalTarefaModule } from 'src/app/components/tarefa/modal-tarefa/modal-tarefa.module';

@Injectable()
export class HammerConfig extends HammerGestureConfig  {
  buildHammer(element: HTMLElement): HammerManager {
     return new Hammer.Manager(element, {
      touchAction: 'auto',
      inputClass: window.innerWidth > 870 ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Pan, {
          direction: Hammer.DIRECTION_ALL
        }],
        [Hammer.Press],
        [Hammer.Swipe, {direction: Hammer.DIRECTION_HORIZONTAL}],
        [Hammer.Tap, {enable: false}]
      ]
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AlertConfirmComponent,
    EstatisticasVeiculoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    HammerModule,
    DeviceDetectorModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true,  registrationStrategy: 'registerImmediately' }),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatSnackBarModule,
    ModalDocsModule,
    FiltroModule,
    MatDatepickerModule,
    FabButtonModule,
    MatDialogModule,
    ModalFormModule,
    ModalNegocioModule,
    ModalTarefaModule,
    NgbModule,
    SobreComponentModule,
    ModalVideoIntroModule,
    LinkVideoModule,
    ModalFechamentoModule,
    // GtagModule.forRoot({ trackingId: 'G-PWS62VJ75E', trackPageviews: true })
  ],
  providers: [
    AuthGuard,
    AlertConfirmService,
    EstatisticasVeiculoService,
    FechamentoService,
    ModalPersonalizadaService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: ApiInterfaceService, useClass: ApiApiceService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig}
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
