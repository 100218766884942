import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParceirosService {
  private CODES_PARCEIROS = ["ALLZ-1", "ALLZ-3"];
  constructor() { }

  getCodesParceiros(){
		return this.CODES_PARCEIROS;
	}
}
