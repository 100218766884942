<div [ngClass]="'fileUploadRow'">
	<div no-padding [ngClass]="[anexo.editMode ? 'nome-anexo' : 'link-anexo']">
		<!-- modo de edição -->
		<mat-form-field *ngIf="anexo.editMode">
			<input autocomplete="off" matInput placeholder="Nome do arquivo" type="text" [(ngModel)]="anexo.tempName">
		</mat-form-field>

		<!-- fora do modo de edição-->
		<span *ngIf="!anexo.editMode">

			<!-- dados arquivo -->
			<div ion-item no-lines *ngIf="!anexo.fileUrl">
				{{ anexo.fileName }}
			</div>
			<a ion-item no-lines [ngClass]="'texto-nome-anexo'" [href]="anexo.fileUrl" target="_blank" *ngIf="anexo.fileUrl">
				{{ anexo.fileName }}
			</a>

			<!-- barra de progresso -->
			<div [ngClass]="'loading'" [ngStyle]="{height: '5px', width: '100%', background:'rgba(0,137,123,0.5)'}" *ngIf="!anexo.error && (anexo.progress !== null && anexo.progress !== undefined)">
				<div [ngStyle]="{height:'100%', background:'rgba(0,137,123,1)', width: anexo.progress + '%'}"></div>
			</div>

		</span>
		<p no-margin *ngIf="anexo.error" class="erro"><small>{{anexo.error}}</small></p>

	</div>
	
	<div size="auto" *ngIf="anexo.key && !anexo.editMode && anexo.progress == null && anexo.error">
		<button mat-icon-button (click)="enterEdition()" title="Editar" [disabled]="anexo.salvandoAnexo">
			<mat-icon [ngClass]="['mdi mdi-pencil mdi-18px']"></mat-icon>
		</button>
	</div>
	
	<div size="auto" *ngIf="anexo.key && anexo.editMode && anexo.progress == null">
		<button mat-icon-button (click)="cancelEdition ()" title="Cancelar" [disabled]="anexo.salvandoAnexo">
			<mat-icon [ngClass]="['mdi mdi-close mdi-18px']"></mat-icon>
		</button>
	</div>

	<div size="auto" *ngIf="anexo.key && anexo.editMode && anexo.progress == null">
		<button mat-icon-button (click)="saveEdition()" title="Salvar" [disabled]="anexo.salvandoAnexo">
			<mat-icon [ngClass]="['mdi mdi-check mdi-18px']"></mat-icon>
		</button>
	</div>

	<div size="auto" *ngIf="!anexo.editMode && anexo.progress == null && anexo.error" no-padding>
		<button mat-icon-button (click)="remove ()" title="Deletar" [disabled]="anexo.salvandoAnexo">
			<mat-icon [ngClass]="['mdi mdi-delete mdi-18px']"></mat-icon>
		</button>
	</div>


</div>