import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NegociosComponent } from './negocios.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [NegociosComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    MatTooltipModule
  ],
  exports: [NegociosComponent]
})
export class NegociosFiltroModule { }
