import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export abstract class ApiInterfaceService {

	constructor() { }

	//Obs: Necessário discutir criação de model para cotação/negocio
	//Inclusive das entidades de "retorno", para facilitar implementação

	/**
	 * Realiza cotacao dos calculos
	 * @param cotacao JSON da cotação contendo configurações e array de cálculos
	*/
	abstract async calcular(cotacao: any);

	/**
	 * Salva um rascunho de calculo para futuramente ser calculado
	 * @param negocio JSON da cotação contendo informações do negocio
	*/
	abstract async saveNegocio(negocio: any);

	/**
	 * Busca array de calculos para determinada versão
	 * @param idIntegracao idIntegração da cotação
	 * @param version versão da cotação que queremos os calculos
	*/
	abstract async getCalculos(idIntegracao: string, version: number);

	/**
	 * Busca array de versões da cotação
	 * @param idIntegracao idIntegração da cotação
	*/
	abstract async getVersoes(idIntegracao: string);

	/**
	 * Busca negocio/rascunho da cotacao
	 * @param idIntegracao idIntegração da cotação
	*/
	abstract async getNegocio(idIntegracao: string);

}
