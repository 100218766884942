<div mat-dialog-title [ngStyle]="{'display': 'flex',  'align-items': 'center'}">
        <img class="imagem" src="assets/logos/apice.png"/>    
        <h3 [ngStyle]="{'margin': '10px 0 0px 6px'}">Sobre o Ápice</h3>
        <p class="fontSmall versao"> - Versão {{ version }}</p>

    <button     
        mat-icon-button
        mat-dialog-close
        tabindex="-1"
        [ngStyle]="{'margin': '4px 4px 0 auto',  'color': '#727272'}">
        <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
</div>
<mat-dialog-content>
    <div class="conteudos">
        <p class="fontDefault sobre">
            O Ápice é um CRM com multicálculo para corretoras de seguros. Com um prático funil de vendas,
            ajuda a organizar a área comercial da corretora, controlando o negócio desde a prospecção até o fechamento. 
        </p>
    </div>    
</mat-dialog-content>
<mat-dialog-actions [ngStyle]="{'display': 'flex','justify-content': 'center',  'align-items': 'center'}">
    <p class="fontSmall copyright">© Infocap Tecnologia da Informação 2021. Todos os Direitos Reservados.</p>
</mat-dialog-actions>