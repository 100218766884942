import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModelStatusPagamento } from '../models/status-pagamento.model';
import { ApiInfocapebsVindiService } from './api-infocapebs-vindi.service';
import { ApiUsuarioService } from './api-usuario.service';

import { ApiCotacoesService } from 'src/app/services/api-cotacoes.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import  *  as  dataPlanos  from  'src/docs/planos.json';
import { ApiSeguradoraService } from './api-seguradora.service';
import { ConfSeguradora } from 'src/app/models/conf-seguradora.model';
import *  as  linksVideos from 'src/docs/links-videos.json';
import * as Moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class ControllerStatusService {

	STATUS = new ModelStatusPagamento();
	// private ListaPlanos = (dataPlanos as any).default;
	private ListaPlanos:any = {};
	linksVideos:any = {};
	private interval:any;
	private timerInterval = 1000 * 30;

	moment = Moment;
	dataAtual = this.moment().locale('pt-br');
	confsSeguradoras = [];
	confsSeguradorasInv = [];
	checkPeloLoginOuInicial: boolean = false;
	faturaAvulsa: boolean = false;
	statusPagamento: BehaviorSubject<any>;
	statusTransacao: 'success'|'aguardando'|'free'|'error'|'teste' = 'aguardando';
	mensagemCartao;
	cartaoInvalido: boolean = false;
	isUpgrade: boolean = false;
	assinaturaUpgrade;
	dadosAssinaturaDBApice;
	planos; 
	cobranca_id;
	ENV = environment;
	constructor(private snack: MatSnackBar, private apiEbs: ApiInfocapebsVindiService, private apiUsuario: ApiUsuarioService, private base: BaseService, private apiCotacoes: ApiCotacoesService, private apiSeguradora: ApiSeguradoraService, private http: HttpClient) {
		this.statusPagamento = new BehaviorSubject<any>('');
	}
	
	// FAZER O CHECK QUANDO
	// 1 - CONTRATOU/RENOVOVOU UMA ASSINATURA
	// 2 - ADICIONOU MAIS USUÁRIOS

	// REF2 - COMO É POR CARTÃO SERIA INTERESSANTE FAZER A CHECAGEM DURANTE UNS 5 MINUTOS
	// SE NESSE TEMPO NÃO TIVER RETORNO É PORQUE TEVE ALGUM PROBLEMA COM A FORMA DE PAGAMENTO
	// NESTE CASO AVISAR COM UMA MENSAGEM (SNACK) E NA TELA DO PLANO QUE HOUVE FALHA NO PAGAMENTO
	// ASSIM O CLIENTE PODERA EDITAR OS SEUS DADOS E TENTAR NOVAMENTE
	// SENDO ASSIM TEREMOS QUE CANCELAR ESSA "ASSINATURA PENDENTE" E CRIAR UMA NOVA COM OS DADOS DO PERFIL DE PAGAMENTO NOVO

	getJsonPlanos() {
		try {
			this.ListaPlanos = (dataPlanos as any).default;
			this.planos = this.ListaPlanos;
		} catch (error) {
			console.log("Erro ao pegar planos do docs");
			console.log(error);
		}
	}

	getJsonPlanos_old(){
		this.http.get(window.location.origin + '/docs/planos.json', this.base.getHeaders()).toPromise().then((data)=>{			
			this.ListaPlanos = data;
			this.planos = data;
		}).catch(err =>{
			console.log(err);
			//caso falhe ele vai buscar diretamente dos assets, neste caso se houver atualização do json no s3 pode ser que não o pegue até o cloudfront recachear
			this.ListaPlanos = (dataPlanos as any).default;
			this.planos = this.ListaPlanos;
		})
	}

	getJsonLinksVideos(){
		this.http.get(window.location.origin + '/docs/links-videos.json', this.base.getHeaders()).toPromise().then((data)=>{			
			this.linksVideos = data;
		}).catch(err =>{
			console.log(err);
			//caso falhe ele vai buscar diretamente dos assets, neste caso se houver atualização do json no s3 pode ser que não o pegue até o cloudfront recachear
			this.linksVideos = (linksVideos as any).default;
		})
	}

	async buscaListaPlanos(){	
		this.planos = this.ListaPlanos;
		//console.log('Planos => ', this.planos);
	}
	async buscaDadosAssinaturaDBApice(){
		try {
			let ret:any = await this.apiEbs.dadosAssinaturaDBApice();
			if (ret?.assinaturaId?.length) {
				ret.assinaturaVigente = ret?.assinaturaId[0]
				ret.assinaturaFutura = ret?.assinaturaId[1]
				this.dadosAssinaturaDBApice = ret;
			}
			return ret
		} catch (error) {
			console.log('dados assinatura Apice', error);
			throw error;
		}
	}

	// [
	// 	{"id":"73830292000163-APC-MENSAL-1605731475726","vigente":true,"status":1},
	// 	{"id":"73830292000163-APC-MENSAL-1605795788205","vigente":false,"status":2}
	// ]

	// APÓS RETORNAR O ARRAY DE ASSINATURAS IDS, DEVO PEGAR E VERIFICAR OS DOIS INDEX DO ARRAY.
	// SE ELES ESTIVEREM O MESMO STATUS 1 E VIGENTE TRUE E MESMO ID, ESTE É O PLANO VIGENTE
	// CASO OS DOIS INDEX TENHA DIFERENCA NO STATUS, VIGENTE E ID, QUER DIZER QUE ELE FEZ UPGRADE E ACONTECEU ALGO

	checkStatusPagamento(initial?){
		
		//console.log('checkStatusPagamento');	
		this.buscaDadosAssinaturaDBApice().then( (ret:any) =>{	
			
			if(ret?.assinaturaId?.length){				
				if(ret?.assinaturaVigente.status == this.STATUS.PENDENTE || ret?.assinaturaFutura.status == this.STATUS.PENDENTE){
					if(initial && !this.checkPeloLoginOuInicial){
						this.checkPeloLoginOuInicial = true;
						this.checandoStatusPagamento();
					}else{
						this.intervalChecagem();
					}
				}else if(ret?.assinaturaVigente.status == this.STATUS.CANCELADO_FALHA || ret?.assinaturaFutura.status == this.STATUS.CANCELADO_FALHA){
					if(initial && !this.checkPeloLoginOuInicial){
						this.checkPeloLoginOuInicial = true;
						this.checandoStatusPagamento();
					}else{
						this.intervalChecagem();
					}
				}else if(ret?.assinaturaVigente.status == this.STATUS.PAGO && ret?.assinaturaFutura.status == this.STATUS.PAGO && !ret?.faturaAvulsas?.length){
					this.statusTransacao = 'success';
					let dtLicenca = this.moment(ret.licenca);
					if (dtLicenca < this.dataAtual) {
						this.checandoStatusPagamento();
					}
				}else if(ret?.faturaAvulsas?.length){
					let lastIndex = ret.faturaAvulsas.length - 1;
					if(ret.faturaAvulsas[lastIndex].status == this.STATUS.PENDENTE){
						this.faturaAvulsa = true;
						if(initial && !this.checkPeloLoginOuInicial){
							this.checkPeloLoginOuInicial = true;
							this.checandoStatusPagamento(true);
						}else{
							this.intervalChecagem(true);
						}
					}else{
						this.statusTransacao = 'success';
						let dtLicenca = this.moment(ret.licenca);
						if (dtLicenca < this.dataAtual) {
							this.checandoStatusPagamento();
						} 
					}		
				}
			}
		})
		.catch(err =>  console.log(err))		
	}

	intervalChecagem(faturaAvulsa?){
		let count = 0;
		this.interval = setInterval(()=>{
			count++;
			if(count < 10){
				this.checandoStatusPagamento(faturaAvulsa);
			}else{
				// AQUI JÁ EXCEDEU O TEMPO DE CHECAGEM DO STATUS DO PAGAMENTO
				// VER REF2
				clearInterval(this.interval);
			}
		}, this.timerInterval);
	}

	checandoStatusPagamento(faturaAvulsa?){
		this.isUpgrade = false;
		this.apiEbs.checkStatusPagamento(faturaAvulsa).then( (ret:any) =>{
			// AQUI CONFORME VEM O RETORNO ESTOU PENSANDO EM USAR UM OBSERVABLE PARA NOTIFICAR AS DIFERENTES PARTES QUE O SISTEMA IRÁ PRECISAR
			if(ret?.status){
				this.atualizaStatusTransacao(ret, null, null);
				// this.statusPagamento.next(ret.status);				
			}
		})
		.catch( err => {
			// NESTE CASO DEU ERRO NA API, PODEMOS ENTÃO MOSTRAR UM ALERTA
			// PEDINDO PARA RETORNAR A CHECAGEM DO STATUS
			clearInterval(this.interval);
		})
	}

	checkStatusTransacao(){
		let credenciais = this.apiUsuario.getCredenciais();
		let status = credenciais;
		let faturaPendente = false;
		let faturaPendenteError = false;
		let pendentes = credenciais.faturaAvulsas;

		if(pendentes.length){
			let lastIndex = pendentes.length - 1;
			if(pendentes[lastIndex].status == 1) faturaPendente = false;		
			if(pendentes[lastIndex].status == 2) faturaPendente = true;		
			if(pendentes[lastIndex].status == 3) faturaPendenteError = true;		
		}	
		
		this.atualizaStatusTransacao({status: status.statusCorretora}, faturaPendente, faturaPendenteError);
	}

	atualizaStatusTransacao(status, faturaPendente?, faturaPendenteError?){	
		if(status.isUpgrade){
			this.isUpgrade = true;
			this.assinaturaUpgrade = this.dadosAssinaturaDBApice?.assinaturaFutura;			
		}	
		if(status.status == this.STATUS.PAGO && !faturaPendente){			
			this.statusTransacao = 'success';
			this.faturaAvulsa = false;
			this.apiUsuario.atualizaKeysPagamento(status);
			clearInterval(this.interval);		
		}else if(status.status == this.STATUS.PENDENTE || faturaPendente){
			if(status.statusCartao != 'success' && status.statusCartao != 'pending'){
				this.mensagemCartao = status.mensagemCartao;
				this.cartaoInvalido = true;
				this.statusTransacao = 'error';
				this.cobranca_id = status.cobranca_id;	
				clearInterval(this.interval);			
			}else{
				this.statusTransacao = 'aguardando';
			}
		}else if(status.status == this.STATUS.TESTE){
			this.statusTransacao = 'teste';
		}else if(status.status == this.STATUS.CANCELADO_FALHA || faturaPendenteError){
			this.statusTransacao = 'error';
			this.mensagemCartao = status.mensagemCartao;
			this.cartaoInvalido = true;
			this.cobranca_id = status.cobranca_id;	
			this.faturaAvulsa = false;
			clearInterval(this.interval);
		}else{			
			this.statusTransacao = 'teste';
		}
	}

	stopInterval() {
		this.checkPeloLoginOuInicial = false;
		clearInterval(this.interval);
	}

	buscaQtdeCalculos(dtStartSubscribe) {
		return this.apiCotacoes.buscaQtdeNegociosMes(dtStartSubscribe);
	}

	verificaStatus() {
		let result : any = this.apiUsuario.verificaLicenca();
		if (result?.prazo >= 0 && !result.periodoTeste) {
		  return false;
		}	
		return true;
	}

	assinaturaBkp:any;
	async verificaPermissoes(rota?) {
		let planoUsuario:any;
		let dtStartSubscribe;
		/** SE FOR CORRETORA COM PARCERIA pt */ 
		if(this.apiUsuario.getCredenciais()?.parceiro_id == "PARCEIRO-PT"){
			planoUsuario = this.planos.filter(plano => plano.codeVindi == 'APC-AVANCADO')[0];
			dtStartSubscribe = this.apiUsuario.getCredenciais()?.createdAt;
			
		}else{
			if (this.apiUsuario.assinaturaUser) {
				this.assinaturaBkp = this.apiUsuario.assinaturaUser;
				dtStartSubscribe = this.apiUsuario.assinaturaUser.dataStartAss
				planoUsuario = this.planos.filter(plano => plano.codeVindi === this.apiUsuario.assinaturaUser?.assinatura?.produtos[0].produtoId)[0];
			} else {
				if (this.assinaturaBkp) {
					dtStartSubscribe = this.assinaturaBkp.dataStartAss
					planoUsuario = this.planos.filter(plano => plano.codeVindi === this.assinaturaBkp?.assinatura?.produtos[0].produtoId)[0];
				} else {
					await this.apiUsuario.getAssinaturaId();
					dtStartSubscribe = this.apiUsuario.assinaturaUser.dataStartAss
					planoUsuario = this.planos.filter(plano => plano.codeVindi === this.apiUsuario.assinaturaUser?.assinatura?.produtos[0].produtoId)[0];
				}
				
			}
		}

		let regexResults = /resultados/gm
		let regexAuto = /auto/gm
		if(rota === '/lista-tarefas') {
			return planoUsuario.funcionalidades.controleDeTarefas.status === 1 ? true : false;
		} else if (rota === '/cotacao/auto/formulario' || rota === "/cotacao/auto/enviarCalculo") {
			let qtdeCalc:any = await this.buscaQtdeCalculos(dtStartSubscribe);
			// if(planoUsuario.funcionalidades.auto.status === 1 && qtdeCalc.total > planoUsuario.qtdeCalcsMes && planoUsuario.qtdeCalcsMes != 'ilimitado'){
			// 	let msg = 'Limite de cálculos ultrapassados no mês ' + qtdeCalc.total  + '/' + planoUsuario.qtdeCalcsMes;
			// 	this.snack.open(msg)
			// }
			return planoUsuario.funcionalidades.auto.status === 1 && (qtdeCalc.total <= planoUsuario.qtdeCalcsMes || planoUsuario.qtdeCalcsMes === 'ilimitado') ? true : false;
		} else if (rota === '/cotacao/residencial/formulario') {
			let qtdeCalc:any = await this.buscaQtdeCalculos(dtStartSubscribe);
			return planoUsuario.funcionalidades.residencial.status === 1 && (qtdeCalc.total <= planoUsuario.qtdeCalcsMes || planoUsuario.qtdeCalcsMes === 'ilimitado') ? true : false;
		} else if (rota === '/cotacao/empresarial/formulario') {
			let qtdeCalc:any = await this.buscaQtdeCalculos(dtStartSubscribe);
			return planoUsuario.funcionalidades.empresarial.status === 1 && (qtdeCalc.total <= planoUsuario.qtdeCalcsMes || planoUsuario.qtdeCalcsMes === 'ilimitado') ? true : false;
		} else if (rota === '/cotacao/vida/formulario') {
			let qtdeCalc:any = await this.buscaQtdeCalculos(dtStartSubscribe);
			return planoUsuario.funcionalidades.vida.status === 1 && (qtdeCalc.total <= planoUsuario.qtdeCalcsMes || planoUsuario.qtdeCalcsMes === 'ilimitado') ? true : false;
		} else if (rota === '/cotacao/condominio/formulario') {
			let qtdeCalc:any = await this.buscaQtdeCalculos(dtStartSubscribe);
			return planoUsuario.funcionalidades.condominio.status === 1 && (qtdeCalc.total <= planoUsuario.qtdeCalcsMes || planoUsuario.qtdeCalcsMes === 'ilimitado') ? true : false;
		} else if (rota === '/funil-de-vendas' || rota === '/lista-negocios') {
			return planoUsuario.funcionalidades.funilDeVendas.status === 1 ? true : false;
		} else if (rota === '/corretor-digital') {
			return planoUsuario.funcionalidades.corretorDigital.status === 1 ? true : false;
		} else if (rota === '/parcelas') {
			return planoUsuario.funcionalidades.parcelasVencidas.status === 1 ? true : false;
		} else if (rota === '/documentos') {
			return planoUsuario.funcionalidades.buscaDeDocsEmitidos.status === 1 ? true : false;
		} else if (rota === '/fechamento-de-negocio') {
			return planoUsuario.funcionalidades.fechamentoDeNegocio.status === 1 ? true : false;
		} else if(rota === '/orcamento' || rota === '/preview-orcamento') {
			return planoUsuario.funcionalidades.orcamentoCompleto.status === 1 ? true : false;
		}  else if(planoUsuario.code == 'APC-BASICO' && !regexResults.test(rota) && !regexAuto.test(rota)) {
			return false;
		} else {
			return true;
		}
	}

	async verificaPermissoesFuncts(funcionalidade) {

		let planoUsuario:any;
		if(this.verificaStatus() === false && this.apiUsuario.getCredenciais()?.parceiro_id != "PARCEIRO-PT") {
			if (this.apiUsuario.assinaturaUser) {
				this.assinaturaBkp = this.apiUsuario.assinaturaUser;
				planoUsuario = this.planos.filter(plano => plano.codeVindi === this.apiUsuario.assinaturaUser?.assinatura?.produtos[0].produtoId)[0];
			} else {
				if (this.assinaturaBkp) {
					planoUsuario = this.planos.filter(plano => plano.codeVindi === this.assinaturaBkp?.assinatura?.produtos[0].produtoId)[0];
				} else {
					await this.apiUsuario.getAssinaturaId();
					planoUsuario = this.planos.filter(plano => plano.codeVindi === this.apiUsuario.assinaturaUser?.assinatura?.produtos[0].produtoId)[0];
				}
			}
			planoUsuario = this.planos.filter(plano => plano.codeVindi === this.apiUsuario.assinaturaUser?.assinatura?.produtos[0].produtoId)[0];
		/** SE FOR CORRETORA COM PARCERIA ALLIANZ */	
		}else if(this.apiUsuario.getCredenciais()?.parceiro_id == "PARCEIRO-PT"){
			planoUsuario = this.planos.filter(plano => plano.codeVindi == 'APC-AVANCADO')[0]
		}

		if(funcionalidade === 'pdf_orçamentoS') {
			return this.verificaStatus() || planoUsuario.funcionalidades.orcamentoSimplificado.status === 1 ? true : false;
		} else if(funcionalidade === 'orcamentoCompleto') {
			return this.verificaStatus() || planoUsuario.funcionalidades.orcamentoCompleto.status === 1 ? true : false;
		} else if(funcionalidade === 'simuladorDeComissoes') {
			return this.verificaStatus() || planoUsuario.funcionalidades.simuladorDeComissoes.status === 1 ? true : false;
		} else if(funcionalidade === 'estatisticaVeiculos') {
			return this.verificaStatus() || planoUsuario.funcionalidades.estatisticaVeiculos.status === 1 ? true : false;
		} else if(funcionalidade === 'parcelasVencidas') {
			return this.verificaStatus() || planoUsuario.funcionalidades.parcelasVencidas.status === 1 ? true : false;
		} else if(funcionalidade === 'buscaDeDocsEmitidos') {
			return this.verificaStatus() || planoUsuario.funcionalidades.buscaDeDocsEmitidos.status === 1 ? true : false;
		} else if(funcionalidade === 'relacoesDeComissoes') {
			return this.verificaStatus() || planoUsuario.funcionalidades.relacoesDeComissoes.status === 1 ? true : false;
		} else if(funcionalidade === 'assinaturaDigital') {
			return this.verificaStatus() || planoUsuario.funcionalidades.assinaturaDigital.status === 1 ? true : false;
		} else if(funcionalidade === 'renovacoesResgates') {
			return this.verificaStatus() || planoUsuario.funcionalidades.renovacoesResgates.status === 1 ? true : false;
		} else if(funcionalidade === 'gestaoDeComissoes') {
			return this.verificaStatus() || planoUsuario.funcionalidades.gestaoDeComissoes.status === 1 ? true : false;
		} else if(funcionalidade === 'gestaoFinanceira') {
			return this.verificaStatus() || planoUsuario.funcionalidades.gestaoFinanceira.status === 1 ? true : false;
		} else if(funcionalidade === 'repasseProdutores') {
			return this.verificaStatus() || planoUsuario.funcionalidades.repasseProdutores.status === 1 ? true : false;
		} else if(funcionalidade === 'centralDeNegocios') {
			return this.verificaStatus() || planoUsuario.funcionalidades.centralDeNegocios.status === 1 ? true : false;
		} else if(funcionalidade === 'mapasEstatisticos') {
			return this.verificaStatus() || planoUsuario.funcionalidades.mapasEstatisticos.status === 1 ? true : false;
		} else if(funcionalidade === 'gestaDeSinistros') {
			return this.verificaStatus() || planoUsuario.funcionalidades.gestaDeSinistros.status === 1 ? true : false;
		} else if(funcionalidade === 'suporteVideo') {
			return this.verificaStatus() || planoUsuario.funcionalidades.suporteVideo.status === 1 ? true : false;
		} else if(funcionalidade === 'suporteTicket') {
			return this.verificaStatus() || planoUsuario.funcionalidades.suporteTicket.status === 1 ? true : false;
		} else if(funcionalidade === 'suporteChat') {
			return this.verificaStatus() || planoUsuario.funcionalidades.suporteChat.status === 1 ? true : false;
		} else if(funcionalidade === 'suporteEmail') {
			return this.verificaStatus() || planoUsuario.funcionalidades.suporteEmail.status === 1 ? true : false;
		} else if(funcionalidade === 'suporteFone') {
			return this.verificaStatus() || planoUsuario.funcionalidades.suporteFone.status === 1 ? true : false;
		}else if(funcionalidade === 'whatsapp') {
			return this.verificaStatus() || planoUsuario.funcionalidades.suporteWhatsApp.status === 1 ? true : false;
		} else if (funcionalidade === 'novoNegocioFB') {
			return this.verificaStatus() || planoUsuario.code != 'APC-BASICO' ? true : false;
		} else {
			return true;
		}
	}

	async buscaSeguradoras() {
		this.confsSeguradoras = [];
		this.confsSeguradorasInv = [];
		try {
			let _confsSeguradoras:any = await this.apiSeguradora.buscaConfSeguradoras();
			

			for (let configs of _confsSeguradoras) {
				let confSeguradora = new ConfSeguradora(configs);
				// não exibe a Itaú
				if (confSeguradora.seguradoraId != 15) {
					confSeguradora.credenciaisValidas = true; 
					if (confSeguradora && confSeguradora.ativo && confSeguradora.credenciaisValidas) {
						this.confsSeguradoras.push(confSeguradora);
					}
					else if (!confSeguradora.credenciaisValidas && confSeguradora.ativo) {
						this.confsSeguradorasInv.push(confSeguradora);
					}
				}
			}


		} catch (error) {
			console.error("Falha ao buscar configurações de seguradora", error);
		}
	}
}

