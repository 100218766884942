<div
    class="componentSearchInput"
    [ngStyle]="{'width': platformService.virtualDeviceType != 'desktop' ? openSearch ? '100%' : 'calc(100% - 80px)' : '100%', 'left':  platformService.virtualDeviceType != 'desktop' ? openSearch ? '8px' : '80px' : '8px', 'padding-right':platformService.virtualDeviceType != 'desktop' ? '15px' : ''}">
    <!-- [ngStyle]="{'width': platformService.virtualDeviceType != 'desktop' ? openSearch ? '100%' : 'calc(100% - 50px)' : '100%',
    'left':  platformService.virtualDeviceType != 'desktop' ? openSearch ? '8px' : '50px' : '8px' }" -->
    <div
        mat-icon-button
        [ngClass]="[openSearch ? 'searchInput searchInput__open' : 'searchInput']"
        *ngIf="showSearch">


        <span *ngIf="showPrefix" class="fontDefault">em:{{prefix}}
            <mat-icon class="x" [ngStyle]="{'margin-left':'4px'}" (click)="openSearchBar()">cancel</mat-icon>
        </span>

        <input
            type="text"
            [placeholder]="placeholder"
            (keyup)="searching($event)"
            #inputSearch
            [(ngModel)]="valorInput">

        <button
            mat-icon-button
            [ngStyle]="{'margin':'0 4px'}"
            [matTooltip] = "openSearch && showPrefix ? 'Limpar Busca' : openSearch && !showPrefix ? 'Fechar' : 'Buscar'"
            (click)="openSearchBar()"
            [disabled]="searchingLoad">
            <!-- <mat-icon [ngClass]="['mdi', openSearch ? 'mdi-close' :  searchingLoad ? 'mdi-loading mdi-spin' : 'mdi-magnify']"></mat-icon> -->
            <mat-icon [ngStyle]="{'color':'#fff'}" [svgIcon]="openSearch ? 'close' : searchingLoad ? 'loading' : 'magnify'" [ngClass]="[searchingLoad ? 'loading' : '']"></mat-icon>
        </button>

        
    </div>
    <button
        mat-icon-button
        (click)="openFilter()"
        [ngStyle]="{'margin':'0 4px'}"
        *ngIf="filtro">
        <!-- fazer verificação de rota e variaveis para aparecer a notificação no icon -->
        <mat-icon 
            [svgIcon]="!menuFiltro.open ? 'filter-outline' : 'filter'"
            [ngClass]="[notificacao1 === true || notificacao2 === true || notificacao3 === true || notificacao4 === true || notificacao5 === true || notificacao6 === true || notificacao7 === true || notificacao8 === true ? 'badgeCustom' : '']"
            style="color: #fff;">
        </mat-icon>
        
    </button>

    <lib-lista-notificacoes #listaNotificacoes [ngStyle]="{'margin':'0 4px'}"></lib-lista-notificacoes>


    <perfil-usuario [ngStyle]="{'margin-letf':'4px'}"></perfil-usuario>
</div>
