import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RamosComponent } from './ramos.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';



@NgModule({
  declarations: [RamosComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario
  ],
  exports: [RamosComponent]
})
export class RamosFiltroModule { }
