import { Directive, Output, EventEmitter, Input, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[debounce-validation]' // era ion-input
})
/**
 * Aplica validação em um FormControl após o usuário parar de digitar.
 * Usar com updateOn: 'blur'
 */
export class DebouncerValidationDirective {

	@Output() debounceFunction = new EventEmitter();
	
	/**
	 * Quanto tempo após o usuário árar de digitar
	 */
	@Input('debounce-validation') debounce: any; // tempo em milissegundos para ocorrer o evento de validação
	@Input('validate-on-begin') validateOnBegin: boolean; // se aplica a validação sem o usuário ter digitado

	@Input('validate-on-keydown') useKeydown: boolean = false;

	time: any;
	timerDebounce: any;

	constructor(public el: ElementRef, public control : NgControl) {
		

		
	}

	ngOnInit () {
		if (this.validateOnBegin) {
			this.validate();
		} 
	}

	@HostListener('keydown', ['$event'])
	ontype(event) {
		if(this.useKeydown) {
			if([9, 37, 39].includes(event.keyCode)) {
				return;
			}
			else if (event.keyCode == 13) {
				event.preventDefault();
				event.stopPropagation();
			}
			// console.log(event.keyCode)
			
			// console.log('keydown');
			this.timerDebounce = this.debounce > 0 ? this.debounce : 1500;
			this.deboucingFunction(this.timerDebounce, this.control);
		}
		
	}
	

	// TODO: era ionChange. Testar para ver se funciona ainda
	@HostListener('change', ['$event'])
	onchange(arg1, arg2) {	
		if (!this.useKeydown) {
			this.timerDebounce = this.debounce > 0 ? this.debounce : 1500;
			this.deboucingFunction(this.timerDebounce, this.control);
		}
	}

	deboucingFunction(timer, control) {
		clearTimeout(this.time);
		this.time = setTimeout(() => {
			this.validate();
			this.debounceFunction.emit(control); //emitir a função recebida			
		}, timer);
	}
	
	/**
	 * Aplica mensagens de validação.
	 */
	validate() {
		this.control.control.markAsTouched();
		this.control.control.markAsDirty();
		this.control.control.updateValueAndValidity();
	}

}
