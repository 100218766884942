<mat-form-field>
    <mat-select placeholder="Status do Ramo" [(ngModel)]="filtroStatus">
        <mat-option [value]="0">Todos</mat-option>
        <mat-option [value]="1">Ativos</mat-option>
        <mat-option [value]="2">Inativos</mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field>
    <mat-select placeholder="Tipo de Produto" [(ngModel)]="filtroTipo">
        <mat-option [value]="0">Todos</mat-option>
        <mat-option [value]="1">Seguro</mat-option>
        <mat-option [value]="2">Financeiro</mat-option>
        <!-- <mat-option [value]="3">Benefício</mat-option> -->
    </mat-select>
</mat-form-field>
<div>
    <mat-form-field>
        <mat-select placeholder="Grupo do Seguro" [(ngModel)]="filtroGrupo">
            <mat-option [value]="0">Todos</mat-option>
            <mat-option [value]="1">Patrimonial</mat-option>
            <mat-option [value]="3">Responsabilidades</mat-option>
            <mat-option [value]="5">Automóvel</mat-option>
            <mat-option [value]="6">Transportes</mat-option>
            <mat-option [value]="7">Riscos Financeiros</mat-option>
            <mat-option [value]="9">Pessoas Coletivo</mat-option>
            <mat-option [value]="10">Habitacional</mat-option>
            <mat-option [value]="11">Rural</mat-option>
            <mat-option [value]="13">Pessoas Individual</mat-option>
            <mat-option [value]="14">Marítimos</mat-option>
            <mat-option [value]="15">Aeronáuticos</mat-option>
            <mat-option [value]="17">Petróleo</mat-option>
            <mat-option [value]="19">Saúde</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="actionButtonsFiltros">
    <button mat-button class="btn-primary-ap" (click)="buildString()">FILTRAR</button>
    <button mat-button class="btn-back" (click)="clearFilter()">LIMPAR FILTROS</button>
</div>