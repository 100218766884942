
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ApiInterfaceService {

  //Obs: Necessário discutir criação de model para cotação/negocio
  //Inclusive das entidades de "retorno", para facilitar implementação

  abstract async sendEmail(obj);

  /**
     * Busca status dos servicos do pdocs
    */
  abstract async getServicesInfos();

  /**
    * Busca status dos servicos do pdocs
   */
  abstract async getSeguradoById(id: string);

  /**
     * Busca lista de coberturas
    */
  abstract async getCoberturasAutoUser(api);

  /**
   * Busca configurações de seguradora para determinado usuário
  */
  abstract async getSeguradorasUser();

  /**
   * Salva um rascunho de calculo para futuramente ser calculado
   * @param negocio JSON da cotação contendo informações do negocio
  */
  abstract async saveNegocio(negocio: any);

  /**
   * Busca array de calculos para determinada versão
   * @param idIntegracao idIntegração da cotação
   * @param version versão da cotação que queremos os calculos
  */
  abstract async calcular(cotacao: any);

  /**
   * Busca array de calculos para determinada versão
   * @param idIntegracao idIntegração da cotação
   * @param version versão da cotação que queremos os calculos
  */
  abstract async getCalculos(idIntegracao: string, version: number);

  /**
   * Busca array de versões da cotação
   * @param idIntegracao idIntegração da cotação
  */
  abstract async getVersoes(idIntegracao: string);

  /**
   * Busca negocio/rascunho da cotacao
   * @param idIntegracao idIntegração da cotação
  */
  abstract async getNegocio(idIntegracao: string);

  /**
   * Copia negocio/rascunho da cotacao
   * @param idIntegracao idIntegração da cotação
  */
  abstract async copiarNegocio(idIntegracao: string);

  /**
   * Busca preferencias do usuario e informaçoes de localização, logo.
  */
  abstract async getUserInfos();

  /**
   * Busca configurações de seguradora para verificar calculo
  */
  abstract async getCfgSeg(idIntegracao);

  abstract async updateSegurado(cpf);

  abstract async updateEndereco(cep);

  abstract async getCalculoFipeModelo(fipe, ano, isZeroKm);

  abstract async getCalculoValorMedio(fipe, ano, cep, maior25, historico);

  abstract async getCalculoDescricaoModelo(tipo, modelo, ano);

  abstract async getCalculoPlacaModelo(chassi);

  abstract async getCalculoChassiModelo(chassi);

  abstract async getSeguradoraAnterior();

  abstract getUrlBase(ramo, full?);

  abstract getAplicacaoId();

  abstract async sendMessageByObservable(property, value);

  abstract async forceRefreshHome();

}
