import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
	providedIn: 'root'
})
export class ApiNegociosService {

	private ENV:any = environment;
	private URL;

	dadosReturn;
	modalClosed: BehaviorSubject<boolean>;

	updateNegocio: BehaviorSubject<boolean>;

	constructor(private http: HttpClient, private BS: BaseService) {
		this.updateNegocio = new BehaviorSubject<boolean>(false);
		this.modalClosed = new BehaviorSubject<boolean>(false);
		this.URL = this.ENV.apice.url;
	}

	_updateNegocio(): Observable<boolean> {
		return this.updateNegocio.asObservable();
	}

	setUpdateNegocio(value): void {
		this.updateNegocio.next(value);
	}

	// async buscaNegociosPorStatus(status, limit, page?, filtroBusca?, criadoAntes?) {
	// 	//6 perdido
	// 	//7 descartado
	// 	//8 fechado/arquivado
	// 	//filtroBusca
	// 	let url = `calculo/negocios?limit=${limit}&status=${status ? status : 0}&painel=true&page=${page}&filtroBusca=${filtroBusca || ''}`;
	// 	// let url = `calculo/negocios?statusList=[${status}]&retornarOrdenacoes=true&limit=${limit}&criadoAntes=${criadoAntes || ''}&filtroBusca=${filtroBusca || ''}`;
	// 	// &criadosApos=2019-10-28 14:08:24&criadosAntesDe=2019-10-28 14:28:35&limit=4&

	// 	try {
	// 		let ret = await this.apiBase.get(url)
	// 		return ret;
	// 	} catch (error) {
	// 		return error;
	// 	}
	// }

	buscaNegocios(users?: any, ramo?): Promise<any> {
		ramo = ramo || '';
		//retornarOrdenacoes
		return this.http.get(this.URL + `calculo/negocios?retornarOrdenacoes=true&usuarioList=${users}&ramo=${ramo}`, this.BS.getHeaders(true)).toPromise();		
	}

	
	buscaNegocio(negocioId: string): Promise<any> {		
		return this.http.get(this.URL + `calculo/negocio/${negocioId}`, this.BS.getHeaders(true)).toPromise();			
	}

	/** Cria ou atualiza um negócio. Adiciona o usuário logado ao negócio no momento da criação deste. */
	salvaNegocio(negocio: {
		ramo: number, //obrigatorio na hora de criar. Não alterar depois de criado.
		segmento: string, //obrigatorio na hora de criar. Não alterar depois de criado.
		status: number, //obrigatorio na hora de criar.
		origem: number, //obrigatorio na hora de criar. Não alterar depois de criado.
		premio: number,
		comissao: number,
		percComissao: number,
		tarefa: any,
		prazo: string,
		vigIni: string,
		vigFim: string,
		observacao: string,
		clienteId: string //obrigatório
	}, negocioAnteriorId, negocioProximoId): Promise<any> {

		debugger
		let path = `calculo/negocio?negocioAnteriorId=${negocioAnteriorId || ''}&negocioProximoId=${negocioProximoId || ''}`;
		return this.http.post(this.URL + path, negocio, this.BS.getHeaders(true)).toPromise();			
	}


	// NEGÓCIO USUÁRIO

	/** Adiciona um usuário a um negócio */
	salvaNegocioUsuario(negocioUsuario: {
		id: string,
		usuarioId: string,
		gravacao: boolean,
		leitura: boolean,
		versao: boolean,
		calculo: boolean,
		adicaoUsuario: boolean,
		remocaoUsuario: boolean,
		negocioId: string,
	}): Promise<any> {	
		return this.http.post(this.URL + 'calculo/negocio/usuario', negocioUsuario, this.BS.getHeaders(true)).toPromise();			
	}

	/** Remove um usuário de um negócio  */
	deletaNegocioUsuario(negocioId: string, usuarioId: string): Promise<any> {		
		return this.http.delete(this.URL + `calculo/negocio/${negocioId}/usuario/${usuarioId}`, this.BS.getHeaders(true)).toPromise();			
	}

	/** Cria uma cotação e associa a um negócio */
	// async criarCotacaoNegocio(cotacao: Cotacao, negocio?: {
	// 	ramo: number,
	// 	segmento: string,
	// 	status: number,
	// 	origem: number,
	// 	premio?: number,
	// 	comissao?: number,
	// 	percComissao?: number,
	// 	tarefa?: any,
	// 	prazo?: string,
	// 	vigIni?: string,
	// 	vigFim?: string,
	// 	observacao?: string,
	// 	clienteId?: string
	// }): Promise<Cotacao> {
	// 	try {
	// 		let body = { cotacao: cotacao.dados, negocio };
	// 		// console.log(JSON.stringify(body,null,4));    
	// 		let ret = await this.apiBase.post('calculo/cotacao', body);

	// 		let retCotacao = new Cotacao(ret);
	// 		return retCotacao;
	// 	} catch (error) {
	// 		return error;
	// 	}
	// }

	/** Cria uma cotação e associa a um negócio */
	// criarCotacaoNegocioV2(cotacao): Promise<CotacaoResidencial> {
	// 	let body = cotacao;
	// 	return this.apiBase.post('calculo/cotacao', body);
	// }


	criaNovoNegocio(documento) {
		return this.http.post(`${this.URL}calculo/docToNegocio`, documento ,this.BS.getHeaders(true)).toPromise();	
	}

}
