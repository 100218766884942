import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    ModulosDeFormulario,
    MatTooltipModule
  ],
  exports: [DashboardComponent]
})
export class DashboardModule { }
