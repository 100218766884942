import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { GetIconMimeTypes } from 'src/app/utils/getIconMimeTypes';
import { Formatter } from 'src/app/utils/formatter';
import { ApiClienteService } from 'src/app/services/api-cliente.service';
import { ApiS3Service } from 'src/app/services/api-s3.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlatformService } from 'src/app/services/platform.service';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { Mask } from 'src/app/utils/masks';
import { ApiCotacoesService } from 'src/app/services/api-cotacoes.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
	selector: 'detalhes-editar-cliente',
	templateUrl: './detalhes-editar.component.html',
	styleUrls: ['./detalhes-editar.component.scss']
})
export class DetalhesEditarComponent implements OnInit {

	@ViewChild('content') content;
	@Input('clienteId') clienteId;
	@Input('negocio') negocio;

	@Output() close = new EventEmitter();
	@Output() dadosClienteNegocio = new EventEmitter();
	@Output() voltaTelaNegocio = new EventEmitter();

	masks = Mask;

	getIconMimeTypes = GetIconMimeTypes;
	cliente;
	enderecoPrincipal;
	formatter = Formatter;

	openCadastroCliente: boolean = false;
	postIt: boolean = false;
	carregando: boolean = false;
	erro: boolean = false;
	mostraMensagem: boolean = false;
	carregandoAnexos: boolean = false;
	favorito: boolean = false;
	mostrarAnexos: boolean = false;
	informationChanged: boolean = false;
	salvandoCliente: boolean = false;
	salvandoAnexo: boolean = false;

	contadorAnexosTemp = 0;
	anexos = [];
	anexosTemp = [];
	erroAnexos;
	lembrete = { lembrete: '' };
	observacao: string = '';

	@ViewChild('arquivosSelecionados') arquivosSelecionados: any;

	constructor(
		public apiCliente: ApiClienteService,
		public apiS3: ApiS3Service,
		public apiCotacoes: ApiCotacoesService,
		public snackBar: MatSnackBar,
		public platformService: PlatformService,
		public icons: RegisterIconsService,
		public utilsService: UtilsService
	) {

		icons.registerIcons(['image', 'file-presentation-box', 'google-spreadsheet', 'block-helper', 'checkbox-blank-circle-outline',
			'file-video-outline', 'file-document-box-outline', 'arrow-left', 'close', 'whatsapp', 'phone', 'card-account-details',
			'cake-variant', 'map-marker-outline', 'card-account-details-outline', 'gender-male', 'gender-female', 'ring', 'loading',
			'folder-zip-outline', 'audiobook', 'star', 'star-outline', 'pencil', 'dots-vertical', 'phone-outline', 'email-outline',
			'file-download-outline']);
	}

	ngOnInit() {
		this.getData();
	}

	getData() {
		this.openCadastroCliente = false;
		this.carregando = true;
		this.buscaCliente();
	}

	atualizarCliente() {
		this.openCadastroCliente = false;
		this.buscaCliente();
	}

	openDocument(anexo) {
		window.open(anexo.url, '_blank')
	}

	numeroWhatsApp;
	docs:any;
	outrosAnexos:any = [];
	negId;
	qtdeAnexosNegocio:number = 0;
	verificaQtdeAnexos(ev) {
		this.qtdeAnexosNegocio = ev;
	}

	async buscaDocumentos(cpfCnpj) {
		try {
			this.docs = await this.apiCotacoes.buscaDocs('','','', '', '', '','',cpfCnpj,'')
			if(this.docs) {
				this.docs.forEach(d => {
					if(d.anexos.length > 0) {
						d.anexos.forEach(a => this.outrosAnexos.push(a));
					}
				});
			}
		} catch (e) {
			console.log('erro ao buscar documentos',e)
		}
	}

	async buscaAnexosNeg(clienteId) {
		try {
			let ret:any = await this.apiCotacoes.buscaNegocioPorCliente(clienteId);
			// console.log('negocios por cliente',ret)
			// debugger
			if(ret.length > 0) {
				this.negId = ret[0]?.id;
				// console.log('negocioId', this.negId)
			}
		} catch (e) {
			console.log('erro ao buscar anexos de nogocio', e)
		}
	}


	async buscaCliente() {
		try {
			this.cliente = await this.apiCliente.buscaCliente(this.clienteId);
			this.postIt = this.cliente.favorito;
			// console.log(this.cliente)

			if(this.cliente && this.cliente.cpfCnpj) {
				this.buscaDocumentos(this.cliente.cpfCnpj);
			}

			if(this.cliente && this.cliente.id) {
				await this.buscaAnexosNeg(this.cliente.id);
			}

			if (this.cliente.favorito != null) {
				this.favorito = this.cliente.favorito
			}

			this.observacao = this.cliente.observacao;

			let linkEndereco = function (end) {
				let logradouro = end.logradouro ? end.logradouro.trim().replace(/(\s)/gm, '+') : '';
				let numero = end.numero ? end.numero.trim() : '';
				let bairro = end.bairro ? end.bairro.trim() : '';
				let cidade = end.cidade ? end.cidade.replace(/(\s)/gm, '+') : '';
				let uf = end.uf ? end.uf.trim() : '';
				let endereco = logradouro + ',+' + numero + '+-+' + bairro + ',+' + cidade + '+-+' + uf;
				return `https://www.google.com.br/maps?q=${endereco},%20Brasil`;
			}

			// busca endereço principal selecionado
			let enderecos = this.cliente.enderecos;
			let listaEnderecos = [];
			for (let endereco of enderecos) {
				endereco['link'] = linkEndereco(endereco);
				endereco['enderecoFormatado'] = this.utilsService.geraStringEndereco(endereco);
				if (endereco.principal) {
					this.enderecoPrincipal = endereco;
				} else {
					listaEnderecos.push(endereco)
				}
			}
			if (enderecos.length) {
				listaEnderecos.unshift(this.enderecoPrincipal);
			}
			this.cliente['listaEnderecos'] = listaEnderecos;

			if (!this.cliente.emails) {
				this.cliente.emails = [];
			}

			let email
			if (this.cliente.email) {
				email = { email: this.cliente.email, principal: true };
				let found = this.cliente.emails.find(emailArr => {
					let emailArray = emailArr.email;
					let emailP = email.email;
					return emailArray == emailP
				});
				if (found == null) {
					this.cliente.emails.unshift(email);
				}
			}

			this.cliente.emails.sort((a, b) => {
				if (a.principal) {
					return -1;
				}
				else if (b.principal) {
					return 1;
				}
				else return 0;
			});

			if (!this.cliente.fones) {
				this.cliente.fones = [];
			}

			let fone;
			if (this.cliente.fone) {

				fone = { numero: this.cliente.fone, principal: true };

				let found = this.cliente.fones.find(foneArr => {
					let foneArray = (foneArr.numero).replace(/\D/g, '');
					let foneP = (fone.numero).replace(/\D/g, '');
					return foneArray == foneP
				});
				if (found == null) {
					this.cliente.fones.unshift(fone);
				}
			}

			this.cliente.fones.forEach(fone => {
				fone.numero = this.formatter.formatFone(fone.numero);
			});
			this.cliente.fones.sort((a, b) => {
				if (a.principal) {
					return -1;
				}
				else if (b.principal) {
					return 1;
				}
				else return 0;
			});

			if (this.cliente.estadoCivil) {
				if (this.cliente.estadoCivil == 1) {
					this.cliente['estadoCivilTxt'] = 'Solteiro(a)';
				} else if (this.cliente.estadoCivil == 2) {
					this.cliente['estadoCivilTxt'] = 'Casado(a)';
				} else if (this.cliente.estadoCivil == 3) {
					this.cliente['estadoCivilTxt'] = 'Separado(a)';
				} else if (this.cliente.estadoCivil == 4) {
					this.cliente['estadoCivilTxt'] = 'Divorciado(a)';
				} else if (this.cliente.estadoCivil == 5) {
					this.cliente['estadoCivilTxt'] = 'Viúvo(a)';
				} else if (this.cliente.estadoCivil == 6) {
					this.cliente['estadoCivilTxt'] = 'União Estável';
				} else {
					this.cliente['estadoCivilTxt'] = '';
				}
			}

			this.carregando = false;
			this.erro = false;

			let cont = 0;

			for (let key in this.cliente) {
				if (key == 'cpfCnpj' && (!this.cliente[key] || this.cliente[key].length == 0)) cont++;
				if (key == 'dataNas' && (!this.cliente[key] || this.cliente[key].length == 0)) cont++;
				if (key == 'email' && (!this.cliente[key] || this.cliente[key].length == 0)) cont++;
				if (key == 'fone' && (!this.cliente[key] || this.cliente[key].length == 0)) cont++;
				if (key == 'cep' && (!this.cliente[key] || this.cliente[key].length == 0)) cont++;
				if (key == 'estadoCivil' && (!this.cliente[key] || this.cliente[key].length == 0)) cont++;
			}

			if (cont == 6) {
				this.mostraMensagem = true;
			}

			setTimeout(() => {
				this.observeScroll();
			})

		} catch (error) {
			this.carregando = false;
			this.erro = true;
		}
	}

	async adicionarObservacao(e) {
		let observacao = e.value;
		this.cliente.observacao = observacao;
		this.informationChanged = true;
	}

	async desativarAtivar() {
		this.cliente.ativo = !this.cliente.ativo;
		this.informationChanged = true;
	}

	mostrarMensagem(mensagem: string) {
		this.snackBar.open(mensagem, 'Ok', {
			duration: 4000,
		});
	}

	btnWhats(num) {
		//console.log(num);
		window.open('https://api.whatsapp.com/send?phone=55' + num);
	}

	btnFone(num) {
		//console.log(num);
		window.open('tel:' + num);
	}

	async changeFavorito(clienteId, favorito) {
		this.cliente.id = clienteId
		this.favorito = !favorito;
		this.cliente.favorito = this.favorito;
		try {
			let resp = await this.apiCliente.salvarFavorito(this.cliente.id, this.cliente.favorito);
			this.apiCliente.updateClienteModal = true;
		} catch (error) {
			this.snackBar.open('Falha ao salvar como favorito!', 'OK', {duration: 6000});
		}
	}

	async salvarCliente(param?: string) {
		this.salvandoCliente = true;
		try {
			let resp = await this.apiCliente.editaCliente(this.cliente, this.cliente.id);
			this.apiCliente.updateClienteModal = true;
		} catch (error) {
			console.error('Erro ao salvar', error);
		}
		finally {
			this.salvandoCliente = false;
		}
	}

	cadastroCliente(ev?) {
		this.openCadastroCliente = !this.openCadastroCliente;
	}

	closeModal() {
		this.close.emit(this.informationChanged);
	}

	shadowHeader: boolean = false;
	observeScroll() {
		this.content.nativeElement.addEventListener('scroll', (e) => {
			let scroll = e.target.scrollTop;
			if (scroll > 10) {
				this.shadowHeader = true;
			} else {
				this.shadowHeader = false;
			}
		})
	}

}
