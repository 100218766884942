<form
    class="col-12 modal-do-fechamento"
    [formGroup]="formVidaValidacaoFechamento"
    *ngIf="isNegocioFechado && !desfazerNegocio"
  >
    <div mat-dialog-content id="content-modal-fechamento">
      <div class="col-12">
        <h4>Fechamento</h4>
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
          <mat-form-field>
            <mat-label>Seguradora</mat-label>
            <mat-select
              [(ngModel)]="draftTemp.fechamento.seguradora"
              name="seguradora"
              formControlName="seguradora"
            >
              <mat-option *ngFor="let seg of seguradoras" [value]="seg.value">
                <span>{{ seg.viewValue }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
          <mat-form-field>
            <mat-label>Forma de Pagamento</mat-label>
            <mat-select
              [(ngModel)]="draftTemp.fechamento.tpPagamento"
              name="tpPagamento"
              formControlName="tpPagamento"
            >
              <mat-option
                *ngFor="let pagamento of tipoPagamento"
                [value]="pagamento.value"
              >
                {{ pagamento.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
          <mat-form-field>
            <mat-label>Quantidade de parcelas</mat-label>
            <mat-select
              [(ngModel)]="draftTemp.fechamento.qtdParcelas"
              name="qtdParcelas"
              formControlName="qtdParcelas"
            >
              <mat-option *ngFor="let parc of qtdParcelas" [value]="parc.value">
                {{ parc.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
          <mat-form-field class="">
            <mat-label>Prémio</mat-label>
            <input
              matInput
              placeholder="Valor do prémio"
              [(ngModel)]="draftTemp.fechamento.premio"
              name="premio"
              formControlName="premio"
              currencyMask
              [options]="{ prefix: '', suffix: ' €', thousands: '.', decimal: ',' }"
            />
          </mat-form-field>
        </div>
  
        <div
          *ngIf="this.ramo == this._ramos.AUTO"
          class="col-12 col-md-12 col-lg-4 col-xl-4"
        >
          <mat-form-field class="">
            <mat-label>Franquia</mat-label>
            <input
              matInput
              placeholder="Valor da franquia"
              [(ngModel)]="draftTemp.fechamento.franquia"
              formControlName="franquia"
              name="franquia"
              currencyMask
              [options]="{ prefix: '', suffix: ' €', thousands: '.', decimal: ',' }"
            />
          </mat-form-field>
        </div>
  
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
          <mat-form-field class="">
            <mat-label>% Comissão</mat-label>
            <input
              matInput
              placeholder="Valor da comissão"
              [(ngModel)]="draftTemp.fechamento.comissao"
              formControlName="comissao"
              name="comissao"
              [textMask]="maskValidate.PERC"
            />
          </mat-form-field>
        </div>
        <br>
        <div class="col-12 col-md-12 btn-upload-file" style="width: 100%;">
          <app-gerencia-arquivo-multi
            [title]="'Upload da proposta'"
            [sizeLimit]="5"
            [configS3]="this.configS3"
            [extensions]="this.extensions"
            [listFiles]="true"
            [singleFileName]="'fechamento'"
          >
          </app-gerencia-arquivo-multi>
        </div>
  
        <div class="col-12">
          <mat-form-field appearance="">
            <mat-label>Observações</mat-label>
            <textarea
              matInput
              [(ngModel)]="draftTemp.fechamento.observacoes"
              name="observacoes"
              formControlName="observacoes"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="div-btns row mt-3 justify-content-center">
      <button mat-stroked-button class="mr-2 buttonclose" (click)="close()">
        CANCELAR
      </button>
      <button
        mat-stroked-button
        class="div-btns px-5 botao-formulario-color"
        (click)="saveCalculoNegocio('negocioFechado')"
      >
        SALVAR
      </button>
    </div>
  </form>
  
  <form
    class="col-12"
    [formGroup]="formVidaValidacaoNaoFechamento"
    *ngIf="!isNegocioFechado && !desfazerNegocio"
  >
    <div mat-dialog-content id="content-modal-fechamento">
      <div class="col-12">
        <h4>Fechamento</h4>
        <div class="col-12">
          <mat-form-field>
            <mat-label>Motivos</mat-label>
            <mat-select
              [(ngModel)]="draftTemp.fechamento.motivo"
              name="motivo"
              formControlName="motivo"
            >
              <mat-option
                *ngFor="let motivo of motivosNaoFechamento"
                [value]="motivo.viewValue"
              >
                {{ motivo.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                formVidaValidacaoNaoFechamento.get('motivo').hasError('required')
              "
            >
              Por favor, insira o motivo
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="">
            <mat-label>Observações</mat-label>
            <textarea
              matInput
              [(ngModel)]="draftTemp.fechamento.observacoes"
              formControlName="observacoes"
              name="observacoes"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12">
        <div class="col-12">
          <button
            mat-button
            class="salvar button-blue"
            (click)="saveCalculoNegocio('negocioPerdido')"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </form>
  
  <div *ngIf="desfazerNegocio" class="col-12 desfazerNegocio">
    <div mat-dialog-content id="content-modal-fechamento">
      <div class="col-12">
        <h4>Desfazer Negócio</h4>
        <div class="col-12" id="label-desfazer-negocio">
          <p>
            <span>Deseja mesmo desfazer o negócio atual?</span>
          </p>
        </div>
      </div>
      <div class="col-12 div-btns" id="btns">
        <button
          mat-stroked-button
          class="div-btns px-5 botao-formulario-color"
          (click)="saveCalculoNegocio('cancelarNegocio')"
        >
          SIM
        </button>
        <button
          mat-stroked-button
          class="div-btns px-5 botao-formulario-color"
          (click)="close()"
        >
          CANCELAR
        </button>
      </div>
    </div>
  </div>
  
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      <img style="width: 24px;" src="./assets/images/close_v4.png" />
    </button>
  </mat-dialog-actions>
  