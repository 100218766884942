
export class Endereco {

    dados: any = 
    {
       id: undefined,
       cep: undefined,
       logradouro: undefined,
       bairro: undefined,
       numero: undefined,
       complemento: undefined,
       cidade: undefined,
       uf: undefined,
       principal: undefined,
       observacao: undefined
    }

    constructor(dados?:any) {
        this.setData(dados)
    }

    setData(dados) {
        if(dados) {
            this.id = dados.id;
            this.cep = dados.cep;
            this.logradouro = dados.logradouro;
            this.bairro = dados.bairro;
            this.numero = dados.numero;
            this.complemento = dados.complemento;
            this.cidade = dados.cidade;
            this.uf = dados.uf;
            this.principal = dados.principal;
            this.observacao = dados.observacao;
        } else {
            this.dados = {}
        }
    }

    get id() {
        return this.dados.id;
    }

    set id(id) {
        this.dados.id = id;
    }

    get cep() {
        return this.dados.cep;
    }

    set cep(cep) {
        this.dados.cep = cep;
    }
    get logradouro() {
        return this.dados.logradouro;
    }

    set logradouro(logradouro) {
        this.dados.logradouro = logradouro;
    }

    get bairro() {
        return this.dados.bairro;
    }

    set bairro(bairro) {
        this.dados.bairro = bairro;
    }

    get numero() {
        return this.dados.numero;
    }

    set numero(numero) {
        this.dados.numero = numero;
    }

    get complemento() {
        return this.dados.complemento;
    }

    set complemento(complemento) {
        this.dados.complemento = complemento;
    }

    get cidade() {
        return this.dados.cidade;
    }

    set cidade(cidade) {
        this.dados.cidade = cidade;
    }

    get uf() {
        return this.dados.uf;
    }

    set uf(uf) {
        this.dados.uf = uf;
    }

    get principal() {
        return this.dados.principal;
    }

    set principal(principal) {
        this.dados.principal = principal;
    }

    get observacao () {
        return this.dados.observacao;
    }
    set observacao(observacao) {
        this.dados.observacao = observacao;
    }
}