import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { animationNgIfEnter } from 'src/app/utils/animations';
import { trigger, transition, sequence, style, animate } from '@angular/animations';
import { MOMENT_LOCALE, MOMENT_ADAPTER, MOMENT_FORMATS, onChangeDate, openCalendarDatepicker } from 'src/app/utils/moment-config-datepicker';
import { FormGroup, FormControl } from '@angular/forms';
import { StatusNegocio } from 'src/app/models/status-negocio.model';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { MessageHandler } from 'src/app/utils/form-field-message-handler';
import { GerenciadorArquivosComponent } from 'src/app/components/gerenciador-arquivos/gerenciador-arquivos.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as Moment from 'moment';
import { Mask } from 'src/app/utils/masks';
import { FabricanteJson } from 'src/app/utils/fabricantes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';
import { ApiCotacoesService } from 'src/app/services/api-cotacoes.service';
import { ApiClienteService } from 'src/app/services/api-cliente.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
import { PlatformService } from 'src/app/services/platform.service';
import { ApiCorretoraService } from 'src/app/services/api-corretora.service';
import { Router } from '@angular/router';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { ApiVeiculoService } from 'src/app/services/api-veiculo.service';
import { validaEmail, validaDate } from 'src/app/utils/validadores';
import { ApiNegociosService } from 'src/app/services/api-negocios.service';
import { Formatter } from 'src/app/utils/formatter';
import { startWith, map } from 'rxjs/operators';
import { getLabelSubTipo } from 'src/app/models/eventos.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { ApiEventosService } from 'src/app/services/api-eventos.service';
import { TarefaComponent } from 'src/app/components/tarefa/tarefa.component';
import { TodoControllerService } from 'src/app/services/todo-controller.service';
import { UtilsService } from 'src/app/services/utils.service';
import { FechamentoService } from 'src/app/services/fechamento.service';
import { ApiInterfaceService } from 'src/app/services/api-interface.service';
import { getIiconeSeguradora, getRotuloSeguradora } from 'src/app/utils/FormatterSeguradoras';
import { ModalDocsAtravesNegocioComponent } from '../../documentos/modal-docs-atraves-negocio/modal-docs-atraves-negocio.component';
import { ConstantPortugal } from '../../../constants/constantPortugal';

@Component({
	selector: 'app-modal-negocio',
	templateUrl: './modal-negocio.component.html',
	styleUrls: ['./modal-negocio.component.scss'],
	providers: [MOMENT_LOCALE, MOMENT_ADAPTER, MOMENT_FORMATS],
	animations: [animationNgIfEnter,
		trigger('anim', [
			transition('void => remove', [
				style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }),
				sequence([
					animate(".45s ease", style({ height: '*', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
					animate(".2s ease", style({ height: '0', opacity: 0, transform: 'translateX(20px)', 'box-shadow': 'none' }))
				])
			]),
			transition('void => novo', [
				style({ height: '0', opacity: '0', transform: 'translateX(20px)', 'box-shadow': 'none' }),
				sequence([
					animate(".555s ease", style({ height: '*', background: 'rgba(255, 153, 0, 0.13)', opacity: '.2', transform: 'translateX(20px)', 'box-shadow': 'none' })),
					animate(".666s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)', 'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.3)' }))
				])
			])
		])
	]
})

export class ModalNegocioComponent implements OnInit {

	onChange = onChangeDate;	
	openCalendar = openCalendarDatepicker;

	@ViewChild('anexosComponent') anexosComponent: GerenciadorArquivosComponent;
	@ViewChild('content') content: any;
	moment = Moment;
	errorMessage = MessageHandler.errorMessage;
	visible = false;
	selectable = false;
	removable = false;
	addOnBlur = false;
	separatorKeysCodes: number[] = [ENTER, COMMA];

	diameter = '36';
	color = 'accent';
	carregando: boolean = false;

	userCtrl = new FormControl();
	filteredUsers: Observable<string[]>;
	users = [];
	allUsers:any = [];
	selectedIndex = 0;

	/**
	 * carregandoRamos serve para identificar se ainda está buscando ou se já buscou os ramos.
	 */
	carregandoRamos = false;

	@ViewChild('usersInput') usersInput: ElementRef<HTMLInputElement>;
	@ViewChild('usuariosChip') matAutocomplete: MatAutocomplete;
	@ViewChild('vigIniDate') vigIniDate;
	@ViewChild('prazoDate') prazoDate;
	constantPortugal = ConstantPortugal;
	masksPortugal = ConstantPortugal.maskValidate;
	mask = Mask;
	fabricantes: any[] = FabricanteJson.dados;
	ramosProdutos = [];
	ramos: any;

	openCadastroCliente: boolean = false;
	negocioRecuperar: boolean = false;
	desativarRamo: boolean = false;
	negocioId;
	clienteId;
	objetoCliente;
	nomeCliente = '';
	listaDeClientes = [];

	ativaCadastro = true;
	erroBuscaClientes = false;

	segmento = 'v';
	origem = 3;
	prazo;

	modelo = '';
	modelos = [];
	anoFab = '';
	anoMod;
	anosMod = [];
	placa = '';
	fipe = '';;
	fabricante = '';
	fabricanteId;

	observacao = '';

	regexDate = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/m;

	negocio;
	editarNegocio: boolean = false;

	formCliente: FormGroup;
	formDadosSeguro: FormGroup;
	formSeguroItem: FormGroup;

	descricaoEvento;

	ngxCurrencyOptions = {
		prefix: '',
		suffix : ' €',
		thousands: '.',
		decimal: ',',
		allowNegative: false,
		nullable: true
	};

	userSession: any = {};

	listEvents = [];

	statusModel = new StatusNegocio()
	iconeStatus = 'oportunidade';
	eventoSelecionado = 1;

	routeData:any = '';
	documento:any;
	constructor(private dialog: MatDialog,	
		private apiApice: ApiInterfaceService,
		public fechamentoService: FechamentoService,
		private utilsService: UtilsService,
		private apiVeiculo: ApiVeiculoService,
		private apiUsuario: ApiUsuarioService,
		private apiCotacao: ApiCotacoesService,
		private apiEventos: ApiEventosService,
		private apiNegocio: ApiNegociosService,
		private apiCliente: ApiClienteService,
		private dialogRef: MatDialogRef<ModalNegocioComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private snackBar: MatSnackBar,
		private adapter: DateAdapter<any>,
		public platformService: PlatformService,
		public corretoraService: ApiCorretoraService,
		public icons: RegisterIconsService,
		private router: Router,
		private apiCorretora: ApiCorretoraService,
		public todoController: TodoControllerService) {

		let _icons = ['oportunidade', 'iniciar', 'hospital-box', 'flash-alert', 'car-hatchback',
			'cotacao', 'acompanhamento', 'fechado_concluido', 'fechado', 'account', 'motorbike',
			'negocio_perdido', 'descartado', 'close', 'file-star-outline', 'loading', 'earth',
			'email', 'phone', 'calendar', 'apice', 'pencil', 'calendar-text-outline',
			'file-refresh', 'file-refresh-outline', 'calendar-text-outline2','calculado', 'calculando', ...apiCorretora.iconesRamos];
		icons.registerIcons(_icons);

		this.formCliente = new FormGroup({
			id: new FormControl(''),
			nome: new FormControl(''),
			email: new FormControl('', { validators: [validaEmail] }),
			fone: new FormControl('')
		});

		this.formDadosSeguro = new FormGroup({
			tipoSeguro: new FormControl(1),
			ramo: new FormControl(),
			vigIni: new FormControl('', { validators: [validaDate] }),
			vigInv: new FormControl(''),
			premio: new FormControl(null),
			percComissao: new FormControl(null),
			comissaoEstimada: new FormControl(null)
		})

		this.formSeguroItem = new FormGroup({
			status: new FormControl(1),
			detalhesSeguro: new FormControl(''),
			origemNegocio: new FormControl(1),
			prazo: new FormControl('', { validators: [validaDate] }),
			prazoInv: new FormControl(''),
			segmento: new FormControl(''),
			observacao: new FormControl('')
		})

	
		this.userSession = { id: this.apiUsuario.getCredenciais()?.id, nome:  this.apiUsuario.getCredenciais()?.nome };
		

		if (!this.data.negocio) {
			this.getRamosProdutos();
		}

		if(this.data.route) this.routeData = this.data.route

	}

	dadosFechamento;
	subscriptions = new Subscription();
	ngOnInit() {
		this.adapter.setLocale('pt');
		if (this.data.negocio) {
			this.buscarNegocio(this.data.negocio);
			
			this.subscriptions.add(this.apiNegocio.modalClosed.asObservable().subscribe(ret=>{
				if(ret){
					
					this.dadosFechamento = this.apiNegocio.dadosReturn;
					if(this.dadosFechamento?.fechamento?.premio){
						this.formDadosSeguro.controls.premio.setValue(this.dadosFechamento.fechamento.premio);
						this.changeComissao();
						this.formDadosSeguro.get('vigIni').setValue(this.dadosFechamento.vigenciaIni ? Formatter.dateDDMMAAAA(this.dadosFechamento.vigenciaIni) : this.dadosFechamento.vigenciaIni);
						if(this.dadosFechamento.ramo == 32){
							let dados = this.dadosFechamento.automoveis[0];
							this.formSeguroItem.controls.placa.setValue(dados ? dados.placa : '');
							this.formSeguroItem.controls.anoFab.setValue(dados ? parseInt(dados.anoFabricacao) : '');
							this.formSeguroItem.controls.anoMod.setValue(dados ? parseInt(dados.anoModelo): '');
							this.formSeguroItem.controls.descricao.setValue(dados ? dados.descricao : '');
							this.formSeguroItem.controls.fabricante.setValue(dados ? dados.fabricante : '');
							this.formSeguroItem.controls.fipe.setValue(dados ? dados.fipe : '');
							this.formSeguroItem.get('segmento').setValue(dados.tipo);
							this.changeAnoFab(dados ? dados.anoFabricacao : '');
						}						
					}
					if(this.dadosFechamento?.fechamento?.comissao){
						this.formDadosSeguro.controls.percComissao.setValue(this.dadosFechamento.fechamento.comissao);
						this.changeComissao();
					}					
				}
			}))

		}else{
			this.getUsuarios();
		}


		this.filteredUsers = this.userCtrl.valueChanges.pipe(
			startWith(null),
			map((user: any) => {				
				if (user) {
					return this._filter(user)
				} else {
					return this.allUsers.slice()
				}
			}
			));

		if (!this.data.negocio) {
			this.userCtrl.disable();
		} else {
			this.visible = true;
			this.selectable = true;
			this.removable = true;
			this.addOnBlur = true;
		}
	}

	ngAfterContentInit() {
		if (this.negocio) {
			setTimeout(() => {
				this.vigIniDate.nativeElement.value = this.formDadosSeguro.get('vigIni').value;
				this.prazoDate.nativeElement.value = this.formSeguroItem.get('prazo').value;
			})
		}
	}

	async buscaDocumento(){
		try {
			let doc:any = await this.apiCotacao.buscaDocumento(this.negocio.documentoId);
			doc.segurado = doc?.metadata?.segurado;
			doc.parcelas = doc?.metadata?.parcelas;
			doc.anexos = doc?.url;
			doc.itens = doc?.metadata?.itens;
			doc.premioLiquido = doc?.metadata?.premioLiquido;
			doc.premioTotal = doc?.metadata?.premioTotal;
			this.documento = doc;
			await this.getAllRamos();			
		} catch (error) {
			console.log('erro ao buscar doc', error);
		}
	}

	async getAllRamos() {
		try {
		  this.ramos = await this.apiCorretora.buscarRamos();      
		} catch (e) {
		  console.log('erro ao buscar ramos')
		}
	}

	cotacao;
	async buscaCotacao(negocio){
		if(negocio.ramo == 32 || negocio.ramo == 2 || negocio.ramo == 18 || negocio.ramo == 91){
			if(negocio.cotacao){
				try {
					let cota = await this.apiApice.getNegocio(negocio.cotacao.id);
					this.cotacao = cota;				
				} catch (error) {				
					console.log("Erro ao buscar negócio", error);
					await this.utilsService.createAlert("Atenção", "Erro ao buscar negócio");
				}
				
			}
		}
	}

	async buscarNegocio(id) {
		this.carregando = true;
		try {
			let ret = await this.apiNegocio.buscaNegocio(id);
			let usuarios:any;	
			if (this.apiUsuario.listaUsuarios.length){
				usuarios = this.apiUsuario.listaUsuarios;
			}else{
				usuarios = await this.apiUsuario.buscaUsuarios2();	
				this.apiUsuario.listaUsuarios = usuarios;	
			}
			this.allUsers = usuarios;
			this.negocio = ret;
			this.buscaCotacao(this.negocio);
			this.getRamosProdutos();
			if(this.negocio?.documentoId){
				this.buscaDocumento();
			}

			this.formCliente.get('id').setValue(this.negocio.cliente.id);
			this.formCliente.get('nome').setValue(this.negocio.cliente.nome);
			this.formCliente.get('email').setValue(this.negocio.cliente.email);
			this.formCliente.get('fone').setValue(this.negocio.cliente.fone ? Formatter.formatFone(this.negocio.cliente.fone) : this.negocio.cliente.fone);

			this.formDadosSeguro.get('tipoSeguro').setValue(this.negocio.origem);
			this.formDadosSeguro.get('tipoSeguro').disable();

			this.formDadosSeguro.get('vigIni').setValue(this.negocio.vigIni ? Formatter.dateDDMMAAAA(this.negocio.vigIni) : this.negocio.vigIni);
			this.formDadosSeguro.get('premio').setValue(this.negocio.premio);
			this.formDadosSeguro.get('percComissao').setValue(this.negocio.percComissao);

			this.changeComissao();

			this.formSeguroItem.get('status').setValue(this.negocio.status);
			this.iconeStatus = this.statusModel.getNameIcons(this.negocio.status);

			this.formSeguroItem.get('detalhesSeguro').setValue(this.negocio.configs.detalhesSeguro || null);
			this.formSeguroItem.get('origemNegocio').setValue(this.negocio.configs.origemNegocio || null);
			this.formSeguroItem.get('prazo').setValue(this.negocio.prazo ? Formatter.dateDDMMAAAA(this.negocio.prazo) : this.negocio.prazo);

			this.formSeguroItem.get('segmento').setValue(this.negocio.segmento);
			this.formSeguroItem.get('observacao').setValue(this.negocio.observacao);

			this.editarNegocio = true;

			let events = this.negocio.eventos;
			if (events.length) {
				let orderEvents = events.sort((a, b) => {
					return a.updatedAt > b.updatedAt ? -1 : a.updatedAt < b.updatedAt ? 1 : 0;
				})
				this.listEvents = orderEvents;

				for (let event of this.listEvents) {
					event.dataLabel = event.tipo == 3 ? this.convertDateEvent(event.dataHoraIni, event.subTipo) : this.convertDateEvent(event.createdAt, event.subTipo);
					if (event.dataEncerramento) {
						event.dataEncLabel = this.convertDateEvent(event.dataEncerramento, event.subTipo);
					}
					event.subTipoLabel = event.subTipo ? getLabelSubTipo(event.subTipo) : '';					
					event.nomeCriador = this.getUserEvent(event.responsavelId);					
				}
			}

			this.getUsuarios();

		} catch (error) {
			console.log('error buscar négocio', error);
		} finally {
			this.carregando = false;
		}
	}

	async getRamosProdutos() {
		this.carregandoRamos = true;
		try {
			let ret: any = await this.corretoraService.buscarListaProdutosRamos();
			if (ret && ret.merge) {
				let found = ret.merge.filter(arrAtivos => arrAtivos.ativoNaCorretora)
				this.ramosProdutos = found;
				this.ordenaRamos(this.ramosProdutos);
				if (this.negocio) {
					await this.formDadosSeguro.get('ramo').setValue(this.negocio.ramo);
					await this.selectRamo(this.negocio.ramo, this.negocio.configs);
					if (((this.negocio.ramo == 32 || this.negocio.ramo == 2 || this.negocio.ramo == 91 || this.negocio.ramo == 18) && this.negocio.cotacao)
						|| (this.negocio.status == 8 || this.negocio.status == 6 || this.negocio.status == 7)) {
						this.formDadosSeguro.get('ramo').disable();
						this.desativarRamo = true;
					}
				}
			}

		} catch (error) {
			//console.log('erro ao buscar ramos/produtos', error);
		} finally {
			this.carregandoRamos = false;
		}
	}

	ordenaRamos(ramos) {
		let newRamosProdutos = [];
		let removeRamosPadroes = ramos.filter(r => r.nome != 'Residencial' && r.nome != 'Vida (Individual)' && r.nome != 'Empresarial' && r.nome != 'Automóvel');
		removeRamosPadroes.sort((a,b) => {
			if (a && b) {
				let nome1 = a.nome
				let nome2 = b.nome
				if (nome1 < nome2) {
					return -1;
				} else if (nome1 > nome2) {
					return 1;
				} else {
					return 0;
				}
			}
			else if (a) {
				return -1;
			} else if (b) {
				return 1;
			} else {
				return 0;
			}
		});

		//console.log(removeRamosPadroes)

		let ramosPadroes = ramos.filter(r => r.nome === 'Residencial' || r.nome === 'Vida (Individual)' || r.nome === 'Empresarial' || r.nome === 'Automóvel');
		ramosPadroes.sort((a,b) => {
			if (a && b) {
				let nome1 = a.nome
				let nome2 = b.nome
				if (nome1 < nome2) {
					return -1;
				} else if (nome1 > nome2) {
					return 1;
				} else {
					return 0;
				}
			}
			else if (a) {
				return -1;
			} else if (b) {
				return 1;
			} else {
				return 0;
			}
		});

		//console.log(ramosPadroes)

		for(let rp of ramosPadroes) {
			newRamosProdutos.push(rp);
		}
		for(let rrp of removeRamosPadroes) {
			newRamosProdutos.push(rrp);
		}
		//console.log(newRamosProdutos);
		return this.ramosProdutos = newRamosProdutos;
	}

	// async getTodosRamosProdutos() {
	// 	this.carregandoRamos = true;
	// 	try {
	// 		let ret:any = await this.corretoraService.buscarTodosProdutosRamos();
	// 		if (ret && ret.produtosPadroes) {
	// 			this.ramosProdutos = ret.produtosPadroes.filter(arr => arr.id == this.negocio.ramo);
	// 		}
	// 		// else{
	// 		// 	this.ramosProdutos = this.ramosService.produtos.filter(arr => arr.id==this.negocio.ramo);
	// 		// }

	// 		await this.formDadosSeguro.get('ramo').setValue(this.negocio.ramo);
	// 		await this.selectRamo(this.negocio.ramo, this.negocio.configs);

	// 	} catch (error) {
	// 		console.log('erro ao buscar ramos/produtos', error);
	// 	} finally {
	// 		this.carregandoRamos = false;
	// 	}
	// }

	cadastroCliente(ev?) {		
		this.openCadastroCliente = !this.openCadastroCliente;		
	}

	buscandoCliente: boolean = false;
	timerDebounceCliente;
	lastNomeCliente;
	lastIdCliente;
	async buscarClientes(ev) {
		let keyCode = ev.keyCode;
		let notSearch = false;
		this.erroBuscaClientes = false;

		let nome = this.formCliente.get('nome').value;

		if (keyCode == 9 || keyCode == 13 || keyCode == 40 ||
			keyCode == 37 || keyCode == 38 || keyCode == 39 || this.lastNomeCliente == nome) {
			notSearch = true;
		}		

		clearTimeout(this.timerDebounceCliente);
		this.timerDebounceCliente = setTimeout(async () => {
			if (nome.length >= 1 && !notSearch) {
				this.clienteSelecionado = false;
				this.buscandoCliente = true;

				this.formCliente.get('id').setValue('');
				try {
					let dadosClientes = await this.apiCliente.buscarClientes(nome);
					let result = dadosClientes.map(dadosCliente => new ClienteModel().deserialize(dadosCliente));
					this.buscandoCliente = false;
					if (!result.length) {
						this.formCliente.get('fone').enable();
						this.formCliente.get('email').enable();

						this.buscandoCliente = false;
						this.listaDeClientes = [];
					} else {
						this.listaDeClientes = result;
						this.formCliente.get('fone').setValue('');
						this.formCliente.get('email').setValue('');
					}
				} catch (error) {
					this.listaDeClientes = [];

					this.erroBuscaClientes = true;
					this.buscandoCliente = false;
					this.formCliente.get('fone').enable();
					this.formCliente.get('email').enable();
				}
			} else {
				if (!nome) {
					this.listaDeClientes = [];
					this.formCliente.get('id').setValue('');
				}
			}
		}, 400);
	}

	clienteSelecionado: boolean = false;
	setDadosCliente(ev) {
		let id = ev.id;
		let nome = ev.nome;
		let fone = ev.fone;
		let email = ev.email;

		this.formCliente.get('nome').setValue(nome);
		this.formCliente.get('id').setValue(id);
		this.formCliente.get('fone').setValue(fone);
		this.formCliente.get('email').setValue(email);

		this.lastNomeCliente = nome;
		this.listaDeClientes = [];
		this.clienteSelecionado = true;
	}

	selectRamo(ev, dados?) {
		if (ev == 32) {
			this.formSeguroItem.addControl('placa', new FormControl(dados ? dados.placa : ''));
			this.formSeguroItem.addControl('anoFab', new FormControl(dados ? parseInt(dados.anoFab) : ''));
			this.formSeguroItem.addControl('anoMod', new FormControl(dados ? parseInt(dados.anoMod): ''));
			this.formSeguroItem.addControl('descricao', new FormControl(dados ? dados.descricao : ''));
			this.formSeguroItem.addControl('fabricante', new FormControl(dados ? dados.fabricante : ''));
			this.formSeguroItem.addControl('fipe', new FormControl(dados ? dados.fipe : ''));
			this.formSeguroItem.get('segmento').setValue('v');
			this.changeAnoFab(dados ? dados.anoFab : '', this.negocio ? this.negocio?.configs : null);
		} else {
			this.formSeguroItem.removeControl('placa');
			this.formSeguroItem.removeControl('anoFab');
			this.formSeguroItem.removeControl('anoMod');
			this.formSeguroItem.removeControl('descricao');
			this.formSeguroItem.removeControl('fabricante');
			this.formSeguroItem.removeControl('fipe');
			this.formSeguroItem.get('segmento').setValue('');
		}
	}

	dataPrazo(ev) {
		if (this.regexDate.test(ev.targetElement.value)) {
			let value = ev.targetElement.value.replace(/\D/g, '');
			let day = value.slice(0, 2);
			let month = value.slice(2, 4);
			let year = value.slice(4);
			this.formSeguroItem.get('prazo').setValue(new Date(year, month - 1, day).toISOString());
		}
	}

	vigIni(ev) {
		let dataAtual = this.moment();
		
		let regex = /^(\d{2})\/(\d{1,2})\/(\d{4})$/;
		if (!ev.match(regex)) {
			this.formSeguroItem.controls.prazo.setValue('');
			return
		}
	
		let vigIni = ev;
		let prazo = this.moment(vigIni, 'DD/MM/YYYY').subtract(10, 'days');
		let diferencaDiasPrazo = prazo.diff(dataAtual, 'days');
		if (diferencaDiasPrazo < 0) {
			let prazoF = dataAtual.format('DD/MM/YYYY');
			this.formSeguroItem.controls.prazo.setValue(prazoF);
		}
		else {
			this.formSeguroItem.controls.prazo.setValue(prazo.format('DD/MM/YYYY'));
		}
	}

	premio: any = "";
	comissao: any = "";
	comissaoEst: any = "";
	changeComissao(ev?) {
		this.premio = this.formDadosSeguro.get('premio').value;
		this.comissao = this.formDadosSeguro.get('percComissao').value;

		if (this.premio && this.comissao) {
			let calc = this.premio * (this.comissao / 100);
			this.formDadosSeguro.get('comissaoEstimada').setValue(calc);
		} else {
			this.formDadosSeguro.get('comissaoEstimada').setValue(null);
		}
	}

	changeComissaoEst(event?) {
		this.comissaoEst = this.formDadosSeguro.get('comissaoEstimada').value;
		this.comissao = (this.comissaoEst / this.premio) * 100;
		this.formDadosSeguro.get('percComissao').setValue(this.comissao);
	}

	changeRamo(ev) {
		this.fabricante = '';
		this.fabricanteId = null;
		this.modelo = '';
		this.modelos = [];
		this.modeloSelecionado = false;
	}

	selectStatus(status: string) {
		this.dadosFechamento = null;
		this.statusModel.setStatus = status;
		this.formSeguroItem.controls.status.setValue(this.statusModel.status);
		this.iconeStatus = this.statusModel.getNameIcons(this.statusModel.status);
		if(this.statusModel.status == 6 || this.statusModel.status == 8){
			// if(this.negocio.ramo == 31 || this.negocio.ramo == 2 || this.negocio.ramo == 18 || this.negocio.ramo == 91){
			this.openFechamentoCallback(this.statusModel.status == 8, undefined, false)
			
		}
		
	}

	buscaPlacaModelo: boolean = false;
	async buscarPlaca(event: Event) {
		let e: any = event;

		let placa = this.formSeguroItem.get('placa').value;
	
		let keyCode = e.keyCode;
		let notSearch = false;

		if (keyCode == 9 || keyCode == 40 || keyCode == 37 || keyCode == 38 || keyCode == 39) {
			notSearch = true;
		}

		if (placa && !notSearch) {
			this.loadBuscaModelo = true;
			this.buscaPlacaModelo = true;
			try {
				let veiculo: any = await this.apiVeiculo.buscaVeiculoPlaca(placa);
				if (veiculo) {
					this.setVeiculoPlaca(veiculo);
					this.loadBuscaModelo = true;
				}

				this.loadBuscaModelo = false;

			} catch (error) {
				this.loadBuscaModelo = false;
				this.buscaPlacaModelo = false;
				//console.log('error get veiculo', error)
			}
		} else {
			if (!placa) {
				//console.log('error get veiculo')
			}

			this.buscaPlacaModelo = false;
		}

	}

	setVeiculoPlaca(veiculo) {
		this.formSeguroItem.get('anoFab').setValue(parseInt(veiculo.anoFab));
		this.formSeguroItem.get('anoMod').setValue(parseInt(veiculo.anoMod));
		this.formSeguroItem.get('descricao').setValue(veiculo.modelo);
		this.formSeguroItem.get('fipe').setValue(veiculo.fipe);
		this.formSeguroItem.get('fabricante').setValue(veiculo.codFabr);
		this.fabricante = veiculo.codFabr;
		this.fabricanteId = veiculo.codFabr;
		this.formSeguroItem.get('segmento').setValue(veiculo.tipoVeic ? veiculo.tipoVeic : 'v');
		this.changeAnoFab(parseInt(veiculo.anoFab), veiculo);
		this.buscaPlacaModelo = false;
	}

	loadBuscaModelo: boolean = false;
	timerDebounce;
	async buscarModelos(event: Event) {
		let e: any = event;

		let modelo = this.formSeguroItem.get('descricao').value;
		let ano = this.formSeguroItem.get('anoMod').value;

		let keyCode = e.keyCode;
		let notSearch = false;

		if (keyCode == 9 || keyCode == 40 || keyCode == 37 || keyCode == 38 || keyCode == 39) {
			notSearch = true;
		}

		//9 tab
		//40 baixo
		//37 esquerda
		//38 cima
		//39 direita
		clearTimeout(this.timerDebounce);
		this.timerDebounce = setTimeout(async () => {
			if (modelo.length > 1 && ano && !notSearch) {
				this.loadBuscaModelo = true;
				// let tipo = this.tipoForm == 'auto' ? 'v' : 'm';
				let tipo = 'v';
				let paramsFipe = {
					ano: ano,
					modelo: modelo,
					tipo: this.formSeguroItem.get('segmento').value
				};

				try {
					let modelos: any = await this.apiVeiculo.buscaVeiculo(paramsFipe.ano, paramsFipe.modelo, paramsFipe.tipo);
					this.modelos = modelos;
					this.loadBuscaModelo = false;
					// console.log(modelos)
				} catch (error) {
					this.modelos = [];
					this.loadBuscaModelo = false;
					//console.log('error get modelos', error)
				}
			} else {
				if (!modelo) {
					this.modelos = [];
				}
			}
		}, 500);
	}

	modeloSelecionado: boolean = false;
	setModelo(modelo) {
		if (modelo) {
			this.modeloSelecionado = true;
			this.formSeguroItem.get('descricao').setValue(modelo.modelo);
			this.formSeguroItem.get('fipe').setValue(modelo.id);
			this.formSeguroItem.get('fabricante').setValue(modelo.fipeFabricanteId);

			this.modelos = [];
		}
	}

	getFabricante(id, tipo) {
		let fabricante = this.fabricantes.filter(f => {
			return f.valor == id && f.tipo == tipo;
		})
		return fabricante.length ? fabricante[0].nome : ''
	}

	anoFabValido: boolean = false;
	changeAnoFab(ev, dados?) {
		let regex = /^\d{4}$/;
		if (dados) {
			this.anosMod = [];
			this.anosMod = [dados?.anoFab, dados?.anoFab+1];
			this.formSeguroItem.get('anoMod').setValue(dados?.anoMod);
			this.anoFabValido = true;
		} else {			
			if (regex.test(ev)) {
				this.anosMod = [ev, ev + 1];
				// let anoMod = this.anosMod.length ? this.anosMod[1] : ev;
				this.formSeguroItem.get('anoMod').setValue(ev);
				this.anoFabValido = true;
			} else {
				this.formSeguroItem.get('anoMod').setValue(null);
				this.anoFabValido = false;
				this.anosMod = [];
			}
		}

	}

	createCpfCnpjMask(cpfCnpj) {
		return Formatter.formatCPF_CNPJ(cpfCnpj) || '';
	}

	createFoneMask(fone) {
		return Formatter.telefone(fone) || '';
	}

	formatterDate(dataNas) {
		if (dataNas) {
			return Formatter.dateDDMMAAAA(dataNas);
		} else {
			return '';
		}
	}

	add(event: MatChipInputEvent): void {	
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;
		
			if ((value || '').trim()) {
				this.users.push(value.trim());
			}
			// Reset the input value
			if (input) {
				input.value = '';
			}

			this.usersInput.nativeElement.value = '';
			this.userCtrl.setValue(null);
		}
	}

	remove(user: string): void {
		if (!this.loadAddRemoveuser) {
			this.removeUsuario(user);
		}
	}

	selected(event: MatAutocompleteSelectedEvent, user): void {
		if (!this.loadAddRemoveuser) {		
			this.addUsuario(user);
		}
	}

	loadAddRemoveuser: boolean = false;
	async addUsuario(user) {
		this.loadAddRemoveuser = true;
		let obj = {
			id: undefined,
			usuarioId: user.id,
			gravacao: true,
			leitura: true,
			versao: undefined,
			calculo: undefined,
			adicaoUsuario: true,
			remocaoUsuario: false,
			negocioId: this.negocio.id
		}
		try {			
			let ret = await this.apiNegocio.salvaNegocioUsuario(obj);
			this.users.push(user);
			this.usersInput.nativeElement.value = '';
			this.userCtrl.setValue(null);		
			this.loadAddRemoveuser = false;
		} catch (error) {
			this.loadAddRemoveuser = false;
			this.usersInput.nativeElement.value = '';
			this.userCtrl.setValue(null);
			this.openMessage('Falha ao adicionar usuário, tente novamente!');
			//console.log('erro ao adicionar usuário ao negócio ', error);
		}
	}

	async removeUsuario(user) {
		this.loadAddRemoveuser = true;
		try {
			let ret = await this.apiNegocio.deletaNegocioUsuario(this.negocio.id, user.id);
			const index = this.users.indexOf(user);
			if (index >= 0) {
				this.users.splice(index, 1);
			}			
			this.loadAddRemoveuser = false;
		} catch (error) {
			this.loadAddRemoveuser = false;
			this.openMessage('Falha ao remover usuário, tente novamente!');
			//console.log('erro ao remover usuário ao negócio ', error);
		}
	}

	private _filter(value: any) {
		const filterValue = value ? value.toLowerCase() : value;
		return this.allUsers.filter(user => {			
			return user.nome.toLowerCase().indexOf(filterValue) === 0
		});
	}

	erroBuscarUsuarios: boolean = false;
	async getUsuarios() {
		this.erroBuscarUsuarios = false;
		this.loadAddRemoveuser = true;		
		let usuarios = this.apiUsuario.listaUsuarios;		
		if (this.negocio) {
			for (let usuario of this.negocio.negocioUsuarios) {
				usuario['id'] = usuario.usuarioId;
				usuario['nome'] = usuario.usuario.nome;
				this.users.push(usuario);
			}
		} else {
			let userSession = await usuarios.filter(u => u.id == this.userSession.id);
			this.users.push(userSession[0]);
		}	
		
		this.allUsers = usuarios;
		this.loadAddRemoveuser = false;		
	}	

	getDadosClienteSalvo(ev) {
		this.openCadastroCliente = false;
		if (ev) {
			this.formCliente.controls.nome.setValue(ev.nome);
			this.formCliente.get('email').setValue(ev.email);
			this.formCliente.get('fone').setValue(ev.fone ? Formatter.formatFone(ev.fone) : ev.fone);
		}
	}

	loadSalvaNegocio: boolean = false;
	async criarSalvarNegocio() {
		this.loadSalvaNegocio = true;

		let cliente = this.formCliente.getRawValue();
		let seguro = this.formDadosSeguro.getRawValue();
		let seguroItem = this.formSeguroItem.getRawValue();


		let configs = {
			placa: seguroItem.placa ? seguroItem.placa.replace(/[^0-9a-zA-Z]+/, '') : null,
			descricao: seguroItem.descricao || null,
			anoFab: seguroItem.anoFab || null,
			anoMod: seguroItem.anoMod || null,
			fabricante: seguroItem.fabricante || null,
			fipe: seguroItem.fipe ? seguroItem.fipe : null,
			detalhesSeguro: seguroItem.detalhesSeguro,
			origemNegocio: seguroItem.origemNegocio
		}

		let obj = {
			id: this.negocio ? this.negocio.id : null,
			ramo: seguro.ramo,
			segmento: seguroItem.segmento || null,
			status: seguroItem.status || 1,
			origem: seguro.tipoSeguro,
			premio: seguro.premio,
			comissao: seguro.comissaoEstimada,
			percComissao: seguro.percComissao ? parseInt(seguro.percComissao) : null,
			tarefa: this.negocio ? this.negocio.tarefa : {},
			prazo: seguroItem.prazo ? Formatter.dateAAAAMMDD(seguroItem.prazo) : null,
			vigIni: seguro.vigIni ? Formatter.dateAAAAMMDD(seguro.vigIni) : null,
			vigFim: null,
			observacao: seguroItem.observacao,
			configs: configs,
			clienteId: cliente.id,
			clienteNome: cliente.nome,
			clienteFone: cliente.fone,
			clienteEmail: cliente.email
		};

		if(this.negocio?.id && this.dadosFechamento){
			obj['fechamento'] = this.dadosFechamento;
			if(this.cotacao){
				obj['dadosCotacao'] = this.dadosFechamento;
			}
		}else if(this.negocio?.id && this.cotacao && !this.dadosFechamento){
			let corretoraId = await this.apiUsuario.getCredenciais()?.corretoraId;
			this.cotacao.corretoraId = corretoraId;
			this.cotacao.negocioId = this.negocio.id
			obj['dadosCotacao'] = this.cotacao;
		}
		debugger
		try {
			let ret = await this.apiNegocio.salvaNegocio(obj, null, null);		
			this.todoController.verificarToDo("criando_negocio");
			setTimeout(() => {
				this.loadSalvaNegocio = false;
				this.dialogRef.close(this.data.negocio ? { atualizar: 'atualizar', negocioId: this.data.negocio } : 'atualizar');
			}, 800)
		} catch (error) {
			this.loadSalvaNegocio = false;
			let msg = this.negocio ? 'Falha ao salvar négocio, tente novamente!' : 'Falha ao criar négocio, tente novamente!';
			this.openMessage(msg);
			//console.log('erro ao criar negócio ', error);
		}

	}

	convertDateEvent(date, subTipo?) {
		if (date) {
			let ret = this.moment(date).format(subTipo ? 'LLL' : 'll');			
			return ret
		} else {
			return ''
		}
	}

	loadEvent: boolean = false;
	async createUpdateEvent(event?) {
		if (this.negocio && this.negocio.id && this.descricaoEvento) {
			this.loadEvent = true;
			let obj = {};
			
			if (event) {
				obj['id'] = event.id;
				obj['descricao'] = this.descricaoEvento;
			} else {
				obj['tipo'] = 2; //anotação
				obj['descricao'] = this.descricaoEvento;
				obj['concluido'] = true;
				obj['negocioId'] = this.negocio.id;
				obj['responsavelId'] = this.apiUsuario.getCredenciais()?.id;
			}
			
			try {
				let ret:any = await this.apiEventos.salvaEvento(obj);
				if (!event) {
					let date = this.convertDateEvent(ret.createdAt);
					ret.dataLabel = date;
					this.listEvents.unshift(ret);
				}
				this.descricaoEvento = '';
				this.loadEvent = false;
				//console.log('ret createUpdateEvent ', ret);
			} catch (error) {
				this.loadEvent = false;
				//console.log('erro ao criar/editar evento ', error);
			}
		}
	}

	@ViewChild('tarefas') tarefasComponent: TarefaComponent;
	editandoTarefa: boolean = false;
	editarTarefa(tarefa) {
		this.eventoSelecionado = 2;
		setTimeout(() => {
			//console.log(this.tarefasComponent);
			if (this.tarefasComponent) {
				this.tarefasComponent.tarefa = tarefa;
				this.tarefasComponent.setFormTarefa();
				this.editandoTarefa = true;

				this.content.nativeElement.scrollTop = 0;
			}
		})
	}

	atualizarEventos(ev) {
		let date = this.convertDateEvent(ev.dataHoraIni, ev.subTipo);
		ev.dataLabel = date;
		ev.subTipoLabel = getLabelSubTipo(ev.subTipo);
		ev.nomeCriador = this.getUserEvent(ev.responsavelId);
		if (this.editandoTarefa) {
			let index = this.listEvents.findIndex(event => event.id == ev.id);
			this.listEvents[index] = ev;
		} else {
			this.listEvents.unshift(ev);
		}
	}

	getUserEvent(id) {
		// debugger
		//console.log(id)
		if (this.allUsers.length) {
			let ret: any = this.allUsers.filter(u => u.id == id);
			return ret.length ? ret[0].nome : ''
		} else {
			return ''
		}
	}

	openMessage(message) {
		this.snackBar.open(message, '', { duration: 4000 });
	}

	stopModal(ev) {
		this.dialogRef.close();
		ev.stopPropagation();
	}

	iniciarCotacao(segmento, ramo, negocio, ev) {
		this.dialogRef.close();
		ev.stopPropagation();
        //ramo 31 => auto        
        let key = {
            'placa' : negocio.configs.placa  || null,
            'modelo': negocio.configs.descricao || null,
            'anoFab': negocio.configs.anoFab || null,
            'anoMod': negocio.configs.anoMod || null,
            'fipe'  : negocio.configs.fipe || null,
			'fabricante'  : negocio.configs.fabricante || null,
			'seguradoId': negocio.clienteId,
            'negocioId' : negocio.id
        }        

		let key2 = {
			'seguradoId': negocio.clienteId,
			'negocioId': negocio.id
		}
        
        if (ramo == 32) {
            if (segmento == 'v') {
                this.router.navigate([`/cotacao/auto/formulario`], { queryParams: key });              
            } else if (segmento == 'm') {
                this.router.navigate([`/cotacao/auto/formulario`], { queryParams: key });
            } else if (segmento == 'c') {
                this.router.navigate([`/cotacao/auto/formulario`], { queryParams: key });
            } else {
                return false;
			}
			
        } /*else if (ramo == 2) {
            this.router.navigate([`/cotacao/residencial/formulario`], { queryParams: key2 });            
		} else if (ramo == 91) {
            this.router.navigate([`/cotacao/vida/formulario`], { queryParams: key2 });            
		} else if (ramo == 18){
            this.router.navigate([`/cotacao/empresarial/formulario`], { queryParams: key2 });            
		} */else {
            //outros ramos ex: residencial, saúde    
            return false
        }
	}

	shadowHeader: boolean = false;
	observeScroll() {
		this.content.nativeElement.addEventListener('scroll', (e) => {
			let scroll = e.target.scrollTop;
			if (scroll > 10) {
				this.shadowHeader = true;
			} else {
				this.shadowHeader = false;
			}
		})
	}

	public _fechamentoS3: any = { fechamento: {}};
	public async openFechamentoCallback(isNegocioFechado?, infosDefault?, desfazerNegocio?) {
		let negocio = this.negocio

		let corretoraId = await this.apiUsuario.getCredenciais()?.corretoraId;
		let config = this._fechamentoS3;
		config.aplicacaoId = 3;
		config.corretoraId = corretoraId;

		let cotacao;
		let ramoCalcula = false;
		let pararFluxo = false;
		let ramoNaoCalculado = false;
		if(negocio.ramo == 32 /*|| negocio.ramo == 2 || negocio.ramo == 18 || negocio.ramo == 91*/){
			if(this.cotacao){			
				cotacao = this.cotacao;
				ramoCalcula = true;				
				cotacao.corretoraId = config.corretoraId;
				cotacao.negocioId = cotacao.negocioId || negocio.id;
				config.negocioIdIntegracao = cotacao.idIntegracao;				
			}else{
				pararFluxo = true;
			}
		}else{
			//demais ramos não é calculado
			ramoNaoCalculado = true;
			negocio.negocioId = negocio.id;
			config.negocioIdIntegracao = negocio.id;
		}
		
		config.path = config.aplicacaoId + "/" + config.corretoraId + "/propostas/";
		if(!pararFluxo){
			let ret = await this.fechamentoService.open(
				ramoCalcula ? cotacao : negocio,
				config,
				isNegocioFechado,
				infosDefault,
				desfazerNegocio,
				ramoNaoCalculado,
				true
				);
				
			if (!ret)
				await this.utilsService.createAlert("Atenção", "Houve um erro ao abrir serviço de fechamento, tente novamente mais tarde. Obrigado");
		}else{
			await this.utilsService.createAlert("Atenção", "Cotação inexistente ou não vinculado ao negócio.");
		}
	
	}

	async verDocumento(){
		if(!this.documento){
			await this.buscaDocumento();
		}
		await this.formatDocumento();
		this.documento.route = 'negocio';
		let dialog = this.dialog.open(ModalDocsAtravesNegocioComponent, {
			data: this.documento,
			minWidth: 300,
			minHeight: 300,
			panelClass: 'modalNegocio'
		});
	}

	formatDocumento(){

		let getIconRamo = (id) => {
			let iconeRetorno = 'help-circle';
			
			if (this.ramos?.produtosPadroes) {
			  let _ramos: any = this.ramos.produtosPadroes;
			  _ramos.forEach(e => {
				if (e.cod === id) {
				  iconeRetorno = e.icone;
				}
			  });
			}
		
			return iconeRetorno;
		}
		
		let getTooltipRamo = (id) => {
			let tooltipRetorno = 'Não informado';    
			if (this.ramos?.produtosPadroes) {
			  let _ramos: any = this.ramos.produtosPadroes;
			  _ramos.forEach(e => {
				if (e.cod === id) {
				  tooltipRetorno = e.nome;
				}
			  });
			}
			return tooltipRetorno;
		}

		this.documento.iconeRamo = getIconRamo(this.documento.ramo);
        this.documento.tooltipRamo = getTooltipRamo(this.documento.ramo);
		
        this.documento.premios = {
          liquido: this.documento.premioLiquido ? this.documento.premioLiquido.toLocaleString('pt-PT', { style: 'currency', currency: 'EUR' }) : '0,00 €',
          total: this.documento.premioTotal ? this.documento.premioTotal.toLocaleString('pt-PT', { style: 'currency', currency: 'EUR' }) : '0,00 €'
        }
        this.documento.segurado.nome = this.documento.segurado.nome.toLowerCase();
        this.documento.segurado.dataNascF = this.documento.segurado.dataNasc ? this.moment(this.documento.segurado.dataNasc).format('DD/MM/YYYY') : null;
        this.documento.segurado.sexoF = this.documento.segurado.sexo && this.documento.segurado.sexo === 'F' ? 'Feminino' : 'Masculino';
        this.documento.datasVig = {
          ini: this.moment(this.documento.dataIniVigencia).format('DD/MM/YYYY'),
          fim: this.moment(this.documento.dataFimVigencia).format('DD/MM/YYYY')
        }
        this.documento.dataEmissaoF = this.documento.dataEmissao ? this.moment(this.documento.dataEmissao).format('DD/MM/YYYY') : '';
        this.documento.dataImportacaoF = this.documento.dataImportacao ? this.moment(this.documento.dataImportacao).format('DD/MM/YYYY') : '';
        this.documento.extras = {
          icone: getIiconeSeguradora(this.documento.seguradora),
          tooltip: getRotuloSeguradora(this.documento.seguradora)
        }
        this.documento.dataPropostaF = this.documento.dataProposta ? this.moment(this.documento.dataProposta).format('DD/MM/YYYY') : '';
        this.documento.parcelas?.forEach(p => {
          if (p.formaPagamento === 'À vista' || p.formaPagamento === 'Débito Em Conta') {
            p.iconFP = 'cash'
          } else if (p.formaPagamento === 'Cartão de Crédito') {
            p.iconFP = 'credit-card-multiple'
          } else if (p.formaPagamento === 'Boleto' || p.formaPagamento === 'BOLETO') {
            p.iconFP = 'barcode'
          }
          p.valorF = p.valor ? p.valor.toLocaleString('en-US', { style: 'currency', currency: 'EUR' }) : '0,00 €',
            p.dataVencimento = p.dtVencimento ? this.moment(p.dtVencimento).format('DD/MM/YYYY') : p.dtVencimento
        });
        this.documento.parcelas?.sort((a, b) => { return a.numero - b.numero; })
	}

	ngOnDestroy(){
		this.subscriptions.unsubscribe();
		document.querySelector('body').classList.remove('tiraScrollBody');
	}

}
