import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';


@Injectable({
  providedIn: 'root'
})
export class UserRootGuard implements CanActivate {

  constructor(private apiUsuario: ApiUsuarioService, private router: Router){}

  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUserRoot();
  }

  private checkUserRoot(){
    if (this.apiUsuario.getCredenciais() && this.apiUsuario.getCredenciais().root) return true
		this.router.navigate(['/dashboard']);
		return false
  }
  
}

