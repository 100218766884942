import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ControllerStatusService } from 'src/app/services/controller-status.service';
import { ApiUsuarioService } from '../services/api-usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class AuthCrmMarketingGuard implements CanActivate {
  constructor(private apiUsuario: ApiUsuarioService, private controllerStatus: ControllerStatusService, private router: Router, private snack: MatSnackBar) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.verificacao(state.url);
  }

  private async verificacao(url){
    let urlCopy:any = url;
    let status = this.verificaStatus();    
    let newUrl = url.split('?');

    if (!status) {
      let temPermissao:any = await this.controllerStatus.verificaPermissoes(newUrl.length > 1 ? newUrl[0] : url);
      if(temPermissao === false) {
        this.router.navigate(['/contratar']);
        this.snack.open('Recurso não disponível no seu plano atual. Atualize seu plano para ter acesso a esta funcionalidade','Ok', {
          duration: 6000,
        });
        return false;
      } else {
        return true;
      }

    } else {
      return true
    }

  }
  
  private verificaStatus() {
    let result : any = this.apiUsuario.verificaLicenca();
    if (result?.prazo >= 0 && !result.periodoTeste) {
      return false;
    }

    return true;
	}
  
}