import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolBarActionsComponent } from './tool-bar-actions.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { PerfilUsuarioModule } from '../perfil-usuario/perfil-usuario.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ListaNotificacoesModule } from 'lista-notificacoes';


@NgModule({
  declarations: [ToolBarActionsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    FormsModule,
    PerfilUsuarioModule,
    MatCheckboxModule,
    MatTooltipModule,
    ListaNotificacoesModule
  ],
  exports: [ToolBarActionsComponent]
})
export class ToolBarActionsModule { }
