<div
    padding
    [ngClass]="['tarefa', isModal ? 'p-0' : 'p-16']">

    <div mat-dialog-title *ngIf="isModal && !visualizar" [ngStyle]="{'display': 'flex',  'align-items': 'center'}">
        <h3 *ngIf="isModal && !visualizar && !concluindo && editando || isModal && !visualizar && !concluindo && tarefa && !editando ">Editar Tarefa</h3>
        <h3 *ngIf="isModal && !visualizar && !concluindo && !editando && !tarefa && negocio || isModal && !visualizar && !concluindo && !tarefa && !editando">Nova Tarefa</h3>

        <button  tabindex="-1" mat-icon-button mat-dialog-close [disabled]="loadingSave" [ngStyle]="{'margin': '4px 4px 4px auto',  'color': '#727272'}">
            <mat-icon [svgIcon]="'close'"></mat-icon>           
            </button>
    </div>

    <div mat-dialog-title *ngIf="isModal && visualizar" [ngStyle]="{'display': 'flex',  'align-items': 'center'}">
        <h3 *ngIf="isModal && visualizar && !concluindo && !status">Tarefa</h3>
        <h3 *ngIf="isModal && visualizar && concluindo && !status">Concluindo a Tarefa</h3>
        <h3 *ngIf="isModal && visualizar && !concluindo && status">Tarefa Concluída</h3>


        <div class="btnOptions">
            <button tabindex="-1" mat-icon-button matTooltip="Ver negócio" (click)="verNegocio();$event.stopPropagation()"  *ngIf="isModal && visualizar && tarefa.negocioId">
                <mat-icon [svgIcon]="'business'"></mat-icon>
            </button>
            <button tabindex="-1" mat-icon-button matTooltip="Adiar/Antecipar" [matMenuTriggerFor]="menuAdiar" (click)="$event.stopPropagation()"  *ngIf="isModal && visualizar && !concluindo && !status">
                <mat-icon [svgIcon]="'clock-outline'"></mat-icon>
            </button>
            <button  tabindex="-1" mat-icon-button matTooltip="Editar" (click)="editarTarefa($event, tarefa)" *ngIf="isModal && visualizar && !concluindo && !status">
                <mat-icon [svgIcon]="'pencil'"></mat-icon>
            </button>
            <button  tabindex="-1" mat-icon-button matTooltip="Opções"[matMenuTriggerFor]="menuOpt" (click)="$event.stopPropagation()" *ngIf="isModal && visualizar && !concluindo"> 
                <mat-icon [svgIcon]="'dots-vertical'"></mat-icon>
            </button>
            <button mat-icon-button mat-dialog-close matTooltip="Fechar" tabindex="-1" >
                <mat-icon [svgIcon]="'close'"></mat-icon>
            </button>
        </div>
        <mat-menu #menuOpt="matMenu">
            <button mat-menu-item (click)="novaTarefa($event, tarefa)" >
                <mat-icon [svgIcon]="'calendar-plus'"></mat-icon>
                Nova Tarefa
            </button>
            <button mat-menu-item  (click)="excluirTarefa(4, $event)" *ngIf="isModal && visualizar && !concluindo && !status">
                <mat-icon [svgIcon]="'delete'"></mat-icon>
                Excluir Tarefa
                <mat-icon 
                    *ngIf="loadingStatus"
                    [svgIcon]="'loading'"
                    [ngClass]="['loading']"
                    [ngStyle]="{'margin-left':'2px'}">
                </mat-icon>
            </button>
            <!-- <button mat-menu-item (click)="novaTarefa($event, tarefa)"  *ngIf="isModal && visualizar && status">
                <mat-icon [svgIcon]="'calendar-check'"></mat-icon>
                Reabrir Tarefa
            </button> -->
        </mat-menu>

        <mat-menu #menuAdiar="matMenu">
                <button mat-menu-item (click)="adiarData(tarefa, 1, $event)" [disabled]="loadingAdiar">
                    <mat-icon [svgIcon]="'clock-outline'"></mat-icon>
                    Para Amanhã
                    <mat-icon
                        *ngIf="tarefa.adiar == 1 && loadingAdiar || tarefaBkp.adiar == 1 && loadingAdiar"
                        [svgIcon]="'loading'"
                        [ngClass]="['loading']"
                        [ngStyle]="{'margin-left':'2px'}">
                    </mat-icon>
                </button>
                <button mat-menu-item (click)="adiarData(tarefa, 2, $event)" [disabled]="loadingAdiar">
                    <mat-icon [svgIcon]="'clock-outline'"></mat-icon>
                    Depois de Amanhã
                    <mat-icon
                        *ngIf="tarefa.adiar == 2 && loadingAdiar || tarefaBkp.adiar == 2 && loadingAdiar"
                        [svgIcon]="'loading'"
                        [ngClass]="['loading']"
                        [ngStyle]="{'margin-left':'2px'}">
                    </mat-icon>
                </button>
                <button mat-menu-item (click)="adiarData(tarefa, 7, $event)" [disabled]="loadingAdiar">
                    <mat-icon [svgIcon]="'clock-outline'"></mat-icon>
                    Próxima Semana
                    <mat-icon
                        *ngIf="tarefa.adiar == 7 && loadingAdiar || tarefaBkp.adiar == 7 && loadingAdiar"
                        [svgIcon]="'loading'"
                        [ngClass]="['loading']"
                        [ngStyle]="{'margin-left':'2px'}">
                    </mat-icon>
                </button>
        </mat-menu>

    </div>

    <div class="tarefa__title" *ngIf="!isModal">
        <span class="fontDefault">{{tarefa ? 'Editar' : 'Nova'}} Tarefa</span>
    </div>

    <div *ngIf="isModal && tarefa" [ngClass]="['mb-16', 'title']">
        <p [ngClass]="['fontHelvetica16 personalData', 'mb-0']"  >{{tarefa ? tarefa.titulo : '' }}</p>

        <span class="fontSmall" *ngIf="tarefa.negocioRamo">{{tarefa ? tarefa.negocioRamo ? tarefa.negocioRamo.nome : '' : '' }}</span>
        <span class="fontSmall" *ngIf="tarefa.negocio">{{tarefa ? tarefa.negocio ? tarefa.negocio.negocioRamo.nome : '' : '' }}</span>
    </div>
    <div *ngIf="isModal && negocio " [ngClass]="['mb-16']">
        <p [ngClass]="['fontHelvetica16', 'mb-0']" *ngIf="negocio.cliente" >{{negocio? negocio.cliente.nome  : ''}}</p>
        <p [ngClass]="['fontHelvetica16', 'mb-0']" *ngIf="negocio.titulo && !negocio.cliente" >{{negocio? negocio.titulo  : ''}}</p>


        <span class="fontSmall" >{{negocio ? negocio.negocio ? negocio.negocio.negocioRamo.nome : negocio.negocioRamo.nome : '' }}</span>
    </div>

    <div class="visualizarTarefa" *ngIf="isModal && visualizar">
        <div class="tipoTarefa mb-16">
            <span class="fontSmall">Tipo da Tarefa</span>
            <div [ngStyle]="{'display': 'flex'}">
                <mat-icon [ngStyle]="{ 'margin-right': '5px','width': '20px',  'height': '20px'}" [svgIcon]="tipoTarefa.icon" *ngIf="tipoTarefa"></mat-icon> 
                <p [ngClass]="['fontHelvetica16 personalData', 'mb-0']"> {{tipoTarefa.name}} </p>
            </div>
        </div>

        <div class="descricaoTarefa mb-16">
            <span class="fontSmall">Descrição da Tarefa</span>
            <p [ngClass]="['fontHelvetica16 personalData', 'mb-0']"> {{formTarefa.controls.descricao.value}} </p>
        </div>

        <div class="dataHora">
            <div>
                <span class="fontSmall">Data</span>
                <p [ngClass]="['fontHelvetica16', 'mt-0']"> 
                    {{formTarefa.controls.dataHoraIni.value }}
                </p>
            </div>
            <div>
                <span class="fontSmall">Hora</span>
                <p [ngClass]="['fontHelvetica16', 'mt-0']"> 
                    {{formTarefa.controls.hora.value}}
                </p>
            </div>

        </div>

    </div>

    <div class="concluindo" *ngIf="isModal && visualizar && concluindo || isModal && visualizar && status">
        <mat-radio-group [ngClass]="['fontHelvetica16', 'check-radio']"
        (change)="mudaStatus()"
        [(ngModel)]="estado" *ngIf="isModal && visualizar && concluindo && !status">
            <mat-radio-button no-padding value="2" >Realizada</mat-radio-button>
            <mat-radio-button no-padding value="3" >Não Realizada</mat-radio-button>
        </mat-radio-group>

        <div class="statusTarefa" *ngIf="isModal && visualizar && !concluindo && status">
            <p [ngClass]="['mt-0']" [ngStyle]="{'display': 'flex',  'align-items': 'center', 'font-size':'16px', 'font-weight':'500'}"  > 
                <mat-icon color="primary"  class="check" [ngStyle]="{ 'margin-right': '5px','width': '20px',  'height': '20px'}" *ngIf = "tarefa.status == 2 || tarefaBkp.status == 2" [svgIcon]="'check'"></mat-icon> 
                <mat-icon  class="cancel" [ngStyle]="{ 'margin-right': '5px','width': '20px',  'height': '20px', 'color': 'red'}" *ngIf = "tarefa.status == 3 || tarefaBkp.status == 3" [svgIcon]="'cancel'"></mat-icon> 

                Tarefa {{tarefa.status == 2 ? 'Realizada' : 'Não Realizada'}}
            </p>
        </div>
        <div class="obsTarefa" *ngIf="isModal && visualizar && !concluindo && status">
            <span class="fontSmall">Observação</span>
            <p [ngClass]="['fontHelvetica16', 'mt-0']" > 
                {{formTarefa.controls.observacaoStatus.value}}
            </p>
        </div>

        <mat-form-field [formGroup]="formTarefa" [ngClass]="['mt-16']" *ngIf="isModal && visualizar && concluindo && !status">
            <mat-label>Observação</mat-label>
            <textarea
                formControlName="observacaoStatus"
                cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                matInput
                placeholder="Adicione uma observação"              
                [ngStyle]="{'width':'100%'}">
            </textarea>
        </mat-form-field>
    </div>
            
    <form [formGroup]="formTarefa" *ngIf="!visualizar">
        <div class="tarefa__row">
            <mat-form-field>
                <mat-label>Tipo de Tarefa</mat-label>
                <mat-select formControlName="subTipo">
                    <mat-option
                        *ngFor="let tipo of tiposTarefa"
                        [value]="tipo.value">
                        <mat-icon [svgIcon]="tipo.icon"></mat-icon>
                        {{ tipo.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="tarefa__row">
            <mat-form-field>
                <mat-label>Título da Tarefa</mat-label>
                <input                   
                    matInput
                    type="text"
                    formControlName="titulo">
            </mat-form-field>
        </div>

        <div class="tarefa__row">
            <mat-form-field>
                <mat-label>Descrição da Tarefa</mat-label>
                <textarea
                    formControlName="descricao"
                    matInput
                    matAutosizeMinRows="0"></textarea>
            </mat-form-field>
        </div>

        <div class="tarefa__row data-hora">
            <mat-form-field>
                <mat-label>
                    <span style="color:red">*</span>
                    Data
                </mat-label>
                <input
                    matInput
                    formControlName="dataHoraIni"
                    [mask]="mask.DD_MM_AAAA"
                    [dropSpecialCharacters]="false"
                    autocomplete="off"
                    inputmode="numeric"
                    debounce-validation="500"
                    [validate-on-keydown]="true">

                <mat-icon
                    matSuffix
                    [svgIcon]="'calendar'"
                    (click)="openCalendar(pickerp1, formTarefa.controls.dataHoraIni, formTarefa.controls.inicioHidden, $event)"></mat-icon>
                <mat-datepicker #pickerp1></mat-datepicker>

                <input
                    [matDatepicker]="pickerp1"
                    (dateChange)="onChange($event, formTarefa.controls.dataHoraIni)"
                    [ngStyle]="{display: 'none'}"
                    formControlName="inicioHidden">

                <mat-error *ngIf="errorMessage(formTarefa.controls.dataHoraIni)">
                    {{errorMessage(formTarefa.controls.dataHoraIni)}}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Hora</mat-label>
                <input
                    matInput
                    pattern="^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"
                    [mask]="mask.HORA"
                    [dropSpecialCharacters]="false"
                    formControlName="hora">
            </mat-form-field>

        </div>
    </form>
    <div
        class="tarefa__row"
        [ngStyle]="{'margin-top':'0'}"
        *ngIf="false">
        <div>
            <button
                mat-button
                (click)="abrirDetalhes()">
                <mat-icon [svgIcon]="openDetalhes ? 'minus' : 'plus'"></mat-icon>
                Detalhes
            </button>
        </div>
        <div
            class="mt-16"
            *ngIf="openDetalhes">
            <div [ngStyle]="{'min-height':'50px'}">
                <span class="fontSmall">
                    Utilizador Responsável -
                    <span class="fontDefault">{{usuarioResponsavel?.nome}}</span>
                </span>
            </div>

            <!-- <button
                    mat-button
                    (click)="getUsuarios()"
                    *ngIf="erroBuscarUsuarios">
                    CARREGAR LISTA DE USUÁRIOS
				</button>
				
                <mat-form-field *ngIf="!erroBuscarUsuarios" class="mt-16">
                    <mat-chip-list #chipList>
                        <mat-chip
                            *ngFor="let user of users"
                            [selectable]="selectable"
                            [removable]="removable || users.length == 1"
                            (removed)="remove(user)">
                            {{user.nome}}
                            <mat-icon
                                matChipRemove
                                *ngIf="removable && users.length > 1">
                                cancel
                            </mat-icon>
                        </mat-chip>
                        <input
                            placeholder="Replicar Tarefa para"
                            #usersInput
                            [formControl]="userCtrl"
                            [matAutocomplete]="usuariosChip"
                            [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #usuariosChip="matAutocomplete">
                        <mat-option
                            *ngFor="let user of filteredUsers | async"
                            [value]="user.nome"
                            (onSelectionChange)="selected($event, user)">
                            {{user.nome}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon
                        *ngIf="loadAddRemoveuser"
                        [ngClass]="['mdi mdi-loading mdi-spin']"
                        [ngStyle]="{'position':'absolute','right':'16px','bottom':'6px'}"></mat-icon>
                </mat-form-field> -->
        </div>
    </div>

    <div
        class="tarefa__actions"
        *ngIf="!isModal">
        <button
            [disabled]="formTarefa.invalid || loadingSave"
            mat-button
            class="def-b-btn"
            (click)="criarEditarTarefa()">
            {{tarefa ? 'SALVAR' : 'INCLUIR'}}
            <mat-icon
                *ngIf="loadingSave"
                [ngClass]="['mdi mdi-loading mdi-spin']"
                [ngStyle]="{'margin-left':'2px'}">
            </mat-icon>
        </button>
    </div>

    <mat-dialog-actions
        class="tarefa__actions"
        [ngStyle]="{'grid-auto-flow':'column'}"
        *ngIf="isModal">
        <button
            *ngIf="isModal && !visualizar || isModal && visualizar && concluindo"
            (click)="close()"
            class="def-w-btn"
            [disabled]="formTarefa.invalid || loadingSave"
            mat-button>
            CANCELAR
        </button>
        <button
            *ngIf="isModal && !visualizar || isModal && visualizar && concluindo"
            [disabled]="formTarefa.invalid || loadingSave"
            mat-button
            class="def-b-btn"
            (click)="criarEditarTarefa()">
            SALVAR
            <mat-icon
                *ngIf="loadingSave"
                [svgIcon]="'loading'"
                [ngClass]="['loading']"
                [ngStyle]="{'margin-left':'2px'}">
            </mat-icon>
        </button>
        <button
            *ngIf="isModal && visualizar && !concluindo && !status"
            class="def-b-btn"
            mat-button
            (click)="concluirTarefa($event, tarefa)" tabindex="-1">
            CONCLUIR TAREFA
            <mat-icon [svgIcon]="'check'" [ngStyle]="{'margin':'0px 0px 10px 2px'}">
        </mat-icon>
    </button>
    </mat-dialog-actions>

</div>
