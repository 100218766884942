import { Component, OnInit, Input } from '@angular/core';
import { fabButtonsAnimation } from 'src/app/utils/animations';
import { RegisterIconsService } from 'src/app/services/register-icons.service';
import { ControllerStatusService } from 'src/app/services/controller-status.service';
import { ApiUsuarioService } from 'src/app/services/api-usuario.service';

@Component({
	selector: 'fab-buttons',
	templateUrl: './fab-button.component.html',
	styleUrls: ['./fab-button.component.scss'],
	animations: [fabButtonsAnimation]
})
export class FabButtonComponent implements OnInit {

	@Input() fabButtons = [];
	buttons = [];
	fabTogglerState = 'inactive';

	constructor(private icons: RegisterIconsService, public controllerStatus: ControllerStatusService, private apiUsuario: ApiUsuarioService) {
		icons.registerIcons(['plus'])
	}

	ngOnInit(): void {
		this.verificaButtons();
	}

	showItems() {
		this.fabTogglerState = 'active';
		//console.log('show buttons', this.fabButtons)
		this.buttons = this.fabButtons;
		setTimeout(() => this.hideItems(), 10000)
	}

	hideItems() {
		this.fabTogglerState = 'inactive';
		this.buttons = [];
	}

	onToggleFab() {
		this.buttons.length ? this.hideItems() : this.showItems();
	}

	verificaButtons() {
		let result : any = this.apiUsuario.verificaLicenca();
		if (result?.prazo >= 0 && !result.periodoTeste) {
			setTimeout(async () => {
				let show = await this.controllerStatus.verificaPermissoesFuncts('novoNegocioFB');
				if(show === false) {
					this.fabButtons = this.fabButtons.filter(b => b.url != '/funil-de-vendas');
				}
			},2000)
		}
    }

}
