import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-form',
  templateUrl: './modal-form.component.html',
  styleUrls: ['./modal-form.component.scss']
})
export class ModalFormComponent implements OnInit {

  idCliente;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.idCliente = this.data.id;    
  }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    document.querySelector('body').classList.remove('tiraScrollBody');
  }

}
