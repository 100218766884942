import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalTarefaComponent } from './modal-tarefa.component';
import { TarefaModule } from '../tarefa.module';



@NgModule({
  declarations: [ModalTarefaComponent],
  imports: [
    CommonModule,
    TarefaModule
  ],
  exports: [ModalTarefaComponent]
})
export class ModalTarefaModule { }
