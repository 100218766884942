import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { ApiS3Service } from './api-s3.service';
import { PlatformService } from './platform.service';
import { ApiInfocapebsVindiService } from './api-infocapebs-vindi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FiltroControllerService } from './filtro-controller.service';

@Injectable({
  providedIn: 'root'
})
export class ApiUsuarioService {

  private ENV: any = environment;
  private URL;
  private hashLogin = 'ea496a8fe1b7d512c22029e679f0f2f9';
  private cryptK = 'dfsghjd__FGghf#sgwerShk453troic#5';
  private assinaturaId;
  private credenciais;
  private credenciaisPDocs;
  forceRefresh: boolean = false;
  deslogandoSessao: BehaviorSubject<boolean>;
  avatar;
  avatares = {};
  listaUsuarios = [];

  autenticado: BehaviorSubject<boolean>;
  statusPagamento: BehaviorSubject<boolean>;
  refreshListaTarefas: BehaviorSubject<boolean>;


  pathHistory = '/dashboard';

  constructor(private http: HttpClient, private BS: BaseService, private plataform: PlatformService, private apiEbs: ApiInfocapebsVindiService, private snack: MatSnackBar, public filtroController: FiltroControllerService) {
    this.URL = this.ENV.apice.url;
    this.autenticado = new BehaviorSubject<boolean>(false);
    this.statusPagamento = new BehaviorSubject<boolean>(false);
    this.deslogandoSessao = new BehaviorSubject<boolean>(false);
    this.refreshListaTarefas = new BehaviorSubject<boolean>(false);

    this.plataform.deviceType = this.plataform.deviceType != 'desktop' ? 'mobile' : this.plataform.deviceType;
  }

  login(body) {
    return this.http.post(this.URL + `usuario/login?device=${this.plataform.deviceType}`, body, this.BS.getHeaders()).toPromise();
  }

  loginRoot(body) {
    return this.http.post(this.URL + 'usuario/acessaUsuarioCliente', body, this.BS.getHeaders(true)).toPromise();
  }

  refreshToken(body) {
    return this.http.post(this.URL + `usuario/login?device=${this.plataform.deviceType}`, body, this.BS.getHeaders());
  }

  redefinirSenha(body) {
    return this.http.post(this.URL + 'usuario/redefinir_senha/mudar_senha', body, this.BS.getHeaders()).toPromise();
  }

  solicitaRedefinirSenhaPorEmail(email): Promise<any> {
    return this.http.post(this.URL + 'usuario/solicita_redefinir_senha', { email: email }, this.BS.getHeaders()).toPromise();
  }

  verificaUsuario(email: string, usuarioId?) {
    return this.http.get(this.URL + `usuario/verificacao?email=${email}`).toPromise();
  }

  //Salva o login criptografado no armazenamento local.
  armazenaLogin(login) {
    if (login) {
      let loginStr = JSON.stringify(login);
      loginStr = CryptoJS.AES.encrypt(loginStr, this.cryptK).toString();
      localStorage.setItem(this.hashLogin, loginStr);
      this.credenciais = login;
      this.armazenaAssinaturaId(this.credenciais.assinaturaId);
      delete this.credenciais.senha;
      this.listaUsuarios = [];
      this.buscaUsuarios2();
    }
  }

  //BUSCA credenciais do apice
  getCredenciais() {
    if (this.credenciais) {
      if (!this.autenticado.value) { this.autenticado.next(true) }
      return this.credenciais;
    } else if (localStorage.getItem(this.hashLogin)) {
      let login = localStorage.getItem(this.hashLogin);
      let loginStr = CryptoJS.AES.decrypt(login, this.cryptK).toString(CryptoJS.enc.Utf8);
      if (!this.autenticado.value) { this.autenticado.next(true) }
      return JSON.parse(loginStr);
    } else {
      this.autenticado.next(false);
      return false;
    }
  }

  getCredenciaisCrypto() {
    if (localStorage.getItem(this.hashLogin)) {
      let login = localStorage.getItem(this.hashLogin);
      let loginStr = CryptoJS.AES.decrypt(login, this.cryptK).toString(CryptoJS.enc.Utf8);
      this.autenticado.next(true);
      return JSON.parse(loginStr);
    }
  }

  armazenaAssinaturaId(assinaturaId) {
    if (assinaturaId) {
      this.assinaturaId = assinaturaId;
      localStorage.setItem('assinaturaId', JSON.stringify(assinaturaId));
      this.getAssinaturaId();
    }
  }

  assinaturaUser
  async getAssinaturaId() {
    if (this.assinaturaId && this.assinaturaId.length) {
      try {
        let assinaturaVindi: any = await this.apiEbs.getAssinatura(this.assinaturaId[0].id)
        let dtStartSubscribe = assinaturaVindi?.periodo_atual?.start_at;
        this.assinaturaUser = {
          assinatura: assinaturaVindi,
          dataStartAss: dtStartSubscribe
        }
      } catch (error) {
        console.log(error)
        return error
      }
    } else if (JSON.parse(localStorage.getItem('assinaturaId')) && JSON.parse(localStorage.getItem('assinaturaId')).length) {
      try {
        let assinaturaId: any = JSON.parse(localStorage.getItem('assinaturaId'));
        if (assinaturaId.length) {
          let assinaturaVindi: any = await this.apiEbs.getAssinatura(assinaturaId[0].id)
          let dtStartSubscribe = assinaturaVindi?.periodo_atual?.start_at;
          this.assinaturaUser = {
            assinatura: assinaturaVindi,
            dataStartAss: dtStartSubscribe
          }
        }
      } catch (error) {
        console.log(error)
        return error
      }
    } else {
      this.assinaturaUser = 'Sem assinatura'
    }

    // console.log(this.assinaturaUser)
  }

  putDataCredenciais(nome?, email?) {
    let credenciais = this.getCredenciaisCrypto();
    if (credenciais) {
      credenciais.nome = nome;
      credenciais.email = email;
      this.armazenaLogin(credenciais);
    }
  }

  deslogar() {
    this.credenciais = this.getCredenciais();
    if (this.credenciais) {
      this.deslogandoSessao.next(true);
      let idSessao = this.credenciais.idSessao;
      this.deslogaSessao({ idSessao: idSessao, token: this.credenciais.token })
        .then((response) => {
          console.log('apagou sessão')
          localStorage.removeItem(this.hashLogin);
          localStorage.removeItem('assinaturaId');
          this.autenticado.next(false);
          this.credenciais = null;
          this.credenciaisPDocs = null;
          this.assinaturaUser = null;
        })
        .catch((error) => { console.log(error) })
        .finally(() => {
          localStorage.removeItem(this.hashLogin);
          localStorage.removeItem('assinaturaId');
          this.autenticado.next(false);
          this.credenciais = null;
          this.credenciaisPDocs = null;
          this.assinaturaUser = null;
          this.deslogandoSessao.next(false);
        })
      localStorage.removeItem(this.hashLogin);
      localStorage.removeItem('assinaturaId');
      this.autenticado.next(false);
      this.credenciais = null;
      this.credenciaisPDocs = null;
      this.assinaturaUser = null;
    }
    this.listaUsuarios = [];
  }

  deslogaSessao(body) {
    this.filtroController.clearFilterLS();
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': body.token
    };
    let reqOpts = { headers: new HttpHeaders(headers) }
    return this.http.post(this.URL + 'usuario/deslogaSessao', body, reqOpts).toPromise();
  }

  buscaUsuarios() {
    return this.http.get(this.URL + 'usuario/listaUsuarios', this.BS.getHeaders(true)).toPromise();
  }
  promisePending = false;
  intervalGetUsuarios;
  countIntervalUsuarios = 0;
  async buscaUsuarios2(): Promise<any> {

    return new Promise((resolve, reject) => {
      const BUSCA = async () => {
        this.promisePending = true;
        try {
          let ret: any = await this.http.get(this.URL + 'usuario/listaUsuarios', this.BS.getHeaders(true)).toPromise();
          if (ret?.length) {
            this.listaUsuarios = ret;
          } else {
            clearInterval(this.intervalGetUsuarios);
            reject(new Error('Error ao buscar lista usuarios'));
          }
          this.promisePending = false;
          resolve(ret)
        } catch (error) {
          console.log('error ao buscar usuários', error);
          this.promisePending = false;
          reject(error)
        } finally {
          this.promisePending = false;
        }
      }

      if (this.promisePending) {
        this.intervalGetUsuarios = setInterval(() => {
          this.countIntervalUsuarios++;
          if (!this.promisePending) {
            clearInterval(this.intervalGetUsuarios);
            this.countIntervalUsuarios = 0;
            if (this.listaUsuarios?.length) {
              resolve(this.listaUsuarios)
            } else {
              BUSCA();
            }
          }
          if (this.countIntervalUsuarios > 6) {
            clearInterval(this.intervalGetUsuarios);
            this.countIntervalUsuarios = 0;
            reject(new Error('Error ao buscar lista usuarios'));
          }
        }, 1000);
      } else {
        BUSCA();
      }
    })

  }

  /**
   * Autenticação no P-Docs com credenciais do P-Docs.
   */
  autenticaNoPDocs() {
    let milliseconds = new Date().getTime();
    let environment = this.ENV;
    // se está autenticado retorna a autenticação
    if (this.credenciaisPDocs?.expires - milliseconds > environment.pDocs.tempoReautenticacao) {
      return this.credenciaisPDocs;
    }
    // caso contrário autentica novamente e retorna a eutenticação
    else {
      return this.http.post(this.URL + 'usuario/login/pdocs', {}, this.BS.getHeaders(true)).toPromise();
    }
  }

  setCredenciaisPDocs(credenciais) {
    this.credenciaisPDocs = credenciais;
  }

  resetarSenha(usuarioId): Promise<any> {
    return this.http.post(this.URL + 'usuario/redefinir_senha', { usuarioId: usuarioId }, this.BS.getHeaders(true)).toPromise();
  }

  salvaUsuario(usuario): Promise<any> {
    return this.http.post(this.URL + 'usuario/createUpdate', usuario, this.BS.getHeaders(true)).toPromise();
  }

  buscaUsuario(usuarioId?: string): Promise<any> {
    if (!usuarioId) throw new Error('usuarioId não definido.');
    let path = 'usuario' + (usuarioId ? '/' + usuarioId.toString() : '');
    return this.http.get(this.URL + path, this.BS.getHeaders(true)).toPromise();
  }

  confirmaUsuario(confirmacao: string) {
    return this.http.post(this.URL + 'usuario/confirmacao', { chave: confirmacao }, this.BS.getHeaders()).toPromise();
  }

  redefinirPropriaSenha(body) {
    console.log('BODY', body);
    let ret = this.http.post(this.URL + 'usuario/muda_propria_senha', body, this.BS.getHeaders(true)).toPromise();
    let auth: any = this.getCredenciais();
    if (auth) {
      try {
        auth.senha = body.novaSenha;
        console.log('AUTH', auth);
        let retAuth = this.armazenaLogin(auth);
        console.log('Login efetuado', retAuth);

      } catch (error) {
        console.error(error);
      }
    }
    return ret;
  }

  reenviaEmail(body) {
    return this.http.post(this.URL + 'usuario/reenviaEmail', body, this.BS.getHeaders(true)).toPromise();
  }

  verificaLicenca() {
    let sessao = this.getCredenciais();
    let dataAtual = moment().startOf('day');
    let dtVencLicenca = moment(sessao?.licenca);
    let diffDatas = dtVencLicenca.diff(dataAtual, 'days');
    let result = {};
    result['prazo'] = diffDatas;
    result['status'] = sessao?.statusCorretora;
    if (diffDatas >= 0 && sessao.statusCorretora == 0) {
      result['periodoTeste'] = true;
    } else if (diffDatas < 0 && sessao.statusCorretora == 0) {
      result['periodoLimitado'] = true;
    } else {
      result['periodoTeste'] = false;
    }

    return result;
  }

  verificaLicencaParceiro() {
    let sessao = this.getCredenciais();
    let dataAtual = moment().startOf('day');
    // let dtVencLicenca = moment(sessao?.licenca);
    let dtVencLicenca = moment(sessao?.licenca);
    let dataCriacao = moment(moment(sessao?.createdAt).format('L'));

    let diffDatas = dtVencLicenca.diff(dataCriacao, 'days');
    let diffDatasAtual = dtVencLicenca.diff(dataAtual, 'days');
    let result = {};

    /**se diferença de dias entre data criação e dtLicença for igual 10
     * então não foi liberado ainda acesso pela seguradora */
    if (diffDatas == 10) {
      result['periodoTeste'] = true;
    } else if (diffDatasAtual < 0) {
      result['periodoLimitado'] = true;
    } else {
      result['periodoTeste'] = false;
    }

    return result;
  }

  async atualizaKeysPagamento(licenca?) {
    let credenciais = this.getCredenciaisCrypto();
    let assinatura: any
    try {
      assinatura = await this.apiEbs.dadosAssinaturaDBApice();
    } catch (error) {
      console.log(error);
    }
    if (credenciais && credenciais.statusCorretora != 1) {
      credenciais.statusCorretora = 1;
      credenciais.licenca = licenca?.dtVencLicenca;
      credenciais.assinaturaId = assinatura?.assinaturaId.length ? assinatura?.assinaturaId : ''
      this.armazenaLogin(credenciais);
    } else if (credenciais && credenciais.statusCorretora == 1) {
      credenciais.licenca = licenca?.dtVencLicenca;
      credenciais.assinaturaId = assinatura?.assinaturaId.length ? assinatura?.assinaturaId : ''
      this.armazenaLogin(credenciais);
    }
    setTimeout(() => {
      this.autenticado.next(true);
    }, 2000);

  }

  criarTicket(body) {
    if (this.ENV.production) {
      return this.http.post(this.URL + "usuario/criarTicket", body, this.BS.getHeaders(true)).toPromise();
    }
  }

  loginTemporarioMoviDesk() {
    if (this.ENV.production) {
      return this.http.get(this.URL + "usuario/loginMoviDesk", this.BS.getHeaders(true)).toPromise();
    }
  }

}
