import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiUsuarioService } from './api-usuario.service';

declare var AWS: any;

@Injectable({
	providedIn: 'root'
})
export class ApiS3Service {

	private ENV:any = environment;
	private URL;

	avatarUsuarioLogado;
	credenciaisS3;
	constructor(private http: HttpClient, private BS: BaseService, private apiUsuario: ApiUsuarioService) {
		this.URL = this.ENV.apice.url;		
	}

	async getCredenciaisNoS3() {
		if(this.credenciaisS3){
			return this.credenciaisS3
		}
		try {
			let credenciais:any = await this.http.get(this.URL + 'usuario/getCredentials', this.BS.getHeaders(true)).toPromise();	
			this.credenciaisS3 = credenciais;	
			return credenciais;
		} catch (error) {
			// console.error(error);
			throw error;
		}
	}

	async getBucketName() {
		let environment = this.ENV;
		let s3Config = environment.s3;
		if (!s3Config || !s3Config.bucketName) {
			throw new Error('Configurações do S3 não definidas');
		}
		return s3Config.bucketName;
	}

	async getBucketRegion() {
		let environment = this.ENV;
		let s3Config = environment.s3;
		if (!s3Config || !s3Config.bucketRegion) {
			throw new Error('Configurações do S3 não definidas');
		}
		return s3Config.bucketRegion;
	}

	async isS3Enabled() {
		let environment = this.ENV;
		let s3Config = environment.s3;
		if (!s3Config) {
			throw new Error('Configurações do S3 não definidas');
		}
		return s3Config.enabled;
	}

	async getBaseUrl() {
		let environment = this.ENV;
		let s3Config = environment.s3;
		if (!s3Config || !s3Config.url) {
			throw new Error('Configurações do S3 não definidas');
		}
		return s3Config.url;
	}

	async getS3(prefix: string): Promise<any> {
		let credentials = await this.getCredenciaisNoS3();
		let bucketRegion = await this.getBucketRegion();
		let bucketName = await this.getBucketName();
		AWS.config.update({
			region: bucketRegion,
			credentials: new AWS.Credentials(credentials)
		});
		let s3 = new AWS.S3({
			apiVersion: '2006-03-01',
			params: {
				Prefix: prefix,
				Bucket: bucketName,
				Delimiter: '/'
			}
		});
		return s3;
	}

	async listaObjetos(key: string): Promise<any> {
		let s3: any = await this.getS3(key);
		let bucketName = await this.getBucketName();
		let params = { Bucket: bucketName };
		return new Promise(function (resolve, reject) {
			s3.listObjects(params, function (error: any, data: any) {
				if (error) {
					reject(error);
				}
				else {
					resolve(data);
				}
			});
		});
	}	

	async obTemMetadata(key) {
		let s3: any = await this.getS3(key);
		let bucketName = await this.getBucketName();
		let params = { Bucket: bucketName, Key: key };
		return new Promise(function (resolve, reject) {
			s3.headObject(params, function (err: any, data: any) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	}

	async putObject(key, body?, ACL?) {
		let s3: any = await this.getS3(key);
		let bucketName = await this.getBucketName();
		let params = { Bucket: bucketName, Key: key };
		if (body) { params['Body'] = body; }
		//https://docs.aws.amazon.com/pt_br/AmazonS3/latest/dev/acl-overview.html

		if (body) {
			params['ContentType'] = body.type;
		}

		if (ACL) { params['ACL'] = ACL; }
		let ret = { request: null, result: null };
		ret.request = s3.putObject(params, function (err: any, data: any) {
			ret.result = new Promise(function (resolve2, reject2) {
				if (err) {
					reject2(err);
				} else {
					resolve2(data);
				}
			});
		});

		return ret;
	}


	async renameObject(old_key, key, body?, ACL?) {
		let s3: any = await this.getS3(old_key);
		let bucketName = await this.getBucketName();
		let fileOld = bucketName + '/' + old_key;
		let params = { Bucket: bucketName, CopySource: fileOld, Key: key };

		if (body) { params['Body'] = body; }
		//https://docs.aws.amazon.com/pt_br/AmazonS3/latest/dev/acl-overview.html
		if (ACL) { params['ACL'] = ACL; }

		let ret = { request: null, result: null };
		ret.request = s3.copyObject(params, function (err: any, data: any) {
			ret.result = new Promise(function (resolve2, reject2) {
				if (err) {
					reject2(err);
				} else {
					resolve2(data);
				}
			});
		});
		return ret;
	}

	async deleteObject(key) {
		if (!key) {
			throw new Error('Arquivo não definido');
		}
		let s3: any = await this.getS3(key);
		let bucketName = await this.getBucketName();
		let params = { Bucket: bucketName, Key: key };
		let ret = {
			request: null,
			result: null
		};

		ret.request = s3.deleteObject(params, function (err: any, data: any) {
			ret.result = new Promise(function (resolve, reject) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});

		return ret;
	}

	async uploadAvatarUsuario(usuarioId, imagem) {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais || !credenciais.corretoraId) {
			throw new Error('Usuário não autenticado');
		}
		if (!imagem) {
			throw new Error('Dados não definidos');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		if (!usuarioId) {
			throw new Error('Usuário indefinido');
		}
		var key = 'corretoras/' + encodeURIComponent(corretoraId) + '/usuarios/' + encodeURIComponent(usuarioId) + '/avatar.png';
		//    let key = 'corretoras/' +  encodeURIComponent(corretoraId)
		//    console.log('key', key)
		let obj = await this.putObject(key, imagem, 'public-read');
		return obj;
	}

	async uploadAvatarCorretora(imagem) {		
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais || !credenciais.corretoraId) {
			throw new Error('Usuário não autenticado');
		}
		if (!imagem) {
			throw new Error('Dados não definidos');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		let key = 'corretoras/' + encodeURIComponent(corretoraId) + '/avatar.png';
		let obj = await this.putObject(key, imagem, 'public-read');
		return obj;
	}

	async mountUrl(key) {
		let baseUrl = await this.getBaseUrl();
		let url = baseUrl + key;
		return url;
	}

	async getAvatarCorretora() {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais || !credenciais.id) {
			throw new Error('Usuário não autenticado');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		let baseUrl = await this.getBaseUrl();
		if (!(await this.isS3Enabled())) {
			return 'assets/imgs/corretora.png';
		}
		let urlAvatarCorretora = await this.mountUrl('corretoras/' + encodeURIComponent(corretoraId) + '/avatar.png?val=' + Math.round(Math.random() * 10000));
		return urlAvatarCorretora;
	}

	async getAvatarConfigCorretora(corretoraId) {
		if (!corretoraId) {
			throw new Error('ID da Corretora Indefinido');
		}
		if (!(await this.isS3Enabled())) {
			return 'assets/imgs/corretora.png';
		}
		let urlAvatarCorretora = await this.mountUrl('corretoras/' + encodeURIComponent(corretoraId) + '/avatar.png?val=' + Math.round(Math.random() * 10000));
		return urlAvatarCorretora;
	}

	async getAvatarUsuario(usuarioId) {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais) {
			throw new Error('Usuário não autenticado');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		if (!usuarioId) {
			throw new Error('Usuário indefinido');
		}
		if (!(await this.isS3Enabled())) {
			return 'assets/imgs/usuarioAnonimo.png';
		}
		let time = new Date()
		let urlAvatarUsuario = await this.mountUrl('corretoras/' + encodeURIComponent(corretoraId) + '/usuarios/' + encodeURIComponent(usuarioId) + '/avatar.png?val' + time.getTime());
		return urlAvatarUsuario;
	}

	async loadAvatarUsuarioLogado(update?) {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais || !credenciais.id) {
			throw new Error('Usuário não autenticado');
		}

		if(!this.apiUsuario.avatar || update){			

			return 'assets/imgs/usuarioAnonimo.png';
			
			let urlAvatarUsuario = await this.getAvatarUsuario(credenciais.id);
			this.avatarUsuarioLogado = urlAvatarUsuario;
			if (!(await this.isS3Enabled())) {
				return 'assets/imgs/usuarioAnonimo.png';
			}
			return urlAvatarUsuario;
		}else{
			return this.apiUsuario.avatar;
		}
	}

	async keyAnexoCliente(clienteId, nomeAnexo) {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais) {
			throw new Error('Usuário não autenticado');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		if (!clienteId) {
			throw new Error('Cliente indefinido');
		}
		if (!nomeAnexo) {
			throw new Error('Arquivo indefinido');
		}
		let key = 'corretoras/' + encodeURIComponent(corretoraId) + '/clientes/' + encodeURIComponent(clienteId) + '/anexos/' + nomeAnexo;
		return key;
	}	

	async keyImagemCorretora() {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais) {
			throw new Error('Usuário não autenticado');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		let key = 'corretoras/' + encodeURIComponent(corretoraId) + '/avatar.png';
		return key;
	}	

	async deleteImagemCorretora() {
		let key = await this.keyImagemCorretora();
		return await this.deleteObject(key);
	}

	s3LinkTemporario = async (url, key) => {
		let s3: any = await this.getS3(key);
		const bucket =  await this.getBucketName();	
		let params = {
			"Bucket": bucket,
			"Key": url,
			"Expires": 60 * 20 //tempo de duração do link
		};
	
		return await s3.getSignedUrl('getObject', params);
	};

	async listaAnexos(id, path) {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais) {
			throw new Error('Usuário não autenticado');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		if (!id) {
			throw new Error('Id indefinido');
		}
		var key = 'corretoras/' + encodeURIComponent(corretoraId) + `/${path}/` + encodeURIComponent(id) + '/anexos/';
		let ret = await this.listaObjetos(key);
		
		let thisObj = this;
		
		let anexos = Promise.all(ret.Contents.map(async function (content: any) {			
			let fileName;
			let fileUrl;
			if (content && content.Key) {
				let keyToken = content.Key.split('/');
				if (keyToken.length > 0) {
					fileName = keyToken[keyToken.length - 1];
				}
				fileUrl = await thisObj.s3LinkTemporario(content.Key, key)
			}
			fileName = fileName || 'nome indefinido';
			content.fileName = fileName;
			content.fileUrl = fileUrl;
			return content;
		}));
		
		return anexos;
	}

	async uploadAnexo(id, anexo, nome, path) {
		let key = await this.keyAnexo(id, nome, path);
		// verifica se o objeto já existe
		let metadata;
		try {
			// console.log('Buscando metadados')
			metadata = await this.obTemMetadata(key);
			// console.log('mEWTADADOS', metadata)
		} catch (error) {

			let obj = await this.putObject(key, anexo);
			//console.log('Falha no upload')
			return obj;
		}
		if (metadata) {
			let error = new Error('Já existe arquivo com este nome.');
			error['arquivoExistente'] = true;
			throw error;
		}
	}

	async keyAnexo(id, nomeAnexo, path) {
		let credenciais: any = this.apiUsuario.getCredenciais();
		if (!credenciais) {
			throw new Error('Usuário não autenticado');
		}
		let corretoraId = credenciais.corretoraId;
		if (!corretoraId) {
			throw new Error('Corretora indefinida');
		}
		if (!id) {
			throw new Error('Id indefinido');
		}
		if (!nomeAnexo) {
			throw new Error('Arquivo indefinido');
		}
		let key = 'corretoras/' + encodeURIComponent(corretoraId) + `/${path}/` + encodeURIComponent(id) + '/anexos/' + nomeAnexo;
		return key;
	}

	async deletaAnexo(id, nome, path) {
		let key = await this.keyAnexo(id, nome, path);
		// verifica se o objeto já existe
		await this.deleteObject(key);
	}

	async uploadRenameAnexo(id, anexo, nomeAntigo, nomeNovo, path) {
		let key = await this.keyAnexo(id, nomeNovo, path);
		let keyOld = await this.keyAnexo(id, nomeAntigo, path);

		// verifica se o objeto já existe
		let metadata;
		try {
			metadata = await this.obTemMetadata(key);
		} catch (error) {
			let obj = await this.renameObject(keyOld, key, anexo);
			if (obj) {
				let ret = this.deleteObject(keyOld);
			}
			return obj;
		}
		if (metadata) {
			let error = new Error('Já existe arquivo com este nome.');
			error['arquivoExistente'] = true;
			throw error;
		}
	}

	//INICIO adaptado do multi
	config:any;
	s3: any;
	async setConfig(config) {		
		try {
			this.config = config;

			let userCred: any = await this.getCredenciaisNoS3();
		
			let bucketRegion = await this.getBucketRegion();
			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.Credentials(userCred.accessKeyId, userCred.secretAccessKey, userCred.sessionToken),
				maxRetries: 0
			});

			let bucketName = this.getBucketName();

			this.s3 = new AWS.S3({
				apiVersion: '2006-03-01',
				params: {
					Prefix: this.config.path,
					Bucket: bucketName,
					Delimiter: '/'
				}
			});			
			return true;	
		} catch (error) {			
			throw error
		}
		
	}

	async uploadAnexo2(anexo, nome, cb) { 
		let key = this.config.path + nome;		
		try {
			await this.obTemMetadata(key); // Validar arquivo existente
			// let overwrite = await this.utilsService.createConfirm( "Atenção", "Você já possui um arquivo com este mesmo nome, deseja sobrescrever?");
			// if(overwrite)
				return this.putObject2(key, anexo, 'public-read', cb);
			// return false;
		} catch (error) {
			return this.putObject2(key, anexo, 'public-read', cb);
		}
	}

	async putObject2(key, body, ACL, cb) {
		let bucketName = await this.getBucketName();
		let params = { Bucket: bucketName, Key: key };

		if (body) { 
			params['Body'] = body; 
			params['ContentType'] = body.type;
		}

		if (ACL) 
			params['ACL'] = ACL; 
		
		return this.s3.putObject(params, cb);
	}

	async listaAnexos2(prefix: string) {
		let ret = await this.listaObjetos(prefix);		
		let anexos = ret.Contents.map(async function (content: any) {
			let fileName;
			let fileUrl;
			if (content && content.Key) {
				let keyToken = content.Key.split('/');
				if (keyToken.length > 0) {
					fileName = keyToken[keyToken.length - 1];
				}				
				fileUrl = this.mountUrl(content.Key);
			}
			fileName = fileName || 'Falha ao identificar anexo';
			content.fileName = fileName;
			content.fileUrl = await fileUrl;			
			return content;
		}.bind(this));
			
		return Promise.all(anexos).then(ret => ret)

	}

	//FIM adaptado do multi

}
