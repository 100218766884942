import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor() { }

  getHeaders(check?: boolean, params?) {
    let headers = { 'Content-Type': 'application/json' };
    if (check) headers['check'] = 'true';
    let reqOpts = { headers: new HttpHeaders(headers) }
    if (params) {
      reqOpts['params'] = params;
    }
    return reqOpts;
  }

}
