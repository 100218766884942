import { Injectable } from '@angular/core';
import { ModalFechamentoComponent } from '../components/modal-fechamento/modal-fechamento.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class FechamentoService {

  constructor(public dialog: MatDialog) {
  }

  async open(draft, configS3, isNegocioFechado, infosDefault, desfazerNegocio?, ramoNaoCalculado?, naoSalvar?) {
   
    const dialogRef = this.dialog.open(ModalFechamentoComponent, {
      width: '750px',
      data: {
        "draft": draft,
        "configS3": configS3,
        "isNegocioFechado": isNegocioFechado,
        "infosDefault": infosDefault,
        "desfazerNegocio": desfazerNegocio,
        "ramoNaoCalculado": ramoNaoCalculado,
        "naoSalvar": naoSalvar,
        "close": function () {
          dialogRef.close();
        }
      }
    });

    return true; //modalRef.result;

  }



}
