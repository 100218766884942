import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalVideoIntroComponent } from './modal-video-intro.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ModulosDeFormulario } from 'src/app/utils/formsModules';



@NgModule({
  declarations: [ModalVideoIntroComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ModulosDeFormulario
  ],
  exports: [ModalVideoIntroComponent]
})
export class ModalVideoIntroModule { }
